import { apiSlice } from "../../api/apiSlice";

export const biometricDeduplicationApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Biometric Deduplication",
    "Biometric Deduplication Map",
    "Beneficiaries",
    "BeneficiariesStatus",
    "Beneficiary",
  ],
  endpoints: (builder) => ({
    getAllScores: builder.query({
      query: ({ score, page, pageSize, full_name, ...filters }) => {
        const filterParams = new URLSearchParams(filters).toString();
        const fullNameFilter = full_name
          ? `&filters[full_name]=${full_name}`
          : "";
        console.log("biomerr", page);

        return {
          url: `/deduplication/list?report_type=scores&maximum_score=${score}&per_page=${pageSize}&page=${page}&${filterParams}${fullNameFilter}`,
          method: "GET",
        };
      },
      providesTags: ["Biometric Deduplication"],
      keepUnusedDataFor: 1,
    }),
    getBiometricDuplicateBeneficiaries: builder.query({
      query: ({ score, page, pageSize, isReviewed, ...filters }) => {
        return {
          url: `deduplication/list?report_type=scores&maximum_score=${score}&per_page=${pageSize}&page=${page}&is_reviewed=${isReviewed}`,
          method: "GET",
        };
      },
      providesTags: [
        "Beneficiaries",
        "BeneficiariesStatus",
        "Beneficiary",
        "Biometric Deduplication",
      ],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getOneScore: builder.query({
      query: (id) => ({
        url: `/deduplication/list?report_type=scores&id=${id}`,
        method: "GET",
      }),
      providesTags: ["Biometric Deduplication Map"],
      keepUnusedDataFor: 1,
    }),
    getNoFaces: builder.query({
      query: () =>
        "/deduplication/list?report_type=features&filters[bio_feature]=nofaces",
      providesTags: ["Biometric Deduplication"],
      keepUnusedDataFor: 1,
    }),
    getMultiFaces: builder.query({
      query: () =>
        "/deduplication/list?report_type=features&filters[bio_feature]=multifaces",
      providesTags: ["Biometric Deduplication"],
      keepUnusedDataFor: 1,
    }),
    updateDeduplicationDetails: builder.mutation({
      query: (payload) => ({
        url: `/deduplication/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Biometric Deduplication"],
    }),
    blockBeneficiaryDeduplicationData: builder.mutation({
      query: (payload) => ({
        url: `/deduplication/block/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Biometric Deduplication"],
    }),
    runDeduplicationProcess: builder.mutation({
      query: () => ({
        url: "deduplication/neurotech_biometrics/run",
        method: "GET",
      }),
      invalidatesTags: ["Biometric Deduplication"],
    }),
    checkDeduplicationStatus: builder.mutation({
      query: () => ({
        url: "deduplication/status",
        method: "GET",
      }),
      invalidatesTags: ["Biometric Deduplication"],
    }),
    fetchDeduplicationResults: builder.mutation({
      query: () => ({
        url: "deduplication/fetch_results",
        method: "GET",
      }),
      invalidatesTags: ["Biometric Deduplication"],
    }),
    markBiometricDuplicate: builder.mutation({
      query: (payload) => ({
        url: "/deduplication/biometric/block",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: [
        "Beneficiaries",
        "BeneficiariesStatus",
        "Beneficiary",
        "Biometric Deduplication",
      ],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const {
  useGetAllScoresQuery,
  useGetOneScoreQuery,
  useGetNoFacesQuery,
  useGetMultiFacesQuery,
  useUpdateDeduplicationDetailsMutation,
  useBlockBeneficiaryDeduplicationDataMutation,
  useRunDeduplicationProcessMutation,
  useCheckDeduplicationStatusMutation,
  useFetchDeduplicationResultsMutation,
  useGetBiometricDuplicateBeneficiariesQuery,
  useMarkBiometricDuplicateMutation,
} = biometricDeduplicationApiSlice;
