import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  Box,
  Paper,
  Card,
  CardContent, Avatar,
} from "@mui/material";
import React from "react";
import { tokens } from "../../../theme";
import { useTranslation } from "react-i18next";

const ViewCashDialog = ({ onClose, selectedcashdata, open }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const transaction = JSON.parse(selectedcashdata)[0];

  const handleClose = async (value) => {
    onClose(selectedcashdata);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      selectedcashdata={selectedcashdata}
    >
      <DialogTitle>{t("cash.dialog.transaction_id")}: <br/><strong>{transaction.transaction_id}</strong></DialogTitle>

      <DialogContent>
        <hr />
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gridAutoColumns="600px" gap="20px" padding="20px 0">
          <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" alignItems="flex-start" justifyContent="center">
            <Box
                sx={{
                  display: "flex",
                  "& > :not(style)": {
                    m: 1,
                  },
                }}
            >
              <Paper variant="outlined">
                <Card sx={{ minWidth: 275, minHeight:175 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom fontWeight="800" variant="h5">
                      {t("cash.dialog.verification_photo")}
                    </Typography>
                    <hr />
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Avatar
                          src={transaction?.verification_photo}
                          alt=""
                          variant="rounded"
                          sx={{
                            width: "200px",
                            height: "200px",
                          }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Box>
          </Box>

          <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" alignItems="flex-start" justifyContent="center">
            <Box
                sx={{
                  display: "flex",
                  "& > :not(style)": {
                    m: 1,
                  },
                }}
            >
              <Paper variant="outlined">
                <Card sx={{ minWidth: 275, minHeight:175 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom fontWeight="800" variant="h5">
                      {t("cash.dialog.registration_photo")}
                    </Typography>
                    <hr />
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Avatar
                          src={transaction?.registration_photo}
                          alt=""
                          variant="rounded"
                          sx={{
                            width: "200px",
                            height: "200px",
                          }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Paper>

            </Box>
          </Box>
        </Box>

        <hr />

        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="170px" gridAutoColumns="600px" gap="20px" padding="20px 0">
          
          <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" alignItems="flex-start" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                "& > :not(style)": {
                  m: 1,
                },
              }}
            >
              <Paper variant="outlined">
                {/*beneficiary_details*/}
                <Card sx={{ minWidth: 275, minHeight:175 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom fontWeight="800" variant="h5">
                      {t("cash.dialog.beneficiary_details")}
                    </Typography>
                    <hr />
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("beneficiaries.table.fullname")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.beneficiary?.full_name}
                      </Typography>
                    </Box>

                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("beneficiaries.table.household")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.beneficiary?.household_size}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("beneficiaries.table.pin")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.beneficiary?.pin_code}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("beneficiaries.table.status")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.beneficiary?.status}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("global.table.alternate_redeemed")}
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.alternate_redeemed}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Box>
          </Box>

          <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" alignItems="flex-start" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                "& > :not(style)": {
                  m: 1,
                },
              }}
            >
              <Paper variant="outlined">
                {/*transaction_details*/}
                <Card sx={{ minWidth: 275, minHeight:175 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom fontWeight="800" variant="h5">
                      {t("cash.dialog.transaction_details")}
                    </Typography>
                    <hr />
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.amount")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction.transaction_amount}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.type")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction.transaction_type}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.date")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction.transaction_date}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.status")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction.status}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Box>
          </Box>
        </Box>

        <hr />
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="170px" gridAutoColumns="600px" gap="20px" padding="20px 0">
          
          <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" alignItems="flex-start" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                "& > :not(style)": {
                  m: 1,
                },
              }}
            >
              <Paper variant="outlined">
                {/*intervention_details*/}
                <Card sx={{ minWidth: 275, minHeight:175 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom fontWeight="800" variant="h5">
                      {t("cash.dialog.intervention_details")}
                    </Typography>
                    <hr />
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.intervention_name")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.intervention?.title}
                      </Typography>
                    </Box>

                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("intervention.shared.copay_upfront")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {
                          transaction?.intervention?.copay_upfront === 1 ?
                            t("intervention.form.label.copay_upfront_yes") :
                            t("intervention.form.label.copay_upfront_no")
                        }
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.status")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction.status}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Box>
          </Box>

          <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" alignItems="flex-start" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                "& > :not(style)": {
                  m: 1,
                },
              }}
            >
              <Paper variant="outlined">
                {/*package_details*/}
                <Card sx={{ minWidth: 275, minHeight:175 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom fontWeight="800" variant="h5">
                      {t("cash.dialog.package_details")}
                    </Typography>
                    <hr />
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.code")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.package?.code}
                      </Typography>
                    </Box>

                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.title")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.package?.title}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.copay_amount")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.package?.copay_amount}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.subsidy_amount")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.package?.subsidy_amount}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Box>
          </Box>
        </Box>

        <hr />
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="170px" gridAutoColumns="600px" gap="20px" padding="20px 0">
          <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" alignItems="flex-start" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                "& > :not(style)": {
                  m: 1,
                },
              }}
            >
              <Paper variant="outlined">
                <Card sx={{ minWidth: 275, minHeight:175 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom fontWeight="800" variant="h5">
                      {t("cash.dialog.synced_by")}
                    </Typography>
                    <hr />
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.synced_by_name")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.user?.first_name} {transaction?.user?.last_name}
                      </Typography>
                    </Box>

                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("users.form.label.user_name")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.user?.user_name}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("users.form.label.email")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.user?.email}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("users.form.label.telephone")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.user?.telephone}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Box>
          </Box>

          <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" alignItems="flex-start" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                "& > :not(style)": {
                  m: 1,
                },
              }}
            >
              <Paper variant="outlined">
                <Card sx={{ minWidth: 275, minHeight:175 }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom fontWeight="800" variant="h5">
                      {t("cash.dialog.transaction_metadata")}
                    </Typography>
                    <hr />
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.transaction_app_version")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.transaction_metadata?.app_version}
                      </Typography>
                    </Box>

                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.sync_app_version")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.sync_metadata?.app_version}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.device_id")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.transaction_metadata?.device_id}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.card_id")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.transaction_metadata?.card_id}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.total_card_memory")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.transaction_metadata?.total_card_memory}
                      </Typography>
                    </Box>
                    <Box display="flex" alignContent="center" justifyContent="space-between">
                      <Typography variant="h7" component="div">
                        {t("cash.dialog.available_card_memory")}:
                      </Typography>
                      <Typography variant="h7" component="div">
                        {transaction?.transaction_metadata?.available_card_memory}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Box>
          </Box>
        </Box>

        <hr />

      </DialogContent>
      <DialogActions>
        <button onClick={() => handleClose(false)} autoFocus className="btn btn-danger">
          {t('global.page.close_button')}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewCashDialog;