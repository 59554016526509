//our extended api slice
import { apiSlice } from "../../api/apiSlice";

/* example purchaseOrder:
    "project_code_id": 761,
    "po_number": "8605268",
    "description": "Vendor Name: MADA HOLDINGS UGANDA LIMITED ; Vendor Site: Jinja ; Contract: 8605268 ; Contract Desc: Conference package for 20 pax for 4 days at UGX.123 per pax per day (package includes Venue, 2 x break teas, 2 x drinking water, Buffet lunch) ; Contract Start:  ; Contract End:  ; Duration:  ; Contract Rate: 1 per Curr ; Contract Invoice: 0 ; Contract Amount remaining: 345",
    "loa_number": "123/2023",
    "account_key": "TF.FRUGA.TFAA421021081.5905.ZZZZZ.ZZZZ",
    "actuals": 1612.97,
    "status": 0
} */

export const purchaseOrderApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["PurchaseOrders"],
  endpoints: (builder) => ({
    addOrder: builder.mutation({
      query: (order) => ({
        url: "/purchaseorder/add",
        method: "POST",
        body: { ...order },
      }),
      invalidatesTags: ["PurchaseOrders"],
    }),
    getOrders: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
          const queryString = new URLSearchParams({
              ...apiFilters,
              page: page,
              pageSize: pageSize
          }).toString();

          return {
              url: `/purchaseorder/list/${pageSize}?${queryString}`,
              method: "GET",
          };
      },
      providesTags: ["PurchaseOrders"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getOrderById: builder.query({
      query: (id) => ({
        url: `/purchaseorder/single/${id}`,
        method: "GET",
      }),
    }),
    updateOrder: builder.mutation({
      query: (payload) => ({
        url: `/purchaseorder/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["PurchaseOrders"],
    }),
    deleteOrder: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["PurchaseOrders"],
    }),
  }),
});

export const {
  useAddOrderMutation,
  useGetOrdersQuery,
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
} = purchaseOrderApiSlice;
