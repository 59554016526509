import { apiSlice } from "../../api/apiSlice";

export const reportApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Reports"],
  endpoints: (builder) => ({
    generateReport: builder.mutation({
      query: (payload) => ({
        url: "/reports/export/excel/evoucher_liquidation_report",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Reports"],
    }),

    getReports: builder.query({
      query: ({ page, pageSize }) => ({
        url: `/reports/list/${pageSize}?page=${page}`,
        method: "GET",
      }),
      providesTags: ["Reports"],
      keepUnusedDataFor: 2,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const { useGetReportsQuery, useGenerateReportMutation } = reportApiSlice;
