import { React, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  AlertTitle,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import { MdExpandMore } from "react-icons/md";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";

import Spinner from "../../../global/Spinner";

////
import { useGetBiometricDuplicateBeneficiariesQuery } from "../../../../features/biometric_deduplication/biometricDeduplicationApiSlice";
////
const DuplicatesDataTable = ({
  onPossibleDuplicate,
  onBeneficiarySelect,
  onTabChange,
  filterDuplicates,
}) => {
  const { t } = useTranslation();
  const [score, setScore] = useState("all");
  const [isReviewed, setIsReviewed] = useState(false);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const [value, setValue] = useState("1");
  const [possibleExpanded, setPossibleExpanded] = useState(false);
  const [markedDuplicates, setMarkedDuplicates] = useState([]);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [markedPagination, setMarkedPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const handlePossibleChange = (panel, beneficiary) => (event, isExpanded) => {
    setPossibleExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      onBeneficiarySelect(beneficiary);
      onPossibleDuplicate(undefined);
    } else {
      onBeneficiarySelect(undefined);
      onPossibleDuplicate(undefined);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data: isMarkedDuplicates,
    isLoading: isMarkedDuplicatesLoading,
    isSuccess: isMarkedDuplicatesSuccess,
  } = useGetBiometricDuplicateBeneficiariesQuery({
    score,
    page,
    pageSize,
    isReviewed: 1,
  });

  useEffect(() => {
    if (isMarkedDuplicates?.data) setMarkedDuplicates(isMarkedDuplicates?.data);

    if (isMarkedDuplicates?.meta) {
      setMarkedPagination({
        currentPage: isMarkedDuplicates?.meta?.current_page,
        lastPage: isMarkedDuplicates?.meta?.last_page,
        perPage: isMarkedDuplicates?.meta?.per_page,
        total: isMarkedDuplicates?.meta?.total,
      });
    }
  }, [
    isMarkedDuplicates,
    isMarkedDuplicatesLoading,
    isMarkedDuplicatesSuccess,
    page,
    pageSize,
    markedPagination.currentPage,
  ]);

  const handleDeduplicateView = (duplicate) => {
    onPossibleDuplicate(duplicate);
  };

  const [BiometricDeduplicationData, setBiometricDeduplicationData] = useState(
    []
  );

  const {
    data,
    isLoading,
    isFetchingSuccess,
    isFetching: fetchingBioDedup,
  } = useGetBiometricDuplicateBeneficiariesQuery({
    score,
    page,
    pageSize,
    isReviewed: 0,
  });

  useEffect(() => {
    const sourceData =
      filterDuplicates.length === 0 ? data?.data : filterDuplicates?.data;
    const sourceMeta =
      filterDuplicates.length === 0 ? data?.meta : filterDuplicates?.meta;

    if (sourceData) {
      setBiometricDeduplicationData(sourceData);
      if (sourceMeta) {
        setPagination({
          currentPage: sourceMeta.current_page,
          lastPage: sourceMeta.last_page,
          perPage: sourceMeta.per_page,
          total: sourceMeta.total,
        });
      }
    }
  }, [
    data,
    isFetchingSuccess,
    pagination.currentPage,
    pageSize,
    filterDuplicates,
  ]);

  const handleChangeReview = (index) => {
    index === 1 ? setIsReviewed(false) : setIsReviewed(true);
    onPossibleDuplicate(undefined);
    onTabChange(isReviewed);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      {BiometricDeduplicationData.length === 0 ? (
        <Alert severity="info" sx={{ marginY: "20px" }}>
          <AlertTitle>{t("biometrics.index.biometric_alert_title")}</AlertTitle>
          {t("biometrics.index.biometric_alert_subtitle")}
        </Alert>
      ) : null}
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabChange}
            aria-label={t("sidebar.Beneficiaries")}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              label={t("biometrics.index.possible_duplicates")}
              value="1"
              onClick={() => handleChangeReview(1)}
            />
            <Tab
              label={t("biometrics.index.reviewed_duplicates")}
              value="2"
              onClick={() => handleChangeReview(2)}
            />
          </TabList>
        </Box>
        {isLoading ? (
          <Spinner />
        ) : (
          <TabPanel value="1">
            {/* Search bar goes here */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "79%",
                marginBottom: "10px",
              }}
            >
              <Typography
                sx={{ width: "20%", textAlign: "start", paddingLeft: "24px" }}
              >
                ID
              </Typography>
              <Typography sx={{ width: "20%", textAlign: "center" }}>
                Full Name
              </Typography>
              <Typography sx={{ width: "20%", textAlign: "center" }}>
                Phone Number
              </Typography>
              <Typography sx={{ width: "20%", textAlign: "center" }}>
                Status
              </Typography>
            </Box>
            <div>
              {BiometricDeduplicationData?.map((duplicate) => (
                <Accordion
                  key={duplicate?.id}
                  expanded={possibleExpanded === duplicate?.id}
                  onChange={handlePossibleChange(
                    duplicate?.id,
                    duplicate?.bio_identity_a?.beneficiary_info
                  )}
                >
                  <AccordionSummary
                    expandIcon={<MdExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ borderBottom: "1px solid #CFCFCF" }}
                  >
                    <Typography
                      sx={{
                        width: "20%",
                        flexShrink: 0,
                        textAlign: "start",
                        paddingLeft: "35px",
                      }}
                    >
                      {duplicate?.id}
                    </Typography>
                    <Typography
                      sx={{ width: "20%", flexShrink: 0, textAlign: "center" }}
                    >
                      {duplicate?.bio_identity_a?.beneficiary_info?.full_name}
                    </Typography>
                    <Typography
                      sx={{ width: "20%", flexShrink: 0, textAlign: "center" }}
                    >
                      {duplicate?.bio_identity_a?.beneficiary_info?.telephone}
                    </Typography>
                    <Typography
                      sx={{ width: "20%", flexShrink: 0, textAlign: "center" }}
                    >
                      {duplicate?.bio_identity_a?.beneficiary_info?.status}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        border: "1px solid #CFCFCF",
                        borderRadius: "10px",
                        padding: "10px",
                        marginY: "10px",
                        height: "50px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "80%",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "20%",
                            flexShrink: 0,
                            textAlign: "start",
                          }}
                        >
                          {duplicate?.bio_identity_b?.beneficiary_info?.id}
                        </Typography>
                        <Typography
                          sx={{
                            width: "17%",
                            flexShrink: 0,
                            textAlign: "center",
                            color: "#980000",
                          }}
                        >
                          {
                            duplicate?.bio_identity_b?.beneficiary_info
                              ?.full_name
                          }
                        </Typography>
                        <Typography
                          sx={{
                            width: "20%",
                            flexShrink: 0,
                            textAlign: "center",
                            color: "#980000",
                          }}
                        >
                          {
                            duplicate?.bio_identity_b?.beneficiary_info
                              ?.telephone
                          }
                        </Typography>
                        <Typography
                          sx={{
                            width: "20%",
                            flexShrink: 0,
                            textAlign: "center",
                            color: "#980000",
                          }}
                        >
                          {duplicate?.bio_identity_b?.beneficiary_info?.status}
                        </Typography>
                      </Box>
                      <button
                        className="btn"
                        onClick={() =>
                          handleDeduplicateView(
                            duplicate?.bio_identity_b?.beneficiary_info
                          )
                        }
                      >
                        View
                      </button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            <div>
              <TablePagination
                component="div"
                count={pagination.total}
                page={page === 0 ? page : page - 1}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </TabPanel>
        )}
        <TabPanel value="2">
          {/* search marked */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "79%",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{ width: "20%", textAlign: "start", paddingLeft: "24px" }}
            >
              ID
            </Typography>
            <Typography sx={{ width: "20%", textAlign: "center" }}>
              Full Name
            </Typography>
            <Typography sx={{ width: "20%", textAlign: "center" }}>
              Phone Number
            </Typography>
            <Typography sx={{ width: "20%", textAlign: "center" }}>
              Status
            </Typography>
          </Box>
          <div>
            {markedDuplicates?.map((duplicate) => (
              <Accordion
                key={duplicate?.id}
                expanded={possibleExpanded === duplicate?.id}
                onChange={handlePossibleChange(
                  duplicate?.id,
                  duplicate?.bio_identity_a?.beneficiary_info
                )}
              >
                <AccordionSummary
                  expandIcon={<MdExpandMore />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ borderBottom: "1px solid #CFCFCF" }}
                >
                  <Typography
                    sx={{
                      width: "20%",
                      flexShrink: 0,
                      textAlign: "start",
                      paddingLeft: "35px",
                    }}
                  >
                    {duplicate?.id}
                  </Typography>
                  <Typography
                    sx={{ width: "20%", flexShrink: 0, textAlign: "center" }}
                  >
                    {duplicate?.bio_identity_a?.beneficiary_info?.full_name}
                  </Typography>
                  <Typography
                    sx={{ width: "20%", flexShrink: 0, textAlign: "center" }}
                  >
                    {duplicate?.bio_identity_a?.beneficiary_info?.telephone}
                  </Typography>
                  <Typography
                    sx={{ width: "20%", flexShrink: 0, textAlign: "center" }}
                  >
                    {duplicate?.bio_identity_a?.beneficiary_info?.status}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: "flex",
                      border: "1px solid #CFCFCF",
                      borderRadius: "10px",
                      padding: "10px",
                      marginY: "10px",
                      height: "50px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "20%",
                          flexShrink: 0,
                          textAlign: "start",
                        }}
                      >
                        {duplicate?.bio_identity_b?.beneficiary_info?.id}
                      </Typography>
                      <Typography
                        sx={{
                          width: "17%",
                          flexShrink: 0,
                          textAlign: "center",
                          color: "#980000",
                        }}
                      >
                        {duplicate?.bio_identity_b?.beneficiary_info?.full_name}
                      </Typography>
                      <Typography
                        sx={{
                          width: "20%",
                          flexShrink: 0,
                          textAlign: "center",
                          color: "#980000",
                        }}
                      >
                        {duplicate?.bio_identity_b?.beneficiary_info?.telephone}
                      </Typography>
                      <Typography
                        sx={{
                          width: "20%",
                          flexShrink: 0,
                          textAlign: "center",
                          color: "#980000",
                        }}
                      >
                        {duplicate?.bio_identity_b?.beneficiary_info?.status}
                      </Typography>
                    </Box>
                    <button
                      className="btn"
                      onClick={() =>
                        handleDeduplicateView(
                          duplicate?.bio_identity_b?.beneficiary_info
                        )
                      }
                    >
                      View
                    </button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <div>
            <TablePagination
              component="div"
              count={markedPagination.total}
              page={page === 0 ? page : page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default DuplicatesDataTable;
