import { apiSlice } from "../../api/apiSlice";

export const entitlementCriteriaApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["EntitlementCriteria"],
  endpoints: (builder) => ({
    addEntitlementCriteria: builder.mutation({
      query: (payload) => ({
        url: "/entitlement/criteria/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["EntitlementCriteria"],
    }),
    getEntitlementCriteria: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
          const queryString = new URLSearchParams({
              ...apiFilters,
              page: page,
              pageSize: pageSize
          }).toString();

          return {
              url: `/entitlement/criteria/list/${pageSize}?${queryString}`,
              method: "GET",
          };
      },
      providesTags: ["EntitlementCriteria"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateEntitlementCriteria: builder.mutation({
      query: (payload) => ({
        url: `/entitlement/criteria/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["EntitlementCriteria"],
    }),
    deleteEntitlementCriteria: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["EntitlementCriteria"],
    }),
  }),
});

export const {
  useAddEntitlementCriteriaMutation,
  useGetEntitlementCriteriaQuery,
  useUpdateEntitlementCriteriaMutation,
  useDeleteEntitlementCriteriaMutation,
} = entitlementCriteriaApiSlice;
