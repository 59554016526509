import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
} from "@mui/material";
import React from "react";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const RestoreDialog = ({ onClose, name, open, url, restoreMethod }) => {
  const { t } = useTranslation();
  const handleClose = async (value) => {
    if (value) {
      try {
        const response = await restoreMethod(url);

        if (response.status === 200) {
          Toast(response?.message, "success");
        } else {
          Toast(response?.message, "error");
        }
      } catch (err) {
        Toast(err?.data?.errors, "error");
      }
    }
    onClose(name);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <ToastContainer />
      <DialogTitle>
        {t("global.dialog.restore_heading")} {name}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">
          {t("global.dialog.restore_content")} {name}?{" "}
        </Typography>
      </DialogContent>
      <Box display="flex" gap="10px" justifyContent="end" my="20px">
        <button
          onClick={() => handleClose(true)}
         className="btn btn-primary"
        >
          {t("global.dialog.buttons.restore")}
        </button>
        <button
          onClick={() => handleClose(false)}
          autoFocus
          className="btn btn-danger"
        >
          {t("global.dialog.buttons.dont_restore")}
        </button>
      </Box>
    </Dialog>
  );
};

export default RestoreDialog;
