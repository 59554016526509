import { apiSlice } from "../../api/apiSlice";

export const copayApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["CopayData", "Cash"],
  endpoints: (builder) => ({
    getCopayData: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/copay/list/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["CopayData"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getCashData: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/cash/list/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Cash"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const { useGetCopayDataQuery, useGetCashDataQuery } = copayApiSlice;
