import React from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useAddDonorMutation } from "../../../features/donors/donorsApiSlice";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const DONORADDURL = "/donor/add";

const initialValues = {
  project_id: "",
  title: "",
  description: "",
};

const AddDonor = () => {
  const { t } = useTranslation();
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const donorsSchema = yup.object().shape({
    project_id: yup.string().required(t("global.form.helpertext.project")),
    title: yup.string().required(t("donors.form.helpertext.name")),
    description: yup.string().required(t("donors.form.helpertext.description")),
  });
  const navigate = useNavigate();
  const [addDonor, { isLoading }] = useAddDonorMutation();

  // Fetching projects to add donors
  const { data, isLoading: fetchingProjects } = useGetProjectsQuery({
    page: 1,
    pageSize: default_rows_per_page
  });
  const projects = data?.data;

  const handleFormSubmit = async (values) => {
    try {
      const response = await addDonor(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
        function () {
          navigate("/projects/donors", { state: { message: response?.message } });
        },
        3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("donors.add.title")}
        subtitle={t("donors.add.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects">
          <Typography variant="body2">{t("sidebar.Projects")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects/donors">
          <Typography color="text.primary" variant="body2">
            {t("sidebar.Donors")}
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("donors.index.add_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={donorsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingProjects ? (
                <p>{t("global.loading.project")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.project")}
                  name="project_id"
                  value={values.project_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.project_id && !!errors.project_id}
                  helperText={t("global.form.helpertext.project")}
                >
                    {projects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.code} - {project.title}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("global.form.label.name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={t("donors.form.helpertext.name")}
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={4}
                type="text"
                label={t("global.form.label.description")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={t("donors.form.helpertext.description")}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/projects/donors" underline="none">

              <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("donors.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddDonor;
