import { apiSlice } from "../../api/apiSlice";

export const dynamicFormsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["DynamicForms", "DynamicForm"],
  endpoints: (builder) => ({
    addDynamicForms: builder.mutation({
      query: (payload) => ({
        url: "/dynamic/forms/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["DynamicForms"],
    }),
    getDynamicForms: builder.query({
      query: ({ page, pageSize }) => ({
        url: `/dynamic/forms/list/${pageSize}?page=${page}`,
        method: "GET",
      }),
      providesTags: ["DynamicForms"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getDynamicForm: builder.query({
      query: (id) => ({
        url: `/dynamic/forms/${id}`,
        method: "GET",
      }),
      providesTags: ["DynamicForm"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    deleteDynamicForm: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["DynamicForms"],
    }),
  }),
});

export const {
  useAddDynamicFormsMutation,
  useGetDynamicFormsQuery,
  useGetDynamicFormQuery,
  useDeleteDynamicFormMutation,
} = dynamicFormsApiSlice;
