import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, CardActions, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const Notifications = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const echo = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_WEBSOCKETS_KEY,
      wsHost: process.env.REACT_APP_WEBSOCKETS_SERVER,
      wsPort: process.env.REACT_APP_WEBSOCKETS_PORT,
      forceTLS: false,
      disableStats: true,
      cluster: "mt1",
    });

    echo.channel("jobs").listen("JobStatusEvent", (data) => {
      console.log("Received message:", data);

      setMessages((prevMessages) => [...prevMessages, data.message]); 
    });
    return () => {
      echo.disconnect();
    };
  }, []);

  const content = (
    <Box m="5px">
      <Card sx={{ minWidth: 275 }} variant="contained">
        <CardContent>
          <h3>Notifications</h3>
          <ul>
            {messages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </CardContent>
      </Card>
    </Box>
  );
  return content;
};

export default Notifications;
