import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs, Alert,
} from "@mui/material";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate, Link } from "react-router-dom";
import {
  useGetOrdersQuery,
  useDeleteOrderMutation,
} from "../../../features/purchase_order/purchaseOrderApiSlice";
import Spinner from "../../global/Spinner";
import DeleteDialog from "../shared/DeleteDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const PurchaseOrders = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(permission => permission.name === 'purchase-order-add');
  const canEdit = currentPermissions.some(permission => permission.name === 'purchase-order-update');
  const canDelete = currentPermissions.some(permission => permission.name === 'purchase-order-delete');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
      const apiFilters = {};
      
      filterModel?.forEach(item => {
        if (item.columnField && item.value) {
          apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
        }
      });

      return apiFilters;
  };
  
  const { data, isLoading, isSuccess, isFetching } = useGetOrdersQuery({
    page,
    pageSize,
    ...getApiFilters()
  });

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  useEffect(() => {
    if (data?.data) setPurchaseOrders(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const [deletePurchaseOrder] = useDeleteOrderMutation();

  const handleEdit = (purchaseOrder) => {
    purchaseOrder = JSON.parse(purchaseOrder);
    navigate(`/projects/purchase-orders/edit/${purchaseOrder.id}`, {
      state: { purchaseOrder },
    });
  };

  const handleClickOpen = (purchaseOrder) => {
    setSelectedPurchaseOrder(purchaseOrder);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedPurchaseOrder(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t('global.table.actions'),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => {
        const getPurchaseOrder = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
              {canEdit && (
                <Button
                    color="secondary"
                    onClick={() => handleEdit(getPurchaseOrder())}
                >
                  {t('global.table.edit_button')} <EditOutlinedIcon />
                </Button>
              )}
              {canDelete && (
                <Button
                color="error"
                onClick={() => handleClickOpen(getPurchaseOrder())}
                >
                {t('global.table.delete_button')} <DeleteOutlineOutlinedIcon />
                </Button>
              )}
              </ButtonGroup>


              {openDialog && (
                  <DeleteDialog
                      open={openDialog}
                      onClose={handleClose}
                      name={JSON.parse(selectedPurchaseOrder).po_number}
                      deleteMethod={deletePurchaseOrder}
                      url={`/purchaseorder/delete/${
                          JSON.parse(selectedPurchaseOrder).id
                      }`}
                  />
              )}
            </Box>
        );
      },
    },
    {
      field: "po_number",
      headerName: t('purchase_orders.form.label.po_number'),
      flex: 0.5,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "loa_number",
      headerName: t('purchase_orders.form.label.loa_number'),
      flex: 0.5,
      minWidth: 200
    },
    {
      field: "description",
      headerName: t('global.form.label.description'),
      flex: 1,
      minWidth: 200
    },
    {
      field: "account_key",
      headerName: t('purchase_orders.form.label.account_key'),
      flex: 1,
      minWidth: 200
    },
    {
      field: "expense_account",
      headerName: t('purchase_orders.form.label.expense_account'),
      flex: 0.5,
      minWidth: 100
    },
    {
      field: "actuals",
      headerName: t('purchase_orders.form.label.actuals'),
      flex: 0.5,
      minWidth: 100
    },
    {
      field: "hard_commitments",
      headerName: t('purchase_orders.form.label.hard_commitments'),
      flex: 0.5,
      minWidth: 100
    },
    {
      field: "status",
      headerName: t('purchase_orders.form.label.status'),
      flex: 0.5,
      minWidth: 100,
    }
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header title={t('purchase_orders.index.title')} subtitle={t('purchase_orders.index.subtitle')} />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects">
          <Typography variant="body2">{t('sidebar.Projects')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
        {t('sidebar.Purchase Orders')}
        </Typography>
      </Breadcrumbs>
      {canAdd && (
        <Box display="flex" justifyContent="end" mt="20px">
          <Link to="/projects/purchase-orders/add" underline="none">
            <button className="btn btn-primary">
              {t('purchase_orders.index.add_button')}
            </button>
          </Link>
        </Box>
      )}


      <Box className="data_grid_box_container">
        {purchaseOrders?.length < 1 ? (
            <Alert severity="info">{t("purchase_orders.index.no_purchase_orders_data")}</Alert>
        ) : (
            <StyledDataGrid
                rows={purchaseOrders}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pagination.perPage}
                rowCount={pagination.total}
                onFilterModelChange={handleFilterModelChange}
                loading={isFetching}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={default_rows_per_page_options}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      description: false,
                    },
                  },
                }}
            />
        )}
      </Box>
    </Box>
  );

  return content;
};

export default PurchaseOrders;
