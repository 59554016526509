import React, { useState } from "react";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
  Breadcrumbs,
} from "@mui/material";
import {
  useCreatePaymentMutation,
  useGetPaymentFieldsQuery,
} from "../../../features/payments/paymentsApiSlice";
import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import { useGetPackagesQuery } from "../../../features/packages/packagesApiSlice";
import Header from "../../Header";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const CreatePayment = () => {
  const { t } = useTranslation();
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const navigate = useNavigate();
  const [checkedBenFields, setCheckedBenFields] = useState([]);
  const [checkedPackageFields, setCheckedPackageFields] = useState([]);
  const { data, isLoading } = useGetPaymentFieldsQuery();

  const { data: iData, isLoading: fetchingInterventions } = useGetInterventionsQuery({
    page: 1,
    pageSize: default_rows_per_page
  });
  const interventions = iData?.data;
  const { data: pData, isLoading: fetchingPackages } = useGetPackagesQuery({
    page: 1,
    pageSize: default_rows_per_page,
  });

  const packages = pData?.data;
  const beneficiaryFields = data?.beneficiary_columns;
  const packageFields = data?.package_columns;

  const [createPayment] = useCreatePaymentMutation();

  const handleBeneficiaryCheck = (event) => {
    let updatedList = [...checkedBenFields];
    if (event.target.checked) {
      updatedList = [...checkedBenFields, event.target.value];
    } else {
      updatedList.splice(checkedBenFields.indexOf(event.target.value), 1);
    }
    setCheckedBenFields(updatedList);
  };

  const handlePackageCheck = (event) => {
    let updatedList = [...checkedPackageFields];
    if (event.target.checked) {
      updatedList = [...checkedPackageFields, event.target.value];
    } else {
      updatedList.splice(checkedPackageFields.indexOf(event.target.value), 1);
    }
    setCheckedPackageFields(updatedList);
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      fields: {
        beneficiary: checkedBenFields,
        package: checkedPackageFields,
      },
    };

    try {
      const response = await createPayment(payload).unwrap();

      if (response.status === 200) {
        navigate("/payments");
      } else {
        Toast(response?.message, "error");
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const initialValues = {
    title: "",
    intervention_id: "",
    package_id: "",
    amount: "",
  };

  const paymentSchema = yup.object().shape({
    title: yup.string().required(t('payment.create.missing_title')),
    intervention_id: yup.string().required(t('payment.create.missing_intervention')),
    package_id: yup.string().required(t('payment.create.missing_package')),
    amount: yup.string().required(t('payment.create.missing_amount')),
  });

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
          title={t("payment.create.title")}
          subtitle={t("payment.create.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">{t("sidebar.Payments")}</Typography>
        <Typography color="text.primary" variant="body2">{t("payment.create.create_payment")}</Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={paymentSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("payment.create.name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />
              {fetchingInterventions ? (
                <p>Loading Interventions...</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("payment.create.choose_intervention")}
                  name="intervention_id"
                  value={values.intervention_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.intervention_id && !!errors.intervention_id}
                  helperText={touched.intervention_id && errors.intervention_id}
                >
                  {interventions.map((intervention) => (
                    <MenuItem key={intervention.id} value={intervention.id}>
                      {intervention.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {fetchingPackages ? (
                <p>Loading Packages...</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("payment.create.choose_package")}
                  name="package_id"
                  value={values.package_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.package_id && !!errors.package_id}
                  helperText={touched.package_id && errors.package_id}
                >
                  {packages.map((packageData) => (
                    <MenuItem key={packageData.id} value={packageData.id}>
                      {packageData.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t("payment.create.amount")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={!!touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
              />
            </Box>
            <hr />
            <Box
              display="flex"
              justifyContent="space-around"
              alignContent="space-between"
              alignItems="center"
              justifyItems="center"
              m="20px"
            >
              <Box>
                <Typography variant="h4" color="secondary">
                  {t("payment.create.beneficiary_fields")}
                </Typography>
                <hr />

                {beneficiaryFields.map((field, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span>{field}</span>
                        <input
                          value={field}
                          type="checkbox"
                          onChange={handleBeneficiaryCheck}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              <Box>
                <Typography variant="h4" color="secondary">
                  {t("payment.create.package_fields")}
                </Typography>
                <hr />

                {packageFields.map((field, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span>{field}</span>
                        <input
                          value={field}
                          type="checkbox"
                          onChange={handlePackageCheck}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <hr />
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" size="small">
                {t("payment.create.create_payment")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default CreatePayment;
