import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  useGetBeneficiaryPackageEntriesQuery,
  useGetBeneficiaryCopayEntriesQuery,
  useGetBeneficiaryCashEntriesQuery,
  useGetBeneficiaryEvoucherEntriesQuery,
  useGetBeneficiaryInkindEntriesQuery,
  useGetBeneficiaryQuery,
} from "../../../features/beneficiaries/beneficiariesApiSlice";
import Spinner from "../../global/Spinner";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import BeneficiaryCard from "../shared/BeneficiaryCard";
import PolygonContainer from "../farms/includes/PolygonContainer";

// for the mapping stuff
import L from "leaflet";
import "./leaflet.css";
import icon from "./images/marker-icon.png";
import iconShadow from "./images/marker-shadow.png";
import { getSegment } from "../../../utils/stringUtils";
import { LinkColor } from "../../../theme";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ViewCashDialog from "../cash/ViewCashDialog";
import ViewCopayDialog from "../copay/ViewCopayDialog";
import ViewRedemptionDialog from "../redemptions/ViewRedemptionDialog";
import { useGetCardCashTransactionsQuery } from "../../../features/cards/cardsApiSlice";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;
// end mapping stuff

const ViewBeneficiary = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  // Fetch beneficiary data
  const { data, isLoading } = useGetBeneficiaryQuery(id);
  const beneficiary = data?.data;

  const biometrics =
    beneficiary?.biometrics !== null
      ? t("beneficiaries.view.yes")
      : t("beneficiaries.view.no");

  const alternateBiometrics =
    beneficiary?.alternate?.biometrics !== null
      ? t("beneficiaries.view.yes")
      : t("beneficiaries.view.no");

  const intervention = beneficiary?.intervention ?? {};

  const [packageTransactions, setPackageTransactions] = useState([]);
  const [copayTransactions, setCopayTransactions] = useState([]);
  const [selectedCopayData, setSelectedCopayData] = useState("");
  const [openDialogCopay, setOpenDialogCopay] = useState(false);

  const [cashTransactions, setCashTransactions] = useState([]);
  const [selectedCashData, setSelectedCashData] = useState("");
  const [openDialogCash, setOpenDialogCash] = useState(false);

  const [evoucherTransactions, setEvoucherTransactions] = useState([]);
  const [inkindTransactions, setInkindTransactions] = useState([]);
  const [selectedRedemptionData, setSelectedRedemptionData] = useState("");
  const [openDialogEvoucher, setOpenDialogEvoucher] = useState(false);

  const {
    data: packageEntriesData,
    isLoading: fetchingPackageEntries,
    isSuccess: packageEntriesSuccess,
    isFetching: isFetchingPackageEntries,
  } = useGetBeneficiaryPackageEntriesQuery(id);

  const {
    data: copayEntriesData,
    isLoading: fetchingCopay,
    isSuccess: copaySuccess,
    isFetching: isFetchingCopay,
  } = useGetBeneficiaryCopayEntriesQuery(id);

  const {
    data: cashEntriesData,
    isLoading: fetchingCash,
    isSuccess: cashSuccess,
    isFetching: isFetchingCash,
  } = useGetBeneficiaryCashEntriesQuery(id);

  const {
    data: evoucherEntriesData,
    isLoading: fetchingEvoucher,
    isSuccess: evoucherSuccess,
    isFetching: isFetchingEvoucher,
  } = useGetBeneficiaryEvoucherEntriesQuery(id);

  const {
    data: inkindEntriesData,
    isLoading: fetchingInkind,
    isSuccess: inkindSuccess,
    isFetching: isFetchingInkind,
  } = useGetBeneficiaryInkindEntriesQuery(id);

  useEffect(() => {
    if (packageEntriesData?.data)
      setPackageTransactions(packageEntriesData?.data);
  }, [packageEntriesData]);

  useEffect(() => {
    if (copayEntriesData?.data) setCopayTransactions(copayEntriesData?.data);
  }, [copayEntriesData]);

  useEffect(() => {
    if (cashEntriesData?.data) setCashTransactions(cashEntriesData?.data);
  }, [cashEntriesData]);

  useEffect(() => {
    if (evoucherEntriesData?.data)
      setEvoucherTransactions(evoucherEntriesData?.data);
  }, [evoucherEntriesData]);

  useEffect(() => {
    if (inkindEntriesData?.data) setInkindTransactions(inkindEntriesData?.data);
  }, [inkindEntriesData]);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpenCash = (cash) => {
    const cashData = JSON.parse(cash);
    const transaction = cashTransactions.filter((txn) => {
      return txn.id === cashData.id;
    });

    setSelectedCashData(JSON.stringify(transaction));
    setOpenDialogCash(true);
  };

  const handleCloseCash = (value) => {
    setOpenDialogCash(false);
    setSelectedCashData(value);
  };

  const handleClickOpenCopay = (copay) => {
    const copayData = JSON.parse(copay);
    const transaction = copayTransactions.filter((txn) => {
      return txn.id === copayData.id;
    });

    setSelectedCopayData(JSON.stringify(transaction));
    setOpenDialogCopay(true);
  };

  const handleCloseCopay = (value) => {
    setOpenDialogCopay(false);
    setSelectedCopayData(value);
  };

  const handleClickOpenEvoucher = (redemption) => {
    const redemptionData = JSON.parse(redemption);
    const transaction = evoucherTransactions.filter((txn) => {
      return txn.id === redemptionData.id;
    });

    setSelectedRedemptionData(JSON.stringify(transaction));
    setOpenDialogEvoucher(true);
  };

  const handleCloseEvoucher = (value) => {
    setOpenDialogEvoucher(false);
    setSelectedRedemptionData(value);
  };

  const handleClickOpenInkind = (redemption) => {
    const redemptionData = JSON.parse(redemption);
    const transaction = inkindTransactions.filter((txn) => {
      return txn.id === redemptionData.id;
    });

    setSelectedRedemptionData(JSON.stringify(transaction));
    setOpenDialogEvoucher(true);
  };

  const handleCloseInkind = (value) => {
    setOpenDialogEvoucher(false);
    setSelectedRedemptionData(value);
  };

  const columns = [
    // { field: "id", headerName: t("users.form.label.id") },
    {
      field: "name",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "code",
      headerName: t("packages.form.label.code"),
      flex: 1,
    },
    {
      field: "description",
      headerName: t("global.form.label.description"),
      flex: 1,
    },
    {
      field: "copay_amount",
      headerName: t("packages.form.label.copay_amount"),
      flex: 1,
    },
    {
      field: "subsidy_amount",
      headerName: t("packages.form.label.subsidy_amount"),
      flex: 1,
    },
  ];

  const package_entries_columns = [
    {
      field: "title",
      headerName: t("packages.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "code",
      headerName: t("packages.form.label.code"),
      flex: 1,
    },
    {
      field: "amount",
      headerName: t("entitlements.form.label.amount"),
      flex: 1,
      valueGetter: (params) => params.row?.pivot?.amount,
    },
    {
      field: "copay_paid",
      headerName: t("packages.form.label.copay_paid"),
      flex: 1,
      valueGetter: (params) => params.row?.pivot?.copay_paid,
    },
    {
      field: "subsidy_balance",
      headerName: t("packages.form.label.subsidy_balance"),
      flex: 1,
      valueGetter: (params) => params.row?.pivot?.subsidy_balance,
    },
    {
      field: "transaction_type",
      headerName: t("cash.shared.table.transaction_type"),
      flex: 1,
      valueGetter: (params) => params.row?.pivot?.transaction_type,
    },
  ];

  const intervention_columns = [
    {
      field: "title",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.form.label.description"),
      flex: 1,
    },
    {
      field: "project_code",
      headerName: t("project.form.label.code"),
      flex: 1,
      valueGetter: (params) => {
        const project_code = params?.row?.project?.code;
        return project_code || "";
      },
    },
  ];

  const cash_columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      renderCell: (params) => {
        const getCash = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="warning"
                onClick={() => handleClickOpenCash(getCash())}
              >
                {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
              </Button>
            </ButtonGroup>
            {openDialogCash && (
              <ViewCashDialog
                open={openDialogCash}
                selectedcashdata={selectedCashData}
                onClose={handleCloseCash}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "transaction_id",
      headerName: t("cash.shared.table.transaction_id"),
      flex: 0.5,
    },
    {
      field: "transaction_amount",
      headerName: t("cash.shared.table.amount"),
      flex: 0.5,
    },
    {
      field: "beneficiary",
      headerName: t("cash.shared.table.beneficiary"),
      flex: 1,
      valueGetter: (params) => params.row?.beneficiary?.full_name,
    },
    {
      field: "intervention",
      headerName: t("cash.shared.table.intervention"),
      flex: 1,
      valueGetter: (params) => params.row?.intervention?.title,
    },
    {
      field: "package",
      headerName: t("cash.shared.table.package"),
      flex: 1,
      valueGetter: (params) => params.row?.package?.title,
    },
    {
      field: "service_provider",
      headerName: t("cash.shared.table.service_provider"),
      flex: 1,
      valueGetter: (params) => params.row?.user?.user_name,
    },
    {
      field: "transaction_date",
      headerName: t("cash.shared.table.transaction_date"),
      flex: 1,
    },
  ];

  const copay_columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      renderCell: (params) => {
        const getCash = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="warning"
                onClick={() => handleClickOpenCopay(getCash())}
              >
                {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
              </Button>
            </ButtonGroup>
            {openDialogCopay && (
              <ViewCopayDialog
                open={openDialogCopay}
                selectedcopaydata={selectedCopayData}
                onClose={handleCloseCopay}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "transaction_id",
      headerName: t("cash.shared.table.transaction_id"),
      flex: 0.5,
    },
    {
      field: "transaction_amount",
      headerName: t("cash.shared.table.amount"),
      flex: 0.5,
    },
    {
      field: "beneficiary",
      headerName: t("cash.shared.table.beneficiary"),
      flex: 1,
      valueGetter: (params) => params.row?.beneficiary?.full_name,
    },
    {
      field: "intervention",
      headerName: t("cash.shared.table.intervention"),
      flex: 1,
      valueGetter: (params) => params.row?.intervention?.title,
    },
    {
      field: "package",
      headerName: t("cash.shared.table.package"),
      flex: 1,
      valueGetter: (params) => params.row?.package?.title,
    },
    {
      field: "service_provider",
      headerName: t("cash.shared.table.service_provider"),
      flex: 1,
      valueGetter: (params) => params.row?.user?.user_name,
    },
    {
      field: "transaction_date",
      headerName: t("cash.shared.table.transaction_date"),
      flex: 1,
    },
  ];

  const evoucher_columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      renderCell: (params) => {
        const getRedemption = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="warning"
                onClick={() => handleClickOpenEvoucher(getRedemption())}
              >
                {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
              </Button>
            </ButtonGroup>
            {openDialogEvoucher && (
              <ViewRedemptionDialog
                open={openDialogEvoucher}
                selectedredemptiondata={selectedRedemptionData}
                onClose={handleCloseEvoucher}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "transaction_id",
      headerName: t("cash.shared.table.transaction_id"),
      flex: 0.5,
    },
    {
      field: "grand_total",
      headerName: t("cash.shared.table.amount"),
      flex: 0.5,
    },
    {
      field: "beneficiary",
      headerName: t("cash.shared.table.beneficiary"),
      flex: 1,
      valueGetter: (params) => params.row?.beneficiary?.full_name,
    },
    {
      field: "intervention",
      headerName: t("cash.shared.table.intervention"),
      flex: 1,
      valueGetter: (params) => params.row?.intervention?.title,
    },
    {
      field: "package",
      headerName: t("cash.shared.table.package"),
      flex: 1,
      valueGetter: (params) => params.row?.package?.title,
    },
    {
      field: "service_provider",
      headerName: t("cash.shared.table.service_provider"),
      flex: 1,
      valueGetter: (params) => params.row?.user?.user_name,
    },
    {
      field: "transaction_date",
      headerName: t("cash.shared.table.transaction_date"),
      flex: 1,
    },
  ];

  const inkind_columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      renderCell: (params) => {
        const getRedemption = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="warning"
                onClick={() => handleClickOpenInkind(getRedemption())}
              >
                {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
              </Button>
            </ButtonGroup>
            {openDialogEvoucher && (
              <ViewRedemptionDialog
                open={openDialogEvoucher}
                selectedredemptiondata={selectedRedemptionData}
                onClose={handleCloseInkind}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "transaction_id",
      headerName: t("cash.shared.table.transaction_id"),
      flex: 0.5,
    },
    {
      field: "grand_total",
      headerName: t("cash.shared.table.amount"),
      flex: 0.5,
    },
    {
      field: "beneficiary",
      headerName: t("cash.shared.table.beneficiary"),
      flex: 1,
      valueGetter: (params) => params.row?.beneficiary?.full_name,
    },
    {
      field: "intervention",
      headerName: t("cash.shared.table.intervention"),
      flex: 1,
      valueGetter: (params) => params.row?.intervention?.title,
    },
    {
      field: "package",
      headerName: t("cash.shared.table.package"),
      flex: 1,
      valueGetter: (params) => params.row?.package?.title,
    },
    {
      field: "service_provider",
      headerName: t("cash.shared.table.service_provider"),
      flex: 1,
      valueGetter: (params) => params.row?.user?.user_name,
    },
    {
      field: "transaction_date",
      headerName: t("cash.shared.table.transaction_date"),
      flex: 1,
    },
  ];

  return isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("beneficiaries.view.title")}
        subtitle={t("beneficiaries.view.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} underline="hover" color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link
          style={LinkColor()}
          underline="hover"
          color="inherit"
          to="/beneficiaries"
        >
          {t("sidebar.Beneficiaries")}
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.View Beneficiary")}
        </Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          "& > :not(style)": {
            m: 1,
          },
        }}
      >
        <Paper variant="outlined">
          <BeneficiaryCard beneficiary={beneficiary} />
        </Paper>

        <Paper variant="outlined">
          <Card sx={{ minWidth: 275, height: "100%" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {t("beneficiaries.view.alt_ben")}
              </Typography>
              <hr />
              {beneficiary?.alternate ? (
                <>
                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" component="div">
                      {t("global.form.label.name")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {beneficiary?.alternate?.full_name}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" component="div">
                      {t("beneficiaries.table.unique_id")}:{" "}
                    </Typography>
                    <Typography variant="h6" component="div">
                      {getSegment(beneficiary?.unique_id, "-", -1)}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" component="div">
                      {t("users.form.label.telephone")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {beneficiary?.alternate?.telephone}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" component="div">
                      {t("users.form.label.id_number")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {beneficiary?.alternate?.id_number}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" component="div">
                      {t("beneficiaries.table.dob")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {beneficiary?.alternate?.date_of_birth}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" component="div">
                      {t("beneficiaries.table.pin")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {beneficiary?.alternate?.pin_code}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" component="div">
                      {t("beneficiaries.table.biometrics")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {alternateBiometrics}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" component="div">
                      {t("beneficiaries.table.photo")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      <Avatar
                        src={beneficiary?.alternate?.photo}
                        alt=""
                        variant="rounded"
                        sx={{
                          width: "120px",
                          height: "120px",
                        }}
                      />
                    </Typography>
                  </Box>
                </>
              ) : (
                <Alert severity="info">
                  <AlertTitle>{t("beneficiaries.view.no_alt")}</AlertTitle>
                  <strong>{beneficiary?.full_name}</strong>{" "}
                  {t("beneficiaries.view.has_no_alt")}.
                </Alert>
              )}
            </CardContent>
          </Card>
        </Paper>
      </Box>

      {/* Add tabs for packages and distribution point */}
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label={t("beneficiaries.view.intervention")} value="1" />
              <Tab label={t("beneficiaries.view.package")} value="2" />
              <Tab label={t("sidebar.Cash")} value="3" />
              <Tab label={t("sidebar.Copay")} value="4" />
              <Tab label={t("sidebar.E-Voucher")} value="5" />
              <Tab label={t("sidebar.In-Kind")} value="6" />
              <Tab label={t("beneficiaries.view.map")} value="7" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box className="data_grid_box_container_within_tabs">
              {intervention !== undefined && intervention?.length > 0 ? (
                <StyledDataGrid
                  rows={intervention}
                  columns={intervention_columns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              ) : (
                <Alert severity="info">
                  {t("intervention.index.no_intervention_data")}
                </Alert>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box className="data_grid_box_container_within_tabs">
              {beneficiary?.packages !== undefined &&
              beneficiary?.packages?.length > 0 ? (
                <StyledDataGrid
                  rows={beneficiary?.packages}
                  columns={columns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              ) : (
                <Alert severity="info">
                  {t("packages.index.no_packages_data")}
                </Alert>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Box className="data_grid_box_container_within_tabs">
              {cashTransactions?.length < 1 ? (
                <Alert severity="info">{t("cash.index.no_cash")}</Alert>
              ) : (
                <StyledDataGrid
                  rows={cashTransactions}
                  columns={cash_columns}
                  pagination
                  pageSize={pagination.perPage}
                  rowCount={pagination.total}
                  onPageChange={handlePageChange}
                  loading={isFetchingCash}
                  paginationMode="server"
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>
          <TabPanel value="4">
            <Box className="data_grid_box_container_within_tabs">
              {copayTransactions?.length < 1 ? (
                <Alert severity="info">{t("copay.index.no_copay")}</Alert>
              ) : (
                <StyledDataGrid
                  rows={copayTransactions}
                  columns={copay_columns}
                  pagination
                  paginationMode="server"
                  pageSize={pagination.perPage}
                  rowCount={pagination.total}
                  loading={isFetchingCopay}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>
          <TabPanel value="5">
            <Box className="data_grid_box_container_within_tabs">
              {evoucherTransactions?.length < 1 ? (
                <Alert severity="info">
                  {t("redemption.index.no_redemption")}
                </Alert>
              ) : (
                <StyledDataGrid
                  rows={evoucherTransactions}
                  columns={evoucher_columns}
                  pagination
                  paginationMode="server"
                  pageSize={pagination.perPage}
                  rowCount={pagination.total}
                  loading={isFetchingEvoucher}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>
          <TabPanel value="6">
            <Box className="data_grid_box_container_within_tabs">
              {inkindTransactions?.length < 1 ? (
                <Alert severity="info">{t("redemption.index.no_inkind")}</Alert>
              ) : (
                <StyledDataGrid
                  rows={inkindTransactions}
                  columns={inkind_columns}
                  pagination
                  paginationMode="server"
                  pageSize={pagination.perPage}
                  rowCount={pagination.total}
                  loading={isFetchingEvoucher}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>

          <TabPanel value="7">
            {beneficiary?.plots && beneficiary.plots.length > 0 ? (
              <PolygonContainer
                latitude={beneficiary?.gps_coordinates?.latitude}
                longitude={beneficiary?.gps_coordinates?.longitude}
                beneficiary={beneficiary}
                plots={beneficiary?.plots}
                farms={beneficiary}
              />
            ) : (
              <Alert severity="info">
                <AlertTitle>
                  {" "}
                  <strong> {t("beneficiaries.view.no_map_data")}</strong>
                </AlertTitle>
              </Alert>
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default ViewBeneficiary;
