import { apiSlice } from "../../api/apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Settings", "SettingsGroup"],
  endpoints: (builder) => ({
    fetchProjectsFromGrms: builder.mutation({
      query: (payload) => ({
        url: "/settings/grms/projects",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["ProjectsData", "Donors"],
    }),

    getSettingsGroup: builder.query({
      query: (id) => ({
        url: id ? `/settings/group/single/${id}` : `/settings/group/list`,
        method: "GET",
      }),
      providesTags: ["SettingsGroup"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),

    getAllSettings: builder.query({
      query: () => ({
        url: "/settings/list",
        method: "GET",
      }),
      invalidatesTags: ["Settings"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),

    getGenerateAllSettings: builder.query({
      query: () => ({
        url: "/settings/add/all",
        method: "GET",
      }),
      invalidatesTags: ["Settings"],
    }),

    addSettingsGroup: builder.mutation({
      query: (payload) => ({
        url: "/settings/group/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["SettingsGroup"],
    }),

    addSettings: builder.mutation({
      query: (payload) => ({
        url: "/settings/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Settings"],
    }),

    updateSettingsGroup: builder.mutation({
      query: (payload) => ({
        url: `/settings/group/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["SettingsGroup"],
    }),

    updateSettings: builder.mutation({
      query: (payload) => ({
        url: `/settings/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Settings"],
    }),

    deleteSettingsGroup: builder.mutation({
      query: (urlId) => ({
        url: `/settings/group/delete/${urlId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SettingsGroup"],
    }),

    deleteSettings: builder.mutation({
      query: (urlId) => ({
        url: `/settings/delete/${urlId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Settings"],
    }),
  }),
});

export const {
  useFetchProjectsFromGrmsMutation,
  useGetSettingsGroupQuery,
  useAddSettingsGroupMutation,
  useAddSettingsMutation,
  useUpdateSettingsGroupMutation,
  useUpdateSettingsMutation,
  useDeleteSettingsGroupMutation,
  useDeleteSettingsMutation,
  useGetGenerateAllSettingsQuery,
  useGetAllSettingsQuery,
} = settingsApiSlice;
