import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import * as yup from "yup";

const EditEntitlementDialog = ({
  onClose,
  open,
  packageData,
  entitlementData,
  handleEntitlementEdit,
}) => {
  const { t } = useTranslation();
  const entitlement = JSON.parse(entitlementData);
  const initialValues = {
    max_redeemable: entitlement?.max_redeemable,
    per_season: entitlement?.per_season,
  };

  const entitlementsSchema = yup.object().shape({
    max_redeemable: yup
      .string()
      .required(t("entitlements.form.helpertext.max_redeemable")),
  });

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      per_season: values.per_season === "yes" ? true : false,
      package_id: packageData.id,
      entitlement_id: entitlement.id,
    };

    await handleEntitlementEdit(payload);
    onClose(packageData);
  };
  const content = (
    <Dialog open={open}>
      <DialogTitle>
        {t("packages.add_entitlements.edit")}{" "}
        <b>{entitlement.title}</b>{" "}{t('entitlements.form.label.entitlement')}{" "}
        {t("packages.add_entitlements.in")}{" "}
        <b>{packageData?.title}</b>{" "}
        {t("packages.form.label.package")}
      </DialogTitle>
      <DialogContent>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={entitlementsSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t("entitlements.form.label.max_redeemable")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.max_redeemable}
                name="max_redeemable"
                error={!!touched.max_redeemable && !!errors.max_redeemable}
                helperText={touched.max_redeemable && errors.max_redeemable}
              />
              <Box display="flex" gap="10px" justifyContent="end" mt="20px">
                <button type="submit" className="btn btn-primary" size="small" m="5px">
                  {t("packages.edit_dialog.edit_button")}
                </button>
                <button autoFocus className="btn btn-danger" onClick={onClose}>
                  {t("global.page.cancel_button")}
                </button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );

  return content;
};

export default EditEntitlementDialog;
