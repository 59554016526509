import React, { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useUpdatePackageMutation } from "../../../features/packages/packagesApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditPackage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const packageData = location.state.packageData;

  const initialValues = {
    title: packageData.title,
    code: packageData.code,
    description: packageData.description,
    copay_amount: packageData.copay_amount,
    subsidy_amount: packageData.subsidy_amount,
  };

  const packagesSchema = yup.object().shape({
    title: yup.string().required(t('packages.form.helpertext.name')),
    description: yup.string().required(t('packages.form.helpertext.description')),
    copay_amount: yup.string().required(t('packages.form.helpertext.copay_amount')),
    subsidy_amount: yup.string().required(t('packages.form.helpertext.subsidy_amount')),
  });

  const [updatePackage, { isLoading }] = useUpdatePackageMutation();

  const handleFormSubmit = async (values) => {
    try {
      const payload = { ...values, id: packageData.id };
      const response = await updatePackage(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
          function () {
            navigate("/interventions/packages", { state: { message: response?.message } });
          },
          3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('packages.edit.title')}
        subtitle={t('packages.edit.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/packages">
          <Typography variant="body2">{t("sidebar.Packages")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('packages.edit.edit_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={packagesSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('packages.form.label.name')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('packages.form.label.code')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                name="code"
                error={!!touched.code && !!errors.code}
                helperText={touched.code && errors.code}
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={3}
                type="text"
                label={t('global.form.label.description')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('packages.form.label.copay_amount')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.copay_amount}
                name="copay_amount"
                error={!!touched.copay_amount && !!errors.copay_amount}
                helperText={touched.copay_amount && errors.copay_amount}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('packages.form.label.subsidy_amount')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subsidy_amount}
                name="subsidy_amount"
                error={!!touched.subsidy_amount && !!errors.subsidy_amount}
                helperText={touched.subsidy_amount && errors.subsidy_amount}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/interventions/packages" underline="none">

              <button className="btn btn-danger">
                  {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('packages.edit.edit_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default EditPackage;
