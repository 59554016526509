import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Base64} from "js-base64";
import {useDispatch} from "react-redux";
import {setCredentials} from "../features/auth/authSlice";
import {useLoginMutation} from "../features/auth/authApiSlice";
import Spinner from "./global/Spinner";
import {useTranslation} from "react-i18next";

const LoginSession = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  let { data } = useParams();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [, setErrMsg] = useState("");
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const loginUser = async () => {
      const decodedData = Base64.decode(data);
      const authData = JSON.parse(decodedData.toString());
      try {
        const payload = {
          user_name: authData.value1,
          password: authData.value2,
        };

        const response = await login(payload).unwrap();
        const access_token = response.data.access_token;
        const refresh_token = response.data.refresh_token;
        const expires_in = response.data.expires_in;
        const roles = response.roles;
        const permissions = response.permissions;
        const user = response.user;
        dispatch(
          setCredentials({
            access_token,
            refresh_token,
            expires_in,
            roles,
            permissions,
            user,
          })
        );

        if (response?.status === 200) {
          navigate(from, { replace: true });
        }
      } catch (err) {
        //console.log(err)
        if (!err?.data) {
          setErrMsg(t("login.error.no_server_response"));
        } else if (err?.data.status === 400) {
          setErrMsg(err?.data.message);
        } else {
          setErrMsg(t("login.error.login_failed"));
        }
      }
    };

    loginUser();
  }, [data, dispatch, from, login, navigate, t]);

  return isLoading ? <Spinner/> : " ";
};

export default LoginSession;
