import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Breadcrumbs,
  ButtonGroup,
  Alert,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import moment from "moment";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { useGetReportsQuery } from "../../../features/reports/reportApiSlice";
import Header from "../../Header";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import Spinner from "../../global/Spinner";
import { LinkColor } from "../../../theme";
import StyledDataGrid from "../../global/StyledDataGrid";
import Toast from "../../global/Toast";
import axios from "../../../api/axios";

const ListReports = () => {
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const [downloadingState, setDownloadingState] = useState({});
  const token = useSelector(selectCurrentToken);
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const payload = { page, pageSize };
  const { data, isLoading, isSuccess } = useGetReportsQuery(payload);

  useEffect(() => {
    if (data?.data) setReports(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isLoading, isSuccess, pagination.currentPage]);

  const handleDownload = async (report) => {
    setDownloadingState((prev) => ({ ...prev, [report?.id]: true }));
    const url = `${process.env.REACT_APP_BASE_URL}/reports/download/${report?.id}`;
    try {
      if (report?.report_file) {
        await axios
          .get(url, {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = report?.report_name;
            a.click();
            window.URL.revokeObjectURL(url);
            setDownloadingState((prev) => ({ ...prev, [report?.id]: false }));
            if (response.statusText === "OK") {
              Toast(t("kobo.preloads.preload_download_success"), "success");
            }
          })
          .catch((error) => {
            Toast(t("kobo.preloads.preload_download_fail"), "error");
            setDownloadingState((prev) => ({ ...prev, [report?.id]: false }));
          });
      }
    } catch (error) {
      setDownloadingState((prev) => ({ ...prev, [report?.id]: false }));
      Toast(t("kobo.preloads.preload_download_fail"), "error");
    }
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                color="warning"
                size="small"
                onClick={() => handleDownload(params.row)}
              >
                {downloadingState[params.row.id]
                  ? t("global.buttons.downloading")
                  : t("global.buttons.download")}{" "}
                <FileDownloadOutlinedIcon />
              </Button>
            </ButtonGroup>
          </Box>
        );
      },
    },
    {
      field: "report_name",
      headerName: t("reports.table.report_name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "generated_by",
      headerName: t("reports.table.generated_by"),
      flex: 1,
      valueGetter: (params) =>
        params.row?.generated_by?.first_name +
          " " +
          params.row?.generated_by?.last_name || "",
    },
    {
      field: "report_modality",
      headerName: t("reports.table.report_modality"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: t("kobo.table.created_at"),
      flex: 1,
      valueGetter: (params) =>
        moment(params.row?.created_at).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("reports.index.title")}
        subtitle={t("reports.index.subtitle")}
      />
      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" href="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Reports")}
        </Typography>
      </Breadcrumbs>

      <Box className="data_grid_box_container">
        {reports?.length < 1 ? (
          <Alert severity="info">{t("reports.index.no_reports")}</Alert>
        ) : (
          <StyledDataGrid
            rows={reports}
            columns={columns}
            pagination
            pageSize={pagination.perPage}
            rowCount={pagination.total}
            onPageChange={handlePageChange}
            loading={isLoading}
            paginationMode="server"
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={default_rows_per_page_options}
          />
        )}
      </Box>
    </Box>
  );

  return content;
};

export default ListReports;
