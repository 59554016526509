import React, { useEffect, useState } from "react";
import { useGetUsersQuery } from "../../../features/users/usersApiSlice";
import {
  useAddRoleUsersMutation,
  useGetRoleUsersQuery,
} from "../../../features/roles/rolesApiSlice";
import { useParams, useNavigate, Link } from "react-router-dom";
import Spinner from "../../global/Spinner";
import {
  Box,
  Button,
  Alert,
  AlertTitle,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const AddRoleUsers = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [ids, setIds] = useState([]);

  const [users, setUsers] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  // Get the users from the API
  const { data, isLoading, isSuccess, isFetching } = useGetUsersQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (data?.data) setUsers(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage]);

  const [presentUsers, setPresentUsers] = useState([]);

  // Fetch pressent users in a role
  const {
    data: userData,
    isLoading: fetchingUsers,
    isSuccess: userSuccess,
  } = useGetRoleUsersQuery(id);

  useEffect(() => {
    if (userData?.users) {
      setPresentUsers(userData?.users);
    }
  }, [userData, userSuccess]);

  // check if the title value of tale matches present users title value
  const checkNameMatch = (name) => {
    // console.log("this name", name);
    // console.log("this", presentUsers);

    return presentUsers.some((user) => user.user_name === name);
  };
  const isSelectionDisabled = (params) => {
    return checkNameMatch(params.row.user_name);
  };
  const getCellClassName = (params) => {
    return checkNameMatch(params.row.user_name) ? "matched-name-cell" : "";
  };

  // Separate matching and non-matching permission
  const matchingPermissions = [];
  const nonMatchingPermissions = [];
  users.forEach((user) => {
    if (presentUsers.some((present) => present.user_name === user.user_name)) {
      matchingPermissions.push(user);
    } else {
      nonMatchingPermissions.push(user);
    }
  });

  // Concatenate arrays to place matching users at the bottom
  const sortedPermissions = [...nonMatchingPermissions, ...matchingPermissions];

  // Add role users
  const [addUsers] = useAddRoleUsersMutation();

  const columns = [
    {
      field: "first_name",
      headerName: t("users.form.label.first_name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "last_name",
      headerName: t("users.form.label.last_name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: t("users.form.label.email"),
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => params.row?.email,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: "user_name",
      headerName: t("users.form.label.user_name"),
      flex: 0.5,
      minWidth: 200,
      valueGetter: (params) => params.row?.user_name,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 0.5,
      minWidth: 200,
    },
  ];

  const selectedUsers = (ids) => {
    setIds(ids);
  };

  const handleAdd = async () => {
    if (users.length) {
      const payload = {
        role_id: id,
        users: ids,
      };
      try {
        const response = await addUsers(payload).unwrap();

        if (response.status === 200) {
          Toast(response?.message, "success");
        } else {
          Toast(response?.message, "error");
        }

        setTimeout(function () {
          navigate(`/roles/${id}`, { state: { message: response?.message } });
        }, 3000);
      } catch (err) {
        Toast(err?.data?.message, "error");
      }
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("roles.add_users.title")}
        subtitle={t("roles.add_users.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/roles">
          <Typography variant="body2">{t("sidebar.Roles")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to={`/roles/${id}`}>
          <Typography variant="body2">{t("roles.view.view_role")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("roles.add_users.add_users")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("roles.add_users.alert_head")}</AlertTitle>
        {t("roles.add_users.alert_message")}
      </Alert>

      <button
        className={!ids.length ? "btn btn-disabled" : "btn btn-primary"}
        onClick={handleAdd}
        disabled={!ids.length}
        style={{ marginTop: "10px" }}
      >
        {t("global.buttons.add_users")}
      </button>
      <Box className="data_grid_box_container">
        <StyledDataGrid
          checkboxSelection
          rows={sortedPermissions}
          columns={columns}
          onSelectionModelChange={(ids) => selectedUsers(ids)}
          rowsPerPageOptions={default_rows_per_page_options}
          getCellClassName={(params) => getCellClassName(params)}
          disableSelectionOnClick={(params) => isSelectionDisabled(params)}
        />
      </Box>
    </Box>
  );

  return content;
};

export default AddRoleUsers;
