import { useState, useEffect } from "react";
import {
  useAddInterventionServiceProvidersMutation,
  useGetInterventionServiceProvidersQuery,
} from "../../../features/interventions/interventionsApiSlice";
import { useParams, useNavigate, Link } from "react-router-dom";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Button,
  Alert,
  AlertTitle,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { useGetServiceProvidersQuery } from "../../../features/users/serviceProvidersApiSlice";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const AddInterventionServiceProviders = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [ids, setIds] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [presentServiceProviders, setPresentServiceProviders] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);
  // Fetch service providers - 15 is for Infinite scroll
  const { data, isLoading, isSuccess } = useGetServiceProvidersQuery({
    page: 1,
    pageSize: default_rows_per_page,
  });

  useEffect(() => {
    if (data?.data) setServiceProviders(data?.data);
  }, [data, isLoading, isSuccess]);

  // Fetch present service providers in an intervention
  const {
    data: spData,
    isLoading: fetchingServiceProviders,
    isSuccess: spSuccess,
  } = useGetInterventionServiceProvidersQuery({ id, page, pageSize });

  useEffect(() => {
    if (spData?.service_providers)
      setPresentServiceProviders(spData?.service_providers);
  }, [spData, spSuccess]);

  // check if the title value of tale matches present serviceProvider title value
  const checkNameMatch = (name) => {
    return presentServiceProviders.some(
      (serviceProvider) => serviceProvider.user_name === name
    );
  };
  const getCellClassName = (params) => {
    return checkNameMatch(params.row.user_name) ? "matched-name-cell" : "";
  };

  // Separate matching and non-matching serviceProviders
  const matchingServiceProviders = [];
  const nonmatchingServiceProviders = [];
  serviceProviders.forEach((serviceProvider) => {
    if (
      presentServiceProviders.some(
        (present) => present.user_name === serviceProvider.user_name
      )
    ) {
      matchingServiceProviders.push(serviceProvider);
    } else {
      nonmatchingServiceProviders.push(serviceProvider);
    }
  });

  // Concatenate arrays to place matching serviceProviders at the bottom
  const sortedServiceProviders = [
    ...nonmatchingServiceProviders,
    ...matchingServiceProviders,
  ];

  // Add intervention serviceProviders
  const [addServiceProviders] = useAddInterventionServiceProvidersMutation();

  const columns = [
    {
      field: "first_name",
      headerName: t("users.form.label.first_name"),
      flex: 1,
      cellClassName: "name-column--cell",
      valueGetter: (params) => params.row?.first_name,
    },
    {
      field: "last_name",
      headerName: t("users.form.label.last_name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: t("users.form.label.email"),
      flex: 1,
      valueGetter: (params) => params.row?.email,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 1,
    },
    {
      field: "user_name",
      headerName: t("users.form.label.user_name"),
      flex: 1,
      valueGetter: (params) => params.row?.user_name,
    },
  ];

  const selectedServiceProviders = (ids) => {
    setIds(ids);
  };

  const handleAdd = async () => {
    if (serviceProviders.length) {
      const payload = {
        intervention_id: id,
        service_providers: ids,
      };
      try {
        const response = await addServiceProviders(payload).unwrap();

        if (response.status === 200) {
          navigate(`/interventions/${id}`);
        } else {
          Toast(response?.message, "error");
        }
      } catch (err) {
        Toast(err?.data?.message, "error");
      }
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("intervention.add_sp.title")}
        subtitle={t("intervention.add_sp.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to={`/interventions/${id}`}>
          <Typography variant="body2">{t("intervention.view.view")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("global.buttons.add_sp")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("intervention.add_sp.alert_head")}</AlertTitle>
        {t("intervention.add_sp.alert_message")}
      </Alert>

      <Box marginTop={"20px"}>
        <button
          className={!ids.length ? "btn btn-disabled" : "btn btn-primary"}
          onClick={handleAdd}
          disabled={!ids.length}
        >
          {t("global.buttons.add_sp")}
        </button>
      </Box>
      <Box className="data_grid_box_container">
        <StyledDataGrid
          checkboxSelection
          rows={sortedServiceProviders}
          columns={columns}
          rowsPerPageOptions={default_rows_per_page_options}
          onSelectionModelChange={(ids) => selectedServiceProviders(ids)}
          getCellClassName={(params) => getCellClassName(params)}
          disableSelectionOnClick={(params) =>
            checkNameMatch(params.row.full_name)
          }
        />
      </Box>
    </Box>
  );

  return content;
};

export default AddInterventionServiceProviders;
