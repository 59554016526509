import React, { useState } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useUpdateTopupMutation } from "../../../features/topups/topupsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditTopup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const topup = location.state.topup;

  const initialValues = {
    title: topup.name,
    copay_amount: topup.copay_amount,
    subsidy_amount: topup.subsidy_amount,
    topup_type: topup.topup_type,
  };

  const topupSchema = yup.object().shape({
    title: yup.string().required(t('topups.form.helpertext.title')),
    copay_amount: yup.string().required(t('topups.form.helpertext.copay_amount')),
    subsidy_amount: yup.string().required(t('topups.form.helpertext.subsidy_amount')),
    topup_type: yup.string().required(t('topups.form.helpertext.type')),
  });

  const [startDate, setStartDate] = useState(topup.start_date);
  const [endDate, setEndDate] = useState(topup.end_date);

  const [updateTopup, { isLoading }] = useUpdateTopupMutation();

  const handleFormSubmit = async (values) => {
    values = {
      ...values,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      id: topup.id,
    };
    try {
      const response = await updateTopup(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
          function () {
            navigate("/interventions/topups", { state: { message: response?.message } });
          },
          3000
      );
    } catch (err) {
      Toast(err?.data?.errors, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('topups.edit.title')}
        subtitle={t('topups.edit.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/topups">
          <Typography variant="body2">{t("sidebar.Topups")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('topups.edit.edit_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={topupSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
            <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('topups.form.label.title')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('packages.form.label.copay_amount')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.copay_amount}
                name="copay_amount"
                error={!!touched.copay_amount && !!errors.copay_amount}
                helperText={touched.copay_amount && errors.copay_amount}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('packages.form.label.subsidy_amount')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subsidy_amount}
                name="subsidy_amount"
                error={!!touched.subsidy_amount && !!errors.subsidy_amount}
                helperText={touched.subsidy_amount && errors.subsidy_amount}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('project.shared.start_date')}
                  value={startDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setStartDate(Date.parse(newValue));
                  }}
                  formatDate={(startDate) =>
                    moment(startDate).format("DD-MM-YYYY")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('project.shared.end_date')}
                  value={endDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setEndDate(Date.parse(newValue));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <TextField
                select
                variant="filled"
                label={t('topups.form.label.type')}
                name="topup_type"
                value={values.topup_type}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!touched.topup_type && !!errors.topup_type}
                helperText={touched.topup_type && errors.topup_type}
              >
                <MenuItem value="NEW">{t('topups.form.label.new')}</MenuItem>
                <MenuItem value="MID">{t('topups.form.label.mid')}</MenuItem>
              </TextField>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/interventions/topups" underline="none">

              <button className="btn btn-danger">
                {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('topups.edit.edit_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default EditTopup;
