import { useEffect, useState } from "react";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useGetServiceProvidersQuery } from "../../../features/users/serviceProvidersApiSlice";
import {
  Box,
  Button,
  Alert,
  AlertTitle,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import {
  useAddDpServiceProvidersMutation,
  useGetDpServiceProvidersQuery,
} from "../../../features/distribution_points/distributionPointsApiSlice";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const AddServiceProviders = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [serviceProviders, setServiceProviders] = useState([]);
  const [presentServiceProviders, setPresentServiceProviders] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  // Fetch service providers
  const {
    data: spData,
    isLoading: fetchingServiceProviders,
    isSuccess,
  } = useGetServiceProvidersQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (spData?.data) setServiceProviders(spData?.data);
  }, [spData, fetchingServiceProviders, isSuccess]);

  // fetch present sP
  const {
    data: spPData,
    isLoading: fetchServicePProviders,
    isSuccess: spPSuccess,
  } = useGetDpServiceProvidersQuery(id);

  useEffect(() => {
    if (spData?.data) {
      setPresentServiceProviders(spPData.service_providers);
    }
  }, [spPData, spPSuccess, fetchServicePProviders]);

  // check if the title value of tale matches present sp title value
  const checkNameMatch = (name) => {
    return presentServiceProviders.some((sp) => sp.user_name === name);
  };
  const isSelectionDisabled = (params) => {
    return checkNameMatch(params.row.user_name);
  };
  const getCellClassName = (params) => {
    return checkNameMatch(params.row.user_name) ? "matched-name-cell" : "";
  };

  // Separate matching and non-matching permission
  const matchingServiceProviders = [];
  const nonMatchingServiceProviders = [];
  serviceProviders.forEach((sp) => {
    if (
      presentServiceProviders.some(
        (present) => present.user_name === sp.user_name
      )
    ) {
      matchingServiceProviders.push(sp);
    } else {
      nonMatchingServiceProviders.push(sp);
    }
  });

  // Concatenate arrays to place matching serviceProviders at the bottom
  const sortedServiceProviders = [
    ...nonMatchingServiceProviders,
    ...matchingServiceProviders,
  ];

  const [ids, setIds] = useState([]);

  // Add service providers to distribution points
  const [addServiceProviders] = useAddDpServiceProvidersMutation();

  const columns = [
    {
      field: "first_name",
      headerName: t("users.form.label.first_name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "last_name",
      headerName: t("users.form.label.last_name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "email", headerName: t("users.form.label.email"), flex: 1 },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      flex: 0.5,
    },
    {
      field: "user_name",
      headerName: t("users.form.label.user_name"),
      flex: 0.5,
      valueGetter: (params) => params.row?.user_name,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 0.5,
    },
  ];

  const selectedServiceProviders = (ids) => {
    setIds(ids);
  };

  const handleAdd = async (serviceProvider) => {
    if (ids.length > 0) {
      const payload = {
        distribution_point: id,
        service_provider: ids[0],
      };

      try {
        const response = await addServiceProviders(payload).unwrap();
        if (response.status === 200) {
          navigate(`/distribution-points/${id}`);
        } else {
          Toast(response?.message, "error");
        }
      } catch (err) {
        Toast(err?.data?.message, "error");
      }
    }
  };

  const content = fetchingServiceProviders ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("distribution_points.add_sp.title")}
        subtitle={t("distribution_points.add_sp.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/distribution-points">
          <Typography variant="body2">
            {t("sidebar.Distribution Points")}
          </Typography>
        </Link>
        <Link
          style={LinkColor()}
          color="inherit"
          to={`/distribution-points/${id}`}
        >
          <Typography variant="body2">
            {t("distribution_points.form.label.view")}
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("global.buttons.add_sp")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("distribution_points.add_sp.alert_head")}</AlertTitle>
        {t("distribution_points.add_sp.alert_message")}
      </Alert>
      <Box marginTop={"20px"}>
        <button
          onClick={handleAdd}
          className={!ids.length ? "btn btn-disabled" : "btn btn-primary"}
          disabled={!ids.length}
        >
          {t("global.buttons.add_sp")}
        </button>
      </Box>

      <Box className="data_grid_box_container">
        <StyledDataGrid
          checkboxSelection
          rows={sortedServiceProviders}
          columns={columns}
          rowsPerPageOptions={default_rows_per_page_options}
          onSelectionModelChange={(ids) => selectedServiceProviders(ids)}
          getCellClassName={(params) => getCellClassName(params)}
          disableSelectionOnClick={(params) => isSelectionDisabled(params)}
        />
      </Box>
    </Box>
  );

  return content;
};

export default AddServiceProviders;
