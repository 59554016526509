import React, { useState, useEffect } from "react";
import Header from "../../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useGetInterventionsQuery } from "../../../../features/interventions/interventionsApiSlice";
import { useGetPackagesQuery } from "../../../../features/packages/packagesApiSlice";
import { useGetProjectsQuery } from "../../../../features/projects/projectsApiSlice";
import { useGetServiceProvidersQuery } from "../../../../features/users/serviceProvidersApiSlice";
import { selectCurrentToken } from "../../../../features/auth/authSlice";
import Spinner from "../../../global/Spinner";
import Toast from "../../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "../../../../api/axios";
import { useSelector } from "react-redux";
import { LinkColor } from "../../../../theme";
import { useGenerateReportMutation } from "../../../../features/reports/reportApiSlice";
import { displayRequestErrors } from "../../../../utils/ToastUtils";

const CreateEvoucherLiquidationReport = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const [generateReport] = useGenerateReportMutation();

  const initialValues = {
    project_id: "",
    intervention_id: "",
    package_id: "",
    service_provider_id: "",
    start_date: "",
    end_date: "",
    report_type: "",
  };

  const evoucherLiquidationReportSchema = yup.object().shape({
    start_date: yup
      .date()
      .required(t("global.form.helpertext.start_date"))
      .min(new Date(2000, 0, 1), "Start date cannot be before January 1, 2000"),
    end_date: yup
      .date()
      .required(t("global.form.helpertext.end_date"))
      .min(yup.ref("start_date"), "End date cannot be before the start date"),
  });

  const handleFormSubmit = async (values) => {
    let reportModality = "";
    if (values.report_type == "AFG-CASH-REPORT") {
      reportModality = "CASH";
    } else if (values.report_type == "INKIND-LIQUIDATION-REPORT") {
      reportModality = "INKIND";
    } else {
      reportModality = "EVOUCHER";
    }
    values = {
      ...values,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      report_modality: reportModality,
    };

    try {
      setDownloadingExcel(true);
      const response = await generateReport(values).unwrap();

      if (response?.status == 200) {
        setDownloadingExcel(false);
        Toast(response?.message, "success");
        navigate("/reports");
      }
    } catch (err) {
      setDownloadingExcel(false);
      console.log("err", err);
      if (err?.data?.errors) displayRequestErrors(err?.data?.errors);
      displayRequestErrors(err?.status);

      Toast(err.message, "error");
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: evoucherLiquidationReportSchema,
    onSubmit: handleFormSubmit,
    validateOnChange: true,
    validateOnBlur: true,
  });

  // Fetch all projects and write them to the state
  const [projects, setProjects] = useState([]);
  const {
    data: projectsData,
    isLoading: fetchingProjects,
    isSuccess: projectSuccess,
  } = useGetProjectsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (projectsData?.data) setProjects(projectsData?.data);
  }, [projectsData, projectSuccess]);

  // Fetch all interventions and write them to the state
  const [interventions, setInterventions] = useState([]);
  const {
    data: interventionsData,
    isLoading: fetchingInterventions,
    isSuccess: interventionsSuccess,
  } = useGetInterventionsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (interventionsData?.data) {
      const projectId = formik.values.project_id;

      if (projectId) {
        const interventions = interventionsData?.data?.filter(
          (intervention) => {
            return intervention?.project?.id === projectId;
          }
        );
        setInterventions(interventions);
      }
    }
  }, [interventionsData, interventionsSuccess, formik.values.project_id]);

  // Fetch all packages and write them to the state
  const [packages, setPackages] = useState([]);
  const {
    data: packageData,
    isLoading: fetchingPackages,
    isSuccess: packageSuccess,
  } = useGetPackagesQuery({ page: 1, pageSize: default_rows_per_page });
  useEffect(() => {
    if (packageData?.data) {
      const interventionId = formik.values.intervention_id;

      if (interventionId) {
        const packages = packageData?.data?.filter((packageData) => {
          return packageData?.intervention?.id === interventionId;
        });
        setPackages(packages);
      }
    }
  }, [packageData, packageSuccess, formik.values.intervention_id]);

  // Fetch all service providers and write them to the state
  const [serviceProviders, setServiceProviders] = useState([]);
  const {
    data: serviceProvidersData,
    isLoading: fetchingServiceProviders,
    isSuccess: serviceProvidersSuccess,
  } = useGetServiceProvidersQuery({ page: 1, pageSize: default_rows_per_page });
  useEffect(() => {
    if (serviceProvidersData?.data)
      setServiceProviders(serviceProvidersData?.data);
  }, [serviceProvidersData, serviceProvidersSuccess]);

  //@todo @future devs, why are we writing start and end dates to the state?
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const content = fetchingServiceProviders ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("reports.evoucher.title")}
        subtitle={t("reports.evoucher.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/reports">
          <Typography variant="body2">{t("sidebar.Reports")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Evoucher Report")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={formik.handleSubmit}
        initialValues={initialValues}
        validationSchema={evoucherLiquidationReportSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={formik.handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingProjects ? (
                <p>{t("global.loading.project")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.project")}
                  name="project_id"
                  value={formik.values.project_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.project_id && !!formik.errors.project_id
                  }
                  helperText={
                    formik.touched.project_id && formik.errors.project_id
                  }
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.code} - {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {fetchingInterventions ? (
                <p>{t("global.loading.intervention")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    projects?.length > 0 && interventions?.length === 0
                      ? t("global.form.helpertext.no_intervention")
                      : t("global.form.helpertext.intervention")
                  }
                  name="intervention_id"
                  value={formik.values.intervention_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.intervention_id &&
                    !!formik.errors.intervention_id
                  }
                  helperText={
                    formik.touched.intervention_id &&
                    formik.errors.intervention_id
                  }
                >
                  {interventions &&
                    interventions.map((intervention) => (
                      <MenuItem key={intervention.id} value={intervention.id}>
                        {intervention.title}
                      </MenuItem>
                    ))}
                </TextField>
              )}

              {fetchingPackages ? (
                <p>{t("global.loading.package")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    interventions?.length > 0 && packages?.length === 0
                      ? t("global.form.helpertext.no_package")
                      : t("global.form.helpertext.package")
                  }
                  name="package_id"
                  value={formik.values.package_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.package_id && !!formik.errors.package_id
                  }
                  helperText={
                    formik.touched.package_id && formik.errors.package_id
                  }
                >
                  {packages.map((packageData) => (
                    <MenuItem key={packageData.id} value={packageData.id}>
                      {packageData.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {fetchingServiceProviders ? (
                <p>{t("global.loading.service_providers")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.service_provider")}
                  name="service_provider_id"
                  value={formik.values.service_provider_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.service_provider_id &&
                    !!formik.errors.service_provider_id
                  }
                  helperText={
                    formik.touched.service_provider_id &&
                    formik.errors.service_provider_id
                  }
                >
                  {serviceProviders.map((service_provider) => (
                    <MenuItem
                      key={service_provider.id}
                      value={service_provider.id}
                    >
                      {service_provider.first_name} {service_provider.last_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("project.shared.start_date")}
                  name="start_date"
                  value={startDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setStartDate(Date.parse(newValue));
                  }}
                  error={
                    !!formik.touched.start_date && !!formik.errors.start_date
                  }
                  helperText={
                    formik.touched.start_date && formik.errors.start_date
                  }
                  formatDate={(startDate) =>
                    moment(startDate).format("DD-MM-YYYY")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("project.shared.end_date")}
                  name="end_date"
                  value={endDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setEndDate(Date.parse(newValue));
                  }}
                  error={!!formik.touched.end_date && !!formik.errors.end_date}
                  helperText={formik.touched.end_date && formik.errors.end_date}
                  formatDate={(endDate) => moment(endDate).format("DD-MM-YYYY")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <TextField
                select
                variant="filled"
                label={t("reports.report_types.label")}
                name="report_type"
                value={formik.values.report_type}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.topup_type && !!formik.errors.report_type
                }
                helperText={
                  formik.touched.topup_type && formik.errors.report_type
                }
              >
                <MenuItem value="EVOUCHER-LIQUIDATION-REPORT">
                  {t("reports.report_types.evoucher_report")}
                </MenuItem>
                <MenuItem value="INKIND-LIQUIDATION-REPORT">
                  {t("reports.report_types.inkind_report")}
                </MenuItem>
                <MenuItem value="AFG-CASH-REPORT">
                  {t("reports.report_types.afg_cash_report")}
                </MenuItem>
              </TextField>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Link to="/reports" underline="none">
                <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
                </button>
              </Link>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={downloadingExcel}
              >
                {downloadingExcel
                  ? t("reports.evoucher.creating_button")
                  : t("reports.evoucher.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default CreateEvoucherLiquidationReport;
