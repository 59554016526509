import { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useAddEntitlementTypeMutation } from "../../../features/entitlement_types/entitlementTypesApiSlice";
import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const initialValues = {
  // intervention_id: "",
  // project_id: "",
  type: "",
  description: "",
};

const AddEntitlementType = () => {
  const { t } = useTranslation();
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [interventions, setInterventions] = useState([]);

  const entitlementTypeSchema = yup.object().shape({
    // intervention_id: yup.string().required(t('global.form.helpertext.project')),
    // project_id: yup.string().required(t('global.form.helpertext.intervention')),
    type: yup.string().required(t('entitlement_types.form.helpertext.type')),
    description: yup.string().required(t('entitlement_types.form.helpertext.description')),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: entitlementTypeSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const {
    data: projectsData,
    isLoading: fetchingProjects,
    isSuccess: projectSuccess,
  } = useGetProjectsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (projectsData?.data) setProjects(projectsData?.data);
  }, [projectsData, projectSuccess]);

  const {
    data: interventionsData,
    isLoading: fetchingInterventions,
    isSuccess: interventionsSuccess,
  } = useGetInterventionsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (interventionsData?.data) {
      const projectId = formik.values.project_id;

      if (projectId) {
        const interventions = interventionsData?.data?.filter(
          (intervention) => {
            return intervention?.project?.id === projectId;
          }
        );
        setInterventions(interventions);
      }
    }
  }, [interventionsData, interventionsSuccess, formik.values.project_id]);

  const [addEntitlementType, { isLoading }] = useAddEntitlementTypeMutation();

  const handleFormSubmit = async (values) => {
    try {
      const response = await addEntitlementType(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
          function () {
            navigate("/interventions/entitlement-types", { state: { message: response?.message } });
          },
          3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('entitlement_types.add.title')}
        subtitle={t('entitlement_types.add.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t('sidebar.Interventions')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/entitlement-types">
          <Typography variant="body2">{t('sidebar.Entitlement Types')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('entitlement_types.index.add_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={entitlementTypeSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={formik.handleSubmit}>
            <Box display="grid" gap="20px">
              {/* {fetchingProjects ? (
                <p>{t('global.loading.project')}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t('global.form.helpertext.project')}
                  name="project_id"
                  value={formik.values.project_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.project_id && !!formik.errors.project_id
                  }
                  helperText={
                    formik.touched.project_id && formik.errors.project_id
                  }
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.code} - {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )} */}

              {/* {fetchingInterventions ? (
                <p>{t('global.loading.intervention')}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    projects?.length > 0 && interventions?.length === 0
                      ? t('global.form.helpertext.no_intervention')
                      : t('global.form.helpertext.intervention')
                  }
                  name="intervention_id"
                  value={formik.values.intervention_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.intervention_id &&
                    !!formik.errors.intervention_id
                  }
                  helperText={
                    formik.touched.intervention_id &&
                    formik.errors.intervention_id
                  }
                >
                  {interventions &&
                    interventions.map((intervention) => (
                      <MenuItem key={intervention.id} value={intervention.id}>
                        {intervention.title}
                      </MenuItem>
                    ))}
                </TextField>
              )} */}

              <TextField
                fullWidth
                select
                variant="filled"
                label={t('global.table.type')}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.type}
                name="type"
                error={!!formik.touched.type && !!formik.errors.type}
                helperText={formik.touched.type && formik.errors.type}
              >
                <MenuItem value="CASH">{t('entitlement_types.form.label.cash')}</MenuItem>
                <MenuItem value="CASH-PLUS">{t('entitlement_types.form.label.cash-plus')}</MenuItem>
                <MenuItem value="IN-KIND">{t('entitlement_types.form.label.in-kind')}</MenuItem>
                <MenuItem value="E-VOUCHER">{t('entitlement_types.form.label.e-voucher')}</MenuItem>
                <MenuItem value="SERVICE">{t('entitlement_types.form.label.service')}</MenuItem>
                
              </TextField>

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={4}
                type="text"
                label={t('global.table.description')}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                name="description"
                error={
                  !!formik.touched.description && !!formik.errors.description
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/interventions/entitlement-types" underline="none">

              <button  className="btn btn-danger">
                  {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('entitlement_types.add.create_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddEntitlementType;
