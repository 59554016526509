import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Typography,
  Breadcrumbs, Alert,
} from "@mui/material";
import Header from "../../Header";
import { useNavigate, Link } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ToastContainer } from "react-toastify";
import {
  useDeleteProjectMutation,
  useGetProjectsQuery,
} from "../../../features/projects/projectsApiSlice";
import Spinner from "../../global/Spinner";
import DeleteDialog from "../shared/DeleteDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {LinkColor} from '../../../theme';
import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const Projects = () => {
    const currentPermissions = useSelector(selectCurrentPermissions);
    const canAdd = currentPermissions.some(permission => permission.name === 'project-add');
    const canEdit = currentPermissions.some(permission => permission.name === 'project-update');
    const canDelete = currentPermissions.some(permission => permission.name === 'project-delete');
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
      const apiFilters = {};
      
      filterModel?.forEach(item => {
        if (item.columnField && item.value) {
          apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
        }
      });

      return apiFilters;
  };

  const { data, isLoading, isSuccess, isFetching } = useGetProjectsQuery({
    page,
    pageSize,
    ...getApiFilters()
  });

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const [deleteProject] = useDeleteProjectMutation();

  useEffect(() => {
    if (data?.data) setProjects(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleClickOpen = (project) => {
    setSelectedValue(project);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleEdit = (project) => {
    project = JSON.parse(project);
    //navigate(`/projects/edit/${project.id}`, { state: { project } });
    navigate(`/projects/edit/${project.id}`);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => {
        const onClick = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          // @peter said, below i am trying to return all the fields that are available for a project,
          // and not just the ones visible on the grid
          //return JSON.stringify(thisRow, null, 4);
          return JSON.stringify(params.row, null, 4);
        };
       
        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                {canEdit && (
                
                  <Button color="secondary" onClick={() => handleEdit(onClick())} size="small">
                  {t("global.table.edit_button")} <EditOutlinedIcon />
                </Button>
                
                )}
                {canDelete && (
                  <Button color="error" onClick={() => handleClickOpen(onClick())} size="small">
                  {t("global.table.delete_button")} <DeleteOutlineOutlinedIcon />
                </Button>
                )}
             </ButtonGroup>

             
              {open && (
                  <DeleteDialog
                      open={open}
                      onClose={handleClose}
                      name={JSON.parse(selectedValue).title}
                      deleteMethod={deleteProject}
                      url={`/project/delete/${JSON.parse(selectedValue).id}`}
                  />
              )}
            </Box>
        );
      },
    },
    {
      field: "code",
      headerName: t("project.shared.project_code"),
      flex: 0.5,
      minWidth: 250,
    },
    {
      field: "title",
      headerName: t("project.shared.project_name"),
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 250,
    },
    {
      field: "description",
      headerName: t("project.shared.description"),
      flex: 1,
      minWidth: 250
    },
    {
      field: "start_date",
      headerName: t("project.shared.start_date"),
      flex: 0.5,
      minWidth: 100,
      valueFormatter: (params) => moment(params?.value).format("YYYY-MM-DD"),
    },
    {
      field: "nte_date",
      headerName: t("project.shared.end_date"),
      flex: 0.5,
      minWidth: 100,
      valueFormatter: (params) => moment(params?.value).format("YYYY-MM-DD"),
    },
    {
      field: "status",
      headerName: t("project.shared.status"),
      flex: 0.5,
      minWidth: 100,
    }
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("project.index.title")}
        subtitle={t("project.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography underline="none" variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('sidebar.Projects')}
        </Typography>
      </Breadcrumbs>
      {canAdd && (
          <Box display="flex" justifyContent="end" mt="20px">
            
              <Link style={LinkColor()} to="/projects/add" underline="none">
                <button className="btn btn-primary">
                {t("project.index.add_button")}
                </button>
              </Link>
          </Box>
        )}
      
      <Box className="data_grid_box_container">
        {projects?.length < 1 ? (
            <Alert severity="info">{t("project.index.no_project_data")}</Alert>
        ) : (
            <StyledDataGrid
                rows={projects}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pagination.perPage}
                rowCount={pagination.total}
                onFilterModelChange={handleFilterModelChange}
                loading={isFetching}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={default_rows_per_page_options}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      description: false,
                    },
                  },
                }}
            />
        )}
      </Box>
    </Box>
  );

  return content;
};

export default Projects;
