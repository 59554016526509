import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Spinner from "../../global/Spinner";
import { useGetCashDataQuery } from "../../../features/copay/copayApiSlice";
import {
  Box,
  Alert,
  Typography,
  Button,
  ButtonGroup,
  Breadcrumbs, Popover, Stack, Chip,
} from "@mui/material";
import {Link} from 'react-router-dom';
import StyledDataGrid from "../../global/StyledDataGrid";
import ViewCashDialog from "./ViewCashDialog";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useTranslation } from "react-i18next";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import {useSelector} from "react-redux";
import {selectCurrentToken} from "../../../features/auth/authSlice";
import {useNavigate} from "react-router-dom";
import { LinkColor } from "../../../theme";

const Cash = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);

  const [transactions, setTransactions] = useState([]);
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);

  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  const { data, isLoading, isSuccess, isFetching } = useGetCashDataQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (data?.data) setTransactions(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  const [selectedCashData, setSelectedCashData] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "export-popover" : undefined;
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const handleClickOpen = (cash) => {
    const cashData = JSON.parse(cash);
    const transaction = transactions.filter((txn) => {
      return txn.id === cashData.id;
    });

    setSelectedCashData(JSON.stringify(transaction));
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedCashData(value);
  };

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleExcelExport = () => {
    // navigate("/payments/cash/export/excel");
    navigate("/reports/evoucher");
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        const getCash = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                <Button
                    color="warning"
                    onClick={() => handleClickOpen(getCash())}
                >
                  {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
                </Button>
              </ButtonGroup>
            
            </Box>
        );
      },
    },
    {
      field: "transaction_amount",
      headerName: t("cash.shared.table.amount"),
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "beneficiary",
      headerName: t("cash.shared.table.beneficiary"),
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params.row?.beneficiary?.full_name,
    },
    {
      field: "intervention",
      headerName: t("cash.shared.table.intervention"),
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => params.row?.intervention?.title,
    },
    {
      field: "package",
      headerName: t("cash.shared.table.package"),
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => params.row?.package?.title,
    },
    {
      field: "service_provider",
      headerName: t("cash.shared.table.service_provider"),
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params.row?.user?.first_name + ' ' + params.row?.user?.last_name,
    },
    {
      field: "transaction_date",
      headerName: t("cash.shared.table.transaction_date"),
      flex: 1,
      minWidth: 175,
    },
    {
      field: "transaction_id",
      headerName: t("cash.shared.table.transaction_id"),
      flex: 0.5,
      minWidth: 200,
    }
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("cash.index.title")}
        subtitle={t("cash.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">
            {t("sidebar.Home")}
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Payments")}
        </Typography>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Cash")}
        </Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="flex-end" mt="20px">
        {/*Start export button, but only if there is data to be exported*/}
        {transactions?.length > 0 && (
            <>
              <Box display="flex" justifyContent="flex-end" ml="20px">
                <button
                    aria-describedby={popoverId}
                    onClick={(e) => handleExport(e)}
                    className="btn btn-primary"
                >
                  {t("global.buttons.export")}
                </button>
              </Box>

              <Popover
                  id={popoverId}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
              >
                <Stack direction="row" spacing={1} sx={{ padding: "10px" }}>
                  <Chip
                      label={t("global.buttons.export_excel")}
                      onClick={handleExcelExport}
                      icon={<DocumentScannerOutlinedIcon />}
                      color="secondary"
                  />
                </Stack>
              </Popover>
            </>
        )}
        {/*End export button*/}
      </Box>

      <Box className="data_grid_box_container">
        {transactions?.length < 1 ? (
          <Alert severity="info">{t("cash.index.no_cash")}</Alert>
        ) : (
          <StyledDataGrid
              rows={transactions}
              columns={columns}
              pagination
              paginationMode="server"
              pageSize={pagination.perPage}
              rowCount={pagination.total}
              loading={isFetching}
              onFilterModelChange={handleFilterModelChange}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={default_rows_per_page_options}
          />
        )}
      </Box>

      {openDialog && (
                  <ViewCashDialog
                      open={openDialog}
                      selectedcashdata={selectedCashData}
                      onClose={handleClose}
                  />
      )}
    </Box>
  );

  return content;
};

export default Cash;
