import * as React from 'react';
import { useParams } from "react-router";
import { useGetOneScoreQuery } from "../../../features/biometric_deduplication/biometricDeduplicationApiSlice";

import {
    Box,
    Typography,
    Breadcrumbs
} from "@mui/material";
import Header from "../../Header";
import Spinner from "../../global/Spinner";
import { Link } from "react-router-dom";


import L from 'leaflet';
import {MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "./leaflet.css";

import icon from './images/marker-icon.png';
import iconShadow from './images/marker-shadow.png';

import { useTranslation } from "react-i18next";
import { LinkColor } from '../../../theme';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25,41],
    iconAnchor: [12,41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapBeneficiaries = () => {
    const { t } = useTranslation();

    const { id } = useParams();

    // Fetch beneficiary data
    const { data, isLoading } = useGetOneScoreQuery(id, {
        refetchOnMountOrArgChange: true,
    });
    const BiometricDeduplicationMapData = data?.data;

    let key_a = 1;
    let key_b = 2;
    let position_a = 0;
    let position_b = 0;

    if (BiometricDeduplicationMapData !== undefined ) {
        key_a = BiometricDeduplicationMapData[0]?.bio_identity_a?.beneficiary_info?.id;
        key_b = BiometricDeduplicationMapData[0]?.bio_identity_b?.beneficiary_info?.id;

        position_a = [BiometricDeduplicationMapData[0]?.bio_identity_a?.beneficiary_info?.latitude, BiometricDeduplicationMapData[0]?.bio_identity_a?.beneficiary_info?.longitude];
        position_b = [BiometricDeduplicationMapData[0]?.bio_identity_b?.beneficiary_info?.latitude, BiometricDeduplicationMapData[0]?.bio_identity_b?.beneficiary_info?.longitude];
    }

    const map_center_lat = process.env.REACT_APP_DEFAULT_MAP_CENTER_LAT;
    const map_center_lon = process.env.REACT_APP_DEFAULT_MAP_CENTER_LON;
    const map_zoom = process.env.REACT_APP_DEFAULT_MAP_ZOOM_LEVEL;

    const content = isLoading ? (
        <Spinner />
    ) : (
        <Box m="20px">
            <Header
                title={t("biometrics.map.title")}
                subtitle={t("biometrics.map.subtitle")}
            />

            <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
                <Link style={LinkColor()} color="inherit" to="/">
                    <Typography variant="body2">{t("sidebar.Home")}</Typography>
                </Link>
                <Link style={LinkColor()} color="inherit" to="/beneficiaries">
                    <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
                </Link>
                <Typography color="text.primary" variant="body2">{t("sidebar.biometric_deduplication_map")}</Typography>
            </Breadcrumbs>

            <Box sx={{height: "75vh"}} >
                <MapContainer center={[map_center_lat, map_center_lon]} zoom={map_zoom} scrollWheelZoom={false} style={{height: "75vh"}}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker key={key_a} position={position_a} >
                        <Popup position={position_a}>
                            <Typography variant="body2">{t("project.shared.project_code")}: {BiometricDeduplicationMapData[0].bio_identity_a.beneficiary_info?.project}</Typography>
                            <Typography variant="body2">{t("intervention.form.label.intervention")}: {BiometricDeduplicationMapData[0].bio_identity_a.beneficiary_info?.intervention}</Typography>
                            <Typography variant="body2">{t("beneficiaries.table.fullname")}: {BiometricDeduplicationMapData[0].bio_identity_a.beneficiary_info?.full_name}</Typography>
                            <Typography variant="body2">{t("beneficiaries.table.telephone")}: {BiometricDeduplicationMapData[0].bio_identity_a.beneficiary_info?.telephone}</Typography>
                            <Typography variant="body2">{t("beneficiaries.table.date_of_birth")}: {BiometricDeduplicationMapData[0].bio_identity_a.beneficiary_info?.date_of_birth}</Typography>
                            <Typography variant="body2">{t("biometrics.table.biometrics")}: {BiometricDeduplicationMapData[0].bio_identity_a.beneficiary_info?.biometrics}</Typography>
                            <Typography variant="body2">{t("biometrics.table.score")}: {(1-(BiometricDeduplicationMapData[0].deduplication_score))*100}%</Typography>
                        </Popup>
                    </Marker>
                    <Marker key={key_b} position={position_b} >
                        <Popup position={position_b}>
                            <Typography variant="body2">{t("project.shared.project_code")}: {BiometricDeduplicationMapData[0].bio_identity_b.beneficiary_info?.project}</Typography>
                            <Typography variant="body2">{t("intervention.form.label.intervention")}: {BiometricDeduplicationMapData[0].bio_identity_b.beneficiary_info?.intervention}</Typography>
                            <Typography variant="body2">{t("beneficiaries.table.fullname")}: {BiometricDeduplicationMapData[0].bio_identity_b.beneficiary_info?.full_name}</Typography>
                            <Typography variant="body2">{t("beneficiaries.table.telephone")}: {BiometricDeduplicationMapData[0].bio_identity_b.beneficiary_info?.telephone}</Typography>
                            <Typography variant="body2">{t("beneficiaries.table.date_of_birth")}: {BiometricDeduplicationMapData[0].bio_identity_b.beneficiary_info?.date_of_birth}</Typography>
                            <Typography variant="body2">{t("biometrics.table.biometrics")}: {BiometricDeduplicationMapData[0].bio_identity_b.beneficiary_info?.biometrics}</Typography>
                            <Typography variant="body2">{t("biometrics.table.score")}: {(1-(BiometricDeduplicationMapData[0].deduplication_score))*100}%</Typography>
                        </Popup>
                    </Marker>
                </MapContainer>
            </Box>

        </Box>
    );

    return content;
};

export default MapBeneficiaries;