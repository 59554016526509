import React from "react";
import Header from "../../Header";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useUpdateDonorMutation } from "../../../features/donors/donorsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditDonor = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const donor = location.state.donor;

  const initialValues = {
    title: donor.title,
    description: donor.description,
  };

  const donorsSchema = yup.object().shape({
    title: yup.string().required(t('donors.form.helpertext.name')),
    description: yup.string().required(t('donors.form.helpertext.description')),
  });

  const [updateDonor, { isLoading }] = useUpdateDonorMutation();

  const handleFormSubmit = async (values) => {
    try {
      const payload = { ...values, id: donor.id };
      const response = await updateDonor(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
        function () {
          navigate("/projects/donors", { state: { message: response?.message } });
        },
        3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('donors.edit.title')}
        subtitle={t('donors.edit.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects">
          <Typography variant="body2">{t("sidebar.Projects")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects/donors">
          <Typography color="text.primary" variant="body2">
            {t("sidebar.Donors")}
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("donors.edit.edit_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={donorsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('global.form.label.name')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={4}
                type="text"
                label={t('global.form.label.description')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Link to="/projects/donors" underline="none">

              <button className="btn btn-danger">
                  {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('donors.edit.edit_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default EditDonor;
