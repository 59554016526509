import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs,
  Alert,
} from "@mui/material";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate, Link } from "react-router-dom";
import {
  useGetInterventionsQuery,
  useDeleteInterventionMutation,
} from "../../../features/interventions/interventionsApiSlice";
import Spinner from "../../global/Spinner";
import DeleteDialog from "../shared/DeleteDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const Interventions = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(
    (permission) => permission.name === "intervention-add"
  );
  const canEdit = currentPermissions.some(
    (permission) => permission.name === "intervention-update"
  );
  const canDelete = currentPermissions.some(
    (permission) => permission.name === "intervention-delete"
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedIntervention, setSelectedIntervention] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [interventions, setInterventions] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  const { data, isLoading, isSuccess, isFetching } = useGetInterventionsQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (data?.data) setInterventions(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const [deleteIntervention] = useDeleteInterventionMutation();

  const handleEdit = (intervention) => {
    intervention = JSON.parse(intervention);
    navigate(`/interventions/edit/${intervention.id}`, {
      state: { intervention },
    });
  };

  const handleClickOpen = (intervention) => {
    setSelectedIntervention(intervention);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedIntervention(value);
  };

  const handleView = (intervention) => {
    intervention = JSON.parse(intervention);
    navigate(`/interventions/${intervention.id}`, { state: { intervention } });
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        const getIntervention = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="warning"
                onClick={() => handleView(getIntervention())}
              >
                {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
              </Button>
              {canEdit && (
                <Button
                  color="secondary"
                  onClick={() => handleEdit(getIntervention())}
                >
                  {t("global.table.edit_button")} <EditOutlinedIcon />
                </Button>
              )}
              {canDelete && (
                <Button
                  color="error"
                  onClick={() => handleClickOpen(getIntervention())}
                >
                  {t("global.table.delete_button")}{" "}
                  <DeleteOutlineOutlinedIcon />
                </Button>
              )}
            </ButtonGroup>
            {openDialog && (
              <DeleteDialog
                open={openDialog}
                onClose={handleClose}
                name={JSON.parse(selectedIntervention).title}
                deleteMethod={deleteIntervention}
                url={`/intervention/delete/${
                  JSON.parse(selectedIntervention).id
                }`}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t("global.table.name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.table.description"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "copay_upfront",
      headerName: t("intervention.shared.copay_upfront"),
      valueGetter: (params) => (params.row?.copay_upfront ? "Yes" : "No"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "project title",
      headerName: t("project.shared.project_name"),
      valueGetter: (params) => params?.row?.project?.title,
      flex: 1,
      minWidth: 100,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("intervention.index.title")}
        subtitle={t("intervention.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Interventions")}
        </Typography>
      </Breadcrumbs>
      {canAdd && (
        <Box display="flex" justifyContent="end" mt="20px">
          <Link style={LinkColor()} to="/interventions/add" underline="none">
            <button className="btn btn-primary">
              {t("intervention.index.add_button")}
            </button>
          </Link>
        </Box>
      )}

      <Box className="data_grid_box_container">
        {interventions?.length < 1 ? (
          <Alert severity="info">
            {t("intervention.index.no_intervention_data")}
          </Alert>
        ) : (
          <StyledDataGrid
            rows={interventions}
            columns={columns}
            pagination
            paginationMode="server"
            pageSize={pagination.perPage}
            rowCount={pagination.total}
            onFilterModelChange={handleFilterModelChange}
            loading={isFetching}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={default_rows_per_page_options}
          />
        )}
      </Box>
    </Box>
  );

  return content;
};

export default Interventions;
