import { apiSlice } from "../../api/apiSlice";

export const paymentsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["payments"],
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: () => ({
        url: "/payment/list",
        method: "GET",
      }),
      keepUnusedDataFor: 2,
      providesTags: ["payments"],
    }),
    getPaymentFields: builder.query({
      query: () => ({
        url: "/payment/fields",
        method: "GET",
      }),
      keepUnusedDataFor: 2,
    }),
    createPayment: builder.mutation({
      query: (payload) => ({
        url: "/payment/create",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["payments"],
    }),
    getPayment: builder.query({
      query: (id) => ({
        url: `/payment/single/${id}`,
        method: "GET",
      }),
    }),
    exportPaymentList: builder.mutation({
      query: (id) => ({
        url: `/payment/export/${id}`,
        method: "GET",
      }),
    }),
    getPaymentTransactionsList: builder.query({
      query: (id) => ({
        url: `/payment/list/transactions/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetPaymentsQuery,
  useGetPaymentFieldsQuery,
  useCreatePaymentMutation,
  useGetPaymentQuery,
  useExportPaymentListMutation,
  useGetPaymentTransactionsListQuery,
} = paymentsApiSlice;
