import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import React from "react";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {useBlockBeneficiariesMutation} from "../../../features/beneficiaries/beneficiariesApiSlice";

const BlockBeneficiariesDialog = ({ onClose, open, beneficiariesData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialValues = {
    reason: ""
  };
  const beneficiarySchema = yup.object().shape({
    reason: yup.string().required(t('beneficiaries.view.block_reasons'))
  });
  const [blockBeneficiaries] = useBlockBeneficiariesMutation();

  const handleSubmit = async (values) => {
    if (beneficiariesData.length) {
      const payload = {
        ...values,
        beneficiaries: beneficiariesData
      };
      try {
        const response = await blockBeneficiaries(payload).unwrap();

        if (response.status === 200) {
          Toast(response?.message, "success");

          setTimeout(function () {
            navigate("/beneficiaries/edit");
          }, 3000);
        } else {
          Toast(response?.message, "error");
        }
        onClose();
      } catch (err) {
        Toast(err?.data?.message, "error");
      }
    }
  };

  const handleClose = async (value) => {
    onClose(value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <ToastContainer />
      <DialogTitle>
        {t("global.dialog.block_heading")} <b>{beneficiariesData.length}</b> {t("packages.view.beneficiaries")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">
          {t("global.dialog.block_content")} {beneficiariesData.length} {t("packages.view.beneficiaries")}{"? "}
        </Typography>
        {/*--*/}
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={beneficiarySchema}
        >
          {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                    autoFocus
                    fullWidth
                    variant="filled"
                    type="text"
                    multiline
                    maxRows={4}
                    required
                    label={t('beneficiaries.view.block_reasons')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reason}
                    name="reason"
                    error={!!touched.reason && !!errors.reason}
                    helperText={touched.reason && errors.reason}
                />

                <Box display="flex" gap="10px" justifyContent="end" mt="20px">
                  <button type="submit" className="btn btn-primary">
                    {t("global.dialog.buttons.block")}
                  </button>
                  <button autoFocus onClick={onClose} className="btn btn-danger">
                    {t('global.page.cancel_button')}
                  </button>
                </Box>
              </form>
          )}
        </Formik>
        {/*--*/}
      </DialogContent>
      <DialogActions>
        {/*onClick={() => handleClose(false)}*/}
      </DialogActions>
    </Dialog>
  );
};

export default BlockBeneficiariesDialog;
