import React, { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useAddPackageMutation } from "../../../features/packages/packagesApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import { useTranslation } from "react-i18next";
import { useGetEntitlementTypesQuery } from "../../../features/entitlement_types/entitlementTypesApiSlice";
import { LinkColor } from "../../../theme";

const initialValues = {
  project_id: "",
  intervention_id: "",
  entitlement_type_id: "",
  title: "",
  code: "",
  description: "",
  copay_amount: "0",
  subsidy_amount: "",
};

const AddPackage = () => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState();

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const packagesSchema = yup.object().shape({
    project_id: yup.string().required(t("global.form.helpertext.project")),
    intervention_id: yup
      .string()
      .required(t("global.form.helpertext.intervention")),
    entitlement_type_id: yup
      .string()
      .required(t("global.form.helpertext.entitlement_type")),
    title: yup.string().required(t("packages.form.helpertext.name")),
    code: yup.string().required(t("packages.form.helpertext.code")),
    description: yup
      .string()
      .required(t("packages.form.helpertext.description")),
    copay_amount: yup
      .string()
      .required(t("packages.form.helpertext.copay_amount")),
    subsidy_amount: yup
      .string()
      .required(t("packages.form.helpertext.subsidy_amount")),
  });

  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [interventions, setInterventions] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: packagesSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const {
    data: projectsData,
    isLoading: fetchingProjects,
    isSuccess: projectSuccess,
  } = useGetProjectsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (projectsData?.data) setProjects(projectsData?.data);
  }, [projectsData, projectSuccess]);

  const {
    data: interventionsData,
    isLoading: fetchingInterventions,
    isSuccess: interventionsSuccess,
  } = useGetInterventionsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (interventionsData?.data) {
      const projectId = formik.values.project_id;

      if (projectId) {
        const interventions = interventionsData?.data?.filter(
          (intervention) => {
            return intervention?.project?.id === projectId;
          }
        );
        setInterventions(interventions);
      }
    }
  }, [interventionsData, interventionsSuccess, formik.values.project_id]);

  // Fetch available entitlement types
  const [entitlementTypes, setEntitlementTypes] = useState([]);
  const {
    data: entitlementTypeData,
    isLoading: fetchingEntitlementTypes,
    isSuccess,
  } = useGetEntitlementTypesQuery({
    page: 1,
    pageSize: default_rows_per_page,
  });

  useEffect(() => {
    if (entitlementTypeData?.data)
      setEntitlementTypes(entitlementTypeData?.data);
  }, [entitlementTypeData, isSuccess, fetchingEntitlementTypes]);

  const [addPackage, { isLoading }] = useAddPackageMutation();

  const handleFormSubmit = async (values) => {
    //console.log(values);
    try {
      const response = await addPackage(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(function () {
        navigate("/interventions/packages", {
          state: { message: response?.message },
        });
      }, 3000);
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("packages.add.title")}
        subtitle={t("packages.add.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/packages">
          <Typography variant="body2">{t("sidebar.Packages")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("packages.index.add_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={packagesSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={formik.handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingProjects ? (
                <p>{t("global.loading.project")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.project")}
                  name="project_id"
                  value={formik.values.project_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.project_id && !!formik.errors.project_id
                  }
                  helperText={
                    formik.touched.project_id && formik.errors.project_id
                  }
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.code} - {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {fetchingInterventions ? (
                <p>{t("global.loading.intervention")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    projects?.length > 0 && interventions?.length === 0
                      ? t("global.form.helpertext.no_intervention")
                      : t("global.form.helpertext.intervention")
                  }
                  name="intervention_id"
                  value={formik.values.intervention_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.intervention_id &&
                    !!formik.errors.intervention_id
                  }
                  helperText={
                    formik.touched.intervention_id &&
                    formik.errors.intervention_id
                  }
                >
                  {interventions &&
                    interventions.map((intervention) => (
                      <MenuItem key={intervention.id} value={intervention.id}>
                        {intervention.title}
                      </MenuItem>
                    ))}
                </TextField>
              )}

              {fetchingEntitlementTypes ? (
                <p>{t("global.loading.entitlement_types")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.entitlement_type")}
                  name="entitlement_type_id"
                  value={formik.values.entitlement_type_id}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const selectedType = entitlementTypes.find(
                      (type) => type.id === e.target.value
                    );
                    setSelectedType(selectedType ? selectedType.type : "");
                  }}
                  error={
                    !!formik.touched.entitlement_type_id &&
                    !!formik.errors.entitlement_type_id
                  }
                  helperText={
                    formik.touched.entitlement_type_id &&
                    formik.errors.entitlement_type_id
                  }
                >
                  {entitlementTypes.map((entitlementType) => (
                    <MenuItem
                      key={entitlementType.id}
                      value={entitlementType.id}
                    >
                      {entitlementType.type}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("packages.form.label.name")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.title}
                name="title"
                error={!!formik.touched.title && !!formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("packages.form.label.code")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                name="code"
                error={!!formik.touched.code && !!formik.errors.code}
                helperText={formik.touched.code && formik.errors.code}
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={3}
                type="text"
                label={t("global.form.label.description")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                name="description"
                error={
                  !!formik.touched.description && !!formik.errors.description
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              {selectedType &&
                selectedType !== "CASH" &&
                selectedType !== "IN-KIND" && (
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label={t("packages.form.label.copay_amount")}
                    onBlur={handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.copay_amount}
                    name="copay_amount"
                    error={
                      !!formik.touched.copay_amount &&
                      !!formik.errors.copay_amount
                    }
                    helperText={
                      formik.touched.copay_amount && formik.errors.copay_amount
                    }
                  />
                )}

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t("packages.form.label.subsidy_amount")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.subsidy_amount}
                name="subsidy_amount"
                error={
                  !!formik.touched.subsidy_amount &&
                  !!formik.errors.subsidy_amount
                }
                helperText={
                  formik.touched.subsidy_amount && formik.errors.subsidy_amount
                }
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Link to="/interventions/packages" underline="none">
                <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("packages.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddPackage;
