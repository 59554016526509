import React, { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAddTopupMutation } from "../../../features/topups/topupsApiSlice";
import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import { useGetSeasonsQuery } from "../../../features/seasons/seasonsApiSlice";
import { useGetPackagesQuery } from "../../../features/packages/packagesApiSlice";
import { useGetEntitlementCriteriaQuery } from "../../../features/entitlement_criteria/entitlementCriteriaApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import { useTranslation } from "react-i18next";
import { useGetEntitlementTypesQuery } from "../../../features/entitlement_types/entitlementTypesApiSlice";
import { LinkColor } from "../../../theme";

const initialValues = {
  project_id: "",
  intervention_id: "",
  season_id: "",
  package_id: "",
  entitlement_type_id: "",
  entitlement_criteria_id: "",
  title: "",
  copay_amount: "",
  subsidy_amount: "",
  topup_type: "",
};

const AddTopup = () => {
  const { t } = useTranslation();
  const topupSchema = yup.object().shape({
    project_id: yup.string().required(t("global.form.label.project")),
    intervention_id: yup.string().required(t("global.form.label.intervention")),
    season_id: yup.string().required(t("topups.form.helpertext.season")),
    package_id: yup.string().required(t("topups.form.helpertext.package")),

    entitlement_type_id: yup
      .string()
      .required(t("global.form.label.entitlement_type")),
    entitlement_criteria_id: yup
      .string()
      .required(t("topups.form.helpertext.entitlement_criteria")),
    title: yup.string().required(t("topups.form.helpertext.title")),
    copay_amount: yup
      .string()
      .required(t("topups.form.helpertext.copay_amount")),
    subsidy_amount: yup
      .string()
      .required(t("topups.form.helpertext.subsidy_amount")),
    topup_type: yup.string().required(t("topups.form.helpertext.type")),
  });

  const navigate = useNavigate();
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [projects, setProjects] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [packages, setPackages] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: topupSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const {
    data: projectsData,
    isLoading: fetchingProjects,
    isSuccess: projectSuccess,
  } = useGetProjectsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (projectsData?.data) setProjects(projectsData?.data);
  }, [projectsData, projectSuccess]);

  const {
    data: interventionsData,
    isLoading: fetchingInterventions,
    isSuccess: interventionsSuccess,
  } = useGetInterventionsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (interventionsData?.data) {
      const projectId = formik.values.project_id;

      if (projectId) {
        const interventions = interventionsData?.data?.filter(
          (intervention) => {
            return intervention?.project?.id === projectId;
          }
        );
        setInterventions(interventions);
      }
    }
  }, [interventionsData, interventionsSuccess, formik.values.project_id]);

  const {
    data: seasonsData,
    isLoading: fetchingSeasons,
    isSuccess: seasonSuccess,
  } = useGetSeasonsQuery({ page: 1, pageSize: default_rows_per_page });
  useEffect(() => {
    if (seasonsData?.data) {
      const interventionId = formik.values.intervention_id;

      if (interventionId) {
        const seasons = seasonsData?.data?.filter((season) => {
          return season?.intervention?.id === interventionId;
        });
        setSeasons(seasons);
      }
    }
  }, [seasonsData, seasonSuccess, formik.values.intervention_id]);

  const {
    data: packageData,
    isLoading: fetchingPackages,
    isSuccess: packageSuccess,
  } = useGetPackagesQuery({ page: 1, pageSize: default_rows_per_page });
  useEffect(() => {
    if (packageData?.data) {
      const interventionId = formik.values.intervention_id;

      if (interventionId) {
        const packages = packageData?.data?.filter((packageData) => {
          return packageData?.intervention?.id === interventionId;
        });
        setPackages(packages);
      }
    }
  }, [packageData, packageSuccess, formik.values.intervention_id]);
  const {
    data: entitlementCriteriaData,
    isLoading: fetchingEntitlementCriteria,
  } = useGetEntitlementCriteriaQuery({ page: 1, pageSize: default_rows_per_page });
  const entitlementCriteria = entitlementCriteriaData?.data;

  // Fetch available entitlement types
  const [entitlementTypes, setEntitlementTypes] = useState([]);
  const {
    data: entitlementTypeData,
    isLoading: fetchingEntitlementTypes,
    isSuccess,
  } = useGetEntitlementTypesQuery({
    page: 1,
    pageSize: default_rows_per_page,
  });

  useEffect(() => {
    if (entitlementTypeData?.data)
      setEntitlementTypes(entitlementTypeData?.data);
  }, [entitlementTypeData, isSuccess, fetchingEntitlementTypes]);

  const [addTopup, { isLoading }] = useAddTopupMutation();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFormSubmit = async (values) => {
    values = {
      ...values,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    try {
      const response = await addTopup(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(function () {
        navigate("/interventions/topups", {
          state: { message: response?.message },
        });
      }, 3000);
    } catch (err) {
      Toast(err?.data?.errors, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("topups.add.title")}
        subtitle={t("topups.add.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/topups">
          <Typography variant="body2">{t("sidebar.Topups")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("topups.index.add_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={formik.handleFormSubmit}
        initialValues={initialValues}
        validationSchema={topupSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={formik.handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingProjects ? (
                <p>{t("global.loading.project")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.project")}
                  name="project_id"
                  value={formik.values.project_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.project_id && !!formik.errors.project_id
                  }
                  helperText={
                    formik.touched.project_id && formik.errors.project_id
                  }
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.code} - {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {fetchingInterventions ? (
                <p>{t("global.loading.intervention")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    projects?.length > 0 && interventions?.length === 0
                      ? t("global.form.helpertext.no_intervention")
                      : t("global.form.helpertext.intervention")
                  }
                  name="intervention_id"
                  value={formik.values.intervention_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.intervention_id &&
                    !!formik.errors.intervention_id
                  }
                  helperText={
                    formik.touched.intervention_id &&
                    formik.errors.intervention_id
                  }
                >
                  {interventions &&
                    interventions.map((intervention) => (
                      <MenuItem key={intervention.id} value={intervention.id}>
                        {intervention.title}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {fetchingSeasons ? (
                <p>{t("global.loading.season")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    interventions?.length > 0 && seasons?.length === 0
                      ? t("global.form.helpertext.no_season")
                      : t("global.form.helpertext.season")
                  }
                  name="season_id"
                  value={formik.values.season_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.season_id && !!formik.errors.season_id
                  }
                  helperText={
                    formik.touched.season_id && formik.errors.season_id
                  }
                >
                  {seasons.map((season) => (
                    <MenuItem key={season.id} value={season.id}>
                      {season.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {fetchingPackages ? (
                <p>{t("global.loading.package")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    interventions?.length > 0 && packages?.length === 0
                      ? t("global.form.helpertext.no_package")
                      : t("global.form.helpertext.package")
                  }
                  name="package_id"
                  value={formik.values.package_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.package_id && !!formik.errors.package_id
                  }
                  helperText={
                    formik.touched.package_id && formik.errors.package_id
                  }
                >
                  {packages.map((packageData) => (
                    <MenuItem key={packageData.id} value={packageData.id}>
                      {packageData.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {fetchingEntitlementTypes ? (
                <p>{t("global.loading.entitlement_types")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.entitlement_type")}
                  name="entitlement_type_id"
                  value={formik.values.entitlement_type_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.entitlement_type_id &&
                    !!formik.errors.entitlement_type_id
                  }
                  helperText={
                    formik.touched.entitlement_type_id &&
                    formik.errors.entitlement_type_id
                  }
                >
                  {entitlementTypes.map((entitlementType) => (
                    <MenuItem
                      key={entitlementType.id}
                      value={entitlementType.id}
                    >
                      {entitlementType.type}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {fetchingEntitlementCriteria ? (
                <p>{t("global.loading.criteria")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.criteria")}
                  name="entitlement_criteria_id"
                  value={formik.values.entitlement_criteria_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.entitlement_criteria_id &&
                    !!formik.errors.entitlement_criteria_id
                  }
                  helperText={
                    formik.touched.entitlement_criteria_id &&
                    formik.errors.entitlement_criteria_id
                  }
                >
                  {entitlementCriteria.map((entitlementCriteria) => (
                    <MenuItem
                      key={entitlementCriteria.id}
                      value={entitlementCriteria.id}
                    >
                      {entitlementCriteria.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("topups.form.label.title")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.title}
                name="title"
                error={
                  !!formik.touched.title && !!formik.errors.title
                }
                helperText={
                  formik.touched.title && formik.errors.title
                }
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t("packages.form.label.copay_amount")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.copay_amount}
                name="copay_amount"
                error={
                  !!formik.touched.copay_amount && !!formik.errors.copay_amount
                }
                helperText={
                  formik.touched.copay_amount && formik.errors.copay_amount
                }
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t("packages.form.label.subsidy_amount")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.subsidy_amount}
                name="subsidy_amount"
                error={
                  !!formik.touched.subsidy_amount &&
                  !!formik.errors.subsidy_amount
                }
                helperText={
                  formik.touched.subsidy_amount && formik.errors.subsidy_amount
                }
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("project.shared.start_date")}
                  value={startDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setStartDate(Date.parse(newValue));
                  }}
                  formatDate={(startDate) =>
                    moment(startDate).format("DD-MM-YYYY")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("project.shared.end_date")}
                  value={endDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setEndDate(Date.parse(newValue));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <TextField
                select
                variant="filled"
                label={t("topups.form.label.type")}
                name="topup_type"
                value={formik.values.topup_type}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.topup_type && !!formik.errors.topup_type
                }
                helperText={
                  formik.touched.topup_type && formik.errors.topup_type
                }
              >
                <MenuItem value="NEW SEASON">{t("topups.form.label.new")}</MenuItem>
                <MenuItem value="MID-SEASON">
                  {t("topups.form.label.mid")}
                </MenuItem>
              </TextField>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/interventions/topups" underline="none">

              <button
                className="btn btn-danger"
              >
                  {t("global.page.cancel_button")}
              </button>
              </Link>

              <button
                type="submit"
                className="btn btn-primary"
              >
                {t("topups.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddTopup;
