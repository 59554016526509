import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useGetBeneficiariesQuery } from "../../../features/beneficiaries/beneficiariesApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Button,
  AlertTitle,
  Alert,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import { useAddTopupBeneficiariesMutation, useGetTopupQuery } from "../../../features/topups/topupsApiSlice";
import { LinkColor } from "../../../theme";

const TopupAddBeneficiaries = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [ids, setIds] = useState([]);
  const navigate = useNavigate();

  const [beneficiaries, setBeneficiaries] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );

  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

    console.log(default_rows_per_page_options)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);
  const { data, isSuccess, isFetching, isLoading } = useGetBeneficiariesQuery({
    page,
    pageSize,
  });

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  useEffect(() => {
    if (data?.data) setBeneficiaries(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);


  const [addBeneficiaries] = useAddTopupBeneficiariesMutation();

  const { data: topupData } = useGetTopupQuery(id);
  const topup = topupData?.data

  const columns = [
    {
      field: "full_name",
      headerName: t("beneficiaries.table.fullname"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 1,
    },
    {
      field: "distribution_point",
      headerName: t("distribution_points.form.label.distribution_point"),
      valueGetter: (params) => params.row?.distribution_point?.title,
      flex: 1,
    },
  ];

  const selectedBeneficiaries = (ids) => {
    setIds(ids);
  };

  const handleAdd = async () => {
    if (ids.length > 0) {
      const payload = {
        topup_id: id,
        beneficiaries: ids,
      };

      try {
        const response = await addBeneficiaries(payload).unwrap();

        if (response.status === 200) {
          navigate(`/interventions/topups/view/${id}`);
        } else {
          Toast(response?.message, "error");
        }
      } catch (err) {
        Toast(err?.data?.message, "error");
      }
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("topups.add_beneficiaries.title")}
        subtitle={t("packages.add_beneficiaries.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/topups">
          <Typography variant="body2">{t("sidebar.Topups")}</Typography>
        </Link>
        <Link
          style={LinkColor()}
          color="inherit"
          to={`/interventions/topups/view/${id}`}
        >
          <Typography variant="body2">
            {t("topups.form.helpertext.view")}
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("global.buttons.add_ben")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("packages.add_beneficiaries.alert_head")}</AlertTitle>
        {t("packages.add_beneficiaries.alert_message")}{" "}
        <strong>{topup?.name}</strong>
      </Alert>
      <button
        className="btn btn-primary"
        onClick={handleAdd}
      >
        {t("global.buttons.add_ben")}
      </button>
      <Box className="data_grid_box_container">
        <StyledDataGrid
          checkboxSelection
          rows={beneficiaries}
          columns={columns}
          pagination
          paginationMode="server"
          pageSize={pagination.perPage}
          rowCount={pagination.total}
          loading={isFetching}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={default_rows_per_page_options}
          onSelectionModelChange={(ids) => selectedBeneficiaries(ids)}
        />
      </Box>
    </Box>
  );
  return content;
};

export default TopupAddBeneficiaries;
