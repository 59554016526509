import { apiSlice } from "../../api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    addUser: builder.mutation({
      query: (payload) => ({
        url: "/users/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Users"],
    }),
    getUsers: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
          const queryString = new URLSearchParams({
              ...apiFilters,
              page: page,
              pageSize: pageSize
          }).toString();

          return {
              url: `/users/all/${pageSize}?${queryString}`,
              method: "GET",
          };
      },
      providesTags: ["Users"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getUser: builder.query({
      query: (id) => ({
        url: `/users/single/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
    }),
    updateUser: builder.mutation({
      query: (payload) => ({
        url: `/users/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Users"],
    }),
    activateUser: builder.mutation({
      query: (payload) => ({
        url: "/users/activate",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    restoreUser: builder.mutation({
      query: (url) => ({
        url: url,
        method: "GET",
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useAddUserMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useActivateUserMutation,
  useDeleteUserMutation,
  useRestoreUserMutation,
} = usersApiSlice;
