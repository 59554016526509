import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const usePermissionGuard = (permissions) => {
    const currentPermissions = useSelector(selectCurrentPermissions);

  const navigate = useNavigate();
  const checkPermissions = (permissions) => {
    if (currentPermissions) {
        return currentPermissions.some(permission => permission.name === permissions);
    }

  };

  useEffect(() => {
    // Check if the user has the required permissions
    const hasPermission = checkPermissions(permissions);

    if (!hasPermission) {
      // Redirect the user to a different route if they don't have the required permissions
      navigate("/unauthorized");
    }
  }, [permissions, navigate]);

  return null;
};



export default usePermissionGuard;
