import { apiSlice } from "../../api/apiSlice";

export const packagesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Packages",
    "Package",
    "PackageBeneficiaries",
    "PackageEntitlements",
  ],
  endpoints: (builder) => ({
    addPackage: builder.mutation({
      query: (payload) => ({
        url: "/package/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Packages"],
    }),
    getPackages: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/package/list/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Packages"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getPackage: builder.query({
      query: (id) => ({
        url: `/package/single/${id}`,
        method: "GET",
      }),
      providesTags: ["Package"],
      keepUnusedDataFor: 1,
    }),
    updatePackage: builder.mutation({
      query: (payload) => ({
        url: `/package/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Packages"],
    }),
    deletePackage: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Packages"],
    }),
    addPackageEntitlements: builder.mutation({
      query: (payload) => ({
        url: "/package/add/entitlements",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Package", "Packages", "PackageEntitlements"],
    }),
    getPackageEntitlements: builder.query({
      query: (id) => ({
        method: "GET",
        url: `/package/fetch/entitlements/${id}`,
      }),
      providesTags: ["PackageEntitlements"],
      keepUnusedDataFor: 1,
    }),
    changeEntitlementPackageStatus: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: "/package/change/entitlement",
        body: { ...payload },
      }),
      invalidatesTags: ["PackageEntitlements"],
    }),
    updatePackageEntitlement: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: "/package/edit/entitlement",
        body: { ...payload },
      }),
      invalidatesTags: ["PackageEntitlements"],
    }),
    getPackageBeneficiaries: builder.query({
      query: (id) => ({
        url: `/package/fetch/beneficiaries/${id}`,
        method: "GET",
      }),
      providesTags: ["PackageBeneficiaries"],
      keepUnusedDataFor: 1,
    }),
    addPackageBeneficiaries: builder.mutation({
      query: (payload) => ({
        url: "package/add/beneficiaries",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["PackageBeneficiaries", "Packages", "Package"],
    }),
    changeBeneficiaryPackageStatus: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: "/package/change/beneficiary",
        body: { ...payload },
      }),
      invalidatesTags: ["PackageBeneficiaries"],
    }),
  }),
});

export const {
  useAddPackageMutation,
  useGetPackagesQuery,
  useGetPackageQuery,
  useUpdatePackageMutation,
  useDeletePackageMutation,
  useAddPackageEntitlementsMutation,
  useGetPackageEntitlementsQuery,
  useChangeEntitlementPackageStatusMutation,
  useUpdatePackageEntitlementMutation,
  useGetPackageBeneficiariesQuery,
  useAddPackageBeneficiariesMutation,
  useChangeBeneficiaryPackageStatusMutation
} = packagesApiSlice;
