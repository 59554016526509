import React from 'react'

export const AuthGuard = () => {
    let isAuthenticated = false
    const authData = localStorage.getItem('idea')

    return (
    <div>AuthGuard</div>
    )
}

export const SaveTokenExpireTime =  (expires_in) => {
    let now = new Date()
    now.setSeconds(now.getSeconds() + expires_in)
    localStorage.setItem('idexpiry_time', ''+now.getTime())
}

export const IsLoggedIn = () => {
    const authData = localStorage.getItem('idea')
    if(authData) {
        let expiryDate = new Date(parseInt(localStorage.getItem('idexpiry_time')))
        let now = new Date()
        if(expiryDate > now) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

export const getAccessToken = () => {
    const authData = JSON.parse(localStorage.getItem('idea'))
    if(authData) {
        return authData?.data?.access_token
    } else {
        return null
    }
}