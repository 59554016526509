import { apiSlice } from "../../api/apiSlice";

export const distributionPointsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["DistributionPoints", "DPServiceProviders", "DPBeneficiaries"],
  endpoints: (builder) => ({
    addDistributionPoint: builder.mutation({
      query: (payload) => ({
        url: "/distributionpoint/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["DistributionPoints"],
    }),
    getDistributionPoints: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/distributionpoint/list/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["DistributionPoints"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getDistributionPoint: builder.query({
      query: (id) => ({
        url: `/distributionpoint/single/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateDistributionPoint: builder.mutation({
      query: (payload) => ({
        url: `/distributionpoint/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["DistributionPoints"],
    }),
    deleteDistributionPoint: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["DistributionPoints"],
    }),
    getSpDistributionPoints: builder.query({
      query: (id) => ({
        url: `/users/distribution/points/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["DistributionPoints"],
      keepUnusedDataFor: 1,
    }),
    getDpServiceProviders: builder.query({
      query: (id) => ({
        url: `/distributionpoint/service/providers/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      providesTags: ["DPServiceProviders"],
    }),
    removeDpServiceProviders: builder.mutation({
      query: (payload) => ({
        url: "/distributionpoint/remove/service/providers",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["DPServiceProviders", "DistributionPoints"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getDpBeneficiaries: builder.query({
      query: (id) => ({
        url: `/distributionpoint/beneficiaries/${id}/web`,
        method: "GET",
      }),
      providesTags: ["DPBeneficiaries"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    addDpServiceProviders: builder.mutation({
      query: (payload) => ({
        url: "/distributionpoint/add/service/providers",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["DPServiceProviders", "DistributionPoints"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    addDpBeneficiaries: builder.mutation({
      query: (payload) => ({
        url: "/distributionpoint/add/beneficiaries",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["DPBeneficiaries", "DistributionPoints"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const {
  useAddDistributionPointMutation,
  useGetDistributionPointsQuery,
  useGetDistributionPointQuery,
  useUpdateDistributionPointMutation,
  useDeleteDistributionPointMutation,
  useGetSpDistributionPointsQuery,
  useGetDpServiceProvidersQuery,
  useGetDpBeneficiariesQuery,
  useAddDpServiceProvidersMutation,
  useAddDpBeneficiariesMutation,
  useRemoveDpServiceProvidersMutation,
} = distributionPointsApiSlice;
