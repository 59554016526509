import { useState } from "react";
import { Box, TextField, Button} from "@mui/material";
import Spinner from "../../global/Spinner";
import Header from "../../Header";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import * as yup from "yup";
import { useFetchProjectsFromGrmsMutation } from "../../../features/settings/settingsApiSlice";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const initialValues = {
  section: "",
};

const GRMS = () => {
  const { t } = useTranslation();
  const grmsSchema = yup.object().shape({
    section: yup.string().required(t("grms.form.helpertext.section")),
  });
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [fetchProjects, { isLoading }] = useFetchProjectsFromGrmsMutation();

  const handleFormSubmit = async (values) => {
    const payload = {
      param_df: moment(startDate).format("DD-MM-YYYY"),
      param_1: moment(startDate).format("YYYY-MM"),
      param_dt: moment(endDate).format("DD-MM-YYYY"),
      param_2: moment(endDate).format("YYYY-MM"),
      param_section: values?.section,
    };

    try {
      const response = await fetchProjects(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }
      navigate("/projects", { state: { message: response?.message } });
    } catch (err) {
      Toast(JSON.stringify(err?.data?.errors), "error");
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("grms.index.title")}
        subtitle={t("grms.index.subtitle")}
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={grmsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("project.shared.start_date")}
                  value={startDate}
                  name="startDate"
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setStartDate(Date.parse(newValue));
                  }}
                  formatDate={(startDate) =>
                    moment(startDate).format("YYYY-MM-DD")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("project.shared.end_date")}
                  value={endDate}
                  name="endDate"
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setEndDate(Date.parse(newValue));
                  }}
                  formatDate={(endDate) => moment(endDate).format("YYYY-MM-DD")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("grms.form.label.section")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.section}
                name="section"
                message={t("grms.form.helpertext.section")}
                error={!!touched.section && !!errors.section}
                helperText={t("grms.form.helpertext.section")}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link style={LinkColor()} to="/" underline="none">

              <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("grms.index.fetch_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );

  return content;
};

export default GRMS;
