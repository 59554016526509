import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGetInterventionsQuery,
  useAddRoleInterventionMutation,
} from "../../../features/interventions/interventionsApiSlice";
import { useGetRoleInterventionsQuery } from "../../../features/roles/rolesApiSlice";

import {
  Box,
  Alert,
  AlertTitle,
  Typography,
  Breadcrumbs,
  Button,
} from "@mui/material";
import StyledDataGrid from "../../global/StyledDataGrid";
import { LinkColor } from "../../../theme";
import Header from "../../Header";
import { ToastContainer } from "react-toastify";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";

export default function AddRoleIntervention() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  // Default values from environment variables
  const defaultRowsPerPage = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const defaultRowsPerPageOptions =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [presentInterventions, setPresentInterventions] = useState([]);
  const [selectedInterventionIds, setSelectedInterventionIds] = useState([]);
  const [allInterventions, setAllInterventions] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultRowsPerPage);
  const [filterModel, setFilterModel] = useState([]);

  // Fetch present interventions
  const {
    data: interventionData,
    isLoading: isLoadingIntervention,
    isSuccess: isSuccessIntervention,
  } = useGetRoleInterventionsQuery(id);

  useEffect(() => {
    if (isSuccessIntervention && interventionData?.interventions) {
      setPresentInterventions(interventionData.interventions);
    }
  }, [interventionData, isSuccessIntervention]);

  // Handle filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };

  // Convert filter model to API filters
  const getApiFilters = () => {
    const apiFilters = {};
    filterModel.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });
    return apiFilters;
  };

  // Fetch all interventions
  const {
    data: allInterventionsData,
    isLoading: isAllInterventionLoading,
    isSuccess: isAllInterventionSuccess,
    isFetching: fetchingAllInterventions,
  } = useGetInterventionsQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (isAllInterventionSuccess && allInterventionsData?.data) {
      setAllInterventions(allInterventionsData.data);
    }
  }, [allInterventionsData, isAllInterventionSuccess]);

  // Handle selection changes
  const handleSelectionChange = (ids) => {
    setSelectedInterventionIds(ids);
  };

  // Check if the title value matches present role title value
  const checkNameMatch = (title) => {
    return presentInterventions.some((role) => role.title === title);
  };

  // Get cell class name based on name match
  const getCellClassName = (params) => {
    return checkNameMatch(params.row.title) ? "matched-name-cell" : "";
  };

  // Sort interventions to place matching roles at the bottom
  const sortedIntervention = () => {
    const matchingInterventions = [];
    const nonMatchingInterventions = [];

    allInterventions.forEach((intervention) => {
      if (checkNameMatch(intervention.title)) {
        matchingInterventions.push(intervention);
      } else {
        nonMatchingInterventions.push(intervention);
      }
    });

    return [...nonMatchingInterventions, ...matchingInterventions];
  };

  // Add intervention to role
  const [addIntervention] = useAddRoleInterventionMutation();

  const handleAdd = async () => {
    if (selectedInterventionIds.length === 0) {
      // Handle case where no interventions are selected
      return;
    }

    const payload = {
      role_id: id,
      intervention_ids: selectedInterventionIds,
    };

    try {
      const response = await addIntervention(payload).unwrap();
      if (response.status === 200) {
        navigate(`/roles/${id}`);
      } else {
        throw new Error(response?.message || "Unexpected response from server");
      }
    } catch (err) {
      Toast(err?.data?.message || "Failed to add interventions", "error");
    }
  };

  // Define columns for the data grid
  const interventionColumns = [
    {
      field: "title",
      headerName: t("global.table.name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.table.description"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "copay_upfront",
      headerName: t("intervention.shared.copay_upfront"),
      valueGetter: (params) => (params.row?.copay_upfront ? "Yes" : "No"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "project title",
      headerName: t("project.shared.project_name"),
      valueGetter: (params) => params?.row?.project?.title,
      flex: 1,
      minWidth: 100,
    },
  ];

  // Component content
  return fetchingAllInterventions ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("roles.add_interventions.title")}
        subtitle={t("roles.add_interventions.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/roles">
          <Typography variant="body2">{t("sidebar.Roles")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("roles.add_interventions.view_role")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("intervention.add_role.alert_head")}</AlertTitle>
        {t("intervention.add_role.alert_message")}
      </Alert>

      <Box marginTop="20px">
        <button
          className={
            !selectedInterventionIds.length
              ? "btn btn-disabled"
              : "btn btn-primary"
          }
          onClick={handleAdd}
          disabled={!selectedInterventionIds.length}
        >
          {t("global.buttons.add_intervention")}
        </button>
      </Box>

      <Box className="data_grid_box_container" marginTop="20px">
        <StyledDataGrid
          checkboxSelection
          rows={sortedIntervention()}
          columns={interventionColumns}
          rowsPerPageOptions={defaultRowsPerPageOptions}
          onSelectionModelChange={handleSelectionChange}
          getCellClassName={getCellClassName}
          disableSelectionOnClick={(params) => checkNameMatch(params.row.title)}
        />
      </Box>
    </Box>
  );
}
