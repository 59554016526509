import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  useGetRoleQuery,
  useGetRoleUsersQuery,
  useGetRolePermissionsQuery,
  useGetRoleInterventionsQuery,
} from "../../../features/roles/rolesApiSlice";
import Spinner from "../../global/Spinner";
import {
  Box,
  Paper,
  Card,
  Typography,
  CardContent,
  Button,
  Tab,
  Breadcrumbs,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const ViewRole = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [permissions, setPermissions] = useState([]);
  const [users, setUsers] = useState([]);
  const [interventions, setInterventions] = useState([]);
  // Fetch role
  const { data, isLoading } = useGetRoleQuery(id);
  const role = data?.data;

  // Fetch users in a role
  const {
    data: userData,
    isLoading: fetchingUsers,
    isSuccess: userSuccess,
  } = useGetRoleUsersQuery(id);

  useEffect(() => {
    if (userData?.data) setUsers(userData?.data);
  }, [userData, userSuccess]);

  // Fetch permissions in a role
  const {
    data: permissionData,
    isLoading: fetchingPermissions,
    isSuccess: permissionSuccess,
  } = useGetRolePermissionsQuery(id);

  useEffect(() => {
    if (permissionData?.data) setPermissions(permissionData?.data);
  }, [permissionData, permissionSuccess, fetchingPermissions]);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Intervention api call
  const {
    data: interventionData,
    isLoading: isLoadingIntervention,
    isSuccess: isSuccessIntervention,
  } = useGetRoleInterventionsQuery(id);

  useEffect(() => {
    if (interventionData?.interventions)
      setInterventions(interventionData?.interventions);
  }, [interventionData, isSuccessIntervention]);

  // interventions columns
  const interventionColumns = [
    {
      field: "title",
      headerName: t("global.table.name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.table.description"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "copay_upfront",
      headerName: t("intervention.shared.copay_upfront"),
      valueGetter: (params) => (params.row?.copay_upfront ? "Yes" : "No"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "project title",
      headerName: t("project.shared.project_name"),
      valueGetter: (params) => params?.row?.project?.title,
      flex: 1,
      minWidth: 100,
    },
  ];

  const usersColumns = [
    {
      field: "first_name",
      headerName: t("users.form.label.first_name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "last_name",
      headerName: t("users.form.label.last_name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: t("users.form.label.email"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "telephone",
      headerName: "Phone Number",
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: "user_name",
      headerName: t("users.form.label.user_name"),
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 0.5,
      minWidth: 200,
    },
  ];

  const permissionsColumns = [
    {
      field: "name",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("roles.form.label.description"),
      flex: 1.5,
    },
    {
      field: "guard_name",
      headerName: t("roles.form.label.guard_name"),
      flex: 0.5,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("roles.view.title")}
        subtitle={t("roles.view.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/roles">
          <Typography variant="body2">{t("sidebar.Roles")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("roles.view.view_role")}
        </Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          "& > :not(style)": {
            m: 1,
          },
          maxWidth: "400px",
        }}
      >
        <Paper variant="outlined">
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {role?.title}
              </Typography>
              <hr />
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("global.form.label.name")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {role?.name}
                </Typography>
              </Box>

              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("roles.form.label.guard_name")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {role?.guard_name}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </Box>

      {/* Add tabs for users and permissions */}
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="Manage users and permissions in role"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label={t("users.form.label.users")} value="1" />
              <Tab label={t("roles.form.label.permissions")} value="2" />
              <Tab label={t("sidebar.Interventions")} value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Link to={`/roles/${id}/add/users`} underline="none">
              <button className="btn btn-primary">
                {t("global.buttons.add_users")}
              </button>
            </Link>

            <Box className="data_grid_box_container_short_within_tabs">
              {fetchingUsers ? (
                <p>{t("global.loading.users")}</p>
              ) : (
                <StyledDataGrid
                  rows={userData.users}
                  columns={usersColumns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Link
              to={`/roles/${id}/add/permissions`}
              underline="none"
              style={{ marginRight: "10px" }}
            >
              <button className="btn btn-primary">
                {t("global.buttons.add_permissions")}
              </button>
            </Link>

            <Link to={`/roles/${id}/remove/permissions`} underline="none">
              <button className="btn btn-danger">
                {t("global.buttons.remove_permissions")}
              </button>
            </Link>

            <Box className="data_grid_box_container_short_within_tabs">
              {fetchingPermissions ? (
                <p>{t("global.loading.permissions")}</p>
              ) : (
                <StyledDataGrid
                  rows={permissions}
                  columns={permissionsColumns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>

          {/* interventions tab */}
          <TabPanel value="3">
            <Link
              to={`/roles/${id}/add/intervention`}
              underline="none"
              style={{ marginRight: "10px" }}
            >
              <button className="btn btn-primary">
                {t("global.buttons.add_intervention")}
              </button>
            </Link>

            <Link to={`/roles/${id}/remove/intervention`} underline="none">
              <button className="btn btn-danger">
                {t("global.buttons.remove_intervention")}
              </button>
            </Link>

            <Box className="data_grid_box_container_short_within_tabs">
              {fetchingPermissions ? (
                <p>{t("global.loading.permissions")}</p>
              ) : (
                <StyledDataGrid
                  rows={interventions}
                  columns={interventionColumns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );

  return content;
};

export default ViewRole;
