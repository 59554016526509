import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  ButtonGroup,
  Button,
} from "@mui/material";
import Header from "../../Header";
import { useNavigate, Link } from "react-router-dom";
import {
  useGetBeneficiariesQuery,
  useDeleteBeneficiaryMutation,
  useRestoreBeneficiaryMutation,
  useUpdateBeneficiaryMutation,
  useBlockBeneficiaryMutation,
  useUnblockBeneficiaryMutation
} from "../../../features/beneficiaries/beneficiariesApiSlice";
import Spinner from "../../global/Spinner";
import StyledDataGrid from "../../global/StyledDataGrid";
import { ToastContainer } from "react-toastify";
import Toast from "../../global/Toast";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteDialog from "../shared/DeleteDialog";
import RestoreDialog from "../shared/RestoreDeletedDialog";
import BlockBeneficiaryDialog from "../shared/BlockBeneficiaryDialog";
import BlockBeneficiariesDialog from "../shared/BlockBeneficiariesDialog";
import UnBlockBeneficiaryDialog from "../shared/UnBlockBeneficiaryDialog"
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import UnBlockBeneficiariesDialog from "../shared/UnBlockBeneficiariesDialog";
import { LinkColor } from "../../../theme";

const EditBeneficiaries = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [openBlockBeneficiaryDialog, setOpenBlockBeneficiaryDialog] = useState(false);
  const [openBlockBeneficiariesDialog, setOpenBlockBeneficiariesDialog] = useState(false);
  const [openUnblockBeneficiaryDialog, setOpenUnblockBeneficiaryDialog] = useState(false);
  const [openUnblockBeneficiariesDialog, setOpenUnblockBeneficiariesDialog] = useState(false);
  const [, setBeneficiariesData] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const [deleteBeneficiary] = useDeleteBeneficiaryMutation();
  const [restoreBeneficiary] = useRestoreBeneficiaryMutation();
  const [updateBeneficiary] = useUpdateBeneficiaryMutation();
  const [blockBeneficiary] = useBlockBeneficiaryMutation();
  const [unblockBeneficiary] = useUnblockBeneficiaryMutation();

    // Keep track of the filter model to pass to the API
    const [filterModel, setFilterModel] = useState([]);

    // Event handler for filter changes
    const handleFilterModelChange = (params) => {
      setFilterModel(params.items);
    };

    // Get the filters from the filter model and convert them to an object
    const getApiFilters = () => {
      const apiFilters = {};
  
      filterModel?.forEach((item) => {
        if (item.columnField && item.value) {
          apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
        }
      });
  
      return apiFilters;
    };
  
    // Get the beneficiaries from the API
    const { data, isLoading, isSuccess, isFetching } = useGetBeneficiariesQuery({
      page,
      pageSize,
      ...getApiFilters(),
    });

  useEffect(() => {
    if (data?.data) setBeneficiariesData(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage]);

    // Reset the page number when the filters change
    useEffect(() => {
      setPage(1); // Set to the first page whenever filters change
    }, [filterModel]);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const beneficiaries = data?.data;

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    handleSave(updatedRow);
    return updatedRow;
  };

  const handleSave = async (beneficiary) => {
    const payload = { ...beneficiary, id: beneficiary.id };
    try {
      const response = await updateBeneficiary(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(function () {
        navigate("/beneficiaries/edit");
      }, 3000);
    } catch (err) {
      Toast(err?.data?.errors, "error");
    }
  };

  const handleDelete = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setOpenDeleteDialog(true);
  };

  const handleDeleteClose = (value) => {
    setSelectedBeneficiary(value);
    setOpenDeleteDialog(false);
  };

  // this is for restoring a deleted beneficiary
  const handleRestore = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setOpenRestoreDialog(true);
  };

  const handleRestoreClose = (value) => {
    setSelectedBeneficiary(value);
    setOpenRestoreDialog(false)
  };

  // this is for blocking a beneficiary
  const handleBlockBeneficiary = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setOpenBlockBeneficiaryDialog(true);
  };

  const handleBlockBeneficiaries = () => {
    setOpenBlockBeneficiariesDialog(true);
  };

  const handleBlockBeneficiaryClose = (beneficiary) => {
    setSelectedBeneficiary(beneficiary);
    setOpenBlockBeneficiaryDialog(false);
  };

  const handleBlockBeneficiariesClose = () => {
    setOpenBlockBeneficiariesDialog(false);
  };

  const handleUnblockBeneficiary = (value) => {
    setSelectedBeneficiary(value);
    setOpenUnblockBeneficiaryDialog(true);
  };

  const handleUnblockBeneficiaries = () => {
    setOpenUnblockBeneficiariesDialog(true);
  };

  const handleUnblockBeneficiaryClose = (value) => {
    setSelectedBeneficiary(value);
    setOpenUnblockBeneficiaryDialog(false);
  };

  const handleUnblockBeneficiariesClose = () => {
    setOpenUnblockBeneficiariesDialog(false);
  };

  const handleBulkEdit = () => {
    navigate("/beneficiaries/bulk/edit");
  }

  // selectedBeneficiaries is called when the user select something in the checkboxes,
  // it gets those IDs and stores them in ids state variable, by calling setIds
  const [ids, setIds] = useState([]);
  const selectedBeneficiaries = (ids) => {
    setIds(ids);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1.5,
      minWidth: 215,
      renderCell: (params) => {
        const getBeneficiary = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              {params?.row?.is_deleted === 1 ? (
                  <Button
                      color="primary"
                      onClick={() => handleRestore(getBeneficiary())}
                  >
                    {t('global.table.restore_button')} <RestoreFromTrashOutlinedIcon />
                  </Button>
              ) : (
                  <Button
                      color="error"
                      onClick={() => handleDelete(getBeneficiary())}
                  >
                    {t("global.table.delete_button")} <DeleteOutlineOutlinedIcon />
                  </Button>
              )}
              {params.row.status === "BLOCKED" ? (
                  <Button
                      color="primary"
                      onClick={() => handleUnblockBeneficiary(getBeneficiary())}
                  >
                    {t('global.table.unblock_button')} <RemoveCircleOutlineOutlinedIcon />
                  </Button>
              ) : (
                  <Button
                      color="error"
                      onClick={() => handleBlockBeneficiary(getBeneficiary())}
                  >
                    {t('global.table.block_button')} <BlockOutlinedIcon />
                  </Button>
              )}
            </ButtonGroup>
          
          </Box>
        );
      },
    },
    {
      field: "full_name",
      headerName: t('beneficiaries.table.fullname'),
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 250,
      editable: true,
    },
    {
      field: "id_number",
      headerName: t('users.form.label.id_number'),
      minWidth: 150,
      editable: true,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      valueGetter: (params) => params.row?.telephone,
      minWidth: 150,
      editable: true,
    },
    {
      field: "date_of_birth",
      headerName: t("beneficiaries.table.dob"),
      valueGetter: (params) => params.row?.date_of_birth,
      minWidth: 150,
      editable: true,
    },
    {
      field: "code",
      headerName: t("project.form.label.code"),
      valueGetter: (params) => params.row?.intervention[0]?.project?.code,
      minWidth: 200,
      editable: false,
    },
    {
      field: "intervention",
      headerName: t("intervention.form.label.intervention"),
      valueGetter: (params) =>
        params.row?.intervention[0]?.title
          ? params.row?.intervention[0]?.title
          : "",
      minWidth: 200,
      editable: false,
    },
    {
      field: "Distribution Point",
      headerName: t("distribution_points.form.label.distribution_point"),
      valueGetter: (params) =>
        params.row.distribution_point?.title
          ? params.row?.distribution_point?.title
          : "",
      minWidth: 300,
      editable: false,
    },
    {
      field: "status",
      headerName: t("beneficiaries.table.status"),
      valueGetter: (params) => params.row?.status,
      minWidth: 150,
      editable: true,
    },
    {
      field: "Kobo ID",
      headerName: t("beneficiaries.table.kobo_id"),
      valueGetter: (params) => params?.row?.extra?.kobo_id,
      minWidth: 200,
      editable: false,
    },
    {
      field: "geo_level_1",
      headerName: t("beneficiaries.table.geolevel1"),
      valueGetter: (params) => params.row?.geo_level_1,
      minWidth: 200,
      editable: true,
    },
    {
      field: "geo_level_2",
      headerName: t("beneficiaries.table.geolevel2"),
      valueGetter: (params) => params.row?.geo_level_2,
      minWidth: 200,
      editable: true,
    },
    {
      field: "geo_level_3",
      headerName: t("beneficiaries.table.geolevel3"),
      valueGetter: (params) => params.row?.geo_level_3,
      minWidth: 200,
      editable: true,
    },
    {
      field: "geo_level_4",
      headerName: t("beneficiaries.table.geolevel4"),
      valueGetter: (params) => params.row?.geo_level_4,
      minWidth: 200,
      editable: true,
    },
    {
      field: "geo_level_5",
      headerName: t("beneficiaries.table.geolevel5"),
      valueGetter: (params) => params.row?.geo_level_5,
      minWidth: 200,
      editable: true,
    },
    {
      field: "geo_level_6",
      headerName: t("beneficiaries.table.geolevel6"),
      valueGetter: (params) => params.row?.geo_level_6,
      minWidth: 200,
      editable: true,
    },
  ];

  const content = isLoading ? ( <Spinner /> ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("beneficiaries.edit.title")}
        subtitle={t("beneficiaries.edit.title")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/beneficiaries">
          <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Edit Beneficiaries")}
        </Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="flex-end" mt="20px">

        {/*Start Bulk block*/}
        {ids?.length > 1 ? (
            <Box display="flex" justifyContent="flex-end" ml="20px">
              <ButtonGroup variant="contained" aria-label="outlined button group" size="small">
                <Button color="primary" onClick={() => handleUnblockBeneficiaries()}>
                  {t('global.buttons.bulk_unblock_button')} <RemoveCircleOutlineOutlinedIcon />
                </Button>
                <Button color="error" onClick={() => handleBlockBeneficiaries()}>
                  {t('global.buttons.bulk_block_button')} <BlockOutlinedIcon />
                </Button>
              </ButtonGroup>
            </Box>
        ) : (
            <Box display="flex" justifyContent="flex-end" ml="20px">
              {/*show nothing?*/}
            </Box>
        )}
        {/*End Bulk block*/}

        <Box display="flex" justifyContent="flex-end" ml="20px">
          <button
            onClick={(e) => handleBulkEdit(e)}
            className="btn btn-primary"
          >
            {t("global.buttons.bulk_edit_button")}
          </button>
        </Box>
      </Box>

      <Box className="data_grid_box_container">
        <StyledDataGrid
          editMode="row"
          rows={beneficiaries}
          columns={columns}
          pagination
          pageSize={pagination.perPage}
          rowCount={pagination.total}
          onPageChange={handlePageChange}
          onFilterModelChange={handleFilterModelChange}
          loading={isFetching}
          paginationMode="server"
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={default_rows_per_page_options}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          onSelectionModelChange={(ids) => selectedBeneficiaries(ids)}
        />
      </Box>
      {openDeleteDialog && (
              <DeleteDialog
                open={openDeleteDialog}
                onClose={handleDeleteClose}
                name={JSON.parse(selectedBeneficiary).full_name}
                deleteMethod={deleteBeneficiary}
                url={`/beneficiaries/delete/${
                  JSON.parse(selectedBeneficiary).id
                }`}
              />
            )}
            {openRestoreDialog && (
                <RestoreDialog
                    open={openRestoreDialog}
                    onClose={handleRestoreClose}
                    name={JSON.parse(selectedBeneficiary).full_name}
                    restoreMethod={restoreBeneficiary}
                    url={`/beneficiaries/restore/${JSON.parse(selectedBeneficiary).id}`}
                />
            )}
            {openBlockBeneficiaryDialog && (
                <BlockBeneficiaryDialog
                    onClose={handleBlockBeneficiaryClose}
                    name={JSON.parse(selectedBeneficiary).full_name}
                    open={openBlockBeneficiaryDialog}
                    beneficiaryData={JSON.parse(selectedBeneficiary)}
                />
            )}
            {openBlockBeneficiariesDialog && (
                <BlockBeneficiariesDialog
                    onClose={handleBlockBeneficiariesClose}
                    open={openBlockBeneficiariesDialog}
                    beneficiariesData={ids}
                />
            )}
            {openUnblockBeneficiaryDialog && (
                <UnBlockBeneficiaryDialog
                    onClose={handleUnblockBeneficiaryClose}
                    name={JSON.parse(selectedBeneficiary).full_name}
                    open={openUnblockBeneficiaryDialog}
                    beneficiaryData={JSON.parse(selectedBeneficiary)}
                />
            )}
            {openUnblockBeneficiariesDialog && (
                <UnBlockBeneficiariesDialog
                    onClose={handleUnblockBeneficiariesClose}
                    open={openUnblockBeneficiariesDialog}
                    beneficiariesData={ids}
                />
            )}
    </Box>
  );

  return content;
};

export default EditBeneficiaries;
