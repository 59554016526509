import React, { useContext } from "react";
import {
  Box,
  useTheme,
  Typography,
} from "@mui/material";
import { tokens } from "../../theme";
import LanguageSwitcher from "./LanguageSwitcher";
import ThemeSelector from "./ThemeSelector";

const PublicTopbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center"  p={2}>
      <Box display="flex" sx={{ marginLeft: "55px" }}>
        <Typography variant="h3" fontWeight="bold" color={colors.faoBlue[500]}>
          IDEA Web
        </Typography>
      </Box>
      {/* Icons */}
      <Box display="flex" gap={2} borderRadius="100%" sx={{ marginRight: "50px" }}>
        <LanguageSwitcher />
        <ThemeSelector />
      </Box>
    </Box>
  );
};

export default PublicTopbar;
