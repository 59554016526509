import { toast } from "react-toastify";

const Toast = (message, type) => {
  switch (type) {
    case "success":
        return toast.success(message, {
            position: toast.POSITION.TOP_CENTER,
        });
    case "error":
        return toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
        });
    case "warning":
        return toast.warning(message, {
            position: toast.POSITION.TOP_CENTER,
        });
    default:
        return toast.info(message, {
            position: toast.POSITION.TOP_CENTER,
        });
  }
};
export default Toast;