import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const ImageComparisonModal = ({
  open,
  onClose,
  image1,
  image2,
  beneficiary1,
  beneficiary2,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>
        Comparing Selected Beneficiary Images{" "}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-around" gap="10px">
          <div>
            <p style={{ fontWeight: "bold" }}>
              Selected Beneficiary: {beneficiary1}
            </p>
            <img
              src={
                image1 !== null
                  ? image1
                  : "https://media.istockphoto.com/id/520009702/photo/vegetable-farmer.jpg?s=612x612&w=0&k=20&c=0EdWMUbjzEEyrk3SGghda4uzQJrCbYpF8rA91XuRhqY="
              }
              alt="First Not found"
              style={{
                maxWidth: "100%",
                maxHeight: "80vh",
                objectFit: "contain",
              }}
            />
          </div>
          <div>
            <p style={{ fontWeight: "bold" }}>
              Possible Duplicate: {beneficiary2}
            </p>
            <img
              src={
                image2 !== null
                  ? image2
                  : "https://media.istockphoto.com/id/520009702/photo/vegetable-farmer.jpg?s=612x612&w=0&k=20&c=0EdWMUbjzEEyrk3SGghda4uzQJrCbYpF8rA91XuRhqY="
              }
              alt="Second Not found"
              style={{
                maxWidth: "100%",
                maxHeight: "80vh",
                objectFit: "contain",
              }}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <button className="btn btn-danger" onClick={onClose} color="primary">
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageComparisonModal;
