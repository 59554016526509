import { Dialog, DialogContent } from "@mui/material";
import React from "react";

const SystemDialog = ({ open, handleClose, formContent }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent>{formContent}</DialogContent>
    </Dialog>
  );
};

export default SystemDialog;
