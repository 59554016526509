import { useState, useEffect } from "react";
import { useGetBeneficiariesQuery } from "../../../features/beneficiaries/beneficiariesApiSlice";
import {
  useAddInterventionBeneficiariesMutation,
  useGetInterventionBeneficiariesQuery,
} from "../../../features/interventions/interventionsApiSlice";
import { useParams, useNavigate, Link } from "react-router-dom";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Button,
  Alert,
  AlertTitle,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import { getSegment } from "../../../utils/stringUtils";
import { LinkColor } from "../../../theme";

const AddInterventionBeneficiaries = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [ids, setIds] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);

  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const [beneficiaries, setBeneficiaries] = useState([]);
  // Present beneficiaries
  const [presentBeneficiaries, setPresentBeneficiaries] = useState([]);

  // Fetch present beneficiaries in an intervention
  const {
    data: benData,
    isLoading: fetchingBeneficiaries,
    isSuccess: benSuccess,
  } = useGetInterventionBeneficiariesQuery({ id, page, pageSize });

  useEffect(() => {
    if (benData?.data) setPresentBeneficiaries(benData?.data);
  }, [benData, benSuccess]);

  // check if the title value of tale matches present beneficiary title value
  const checkNameMatch = (name) => {
    return presentBeneficiaries.some(
      (beneficiary) => beneficiary.full_name === name
    );
  };

  const getCellClassName = (params) => {
    return checkNameMatch(params.row.full_name) ? "matched-name-cell" : "";
  };

  // Separate matching and non-matching beneficiaries
  const matchingBeneficiaries = [];
  const nonmatchingBeneficiaries = [];
  beneficiaries.forEach((beneficiary) => {
    if (
      presentBeneficiaries.some(
        (present) => present.full_name === beneficiary.full_name
      )
    ) {
      matchingBeneficiaries.push(beneficiary);
    } else {
      nonmatchingBeneficiaries.push(beneficiary);
    }
  });

  // Concatenate arrays to place matching beneficiaries at the bottom
  const sortedBeneficiaries = [
    ...nonmatchingBeneficiaries,
    ...matchingBeneficiaries,
  ];

  const { data, isLoading, isSuccess, isFetching } = useGetBeneficiariesQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (data?.data) setBeneficiaries(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage]);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  // Add intervention beneficiaries
  const [addBeneficiaries] = useAddInterventionBeneficiariesMutation();

  const getPackages = (params) => {
    return Object.keys(params.row.packages).length;
  };

  const columns = [
    {
      field: "full_name",
      headerName: t("beneficiaries.table.fullname"),
      flex: 1,
      minWidth: 250,
      cellClassName: "name-column--cell",
      valueGetter: (params) => params.row?.full_name,
    },
    {
      field: "unique_id",
      headerName: t("beneficiaries.table.unique_id"),
      valueGetter: (params) => getSegment(params.row?.unique_id, "-", -1),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      valueGetter: (params) => params.row?.telephone,
      minWidth: 150,
    },
    {
      field: "date_of_birth",
      headerName: t("beneficiaries.table.dob"),
      valueGetter: (params) => params.row?.date_of_birth,
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "code",
      headerName: t("project.form.label.code"),
      valueGetter: (params) => params?.row?.intervention[0]?.project?.code,
      minWidth: 200,
    },
    {
      field: "intervention",
      headerName: t("intervention.form.label.intervention"),
      valueGetter: (params) =>
        params.row.intervention[0]?.title
          ? params.row?.intervention[0]?.title
          : "",
      minWidth: 200,
    },
    {
      field: "distribution_point",
      headerName: t("distribution_points.form.label.distribution_point"),
      valueGetter: (params) => params.row?.distribution_point?.title,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "packages",
      headerName: t("beneficiaries.table.packages"),
      valueGetter: getPackages,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Status",
      headerName: t("beneficiaries.table.status"),
      valueGetter: (params) => params.row?.status,
      minWidth: 100,
    },
    {
      field: "geo_level_1",
      headerName: t("beneficiaries.table.geolevel1"),
      valueGetter: (params) => params.row?.geo_level_1,
      minWidth: 200,
    },
    {
      field: "geo_level_2",
      headerName: t("beneficiaries.table.geolevel2"),
      valueGetter: (params) => params.row?.geo_level_2,
      minWidth: 200,
    },
    {
      field: "geo_level_3",
      headerName: t("beneficiaries.table.geolevel3"),
      valueGetter: (params) => params.row?.geo_level_3,
      minWidth: 200,
    },
    {
      field: "geo_level_4",
      headerName: t("beneficiaries.table.geolevel4"),
      valueGetter: (params) => params.row?.geo_level_4,
      minWidth: 200,
    },
    {
      field: "geo_level_5",
      headerName: t("beneficiaries.table.geolevel5"),
      valueGetter: (params) => params.row?.geo_level_5,
      minWidth: 200,
    },
    {
      field: "geo_level_6",
      headerName: t("beneficiaries.table.geolevel6"),
      valueGetter: (params) => params.row?.geo_level_6,
      minWidth: 200,
    },
  ];

  const selectedBeneficiaries = (ids) => {
    setIds(ids);
  };

  const handleAdd = async () => {
    if (beneficiaries.length) {
      const payload = {
        intervention_id: id,
        beneficiaries: ids,
      };
      try {
        const response = await addBeneficiaries(payload).unwrap();

        if (response.status === 200) {
          navigate(`/interventions/${id}`);
        } else {
          Toast(response?.message, "error");
        }
      } catch (err) {
        Toast(err?.data?.message, "error");
      }
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("intervention.add_beneficiaries.title")}
        subtitle={t("intervention.add_beneficiaries.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to={`/interventions/${id}`}>
          <Typography variant="body2">{t("intervention.view.view")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("global.buttons.add_ben")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>
          {t("intervention.add_beneficiaries.alert_head")}
        </AlertTitle>
        {t("intervention.add_beneficiaries.alert_message")}
      </Alert>

      <Box marginTop={"20px"}>
        <button
          className={!ids.length ? "btn btn-disabled" : "btn btn-primary"}
          onClick={handleAdd}
          disabled={!ids.length}
        >
          {t("global.buttons.add_ben")}
        </button>
      </Box>

      <Box className="data_grid_box_container">
        <StyledDataGrid
          checkboxSelection
          rows={sortedBeneficiaries}
          columns={columns}
          pagination
          pageSize={pagination.perPage}
          rowCount={pagination.total}
          onPageChange={handlePageChange}
          loading={isFetching}
          paginationMode="server"
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={default_rows_per_page_options}
          onSelectionModelChange={(ids) => selectedBeneficiaries(ids)}
          onFilterModelChange={handleFilterModelChange}
          getCellClassName={(params) => getCellClassName(params)}
          disableSelectionOnClick={(params) =>
            checkNameMatch(params.row.full_name)
          }
        />
      </Box>
    </Box>
  );

  return content;
};

export default AddInterventionBeneficiaries;
