import React, { useState } from "react";
import { useParams, Link} from "react-router-dom";
import {useExportPaymentListMutation, useGetPaymentQuery, useGetPaymentTransactionsListQuery} from "../../../features/payments/paymentsApiSlice";
import Spinner from "../../global/Spinner";
import {
  Box,
  Typography,
  Button,
  Alert,
  Tab,
  ButtonGroup,
  Popover,
  Breadcrumbs,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Header from "../../Header";
import axios from "axios";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import moment from "moment";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const ViewPayment = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [files, ] = useState([]);
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  const { id } = useParams();
  const { data, isLoading } = useGetPaymentQuery(id);
  const generatedPayments = data?.generated_payments;

  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  // Get payment transaction list
  const { data: transactionData } = useGetPaymentTransactionsListQuery(id);
  const transactions = transactionData?.transactions;

  const [exportData] = useExportPaymentListMutation();

  const token = useSelector(selectCurrentToken);

  const [value, setValue] = useState("generated");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExport = async () => {
    try {
      const response = await exportData(id).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { payment_list: files[0] };
    const url = `${process.env.REACT_APP_BASE_URL}/payment/import/${id}`;
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.status === 200) {
        Toast(response?.data?.message, "success");
        handleClose();
      } else {
        Toast(response?.data?.message, "error");
      }
    } catch (err) {
      Toast(err?.response?.data?.message, "error");
      handleClose();
    }
  };

  const handleDownload = (generatedPayment) => {
    generatedPayment = JSON.parse(generatedPayment);
  };

  const transactionColumns = [
    { field: "transaction_code", headerName: t("payment.view.transaction_code"), flex: 1 },
    { field: "amount", headerName: t("payment.view.amount"), flex: 1 },
    {
      field: "beneficiary",
      headerName: t("payment.view.beneficiary"),
      valueGetter: (params) => params.row?.beneficiary.full_name,
      flex: 1,
    },
    {
      field: "package",
      headerName: t("payment.view.package"),
      valueGetter: (params) => params.row?.package.title,
      flex: 1,
    },
    {
      field: "intervention",
      headerName: t("payment.view.intervention"),
      valueGetter: (params) => params.row?.intervention.title,
      flex: 1,
    },
    { field: "status", headerName: t("payment.view.status"), flex: 1 },
  ];

  const columns = [
    {
      field: "action",
      headerName: t('global.table.actions'),
      flex: 1,
      renderCell: (params) => {
        const getGeneratedPayment = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = {id:params.row.id};// doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
            <Link color="secondary" underline="none" to={JSON.parse(getGeneratedPayment()).url}>
              <Button color="secondary" onClick={() => handleDownload(getGeneratedPayment())} size="small">
                  {t("payment.view.download")} <FileDownloadOutlinedIcon />
              </Button>
              </Link>

              <Button color="warning" variant="outlined" aria-describedby={popoverId} onClick={(e) => handleClick(e)} size="small">
                {t("payment.view.download")} <UploadOutlinedIcon />
              </Button>
              <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <form onSubmit={(e) => handleSubmit(e)}>
                  <Typography variant="h3" color="secondary" sx={{ mb: "5px" }}>
                    {t("payment.view.beneficiary_file_to_upload")}
                  </Typography>
                  <hr />
                  <Box sx={{ border: "1px solid #fff", borderRadius: "5px" }}>
                    <Typography>peter broke this intentionally</Typography>
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px" mr="2px" mb="2px">
                    <Button type="submit" color="warning" variant="outlined" size="small">
                      {t("payment.view.import_payment_report")}
                    </Button>
                  </Box>
                </form>
              </Popover>
            </ButtonGroup>
          </Box>
        );
      },
    },
    { field: "file_type", headerName: t("payment.view.file_type"), flex: 0.5 },
    {
      field: "generated_at",
      headerName: t("payment.view.generated_at"),
      valueGetter: (params) => moment(params.row?.generated_at).fromNow(),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "file_name",
      headerName: t("payment.view.file_name"),
      flex: 0.5,
    },
    {
      field: "url",
      headerName: t("payment.view.file_name"),
      flex: 0.5,
    }
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label={t("payment.view.generated_payments")} value="generated" />
              <Tab label={t("payment.view.transactions")} value="transactions" />
            </TabList>
          </Box>
          <TabPanel value="generated">
            <Box display="flex" justifyContent="start" m="20px 0px 20px 0px">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                <Button color="secondary" variant="outlined" onClick={handleExport} size="small">
                  {t("payment.view.generate_payment_list")}
                </Button>
              </ButtonGroup>
            </Box>
            <Box>
              <Header title={t("payment.view.title")} subtitle={t("payment.view.subtitle")} />

              <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
                <Link style={LinkColor()} color="inherit" to="/">
                  <Typography variant="body2">{t("sidebar.Home")}</Typography>
                </Link>
                <Typography color="text.primary" variant="body2">{t("sidebar.Payments")}</Typography>
                <Typography color="text.primary" variant="body2">{t("payment.view.view_payments")}</Typography>
              </Breadcrumbs>

              <Box className="data_grid_box_container_within_tabs">
                {generatedPayments?.length < 1 ? (
                  <Alert severity="info">{t("payment.view.no_payment_list_generated")}</Alert>
                ) : (
                  <StyledDataGrid rows={generatedPayments} columns={columns} rowsPerPageOptions={default_rows_per_page_options} />
                )}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value="transactions">
            <Box className="data_grid_box_container_within_tabs">
              {transactions?.length < 1 ? (
                <Alert severity="info">{t("payment.view.no_transactions_in_payment")}</Alert>
              ) : (
                <StyledDataGrid rows={transactions} columns={transactionColumns} rowsPerPageOptions={default_rows_per_page_options} />
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );

  return content;
};

export default ViewPayment;