import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  useTheme,
  Popover,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Alert,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { LinkColor, tokens } from "../../theme";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../features/auth/authSlice";
import {
  selectCurrentUser,
  selectCurrentRoles,
} from "../../features/auth/authSlice";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import ThemeSelector from "./ThemeSelector";
import Notifications from "./Notifications";

const Topbar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const roles = useSelector(selectCurrentRoles);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const [anchorSettingsEl, setAnchorSettingsEl] = useState(null);
  const openSettings = Boolean(anchorSettingsEl);
  const settingsPopover = openSettings ? "simple-popover" : undefined;

  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState(null);
  const openNotifications = Boolean(anchorNotificationsEl);
  const notificationsPopover = openNotifications ? "simple-popover" : undefined;

  const navigate = useNavigate();

  const [userSource, setUserSource] = useState("CREATED");

  useEffect(() => {
    if (user !== undefined && user !== null) {
      if (user?.user_name?.split("_")[0] === "FAO-SSO") {
        setUserSource("FAO");
      }
    }
  }, [open, user]);

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/login");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleCloseSettings = () => {
    setAnchorSettingsEl(null);
  };

  const handleClickSettings = (event) => {
    setAnchorSettingsEl(event.currentTarget);
  };

  const handleClickAwaySettings = () => {
    setAnchorSettingsEl(null);
  };

  const handleCloseNotifications = () => {
    setAnchorNotificationsEl(null);
  };

  const handleClickNotifications = (event) => {
    setAnchorNotificationsEl(event.currentTarget);
  };

  const handleClickAwayNotifications = () => {
    setAnchorNotificationsEl(null);
  };


  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      ></Box>
      {/* Icons */}
      <Box display="flex">
        <LanguageSwitcher />
        <ThemeSelector />
        <IconButton
          aria-describedby={openNotifications}
          onClick={(e) => handleClickNotifications(e)}
        >
          <NotificationsOutlinedIcon />
          <Popover
            id={notificationsPopover}
            open={openNotifications}
            anchorEl={anchorNotificationsEl}
            onClose={handleCloseNotifications}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <ClickAwayListener
              onClickAway={handleClickAwayNotifications}
            >
              <div> <Notifications /></div> 
            </ClickAwayListener>
          </Popover>
        </IconButton>
        {/* setting */}
        <IconButton
          aria-describedby={settingsPopover}
          onClick={(e) => handleClickSettings(e)}
        >
          <SettingsOutlinedIcon />
          <Popover
            id={settingsPopover}
            open={openSettings}
            anchorEl={anchorSettingsEl}
            onClose={handleCloseSettings}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <ClickAwayListener onClickAway={handleClickAwaySettings}>
              <Box m="5px">
                <Card sx={{ minWidth: 275 }} variant="contained">
                  <CardContent></CardContent>
                  <CardActions>
                    <Link
                      to="/about"
                      style={{
                        textDecoration: "none",
                        color: "green",
                        width: "100%",
                      }}
                      color={colors.greenAccent[500]}
                    >
                      <Button
                        color="success"
                        variant="outlined"
                        sx={{
                          width: "100%",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        size="small"
                      >
                        {t("about_idea.page")}
                      </Button>
                    </Link>
                  </CardActions>
                </Card>
              </Box>
            </ClickAwayListener>
          </Popover>
        </IconButton>
        <IconButton
          aria-describedby={popoverId}
          onClick={(e) => handleClick(e)}
        >
          <PersonOutlinedIcon />

          <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box m="5px">
                <Card sx={{ minWidth: 275 }} variant="contained">
                  <CardContent>
                    <Box display="flex">
                      <Box m="5px">
                        <Avatar alt="User Icon" src="/assets/img/user.png" />
                      </Box>
                      <Box m="5px">
                        <Typography
                          variant="h4"
                          fontWeight="bold"
                          fontSize="18px"
                        >
                          {user?.first_name} {user?.last_name}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontWeight="400"
                          fontStyle="italic"
                          fontSize="14px"
                        >
                          {user?.user_name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      m="5px"
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      <Typography variant="h6" fontWeight="300" fontSize="18px">
                        {t("topbar.settings.roles")}
                      </Typography>
                      <Divider />
                      {roles?.length === 0 ? (
                        <Alert severity="info">
                          {t("topbar.profile.no_roles")}
                        </Alert>
                      ) : (
                        <List>
                          {roles?.map((role, index) => (
                            <ListItem disablePadding key={index}>
                              <ListItemButton>
                                <ListItemIcon>
                                  <AdminPanelSettingsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={role} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </Box>
                    <Divider />

                    {userSource === "CREATED" && (
                      <Link
                        style={LinkColor()}
                        to="/settings/change/password"
                        underline="none"
                        color="white"
                        sx={{
                          display: "flex",
                          flex: "1 1 0",

                          "&:hover": {
                            cursor: "pointer",
                            color: `${colors.faoOrange[400]}`,
                          },
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight="400"
                          fontSize="14px"
                        >
                          {t("topbar.settings.change_password")}
                        </Typography>
                      </Link>
                    )}
                  </CardContent>
                  <CardActions>
                    <button
                      className="btn btn-full btn-outline-danger"
                      onClick={handleLogout}
                    >
                      {t("topbar.profile.logout")}
                    </button>
                  </CardActions>
                </Card>
              </Box>
            </ClickAwayListener>
          </Popover>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
