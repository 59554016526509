import { useState, useEffect } from "react";

import {
  Box,
  Alert,
  AlertTitle,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import {
  useGetEntitlementsQuery,
  useGetUserEntitlementsQuery,
} from "../../../features/entitlements/entitlementsApiSlice";
import {
  useGetPackageQuery,
  useGetPackageEntitlementsQuery,
} from "../../../features/packages/packagesApiSlice";
import Spinner from "../../global/Spinner";
import { useParams, Link } from "react-router-dom";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import AddEntitlementDialog from "./AddEntitlementDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const PackageAddEntitlements = () => {
  const { t } = useTranslation();

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const { id } = useParams();
  const { data: pData } = useGetPackageQuery(id);
  const packageData = pData?.data;

  const [selectedEntitlement, setSelectedEntitlement] = useState("");
  const [entitlements, setEntitlements] = useState([]);

  const [presentEntitlements, setPresentEntitlements] = useState([]);
  const [activeEntitlements, setActiveEntitlements] = useState([]);

  const { data, isLoading, isSuccess, isFetching } = useGetEntitlementsQuery({
    page,
    pageSize,
  });
  useEffect(() => {
    if (data?.data) setEntitlements(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Fetch entitlements in a package
  const {
    data: entitlementsData,
    isLoading: fetchingEntitlements,
    isSuccess: entitlementsSuccess,
  } = useGetPackageEntitlementsQuery(id);

  useEffect(() => {
    if (entitlementsData?.data) {
      setPresentEntitlements(entitlementsData?.data);
      setActiveEntitlements(entitlementsData?.active_entitlements);
    }
  }, [entitlementsData, fetchingEntitlements, entitlementsSuccess]);

  // Separate matching and non-matching entitlements
  const matchingEntitlements = [];
  const nonMatchingEntitlements = [];

  entitlements?.forEach((entitlement) => {
    if (
      presentEntitlements.some((present) => present.title === entitlement.title)
    ) {
      matchingEntitlements.push(entitlement);
    } else {
      nonMatchingEntitlements.push(entitlement);
    }
  });

  // Concatenate arrays to place matching entitlements at the bottom
  const sortedEntitlements = [
    ...nonMatchingEntitlements,
    ...matchingEntitlements,
  ];

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = (entitlement) => {
    setSelectedEntitlement(entitlement);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedEntitlement(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      renderCell: (params) => {
        const getEntitlement = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        const entitlement = params.row;
        const isMatching = matchingEntitlements.some(
          (matching) => matching.title === entitlement.title
        );

        return (
          <Box display="flex" flexDirection="column">
            <Button
              color={isMatching ? "primary" : "secondary"}
              onClick={() => handleClickOpen(getEntitlement())}
              size="small"
            >
              {t("global.buttons.add")} <AddCardOutlinedIcon />
            </Button>
          </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.form.label.description"),
      flex: 1,
    },

    { field: "unit", headerName: t("entitlements.form.label.unit"), flex: 1 },
    {
      field: "max_redeemable",
      headerName: t("entitlements.form.label.max_redeemable"),
      flex: 1,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("packages.add_entitlements.title")}
        subtitle={t("packages.add_entitlements.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/packages">
          <Typography variant="body2">{t("sidebar.Packages")}</Typography>
        </Link>
        <Link
          style={LinkColor()}
          color="inherit"
          to={`/interventions/packages/view/${id}`}
        >
          <Typography variant="body2">
            {t("packages.form.helpertext.view")}
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("global.buttons.add_entitlements")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("packages.add_entitlements.alert_head")}</AlertTitle>
        {t("packages.add_entitlements.alert_message")}{" "}
        <strong>{packageData?.title}</strong>
      </Alert>
      <Box className="data_grid_box_container">
        <StyledDataGrid
          rows={sortedEntitlements}
          columns={columns}
          pagination
          pageSize={pagination.perPage}
          rowCount={pagination.total}
          onPageChange={handlePageChange}
          loading={isFetching}
          paginationMode="server"
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={default_rows_per_page_options}
          getCellClassName={(params) =>
            matchingEntitlements.some(
              (matching) => matching.title === params.row.title
            )
              ? "matched-name-cell" // Apply custom class for matching names
              : ""
          }
        />
      </Box>
      {open && (
        <AddEntitlementDialog
          open={open}
          onClose={handleClose}
          packageData={packageData}
          entitlementData={selectedEntitlement}
        />
      )}
    </Box>
  );
  return content;
};

export default PackageAddEntitlements;
