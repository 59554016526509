import { useContext, useEffect } from "react";
import { IconButton, useTheme } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { ColorModeContext } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../features/settings/themeSlice";

const ThemeSelector = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const dispatch = useDispatch();
  useSelector((state) => state.language.selectedTheme);
  useEffect(() => {
    const storedTheme = localStorage.getItem("ideaTheme");
    if (storedTheme) {
      dispatch(setTheme(storedTheme));
    }
  }, [dispatch]);

  const handleThemeChange = () => {
    const chosenTheme = theme.palette.mode === "light" ? "dark" : "light";
    localStorage.setItem("ideaTheme", chosenTheme);
    dispatch(setTheme(chosenTheme));
    colorMode.toggleColorMode();
  };

  return (
    <IconButton onClick={handleThemeChange}>
      {theme.palette.mode === "dark" ? (
        <DarkModeOutlinedIcon />
      ) : (
        <LightModeOutlinedIcon />
      )}
    </IconButton>
  );
};

export default ThemeSelector;
