import React, { useState } from "react";
import axios from "../../api/axios";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Box,
  useTheme,
  Button,
  TextField,
  Link,
  Typography,
  IconButton,
  Alert,
  Grid,
  ThemeProvider,
  CssBaseline,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { tokens } from "../../theme";
import * as yup from "yup";
import { Formik } from "formik";
import PublicTopbar from "../global/PublicTopbar";

import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import Spinner from "../global/Spinner";
import "./login.css";
import { useTranslation } from "react-i18next";
import Toast from "../global/Toast";
import { ToastContainer } from "react-toastify";

const LOGIN_URL = "/login";
const COGNITOLINK = "/cognito/link";

const initialValues = {
  username: "",
  password: "",
};

const Login = () => {
  const { t } = useTranslation();
  const userSchema = yup.object().shape({
    username: yup.string().required(t("login.form.helpertext.username")),
    password: yup.string().required(t("login.form.helpertext.password")),
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";
  const [errMsg, setErrMsg] = useState("");

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const allowSSOLogin = process.env.REACT_APP_ALLOW_SSO_LOGIN === "true";

  const handleSubmit = async (values) => {
    try {
      const payload = {
        user_name: values.username,
        password: values.password,
      };

      const response = await login(payload).unwrap();
      const access_token = response.data.access_token;
      const refresh_token = response.data.refresh_token;
      const expires_in = response.data.expires_in;
      const roles = response.roles;
      const permissions = response.permissions;
      const user = response.user;
      dispatch(
        setCredentials({
          access_token,
          refresh_token,
          expires_in,
          roles,
          permissions,
          user,
        })
      );

      navigate(from, { replace: true });
    } catch (err) {
      //console.log(err);
      if (!err?.data) {
        setErrMsg(t("login.error.no_server_response"));
      } else if (err?.data.status === 400) {
        setErrMsg(err?.data.message);
      } else {
        setErrMsg(t("login.error.login_failed"));
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  let result = "";

  const loginCognito = async () => {
    try {
      const response = await axios.get(COGNITOLINK);
      result = response?.data?.message;
      const url = response?.data?.url;
      window.location.href = url;
    } catch (err) {
      Toast(err?.data?.message, "error");
      setTimeout(function () {
        navigate(LOGIN_URL, { state: { message: result?.data?.message } });
      }, 3000);
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className="login-body">
        <ToastContainer />
        <PublicTopbar />
        <ThemeProvider theme={theme}>
          <Box className="login-wrapper">
            <Grid container component="main" sx={{ height: "80vh" }}>
              <CssBaseline />

              <Grid item sm={4} md={6} className="login-image">
                <Box className="image-overlay-container">
                  <Box
                    sm={4}
                    md={7}
                    sx={{
                      backgroundImage: "url(assets/img/banner3.webp)",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "100%",
                      height: "80vh",
                    }}
                  ></Box>
                  <Box className="overlay">
                    <Box className="text-container">
                      <Box className="text">
                        <Typography
                          xs={{ fontWeight: "700" }}
                          variant="h4"
                          component="h1"
                          gutterBottom
                        >
                          {t("about_idea.what_is_idea")}
                        </Typography>
                        <Typography variant="body1" paragraph>
                          {t("about_idea.what_is_idea_content")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <div className="login-content">
                <Typography
                  variant="h2"
                  color={colors.faoBlue[500]}
                  sx={{ mb: "30px", my: 2, mx: 2, fontWeight: "bold" }}
                >
                  {t("login.form.label.signin")}
                </Typography>
                {errMsg ? (
                  <Alert severity="error" sx={{ mb: "20px" }}>
                    {errMsg}
                  </Alert>
                ) : (
                  ""
                )}

                <Formik
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={userSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} className="login-form">
                      <Box
                        gap="30px"
                        sx={{
                          my: 2,
                          mx: 2,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          fullWidth
                          required
                          variant="filled"
                          type="text"
                          label={t("users.form.label.user_name")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.username}
                          name="username"
                          error={!!touched.username && !!errors.username}
                          helperText={touched.username && errors.username}
                          sx={{ gridColumn: "span 4" }}
                        />
                        <TextField
                          fullWidth
                          required
                          variant="filled"
                          type={showPassword ? "text" : "password"}
                          label={t("users.form.label.password")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          name="password"
                          error={!!touched.password && !!errors.password}
                          helperText={touched.password && errors.password}
                          sx={{ gridColumn: "span 4" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility}>
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Link
                        style={{ textDecoration: "none", color: "#116AAB" }}
                      >
                        <Typography
                          color={colors.faoBlue[500]}
                          sx={{ mt: "30px", my: 2, mx: 2 }}
                        >
                          {t("login.form.label.forgot")}
                        </Typography>
                      </Link>
                      <Box
                        display="flex"
                        mt="20px"
                        mb="40px"
                        justifyContent="center"
                        sx={{ my: 2, mx: 2 }}
                      >
                        <button
                          className="btn btn-full btn-primary"
                          type="submit"
                        >
                          {t("login.buttons.login")}
                        </button>
                      </Box>

                      {allowSSOLogin && (
                        <>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-around"
                            sx={{ my: 2, mx: 2 }}
                          >
                            <Box sx={{ flexBasis: "30%" }}>
                              <hr />
                            </Box>
                            <Box color={colors.faoOrange[500]}>
                              {t("login.form.helpertext.signin_with")}
                            </Box>
                            <Box sx={{ flexBasis: "30%" }}>
                              <hr />
                            </Box>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            justifyItems="center"
                            mt="20px"
                            sx={{ my: 2, mx: 2 }}
                          >
                            <a
                              className="btn btn-full btn-primary"
                              onClick={loginCognito}
                              role="button"
                              aria-pressed="false"
                            >
                              {t("login.buttons.sso_login")}
                            </a>
                          </Box>
                        </>
                      )}
                    </form>
                  )}
                </Formik>
              </div>
            </Grid>
          </Box>
        </ThemeProvider>
      </div>
    </>
  );

  return content;
};
export default Login;
