import { React, useState, useRef, useEffect } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { MdFilterAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { useCheckDuplicatesMutation } from "../../../features/biometric_deduplication/nonBiometricDeuplicationApiSlice";
import { toast } from "react-toastify";
import { useGetBiometricDuplicateBeneficiariesQuery } from "../../../features/biometric_deduplication/biometricDeduplicationApiSlice";
import { FormControl, Radio, RadioGroup } from "@mui/material";
export default function DropDown({
  borderTitle,
  dropDownTitle,
  checkboxItems,
  buttonTitle,
  onSubmit,
  isBiometric,
}) {
  const { t } = useTranslation();
  const [score, setScore] = useState("all");
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const [fetchBiometric, setFetchBiometric] = useState(false);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    checkboxItems.map((item) => item.defaultChecked || false)
  );
  const [combinedCheck, setCombinedCheck] = useState("no");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [bioDeduplicates, setBioDeduplicates] = useState();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCheckboxChange = (index) => (event) => {
    const newSelectedCheckboxes = [...selectedCheckboxes];
    newSelectedCheckboxes[index] = event.target.checked;
    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  const [checkDuplicates] = useCheckDuplicatesMutation();

  const handleButtonClick = async () => {
    // Filter out the combined checkbox and create the selected labels array
    const selectedLabels = checkboxItems
      .filter((item, index) => selectedCheckboxes[index] && item.value !== "no")
      .map((item) => item.value);

    // Determine if the combined checkbox is checked
    const isCombinedChecked = checkboxItems.some(
      (item, index) => selectedCheckboxes[index] && item.value === "no"
    );

    const payload = {
      fields: selectedLabels,
      combined: isCombinedChecked,
    };

    // onSubmit(selectedLabels);
    try {
      const checkForDuplicates = new Promise((resolve) => {
        setTimeout(resolve, 6000);
        setIsButtonLoading((prevStatus) => !prevStatus);
      });
      toast.promise(checkForDuplicates, {
        pending: t("nonbiometrics.index.checking_duplicates"),
      });
      const response = await checkDuplicates(payload).unwrap();
      setIsButtonLoading((prevStatus) => !prevStatus);
      onSubmit(response?.duplicates);

      setOpen(false);
    } catch (err) {
      console.log("error dedup check:", err?.data?.message);
      setIsButtonLoading((prevStatus) => !prevStatus);
      setOpen(false);
    }
  };

  const [selectedRadioIndex, setSelectedRadioIndex] = useState(0);
  const handleRadioChange = (event) => {
    setSelectedRadioIndex(parseInt(event.target.value, 10));
    const selectedLabels = checkboxItems[event.target.value].value;
    setScore(selectedLabels);
  };

  // Call the hook to get the query function
  const { data, error, refetch } = useGetBiometricDuplicateBeneficiariesQuery({
    score,
    page,
    pageSize,
    isReviewed: 0,
  });

  const handleBiometricClick = async () => {
    setIsButtonLoading(true);

    try {
      const response = await refetch();

      if (response.data.status === 200) {
        onSubmit(response.data);
      } else {
        console.log("something went wrong");
      }

      setIsButtonLoading(false);
      setOpen(false);
    } catch (err) {
      console.log("error dedup check:", err.message);
      setIsButtonLoading(false);
      setOpen(false);
    }
  };

  return (
    <div className="border_style">
      <div className="border_lines_style"></div>
      <div className="border_text_style">{borderTitle}</div>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <button className="btn btn-primary" onClick={handleToggle}>
          <MdFilterAlt />
          {dropDownTitle}
        </button>
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 1,
          padding: "25px",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper
              sx={{
                width: "220px",
                boxShadow: 10,
                borderRadius: 4,
                padding: "20px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {isBiometric ? (
                    <FormControl sx={{ marginBottom: "20px" }}>
                      <RadioGroup
                        value={selectedRadioIndex}
                        onChange={handleRadioChange}
                      >
                        {checkboxItems.map((item, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Radio
                                // checked={selectedCheckboxes[index]}
                                size="medium"
                                sx={{
                                  color: "#1688da",
                                  "&.Mui-checked": {
                                    color: "#1688da",
                                  },
                                }}
                              />
                            }
                            label={item.label}
                            value={index}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <FormGroup sx={{ marginBottom: "20px" }}>
                      {checkboxItems.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={selectedCheckboxes[index]}
                              onChange={handleCheckboxChange(index)}
                              size="medium"
                              sx={{
                                color: "#1688da",
                                "&.Mui-checked": {
                                  color: "#1688da",
                                },
                              }}
                            />
                          }
                          label={item.label}
                        />
                      ))}
                    </FormGroup>
                  )}
                  <button
                    className="btn btn-primary btn-full"
                    onClick={() => {
                      isBiometric
                        ? handleBiometricClick()
                        : handleButtonClick();
                    }}
                  >
                    {isButtonLoading ? "Loading" : buttonTitle}
                  </button>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
