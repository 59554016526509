import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  useAddDpServiceProvidersMutation,
  useGetDistributionPointQuery,
  useGetDpBeneficiariesQuery,
  useGetDpServiceProvidersQuery,
  useRemoveDpServiceProvidersMutation,
} from "../../../features/distribution_points/distributionPointsApiSlice";
import Spinner from "../../global/Spinner";
import Loader from "../../global/Loader";
import Header from "../../Header";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { getSegment } from "../../../utils/stringUtils";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const ViewDistributionPoint = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAddBeneficiary = currentPermissions.some(
    (permission) => permission.name === "beneficiary-add"
  );

  const { t } = useTranslation();
  const { id } = useParams();
  const [serviceProviders, setServiceProviders] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [activeSp, setActiveSp] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const { data, isLoading } = useGetDistributionPointQuery(id);
  const distributionPoint = data?.data;

  const [removeServiceProvider] = useRemoveDpServiceProvidersMutation();
  const [activateServiceProvider] = useAddDpServiceProvidersMutation();

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data: beneficiaryData,
    isLoading: fetchingBeneficiaries,
    isSuccess: benSuccess,
  } = useGetDpBeneficiariesQuery(id);

  useEffect(() => {
    if (beneficiaryData?.data) setBeneficiaries(beneficiaryData?.data);
  }, [beneficiaryData, benSuccess, fetchingBeneficiaries]);

  const {
    data: spData,
    isLoading: fetchServiceProviders,
    isSuccess: spSuccess,
  } = useGetDpServiceProvidersQuery(id);
  useEffect(() => {
    if (spData?.service_providers) {
      setServiceProviders(spData?.service_providers);
    }
  }, [spData, spSuccess, fetchServiceProviders]);
  useEffect(() => {
    if (serviceProviders?.length > 0) {
      setActiveSp(serviceProviders.filter((sp) => sp.status === "ACTIVE"));
    }
  }, [serviceProviders]);

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.75,
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              {params.row.status === "ACTIVE" ? (
                <Button
                  color="error"
                  onClick={() => {
                    handleRemove(params.row.id);
                  }}
                >
                  {t("global.table.deactivate_button")}
                  <DeleteOutlineOutlinedIcon />
                </Button>
              ) : (
                <Button
                  color="warning"
                  variant="outlined"
                  onClick={() => {
                    handleActivate(params.row.id);
                  }}
                >
                  {t("global.table.activate_button")}
                  <RemoveRedEyeOutlinedIcon />
                </Button>
              )}
            </ButtonGroup>
          </Box>
        );
      },
    },
    // { field: "id", headerName: t("users.form.label.id") },
    {
      field: "name",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: t("users.form.label.email"),
      flex: 1,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("users.form.label.status"),
      flex: 1,
    },
  ];

  const getPackages = (params) => {
    return Object.keys(params.row.packages).length;
  };

  const beneficiariesColumns = [
    {
      field: "full_name",
      headerName: t("beneficiaries.table.fullname"),
      flex: 1,
      minWidth: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "unique_id",
      headerName: t("beneficiaries.table.unique_id"),
      valueGetter: (params) => getSegment(params.row?.unique_id, "-", -1),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      valueGetter: (params) => params.row?.telephone,
      minWidth: 150,
    },
    {
      field: "date_of_birth",
      headerName: t("beneficiaries.table.dob"),
      valueGetter: (params) => params.row?.date_of_birth,
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "Status",
      headerName: t("beneficiaries.table.status"),
      valueGetter: (params) => params.row?.status,
      minWidth: 100,
    },
    {
      field: "geo_level_1",
      headerName: t("beneficiaries.table.geolevel1"),
      valueGetter: (params) => params.row?.geo_level_1,
      minWidth: 200,
    },
    {
      field: "geo_level_2",
      headerName: t("beneficiaries.table.geolevel2"),
      valueGetter: (params) => params.row?.geo_level_2,
      minWidth: 200,
    },
    {
      field: "geo_level_3",
      headerName: t("beneficiaries.table.geolevel3"),
      valueGetter: (params) => params.row?.geo_level_3,
      minWidth: 200,
    },
    {
      field: "geo_level_4",
      headerName: t("beneficiaries.table.geolevel4"),
      valueGetter: (params) => params.row?.geo_level_4,
      minWidth: 200,
    },
    {
      field: "geo_level_5",
      headerName: t("beneficiaries.table.geolevel5"),
      valueGetter: (params) => params.row?.geo_level_5,
      minWidth: 200,
    },
    {
      field: "geo_level_6",
      headerName: t("beneficiaries.table.geolevel6"),
      valueGetter: (params) => params.row?.geo_level_6,
      minWidth: 200,
    },
  ];

  const handleRemove = async (serviceProviderId) => {
    const payload = {
      distribution_point: id,
      service_provider: serviceProviderId,
    };

    try {
      const response = await removeServiceProvider(payload).unwrap();
      Toast(response?.message, "success");
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const handleActivate = async (serviceProviderId) => {
    const payload = {
      distribution_point: id,
      service_provider: serviceProviderId,
    };

    try {
      const response = await activateServiceProvider(payload).unwrap();
      Toast(response?.message, "success");
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("distribution_points.view.title")}
        subtitle={t("distribution_points.view.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/distribution-points">
          <Typography variant="body2">
            {t("sidebar.Distribution Points")}
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("distribution_points.form.label.view")}
        </Typography>
      </Breadcrumbs>

      <Box display="flex">
        <Box
          sx={{
            display: "flex",
            "& > :not(style)": {
              m: 1,
            },
            maxWidth: "400px",
          }}
        >
          <Paper variant="outlined">
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {distributionPoint.title}
                </Typography>
                <hr />
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-around"
                >
                  <Typography variant="h5" component="div">
                    {t("global.form.label.description")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {distributionPoint.description}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("distribution_points.form.label.location")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {distributionPoint.location}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Box>

        <Box
          sx={{
            display: "flex",
            "& > :not(style)": {
              m: 1,
            },
            maxWidth: "400px",
          }}
        >
          <Paper variant="outlined">
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  <strong>{distributionPoint.title}</strong>{" "}
                  {t("sidebar.Service Providers")}
                </Typography>
                <hr />
                {fetchServiceProviders ? (
                  <Loader />
                ) : activeSp?.length === 0 ? (
                  <Alert severity="info">
                    {t("distribution_points.view.no_sp")}
                  </Alert>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      "& > :not(style)": {
                        m: 1,
                      },
                    }}
                  >
                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        <Box
                          display="flex"
                          alignContent="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h5" component="div">
                            {t("global.form.label.name")}:
                          </Typography>
                          <Typography variant="h6" component="div">
                            {activeSp[0]?.name}
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignContent="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h5" component="div">
                            {t("users.form.label.email")}:
                          </Typography>
                          <Typography variant="h6" component="div">
                            {activeSp[0]?.email}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignContent="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h5" component="div">
                            {t("users.form.label.id_number")}:
                          </Typography>
                          <Typography variant="h6" component="div">
                            {activeSp[0]?.id_number}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          alignContent="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h5" component="div">
                            {t("users.form.label.user_name")}:
                          </Typography>
                          <Typography variant="h6" component="div">
                            {activeSp[0]?.user_name}
                          </Typography>
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Button
                          color="error"
                          variant="outlined"
                          sx={{
                            display: "flex",
                            flex: "1 1 0",

                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => handleRemove(activeSp[0]?.id)}
                        >
                          {t("global.table.deactivate_button")}
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Paper>
        </Box>
      </Box>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label={t("sidebar.Service Providers")} value="1" />
              <Tab label={t("sidebar.Beneficiaries")} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box className="data_grid_box_container_within_tabs">
              {canAddBeneficiary && (
                <Box
                  mb="10px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Link
                    style={LinkColor()}
                    to={`/distribution-points/${distributionPoint.id}/add/service-providers`}
                    underline="none"
                  >
                    <button className="btn btn-primary">
                      {t("global.buttons.add_sp")}
                    </button>
                  </Link>
                </Box>
              )}
              {fetchServiceProviders ? (
                <Loader />
              ) : serviceProviders.length < 1 ? (
                <Alert severity="info">
                  {t("distribution_points.view.no_sp")}
                </Alert>
              ) : (
                <StyledDataGrid
                  rows={serviceProviders}
                  columns={columns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box className="data_grid_box_container_within_tabs">
              {canAddBeneficiary && (
                <Box
                  mb="10px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Link
                    to={`/distribution-points/${distributionPoint.id}/add/beneficiaries`}
                    underline="none"
                  >
                    <button className="btn btn-primary">
                      {t("global.buttons.add_ben")}
                    </button>
                  </Link>
                </Box>
              )}
              {beneficiaries?.length < 1 ? (
                <Alert severity="info">
                  {t("distribution_points.view.no_ben")}
                </Alert>
              ) : (
                <StyledDataGrid
                  rows={beneficiaries}
                  columns={beneficiariesColumns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default ViewDistributionPoint;
