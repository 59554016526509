import React from "react";
import Header from "../../Header";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs, MenuItem,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useUpdateOrderMutation } from "../../../features/purchase_order/purchaseOrderApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditPurchaseOrder = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const purchaseOrder = location.state.purchaseOrder;

  const initialValues = {
    po_number: purchaseOrder.po_number,
    loa_number: purchaseOrder.loa_number,
    description: purchaseOrder.description,
    account_key: purchaseOrder.account_key,
    actuals: purchaseOrder.actuals,
    expense_account: purchaseOrder.expense_account,
    hard_commitments: purchaseOrder.hard_commitments,
    status: purchaseOrder.status,
  };

  const purchaseOrderSchema = yup.object().shape({
    po_number: yup.string().required(t('purchase_orders.form.helpertext.po_number')),
    account_key: yup.string().required(t('purchase_orders.form.helpertext.account_key')),
    loa_number: yup.string().required(t('purchase_orders.form.helpertext.loa_number')),
    actuals: yup.number().required(t('purchase_orders.form.helpertext.actuals')),
    expense_account: yup.number().required(t('purchase_orders.form.helpertext.expense_account')),
    hard_commitments: yup.number().required(t('purchase_orders.form.helpertext.hard_commitments')),
    status: yup.string().required(t('purchase_orders.form.helpertext.status')),
  });

  const [updateOrder, { isLoading }] = useUpdateOrderMutation();

  const handleFormSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        //status: values.status === "yes" ? true : false,
        id: purchaseOrder.id,
      };
      const response = await updateOrder(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
          function () {
            navigate("/projects/purchase-orders", { state: { message: response?.message } });
          },
          3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('purchase_orders.edit.title')}
        subtitle={t('purchase_orders.edit.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects">
          <Typography variant="body2">{t('sidebar.Projects')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects/purchase-orders">
          <Typography variant="body2">{t('sidebar.Purchase Orders')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('purchase_orders.edit.edit_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={purchaseOrderSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('purchase_orders.form.label.po_number')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.po_number}
                name="po_number"
                error={!!touched.po_number && !!errors.po_number}
                helperText={touched.po_number && errors.po_number}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('purchase_orders.form.label.loa_number')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.loa_number}
                name="loa_number"
                error={!!touched.loa_number && !!errors.loa_number}
                helperText={touched.loa_number && errors.loa_number}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  multiline
                  rows={3}
                  type="text"
                  label={t('global.form.label.description')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('purchase_orders.form.label.account_key')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.account_key}
                name="account_key"
                error={!!touched.account_key && !!errors.account_key}
                helperText={touched.account_key && errors.account_key}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t('purchase_orders.form.label.actuals')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.actuals}
                name="actuals"
                error={!!touched.actuals && !!errors.actuals}
                helperText={touched.actuals && errors.actuals}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label={t('purchase_orders.form.label.expense_account')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.expense_account}
                  name="expense_account"
                  error={!!touched.expense_account && !!errors.expense_account}
                  helperText={touched.expense_account && errors.expense_account}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label={t('purchase_orders.form.label.hard_commitments')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.hard_commitments}
                  name="hard_commitments"
                  error={!!touched.hard_commitments && !!errors.hard_commitments}
                  helperText={touched.hard_commitments && errors.hard_commitments}
              />

              <TextField
                  select
                  variant="filled"
                  label={t('purchase_orders.form.label.status')}
                  name="status"
                  value={values.status}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.status && !!errors.status}
                  helperText={touched.status && errors.status}
              >
                <MenuItem value="ACTIVE">{t('purchase_orders.add.select.active')}</MenuItem>
                <MenuItem value="INACTIVE">{t('purchase_orders.add.select.inactive')}</MenuItem>
                <MenuItem value="PENDING">{t('purchase_orders.add.select.pending')}</MenuItem>
              </TextField>

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/projects/purchase-orders" underline="none">

              <button className="btn btn-danger">
                  {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('purchase_orders.edit.edit_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default EditPurchaseOrder;
