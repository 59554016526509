import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Typography,
  Paper,
  Card,
  CardContent,
  Alert,
  Tab,
  CardActionArea,
  CardActions,
  Button,
  useTheme,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import {
  useActivateTopupMutation,
  useGetTopupBeneficiariesQuery,
  useGetTopupQuery,
} from "../../../features/topups/topupsApiSlice";
import Spinner from "../../global/Spinner";
import Header from "../../Header";
import StyledDataGrid from "../../global/StyledDataGrid";
import { LinkColor, tokens } from "../../../theme";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const ViewTopup = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(permission => permission.name === 'beneficiary-add');

  const { t } = useTranslation();
  const { id } = useParams();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [topup, setTopup] = useState(null);
  const [beneficiaries, setBeneficiaries] = useState([]);


  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  

  const { data, isLoading, isSuccess } = useGetTopupQuery(id);
  useEffect(() => {
    if (data?.data) setTopup(data.data);
  }, [data, isLoading, isSuccess]);

  // Fetching beneficiaries
  const payload = {
    topups: [id],
  };
  const {
    data: benData,
    isLoading: fetchingBeneficiaries,
    isSuccess: benSuccess,
  } = useGetTopupBeneficiariesQuery(payload);
  useEffect(() => {
    if (benData?.data) setBeneficiaries(benData?.data[0]?.beneficiaries);
  }, [benData, fetchingBeneficiaries, benSuccess]);

  const [value, setValue] = useState("Beneficiaries");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [activateTopup, { isLoading: activatingTopup }] =
    useActivateTopupMutation();
  const handleActivate = async (id) => {
    try {
      const payload = { topup_id: id };
      const res = await activateTopup(payload);

      //console.log(res)

      if (res?.data?.status == 200) {
        Toast(res?.data?.message, "success");
      }
    } catch (err) {
      Toast(JSON.stringify(err), "error");
    }
  };

  const beneficiariesColumns = [
    {
      field: "full_name",
      headerName: t("beneficiaries.table.fullname"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "copay_amount",
      headerName: t("packages.form.label.copay_amount"),
      flex: 1,
    },
    {
      field: "subsidy_amount",
      headerName: t("packages.form.label.subsidy_amount"),
      flex: 1,
    },
    {
      field: "date_applied",
      headerName: t("topups.form.label.date_applied"),
      valueGetter: (params) =>
        params.row?.date_applied ? params.row?.date_applied : "Not Applied",
      flex: 1,
    },
    {
      field: "topup_status",
      headerName: t("global.table.status"),
      flex: 1,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("topups.view.title")}
        subtitle={t("topups.view.subtitle")}
      />
      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/topups">
          <Typography variant="body2">{t("sidebar.Topups")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("topups.form.helpertext.view")}
        </Typography>
      </Breadcrumbs>

      <Box display="flex" flexDirection="row">
        <Box
          sx={{
            display: "flex",
            "& > :not(style)": {
              m: 1,
            },
          }}
        >
          <Paper variant="outlined">
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  fontWeight="500"
                >
                  {topup?.name}
                </Typography>
                <hr />
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("global.table.cva_type")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.cva_type}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("topups.form.label.type")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.topup_type}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("project.shared.start_date")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.start_date}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("project.shared.end_date")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.end_date}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("packages.form.label.copay_amount")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.copay_amount}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("packages.form.label.subsidy_amount")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.subsidy_amount}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("project.shared.status")}:
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    color={
                      topup?.status == "INACTIVE"
                        ? colors.faoOrange[500]
                        : colors.greenAccent[500]
                    }
                  >
                    {topup?.status}
                  </Typography>
                </Box>
              </CardContent>
              {topup?.status == "INACTIVE" ? (
                <CardActions sx={{ bgcolor: colors.primary[900] }}>
                  <Button
                    color="success"
                    variant="outlined"
                    sx={{
                      display: "flex",
                      flex: "1 1 0",

                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleActivate(id)}
                  >
                    {activatingTopup
                      ? "Activating Topup..."
                      : t("global.table.activate_button")}
                  </Button>
                </CardActions>
              ) : (
                ""
              )}
            </Card>
          </Paper>
        </Box>
        {/* Extra Data */}
        <Box
          sx={{
            display: "flex",
            "& > :not(style)": {
              m: 1,
            },
          }}
        >
          <Paper variant="outlined">
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  fontWeight="500"
                >
                  {t("topups.form.label.info")}:
                </Typography>
                <hr />
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("project.shared.project_code")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.intervention?.project?.code}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("intervention.shared.intervention")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.intervention?.title}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("intervention.shared.cva_type")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.intervention?.cva_type}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("packages.form.label.code")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.package?.code}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("packages.shared.cva_type")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.package?.cva_type}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("seasons.shared.season")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.season?.title}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("seasons.form.label.code")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {topup?.season?.code}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Box>
      </Box>

      <Box sx={{ width: "100%", typography: "body1", height: "100vh" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="Topups Tabs"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                label={t("packages.view.beneficiaries")}
                value="Beneficiaries"
              />
            </TabList>
          </Box>
          <TabPanel value="Beneficiaries">
            <Box className="data_grid_box_container_within_tabs">
              {canAdd && (
              <Box
                mb="10px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Link
                    to={`/interventions/topups/${topup?.id}/add/beneficiaries`}
                    underline="none"
                  >
                <button className="btn btn-primary">
                  
                    {t("global.buttons.add_ben")}
                </button>
                </Link>

              </Box>
              )}
              {beneficiaries?.length < 1 ? (
                <Alert severity="info">
                  {t("topups.view.no_beneficiaries")}
                </Alert>
              ) : (
                <Box height="75vh" width="100%">
                  <StyledDataGrid
                    rows={beneficiaries}
                    columns={beneficiariesColumns}
                    rowsPerPageOptions={default_rows_per_page_options}
                  />
                </Box>
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );

  return content;
};

export default ViewTopup;
