import { useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import FloatingPlotTable from "./FloatingPlotTable";
import PlotData from "./PlotData";
import { Box } from "@mui/material";
import ZoomToPolygon from "./ZoomToPolygon";

const PinsContainer = ({
  farms,
  latitude = 25.0888596,
  longitude = 55.1482862,
}) => {
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [farmToZoom, setFarmToZoom] = useState(null);

  const handleMarkerClick = (farm) => {
    setSelectedFarm(farm);
  };

  const handleFarmView = (plotId) => {
    const farm = farms.find((farm) => farm?.id === plotId);
    setFarmToZoom(farm);
  };

  const map_center_lat =
    process.env.REACT_APP_DEFAULT_MAP_CENTER_LAT || 25.0888596;
  const map_center_lon =
    process.env.REACT_APP_DEFAULT_MAP_CENTER_LON || 55.1482862;
  const map_zoom = process.env.REACT_APP_DEFAULT_MAP_ZOOM_LEVEL || 15;

  return (
    <Box position="relative">
      <MapContainer
        center={[map_center_lat, map_center_lon]}
        zoom={map_zoom}
        style={{ height: "100vh" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="OpenStreetMap"
        />
        {farmToZoom && <ZoomToPolygon farm={farmToZoom} />}
        {farms.map((farm) => {
          const coordinates = farm?.plot_polygon_geometry?.coordinates[0];
          const lat = coordinates[0];
          const lng = coordinates[1];

          return (
            <Marker
              key={farm.id}
              position={[lat, lng]}
              eventHandlers={{
                click: () => handleMarkerClick(farm),
              }}
            >
              <PlotData selectedFarm={selectedFarm} />
            </Marker>
          );
        })}
      </MapContainer>
      <FloatingPlotTable farms={farms} farmToView={handleFarmView} />
    </Box>
  );
};

export default PinsContainer;
