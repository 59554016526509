import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs, Alert
} from "@mui/material";
import Header from "../../Header";
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate, Link } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  useGetCardsQuery,
  useBlockCardMutation,
  useUnBlockCardMutation
} from "../../../features/cards/cardsApiSlice";
import Spinner from "../../global/Spinner";
import BlockCardDialog from "../shared/BlockCardDialog";
import UnBlockCardDialog from "../shared/UnBlockCardDialog"
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import {getSegment} from "../../../utils/stringUtils";
import { LinkColor } from "../../../theme";

const Cards = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState("");
  const [openDialogCardStatus, setOpenDialogCardStatus] = useState(false);

  const [cards, setCards] = useState([]);

  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const { data, isLoading, isSuccess, isFetching } = useGetCardsQuery({
    page,
    pageSize,
  });

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  useEffect(() => {
    if (data?.data) setCards(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  const [blockCard] = useBlockCardMutation();
  const [unBlockCard] = useUnBlockCardMutation();

  const handleView = (card) => {
    card = JSON.parse(card);
    //navigate(`/beneficiaries/cards/${card.id}`, {state: { card }});
    navigate(`/beneficiaries/cards/${card.id}`);
  };

  const handleClickOpenStatus = (card) => {
    setSelectedCard(card);
    setOpenDialogCardStatus(true);
  };

  const handleCloseStatus = (value) => {
    setOpenDialogCardStatus(false);
    setSelectedCard(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t('global.table.actions'),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => {
        const getCard = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                <Button
                    color="warning"
                    onClick={() => handleView(getCard())}
                >
                  {t('global.table.view_button')} <RemoveRedEyeOutlinedIcon />
                </Button>
                {params.row.status === "ACTIVE" ? (
                    <Button
                        color="error"
                        onClick={() => handleClickOpenStatus(getCard())}
                    >
                      {t('global.table.block_button')} <BlockOutlinedIcon />
                    </Button>
                ) : (
                    <Button
                        color="error"
                        onClick={() => handleClickOpenStatus(getCard())}
                    >
                      {t('global.table.unblock_button')} <RemoveCircleOutlineOutlinedIcon />
                    </Button>
                )}
              </ButtonGroup>
             
            </Box>
        );
      },
    },
    {
      field: "serial",
      headerName: t('cards.table.serial'),
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 100,
    },
    { field: "type", headerName: t('cards.table.type'), flex: 0.5, minWidth: 100 },
    { field: "has_chip", headerName: t('cards.table.has_chip'), flex: 0.5, minWidth: 100 },
    { field: "storage", headerName: t('cards.table.storage'), flex: 0.5, minWidth: 100 },
    { field: "free_space", headerName: t('cards.table.free_space'), flex: 0.5, minWidth: 100 },
    { field: "status", headerName: t('cards.table.status'), flex: 0.5, minWidth: 100 },
    {
      field: "full_name",
      headerName: t("beneficiaries.table.fullname"),
      valueGetter: (params) => params.row?.beneficiary?.full_name,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "unique_id",
      headerName: t("beneficiaries.table.unique_id"),
      valueGetter: (params) => getSegment(params.row?.beneficiary?.unique_id, "-", -1),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      valueGetter: (params) => params.row?.beneficiary?.id_number,
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      valueGetter: (params) => params.row?.beneficiary?.telephone,
      minWidth: 150,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header title={t('cards.index.title')} subtitle={t('cards.index.subtitle')}/>

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/beneficiaries">
          <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('sidebar.Cards')}
        </Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="end" mt="20px">
        {/*@todo Add button to add card here, if needed in future*/}
      </Box>

      <Box className="data_grid_box_container">
        {cards?.length < 1 ? (
            <Alert severity="info">{t("cards.index.no_cards_data")}</Alert>
        ) : (
            <StyledDataGrid
                rows={cards}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pagination.perPage}
                rowCount={pagination.total}
                loading={isFetching}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={default_rows_per_page_options}
            />
        )}
      </Box>
      {openDialogCardStatus && JSON.parse(selectedCard).status === "ACTIVE" && (
                  <BlockCardDialog
                      open={openDialogCardStatus}
                      onClose={handleCloseStatus}
                      name={JSON.parse(selectedCard).serial}
                      blockMethod={blockCard}
                      url={`/cards/block/${
                          JSON.parse(selectedCard).id
                      }`}
                      cardData={JSON.parse(selectedCard)}
                  />
              )}
              {openDialogCardStatus && JSON.parse(selectedCard).status !== "ACTIVE" && (
                  <UnBlockCardDialog
                      open={openDialogCardStatus}
                      onClose={handleCloseStatus}
                      name={JSON.parse(selectedCard).serial}
                      blockMethod={unBlockCard}
                      url={`/cards/unblock/${
                          JSON.parse(selectedCard).id
                      }`}
                      cardData={JSON.parse(selectedCard)}
                  />
              )}
    </Box>
  );

  return content;
};

export default Cards;
