import React, { useEffect, useState } from "react";
import { useRemoveRolePermissionsMutation } from "../../../features/roles/rolesApiSlice";
import { useParams, useNavigate, Link } from "react-router-dom";
import Spinner from "../../global/Spinner";
import {
  Box,
  Button,
  Alert,
  AlertTitle,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { useGetPermissionsQuery } from "../../../features/permissions/permissionsApiSlice";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const RemoveRolePermissions = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [names, setNames] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  // Fetch Permisions
  const { data, isLoading, isSuccess } = useGetPermissionsQuery();

  useEffect(() => {
    if (data?.permissions) setPermissions(data?.permissions);
  }, [data, isLoading, isSuccess]);

  // Remove permissions from role
  const [removePermissions] = useRemoveRolePermissionsMutation();

  const columns = [
    {
      field: "name",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "guard_name",
      headerName: t("roles.form.label.guard_name"),
      flex: 1,
    },
  ];

  const selectedPermissions = (ids) => {
    const selectedRowsData = ids.map((id) =>
      permissions.find((row) => row.id === id)
    );
    let listItems = [];
    selectedRowsData?.forEach((item) => {
      listItems.push(item?.name);
    });
    setNames(listItems);
  };

  const handleRemove = async () => {
    if (permissions.length) {
      const payload = {
        role_id: id,
        permissions: names,
      };

      try {
        const response = await removePermissions(payload).unwrap();

        if (response.status === 200) {
          Toast(response?.message, "success");
        } else {
          Toast(response?.message, "error");
        }

        setTimeout(function () {
          navigate(`/roles/${id}`, { state: { message: response?.message } });
        }, 3000);
      } catch (err) {
        Toast(err?.data?.message, "error");
      }
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("roles.remove_permissions.title")}
        subtitle={t("roles.remove_permissions.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/roles">
          <Typography variant="body2">{t("sidebar.Roles")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to={`/roles/${id}`}>
          <Typography variant="body2">{t("roles.view.view_role")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("roles.remove_permissions.remove_permissions")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("roles.remove_permissions.alert_head")}</AlertTitle>
        {t("roles.remove_permissions.alert_message")}
      </Alert>

      <button
        className={!names.length ? "btn btn-disabled" : "btn btn-primary"}
        onClick={handleRemove}
        disabled={!names.length}
        style={{ marginTop: "10px" }}
      >
        {t("global.buttons.remove_permissions")}
      </button>
      <Box className="data_grid_box_container">
        <StyledDataGrid
          checkboxSelection
          rows={permissions}
          columns={columns}
          onSelectionModelChange={(ids) => selectedPermissions(ids)}
          rowsPerPageOptions={default_rows_per_page_options}
        />
      </Box>
    </Box>
  );
};

export default RemoveRolePermissions;
