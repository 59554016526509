import React from 'react'
import { useNavigate } from "react-router-dom";

import {  Box,
    Typography,
    Card,
    CardContent,
    Button,
    CardHeader,} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useTranslation } from "react-i18next";
import { useDispatch} from "react-redux";
import { logOut } from "../../../features/auth/authSlice";
import PublicTopbar from "../../global/PublicTopbar";

function UnauthorizedPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logOut());
    navigate("/login");
  };

  return (
    <>
    <PublicTopbar/>
    <Box
        position="absolute"
        width="50%"
        maxWidth="600px"
        top="100px"
        left="30%"
        right="40%"
      >
        <Card variant="outlined">
          <CardHeader title={t('roles.responses.unauthorized.title')} />
          <CardContent>
            <Typography variant="body1">{t('roles.responses.unauthorized.message')}</Typography>
          </CardContent>
          <Box
            mt={2}
            p={2}
            borderTop={1}
            borderColor="divider"
            display="flex"
            justifyContent="flex-end"
          >
           <Button
               variant="outlined"
               color="success"
               startIcon={<KeyboardBackspaceIcon />}
               onClick={handleLogout}
           >
               {t('login.buttons.back_to_login')}
            </Button>
          </Box>
        </Card>
    </Box> 
    </>
          
  )
}

export default UnauthorizedPage