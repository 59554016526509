import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    refresh_token: null,
    permissions: null,
    roles: null,
    expires_in: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const {
        access_token,
        refresh_token,
        roles,
        permissions,
        user,
        expires_in,
      } = action.payload;
      state.user = user;
      state.token = access_token;
      state.refresh_token = refresh_token;
      state.roles = roles;
      state.permissions = permissions;
      state.expires_in = expires_in;
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
      state.refresh_token = null;
      state.roles = null;
      state.permissions = null;
      state.expires_in = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentRefreshToken = (state) => state.auth.refresh_token;
export const selectCurrentRoles = (state) => state.auth.roles;
export const selectCurrentPermissions = (state) => state.auth.permissions;
export const selectCurrentExpiresIn = (state) => state.auth.expires_in;