import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs,
  useTheme,
} from "@mui/material";
import Header from "../../Header";
import {Field, Formik} from "formik";
import * as yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, Link } from "react-router-dom";
import { useAddProjectMutation } from "../../../features/projects/projectsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { LinkColor, tokens } from "../../../theme";
import { useTranslation } from "react-i18next";

const initialValues = {
  code: "",
  title: "",
  description: "",
  document_number: "",
  project_number: "",
  section_name: "",
  section_code: "",
  status: "",
  status_code: "",
  country_coverage: 1,
};

const AddProject = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [errs, setErrors] = useState({});

  const [addProject, { isLoading, isError, error }] = useAddProjectMutation();

  const projectSchema = yup.object().shape({
    code: yup.string().required(t("project.add.form.helpertext.code")),
    title: yup.string().required(t("project.add.form.helpertext.name")),
    description: yup.string().required(t("project.add.form.helpertext.description")),
  });

  useEffect(() => {
    setErrors(error?.data?.errors);
  }, [isError, error, isLoading]);

  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    const payload = {
      ...values,
      description: values.description,
      start_date: startDate === null ? null : moment(startDate).format("YYYY-MM-DD"),
      nte_date: endDate === null ? null : moment(endDate).format("YYYY-MM-DD"),
    };

    try {
      const response = await addProject(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
        function () {
          navigate("/projects", { state: { message: response?.message } });
        },
        3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header title={t('project.add.title')} subtitle={t('project.add.subtitle')} />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects">
          <Typography variant="body2">{t('sidebar.Projects')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('project.index.add_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={projectSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                required
                variant="filled"
                type="text"
                label={t('project.shared.project_code')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                name="code"
                message={t("project.form.helpertext.code")}
                error={!!touched.code && !!errors.code}
                helperText={
                  isError && errs?.code ? (
                    <Typography variant="body2" color={colors.redAccent[500]}>
                      {errs?.code[0]}
                    </Typography>
                  ) : (
                    t("project.form.helpertext.code")
                  )
                }
              />

              <TextField
                fullWidth
                required
                variant="filled"
                type="text"
                label={t('project.shared.project_name')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                message={t("project.form.helpertext.name")}
                error={!!touched.title && !!errors.title}
                helperText={
                  isError && errs?.title ? (
                    <Typography variant="body2" color={colors.redAccent[500]}>
                      {errs?.title[0]}
                    </Typography>
                  ) : (
                    t("project.form.helpertext.name")
                  )
                }
              />

              <TextField
                fullWidth
                required
                variant="filled"
                type="text"
                multiline
                rows={4}
                label={t('project.shared.description')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={
                  isError && errs?.description ? (
                    <Typography variant="body2" color={colors.redAccent[500]}>
                      {errs?.description[0]}
                    </Typography>
                  ) : (
                    t("project.form.helpertext.description")
                  )
                }
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('project.shared.start_date')}
                  value={startDate}
                  name="startDate"
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setStartDate(Date.parse(newValue));
                  }}
                  formatDate={(startDate) =>
                    moment(startDate).format("YYYY-MM-DD")
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      helperText={
                        isError && errs?.start_date ? (
                          <Typography
                            variant="body2"
                            color={colors.redAccent[500]}
                          >
                            {errs?.start_date[0]}
                          </Typography>
                        ) : (
                          t("project.form.helpertext.start_date")
                        )
                      }
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('project.shared.end_date')}
                  disablePast
                  value={endDate}
                  name="endDate"
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setEndDate(Date.parse(newValue));
                  }}
                  formatDate={(endDate) => moment(endDate).format("YYYY-MM-DD")}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      helperText={
                        isError && errs?.nte_date ? (
                          <Typography
                            variant="body2"
                            color={colors.redAccent[500]}
                          >
                            {errs?.nte_date[0]}
                          </Typography>
                        ) : (
                          t("project.form.helpertext.end_date")
                        )
                      }
                    />
                  )}
                />
              </LocalizationProvider>

              <div id="my-radio-group">
                {t("project.form.label.country_coverage")}
              </div>
              <div role="group" aria-labelledby="my-radio-group">
                <label>
                  <Field
                      type="radio"
                      name="country_coverage"
                      value="1"
                      onChange={handleChange}
                      checked={true}
                  />
                  {t("project.form.label.country_coverage_single")}
                </label>
                <label>
                  <Field
                      type="radio"
                      name="country_coverage"
                      value="2"
                      onChange={handleChange}
                  />
                  {t("project.form.label.country_coverage_multiple")}
                </label>
              </div>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('project.shared.document_number')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.document_number}
                name="document_number"
                message={t("project.form.helpertext.document_number")}
                error={!!touched.document_number && !!errors.document_number}
                helperText={t("project.form.helpertext.document_number")}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('project.shared.number')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.project_number}
                name="project_number"
                message={t("project.form.helpertext.number")}
                error={!!touched.project_number && !!errors.project_number}
                helperText={t("project.form.helpertext.number")}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('project.shared.section_name')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.section_name}
                name="section_name"
                message={t("project.form.helpertext.section_name")}
                error={!!touched.section_name && !!errors.section_name}
                helperText={t("project.form.helpertext.section_name")}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('project.shared.section_code')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.section_code}
                name="section_code"
                message={t("project.form.helpertext.section_code")}
                error={!!touched.section_code && !!errors.section_code}
                helperText={t("project.form.helpertext.section_code")}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('project.shared.status')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.status}
                name="status"
                message={t("project.form.helpertext.status")}
                error={!!touched.status && !!errors.status}
                helperText={t("project.form.helpertext.status")}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('project.shared.status_code')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.status_code}
                name="status_code"
                message={t("project.form.helpertext.status_code")}
                error={!!touched.status_code && !!errors.status_code}
                helperText={t("project.form.helpertext.status_code")}
              />
            </Box>
            <Box display="flex"  gap="1rem" justifyContent="end" mt="20px">
              <Link to="/projects" underline="none">
                <button className="btn btn-danger">
                {t("global.page.cancel_button")}
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("project.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );

  return content;
};

export default AddProject;
