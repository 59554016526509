import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  CardHeader,
} from "@mui/material";
import axios from "../../api/axios";
import PublicTopbar from "../global/PublicTopbar";

const SSOLoginFail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [failResponse, setFailResponse] = useState([]);

  const { data } = useParams();

  useEffect(() => {
    if (data) {
      const decodedData = Base64.decode(data);
      setFailResponse(JSON.parse(decodedData.toString()));
    }
  }, [data]);

  const handleProceed = async () => {
    try {
      const userEmail = failResponse?.user_email;
      const res = await axios.get(`/cognito/link/${userEmail}`);
      const url = res?.data?.url;
      window.location.href = url;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <PublicTopbar />
      <Box
        position="absolute"
        width="50%"
        maxWidth="600px"
        top="100px"
        left="30%"
        right="40%"
      >
        <Card variant="outlined">
          <CardHeader title={t("login.sso.login_fail")} />
          <CardContent>
            <Typography variant="body1">{failResponse?.message}</Typography>
          </CardContent>
          <Box
            mt={2}
            p={2}
            borderTop={1}
            borderColor="divider"
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => navigate("/login")}
            >
                <ArrowBackOutlinedIcon /> {t("login.sso.go_home_button")}
            </Button>
            {failResponse?.status === 426 && (
              <Button
                variant="outlined"
                color="success"
                onClick={() => handleProceed()}
                style={{ marginLeft: "8px" }}
              >
                  {t("login.sso.proceed_button")} <ArrowForwardOutlinedIcon />
              </Button>
            )}
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default SSOLoginFail;
