import { apiSlice } from "../../api/apiSlice";

export const topupsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Topups", "Topup", "TopupBeneficiaries"],
  endpoints: (builder) => ({
    addTopup: builder.mutation({
      query: (payload) => ({
        url: "/topup/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Topups"],
    }),
    getTopups: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/topup/list/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Topups"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getTopup: builder.query({
      query: (id) => ({
        url: `/topup/single/${id}`,
        method: "GET",
      }),
      providesTags: ["Topup"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getTopupBeneficiaries: builder.query({
      query: (payload) => ({
        url: `/topup/beneficiaries`,
        method: "POST",
        body: { ...payload },
      }),
      providesTags: ["TopupBeneficiaries"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    activateTopup: builder.mutation({
      query: (payload) => ({
        url: `/topup/activate`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["TopupBeneficiaries", "Topups", "Topup"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateTopup: builder.mutation({
      query: (payload) => ({
        url: `/topup/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Topups"],
    }),
    deleteTopup: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Topups"],
    }),
    addTopupBeneficiaries: builder.mutation({
      query: (payload) => ({
        url: "topup/add/beneficiaries",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["TopupBeneficiaries", "Topup"],
    }),
  }),
});

export const {
  useAddTopupMutation,
  useGetTopupsQuery,
  useGetTopupQuery,
  useGetTopupBeneficiariesQuery,
  useActivateTopupMutation,
  useUpdateTopupMutation,
  useDeleteTopupMutation,
  useAddTopupBeneficiariesMutation
} = topupsApiSlice;
