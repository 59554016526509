// stringUtils.js

/**
 * Returns a segment or multiple segments from a string based on the provided delimiter and index.
 *
 * @param {string} str - The string to be processed.
 * @param {string} delimiter - The character or sequence to split the string by.
 * @param {number} index - The zero-based index of the desired segment or starting index for multiple segments. 
 *                         Negative numbers count from the end.
 * @param {boolean} includeFollowing - If true, returns segments from the index till the end.
 * @returns {string|null} - The desired segment(s) or null if the index is out of range.
 */
export const getSegment = (str, delimiter = '-', index = 0, includeFollowing = false) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const parts = str.split(delimiter);

    if (index < 0) {
        index = parts.length + index;
    }

    if (index >= parts.length || index < 0) {
        return null;
    }

    if (includeFollowing) {
        return parts.slice(index).join(delimiter);
    }

    return parts[index];
}

// Example usages:
// getSegment("IDEAOER-IN-CG1101-832b52e02a42", "-", -1) // Returns "832b52e02a42"
// getSegment("IDEAOER-IN-CG1101-832b52e02a42", "-", 2) // Returns "CG1101"
// getSegment("IDEAOER-IN-CG1101-832b52e02a42", "-", 2, true) // Returns "CG1101-832b52e02a42"
