import axios from 'axios'
import { getAccessToken } from '../components/AuthGuard'

const BASE_URL = process.env.REACT_APP_BASE_URL

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate =  axios.create({
    baseURL: BASE_URL,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
    }
})