import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import About from "./components/scenes/about_idea";
import Dashboard from "./components/scenes/dashboard";

import LoginSession from "./components/LoginSession";
import RequireAuth from "./features/auth/RequireAuth";
import PrivateRoutes from "./utils/PrivateRoutes";
import Layout from "./components/Layout";

import "./index.css";
import Login from "./components/login";

import Projects from "./components/scenes/projects";
import AddProject from "./components/scenes/projects/AddProject";
import EditProject from "./components/scenes/projects/EditProject";

import Donors from "./components/scenes/donors";
import AddDonor from "./components/scenes/donors/AddDonor";
import EditDonor from "./components/scenes/donors/EditDonor";

import PurchaseOrders from "./components/scenes/purchase_orders";
import AddPurchaseOrder from "./components/scenes/purchase_orders/AddPurchaseOrder";
import EditPurchaseOrder from "./components/scenes/purchase_orders/EditPurchaseOrder";

import Interventions from "./components/scenes/interventions";
import AddIntervention from "./components/scenes/interventions/AddIntervention";
import EditIntervention from "./components/scenes/interventions/EditIntervention";
import ViewIntervention from "./components/scenes/interventions/ViewIntervention";
import AddInterventionBeneficiaries from "./components/scenes/interventions/AddInterventionBeneficiaries";
import AddInterventionServiceProviders from "./components/scenes/interventions/AddInterventionServiceProviders";

import EntitlementTypes from "./components/scenes/entitlement_types";
import AddEntitlementType from "./components/scenes/entitlement_types/AddEntitlementType";

import Entitlements from "./components/scenes/entitlements";
import AddEntitlement from "./components/scenes/entitlements/AddEntitlement";
import EditEntitlement from "./components/scenes/entitlements/EditEntitlement";

import EntitlementCriteria from "./components/scenes/entitlement_criteria";
import AddEntitlementCriteria from "./components/scenes/entitlement_criteria/AddEntitlementCriteria";
import EditEntitlementCriteria from "./components/scenes/entitlement_criteria/EditEntitlementCriteria";

import Packages from "./components/scenes/packages";
import AddPackage from "./components/scenes/packages/AddPackage";
import EditPackage from "./components/scenes/packages/EditPackage";
import ViewPackage from "./components/scenes/packages/ViewPackage";
import PackageAddEntitlements from "./components/scenes/packages/PackageAddEntitlements";
import PackageAddBeneficiaries from "./components/scenes/packages/PackageAddBeneficiaries";

import Seasons from "./components/scenes/seasons";
import AddSeason from "./components/scenes/seasons/AddSeason";
import EditSeason from "./components/scenes/seasons/EditSeason";

import DistributionPoints from "./components/scenes/distribution_points";
import AddDistributionPoint from "./components/scenes/distribution_points/AddDistributionPoint";
import EditDistributionPoint from "./components/scenes/distribution_points/EditDistributionPoint";
import ViewDistributionPoint from "./components/scenes/distribution_points/ViewDistributionPoint";
import AddServiceProviders from "./components/scenes/distribution_points/AddServiceProviders";
import AddBeneficiaries from "./components/scenes/distribution_points/AddBeneficiaries";

import Topups from "./components/scenes/topups";
import AddTopup from "./components/scenes/topups/AddTopup";
import EditTopup from "./components/scenes/topups/EditTopup";
import ViewTopup from "./components/scenes/topups/ViewTopup";

import Users from "./components/scenes/users";
import AddUser from "./components/scenes/users/AddUser";
import EditUser from "./components/scenes/users/EditUser";

import Roles from "./components/scenes/roles";
import AddRole from "./components/scenes/roles/AddRole";
import EditRole from "./components/scenes/roles/EditRole";
import ViewRole from "./components/scenes/roles/ViewRole";
import AddRoleUsers from "./components/scenes/roles/AddRoleUsers";
import AddRolePermissions from "./components/scenes/roles/AddRolePermissions";
import RemoveRolePermissions from "./components/scenes/roles/RemoveRolePermissions";

import ServiceProviders from "./components/scenes/service_providers";
import ViewServiceProvider from "./components/scenes/service_providers/ViewServiceProvider";
import ServiceProviderAddEntitlements from "./components/scenes/service_providers/ServiceProviderAddEntitlements";

import Beneficiaries from "./components/scenes/beneficiaries";
import ViewBeneficiary from "./components/scenes/beneficiaries/ViewBeneficiary";
import EditBeneficiaries from "./components/scenes/beneficiaries/EditBeneficiaries";
import ImportBeneficiaries from "./components/scenes/beneficiaries/ImportBeneficiaries";
import ImportBeneficiariesPhotos from "./components/scenes/beneficiaries/ImportBeneficiariesPhotos";
import MapAllBeneficiaries from "./components/scenes/beneficiaries/MapAllBeneficiaries";

import KoboForms from "./components/scenes/kobo";
import KoboForm from "./components/scenes/kobo/KoboForm";
import ImportKoboForm from "./components/scenes/kobo/ImportKoboForm";
import KoboFormPreloads from "./components/scenes/kobo/KoboFormPreloads";

import Payments from "./components/scenes/payments";
import CreatePayment from "./components/scenes/payments/CreatePayment";
import ViewPayment from "./components/scenes/payments/ViewPayment";

import CopayData from "./components/scenes/copay";
import CreateCopayTransactionsReport from "./components/scenes/copay/CreateCopayTransactionsReport";
import Redemptions from "./components/scenes/redemptions";
import VoucherRedemptions from "./components/scenes/redemptions/voucher";
import InkindRedemptions from "./components/scenes/redemptions/inkind";
import CreateRedemptionTransactionsReport from "./components/scenes/redemptions/CreateRedemptionTransactionsReport";
import Cash from "./components/scenes/cash";
import CreateCashTransactionsReport from "./components/scenes/cash/CreateCashTransactionsReport";

// import BiometricDeduplication from "./components/scenes/biometric_deduplication";
import BiometricDeduplication from "./components/scenes/biometric_deduplication/BiometricDeduplication";
import MapBeneficiaries from "./components/scenes/biometric_deduplication/Map";

import GRMS from "./components/scenes/grms";
import SettingsGroups from "./components/scenes/settings-groups";
// import CheckDuplicates from "./components/scenes/non_biometric_deduplication";
import NonBiometricDeduplication from "./components/scenes/non_biometric_deduplication/NonBiometricDeduplication";
import ChangePassword from "./components/scenes/auth/ChangePassword";
import Farms from "./components/scenes/farms";
import DynamicForms from "./components/scenes/dynamic_forms";
import AddDynamicForm from "./components/scenes/dynamic_forms/AddDynamicForm";
import ViewDynamicForm from "./components/scenes/dynamic_forms/ViewDynamicForm";

import Cards from "./components/scenes/cards";
import ViewCard from "./components/scenes/cards/ViewCard";
import TopupAddBeneficiaries from "./components/scenes/topups/TopupAddBeneficiaries";
import CreatePreload from "./components/scenes/kobo/CreatePreload";

import Reports from "./components/scenes/reports";
import CreateEvoucherLiquidationReport from "./components/scenes/reports/evoucher/CreateEvoucherLiquidationReport";
import BulkEditBeneficiaries from "./components/scenes/beneficiaries/BulkEditBeneficiaries";
import usePermissionGuard from "./components/global/shared/UsePermissionGuard";
import SSOLoginFail from "./components/login/SSOLoginFail";
import UnauthorizedPage from "./components/global/shared/UnauthorizedPage";
import ListReports from "./components/scenes/reports/ListReports";
import AddInterventionRoles from "./components/scenes/interventions/AddInterventionRole";
import AddRoleIntervention from "./components/scenes/roles/AddRoleIntervention";
import RemoveRoleIntervention from "./components/scenes/roles/RemoveRoleIntervention";

const App = () => {
  const [theme, colorMode] = useMode();
  const containerStyle = {
    height: "100vh",
    overflow: "hidden",
  };
  return (
    <div style={containerStyle}>
      <Suspense fallback={null}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="login/session/:data" element={<LoginSession />} />
              <Route
                path="/login/cognito/fail/:data"
                element={<SSOLoginFail />}
              />
            </Routes>
          </ThemeProvider>
        </ColorModeContext.Provider>

        <Routes>
          <Route path="/unauthorized" element={<NoPermissionsGuard />} />
          <Route element={<Layout />}>
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/about" element={<About />} />

              <Route element={<RequireAuth />}>
                <Route path="/users">
                  <Route index element={<ViewUsersGuard />} />
                  <Route path="add" element={<AddUsersGuard />} />
                  <Route path="edit/:id" element={<EditUsersGuard />} />
                </Route>

                <Route path="/roles">
                  <Route index element={<ListRolesGuard />} />
                  <Route path=":id" element={<ViewRolesGuard />} />
                  <Route path="add" element={<AddRolesGuard />} />
                  <Route path="edit/:id" element={<EditRolesGuard />} />
                  <Route path=":id/add/users" element={<AddRoleUserGuard />} />
                  <Route
                    path=":id/add/permissions"
                    element={<AddRolePermissionsGuard />}
                  />
                  <Route
                    path=":id/remove/permissions"
                    element={<RemoveRolePermissionsGuard />}
                  />
                  <Route
                    path=":id/add/intervention"
                    element={<AddRoleInterventionGuard />}
                  />

                  <Route
                    path=":id/remove/intervention"
                    element={<RemoveRoleInterventionGuard />}
                  ></Route>
                </Route>

                <Route path="/projects">
                  <Route index element={<ViewProjectsGuard />} />
                  <Route path="add" element={<AddProjectsGuard />} />
                  <Route path="edit/:id" element={<EditProjectsGuard />} />
                  <Route path="donors">
                    <Route index element={<ViewDonorGuard />} />
                    <Route path="add" element={<AddDonorsGuard />} />
                    <Route path="edit/:id" element={<EditDonorsGuard />} />
                  </Route>
                  <Route path="purchase-orders">
                    <Route index element={<ViewPurchaseOrderGuard />} />
                    <Route path="add" element={<AddPurchaseOrderGuard />} />
                    <Route
                      path="edit/:id"
                      element={<EditPurchaseOrderGuard />}
                    />
                  </Route>
                </Route>

                <Route path="/interventions">
                  <Route index element={<ListInterventionsGuard />} />
                  <Route path=":id" element={<ViewInterventionsGuard />} />
                  <Route path="add" element={<AddInterventionsGuard />} />
                  <Route path="edit/:id" element={<EditInterventionsGuard />} />
                  <Route
                    path=":id/add/beneficiaries"
                    element={<AddInterventionsBeneficiariesGuard />}
                  />
                  <Route
                    path=":id/add/service/providers"
                    element={<AddInterventionsServiceProviderGuard />}
                  />
                  <Route
                    path=":id/add/roles"
                    element={<AddInterventionsRolesGuard />}
                  />

                  <Route path="entitlement-types">
                    <Route index element={<ListEntitlementTypeGuard />} />
                    <Route path="add" element={<AddEntitlementTypeGuard />} />
                  </Route>
                  <Route path="entitlement-criteria">
                    <Route index element={<ListEntitlementCriteriaGuard />} />
                    <Route
                      path="add"
                      element={<AddEntitlementCriteriaGuard />}
                    />
                    <Route
                      path="edit/:id"
                      element={<EditEntitlementCriteriaGuard />}
                    />
                  </Route>
                  <Route path="entitlements">
                    <Route index element={<ListEntitlementsGuard />} />
                    <Route path="add" element={<AddEntitlementsGuard />} />
                    <Route
                      path="edit/:id"
                      element={<EditEntitlementsGuard />}
                    />
                  </Route>
                  <Route path="packages">
                    <Route index element={<ListPackagesGuard />} />
                    <Route path="add" element={<AddPackagesGuard />} />
                    <Route path="edit/:id" element={<EditPackagesGuard />} />
                    <Route path="view/:id" element={<ViewPackagesGuard />} />
                    {/* #TODO: find out what permissions are needed for below */}
                    <Route
                      path=":id/add/entitlements"
                      element={<PackageAddEntitlements />}
                    />
                    <Route
                      path=":id/add/beneficiaries"
                      element={<PackageAddBeneficiaries />}
                    />
                  </Route>
                  <Route path="seasons">
                    <Route index element={<ListSeasonsGuard />} />
                    <Route path="add" element={<AddSeasonsGuard />} />
                    <Route path="edit/:id" element={<EditSeasonsGuard />} />
                  </Route>
                  <Route path="topups">
                    <Route index element={<ListTopupGuard />} />
                    <Route path="add" element={<AddTopupGuard />} />
                    <Route path="edit/:id" element={<EditTopupGuard />} />
                    <Route path="view/:id" element={<ViewTopupGuard />} />
                    <Route
                      path=":id/add/beneficiaries"
                      element={<TopupAddBeneficiaries />}
                    />
                  </Route>
                </Route>

                <Route path="/beneficiaries">
                  <Route index element={<ListBeneficiariesGuard />} />
                  <Route path=":id" element={<ViewBeneficiariesGuard />} />
                  <Route path="import" element={<ImportBeneficiaries />} />
                  <Route
                    path="import/photos"
                    element={<ImportBeneficiariesPhotos />}
                  />
                  <Route path="edit" element={<EditBeneficiariesGuard />} />
                  <Route
                    path="bulk/edit"
                    element={<BultEditBeneficiariesGuard />}
                  />
                  <Route path="map" element={<MapAllBeneficiaries />} />

                  {/* #TODO: Find out what permission for below */}
                  <Route path="biometric-deduplication">
                    <Route index element={<BiometricDeduplication />} />
                    <Route path="map/:id" element={<MapBeneficiaries />} />
                  </Route>

                  <Route path="non-biometric-deduplication">
                    <Route index element={<NonBiometricDeduplication />} />
                  </Route>

                  <Route path="farms">
                    <Route index element={<Farms />} />
                  </Route>

                  <Route path="cards">
                    <Route index element={<ListCardsGuard />} />
                    <Route path=":id" element={<ViewCardsGuard />} />
                  </Route>
                </Route>

                <Route path="/distribution-points">
                  <Route index element={<ListDistributionGuard />} />
                  <Route path="add" element={<AddDistributionsGuard />} />
                  <Route path=":id" element={<ViewDistributionsGuard />} />
                  <Route path="edit/:id" element={<EditDistributionsGuard />} />
                  {/* #TODO: Find permissions for below */}
                  <Route
                    path=":id/add/service-providers"
                    element={<AddServiceProviders />}
                  />
                  <Route
                    path=":id/add/beneficiaries"
                    element={<AddBeneficiaries />}
                  />
                </Route>

                <Route path="/service-providers">
                  <Route index element={<ViewServiceProviderGuard />} />
                  <Route path="view/:id" element={<ViewServiceProvider />} />
                  <Route
                    path=":id/add/entitlements"
                    element={<ServiceProviderAddEntitlements />}
                  />
                </Route>
              </Route>

              <Route path="/data">
                <Route path="kobo">
                  <Route index element={<ViewDataManagerGuard />} />
                  {/* #TODO: Find permissions for below */}
                  <Route path=":formid" element={<KoboForm />} />
                  <Route path="import/:formid" element={<ImportKoboForm />} />
                  <Route
                    path="form/preloads/:id"
                    element={<KoboFormPreloads />}
                  />
                  <Route
                    path="create/preload/:id"
                    element={<CreatePreload />}
                  />
                </Route>
              </Route>

              <Route path="payments">
                <Route index element={<ListPaymentsGuard />} />
                <Route path="create" element={<CreatePaymentsGuard />} />
                <Route path=":id" element={<ViewPaymentsGuard />} />
                <Route path="cash">
                  <Route index element={<ListCashGuard />} />
                  <Route
                    path="export/excel"
                    element={<CreateCashTransactionsReportGuard />}
                  />
                </Route>
                <Route path="copay">
                  <Route index element={<ListCopayDataGuard />} />
                  <Route
                    path="export/excel"
                    element={<CreateCopayTransactionsReportGuard />}
                  />
                </Route>
                <Route path="evoucher">
                  <Route index element={<ListVoucherRedemptionsGuard />} />
                  <Route
                    path="export/excel"
                    element={<CreateRedemptionTransactionsReportGuard />}
                  />
                </Route>
                <Route path="inkind">
                  <Route index element={<ListInkindRedemptionsGuard />} />
                  <Route
                    path="export/excel"
                    element={<CreateRedemptionTransactionsReportGuard />}
                  />
                </Route>
              </Route>

              <Route path="settings">
                <Route path="grms" element={<GRMS />} />
                <Route path="settings-groups" element={<SettingsGroups />} />
                <Route path="change/password" element={<ChangePassword />} />
              </Route>

              <Route path="reports">
                {/* #TODO:Verify permission used below */}
                {/* <Route index element={<ViewReportsGuard />} /> */}
                <Route index element={<ListReports />} />
                <Route path="evoucher">
                  <Route
                    index
                    element={<CreateEvoucherLiquidationReportGuard />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

// First level guard protection: #TODO: Add submenu guard protection to each component
//////// REPORTS GUARD ////////////
const ViewReportsGuard = () => {
  // Define the required permissions for generating various reports
  const requiredPermissions = "report-evoucher-liquidation";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Reports />;
};
const CreateEvoucherLiquidationReportGuard = () => {
  // Define the required permissions for generating various reports
  const requiredPermissions = "report-evoucher-liquidation";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <CreateEvoucherLiquidationReport />;
};
//////// REPORTS GUARD ////////////

//////// COPAY GUARD ////////////
const ListCopayDataGuard = () => {
  // Define the required permissions for list copay
  const requiredPermissions = "copay-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <CopayData />;
};

const CreateCopayTransactionsReportGuard = () => {
  // Define the required permissions for export copay
  const requiredPermissions = "copay-export-excel";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <CreateCopayTransactionsReport />;
};
//////// END OF COPAY GUARD ////////////

//////// EVOUCHER GUARD ////////////
const ListRedemptionsGuard = () => {
  // Define the required permissions for list redemption
  const requiredPermissions = "redemption-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Redemptions />;
};
const ListVoucherRedemptionsGuard = () => {
  // Define the required permissions for list redemption
  const requiredPermissions = "redemption-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <VoucherRedemptions />;
};
const ListInkindRedemptionsGuard = () => {
  // Define the required permissions for list redemption
  const requiredPermissions = "redemption-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <InkindRedemptions />;
};
const CreateRedemptionTransactionsReportGuard = () => {
  // Define the required permissions for export redemption
  const requiredPermissions = "redemption-export-excel";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <CreateRedemptionTransactionsReport />;
};

//////// END OF EVOUCHER GUARD ////////////

//////// CASH GUARD ////////////
const ListCashGuard = () => {
  // Define the required permissions for list cash
  const requiredPermissions = "cash-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Cash />;
};
const CreateCashTransactionsReportGuard = () => {
  // Define the required permissions for export cash
  const requiredPermissions = "cash-export-excel";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <CreateCashTransactionsReport />;
};
//////// END OF CASH GUARD ////////////

//////// PAYMENT GUARD ////////////
const ListPaymentsGuard = () => {
  // Define the required permissions for list payments
  const requiredPermissions = "payment-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Payments />;
};
const ViewPaymentsGuard = () => {
  // Define the required permissions for viewing payments
  const requiredPermissions = "payment-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <ViewPayment />;
};
const CreatePaymentsGuard = () => {
  // Define the required permissions for create payments
  const requiredPermissions = "payment-create";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <CreatePayment />;
};
//////// END OF PAYMENT GUARD ////////////

//////// DISTRIBUTION GUARD ////////////
const ListDistributionGuard = () => {
  // Define the required permissions for list distribution
  const requiredPermissions = "distribution-point-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <DistributionPoints />;
};

const ViewDistributionsGuard = () => {
  // Define the required permissions for viewing distribution points
  const requiredPermissions = "distribution-point-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <ViewDistributionPoint />;
};
const AddDistributionsGuard = () => {
  // Define the required permissions for add distribution points
  const requiredPermissions = "distribution-point-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddDistributionPoint />;
};
const EditDistributionsGuard = () => {
  // Define the required permissions for edit distribution points
  const requiredPermissions = "distribution-point-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditDistributionPoint />;
};
//////// END OF DISTRIBUTION GUARD ////////////

//////// CARD GUARD ////////////
const ListCardsGuard = () => {
  // Define the required permissions for list cards
  const requiredPermissions = "card-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Cards />;
};
const ViewCardsGuard = () => {
  // Define the required permissions for list cards
  const requiredPermissions = "card-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <ViewCard />;
};
//////// END OF CARD GUARD ////////////

//////// BENEFICIRAY GUARD ////////////
const ListBeneficiariesGuard = () => {
  // Define the required permissions for list beneficiaries
  const requiredPermissions = "beneficiary-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Beneficiaries />;
};
const ViewBeneficiariesGuard = () => {
  // Define the required permissions for viewing beneficiaries
  const requiredPermissions = "beneficiary-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <ViewBeneficiary />;
};
const EditBeneficiariesGuard = () => {
  // Define the required permissions for update beneficiaries
  const requiredPermissions = "beneficiary-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditBeneficiaries />;
};
const BultEditBeneficiariesGuard = () => {
  // Define the required permissions for bulk update beneficiaries
  const requiredPermissions = "beneficiary-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <BulkEditBeneficiaries />;
};
//////// END OF BENEFICIRAY GUARD ////////////

//////// TOPUP GUARD ////////////
const ListTopupGuard = () => {
  // Define the required permissions for listing topup .
  const requiredPermissions = "topup-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Topups />;
};
const ViewTopupGuard = () => {
  // Define the required permissions for view topup .
  const requiredPermissions = "topup-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <ViewTopup />;
};
const AddTopupGuard = () => {
  // Define the required permissions for add topup .
  const requiredPermissions = "topup-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddTopup />;
};
const EditTopupGuard = () => {
  // Define the required permissions for edit topup .
  const requiredPermissions = "topup-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditTopup />;
};
//////// END OF TOPUP GUARD ////////////

//////// SEASONS GUARD ////////////
const ListSeasonsGuard = () => {
  // Define the required permissions for listing seasons .
  const requiredPermissions = "season-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Seasons />;
};
const AddSeasonsGuard = () => {
  // Define the required permissions for adding seasons .
  const requiredPermissions = "season-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddSeason />;
};
const EditSeasonsGuard = () => {
  // Define the required permissions for edit seasons .
  const requiredPermissions = "season-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditSeason />;
};
//////// END OF SEASONS GUARD ////////////

//////// PACKAGES GUARD ////////////
const ListPackagesGuard = () => {
  // Define the required permissions for listing packages .
  const requiredPermissions = "package-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Packages />;
};
const AddPackagesGuard = () => {
  // Define the required permissions for add packages .
  const requiredPermissions = "package-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddPackage />;
};
const EditPackagesGuard = () => {
  // Define the required permissions for update packages .
  const requiredPermissions = "package-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditPackage />;
};
const ViewPackagesGuard = () => {
  // Define the required permissions for view packages .
  const requiredPermissions = "package-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <ViewPackage />;
};
//////// END OF PACKAGES GUARD ////////////

//////// ENTITLEMENTS GUARD ////////////
const ListEntitlementsGuard = () => {
  // Define the required permissions for listing entitlement .
  const requiredPermissions = "entitlement-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Entitlements />;
};
const AddEntitlementsGuard = () => {
  // Define the required permissions for adding entitlement .
  const requiredPermissions = "entitlement-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddEntitlement />;
};
const EditEntitlementsGuard = () => {
  // Define the required permissions for update entitlement .
  const requiredPermissions = "entitlement-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditEntitlement />;
};
const ListEntitlementCriteriaGuard = () => {
  // Define the required permissions for listing entitlement criteria.
  const requiredPermissions = "entitlement-criteria-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EntitlementCriteria />;
};
const AddEntitlementCriteriaGuard = () => {
  // Define the required permissions for adding entitlement criteria.
  const requiredPermissions = "entitlement-criteria-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddEntitlementCriteria />;
};
const EditEntitlementCriteriaGuard = () => {
  // Define the required permissions for update entitlement criteria.
  const requiredPermissions = "entitlement-criteria-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditEntitlementCriteria />;
};
const ListEntitlementTypeGuard = () => {
  // Define the required permissions for listing entitlements types.
  const requiredPermissions = "entitlement-type-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EntitlementTypes />;
};
const AddEntitlementTypeGuard = () => {
  // Define the required permissions for listing entitlements
  const requiredPermissions = "entitlement-type-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddEntitlementType />;
};
//////// END OF ENTITLEMENTS GUARD ////////////

//////// INTERVENTIONS GUARD ////////////
const ListInterventionsGuard = () => {
  // Define the required permissions for listing interventions
  const requiredPermissions = "intervention-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Interventions />;
};
const ViewInterventionsGuard = () => {
  // Define the required permissions for viewing interventions
  const requiredPermissions = "intervention-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <ViewIntervention />;
};
const AddInterventionsGuard = () => {
  // Define the required permissions for add interventions
  const requiredPermissions = "intervention-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddIntervention />;
};
const EditInterventionsGuard = () => {
  // Define the required permissions for edit interventions
  const requiredPermissions = "intervention-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditIntervention />;
};
const AddInterventionsBeneficiariesGuard = () => {
  // Define the required permissions for adding interventions beneficiaries
  const requiredPermissions = "intervention-add-beneficiaries";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddInterventionBeneficiaries />;
};
const AddInterventionsServiceProviderGuard = () => {
  // Define the required permissions for adding interventions SA
  const requiredPermissions = "intervention-add-beneficiaries";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddInterventionServiceProviders />;
};
const AddInterventionsRolesGuard = () => {
  // Define the required permissions for adding interventions SA
  const requiredPermissions = "intervention-add-roles";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddInterventionRoles />;
};
//////// END OF INTERVENTIONS GUARD ////////////

//////// PURCHASE ORDERS GUARD ////////////
const ViewPurchaseOrderGuard = () => {
  // Define the required permissions for viewing purchase order
  const requiredPermissions = "purchase-order-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <PurchaseOrders />;
};
const AddPurchaseOrderGuard = () => {
  // Define the required permissions for adding purchase order
  const requiredPermissions = "purchase-order-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddPurchaseOrder />;
};
const EditPurchaseOrderGuard = () => {
  // Define the required permissions for editing purchase order
  const requiredPermissions = "purchase-order-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditPurchaseOrder />;
};
//////// END OF PURCHASE ORDERS GUARD ////////////

//////// DONORS GUARD ////////////
const ViewDonorGuard = () => {
  // Define the required permissions for viewing donors
  const requiredPermissions = "donor-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Donors />;
};
const AddDonorsGuard = () => {
  // Define the required permissions for adding donors
  const requiredPermissions = "donor-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddDonor />;
};
const EditDonorsGuard = () => {
  // Define the required permissions for update donors
  const requiredPermissions = "donor-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditDonor />;
};
//////// END OF DONORS GUARD ////////////

//////// PROJECTS GUARD ////////////
const ViewProjectsGuard = () => {
  // Define the required permissions for viewing a project
  const requiredPermissions = "project-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Projects />;
};
const AddProjectsGuard = () => {
  // Define the required permissions for adding a project
  const requiredPermissions = "project-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddProject />;
};
const EditProjectsGuard = () => {
  // Define the required permissions for editing a project
  const requiredPermissions = "project-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditProject />;
};
//////// END PROJECTS GUARD ////////////

//////// USERS GUARD ////////////
const ViewUsersGuard = () => {
  // Define the required permissions for viewing a user
  const requiredPermissions = "user-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Users />;
};
const AddUsersGuard = () => {
  // Define the required permissions for adding a user
  const requiredPermissions = "user-add";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddUser />;
};
const EditUsersGuard = () => {
  // Define the required permissions for editing a user
  const requiredPermissions = "user-update";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditUser />;
};
//////// END OF USERS GUARD ////////////

//////// ROLES GUARD ////////////
const ListRolesGuard = () => {
  // Define the required permissions for listing roles
  const requiredPermissions = "role-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Roles />;
};
const ViewRolesGuard = () => {
  // Define the required permissions for viewing roles
  const requiredPermissions = "role-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <ViewRole />;
};
const AddRolesGuard = () => {
  // Define the required permissions for adding roles
  const requiredPermissions = "role-create";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddRole />;
};
const EditRolesGuard = () => {
  // Define the required permissions for editing roles
  const requiredPermissions = "role-edit";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <EditRole />;
};
const AddRoleUserGuard = () => {
  // Define the required permissions for adding role user
  const requiredPermissions = "role-create";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddRoleUsers />;
};
const AddRolePermissionsGuard = () => {
  // Define the required permissions for eadding role permissions
  const requiredPermissions = "role-create";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddRolePermissions />;
};
const RemoveRolePermissionsGuard = () => {
  // Define the required permissions for removing role permissions
  const requiredPermissions = "role-delete";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <RemoveRolePermissions />;
};

const AddRoleInterventionGuard = () => {
  // Define the required permissions for removing role permissions
  const requiredPermissions = "role-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <AddRoleIntervention />;
};
const RemoveRoleInterventionGuard = () => {
  // Define the required permissions for removing role permissions
  const requiredPermissions = "role-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <RemoveRoleIntervention />;
};
//////// END OF ROLES GUARD ////////////

const ViewPermissionsGuard = () => {
  // Define the required permissions for viewing permissions
  const requiredPermissions = "permission-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <Users />;
};

const ViewDataManagerGuard = () => {
  // Define the required permissions for viewing a kobo forms
  const requiredPermissions = "koboforms-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <KoboForms />;
};

const ViewServiceProviderGuard = () => {
  // Define the required permissions for viewing service providers, who for now, are just like users
  const requiredPermissions = "user-list";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <ServiceProviders />;
};

const NoPermissionsGuard = () => {
  const requiredPermissions = "report-evoucher-liquidation";
  // Use the permission guard hook
  usePermissionGuard(requiredPermissions);
  return <UnauthorizedPage />;
};

export default App;
