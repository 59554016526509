import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import {
  useGetSettingsGroupQuery,
  useGetAllSettingsQuery,
  useGetGenerateAllSettingsQuery,
} from "../../../features/settings/settingsApiSlice";
import StyledDataGrid from "../../global/StyledDataGrid";

import { LinkColor } from "../../../theme";

import {
  Box,
  ButtonGroup,
  Button,
  AlertTitle,
  Typography,
  Breadcrumbs,
  Alert,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

import Header from "../../Header";
import SystemDialog from "../shared/SystemDialog";
import AddSettingsForm from "./AddSettingsForm";
import AddSettingGroupForm from "./AddSettingGroupForm";
import Spinner from "../../global/Spinner";

export default function SettingsGroups() {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(
    (permission) => permission.name === "intervention-add-beneficiaries"
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [allSettingGroups, setAllSettingGroups] = useState([]);
  const [allSettings, setAllSettings] = useState([]);
  const [tabValue, setTabValue] = useState("1");
  const [openGroupDialog, setOpenGroupDialog] = useState(false);
  const [openSettingDialog, setOpenSettingDialog] = useState(false);
  const [isGroupEditing, setIsGroupEditing] = useState(false);
  const [isSettingEditing, setIsSettingEditing] = useState(false);
  const [isGroupDelete, setIsGroupDelete] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedGroupSettings, setSelectedGroupSettings] = useState({});

  // Get group settings
  const {
    data: allSettingGroup,
    isSuccess: isAllSettingGroupSuccess,
    isLoading: isAllSettingGroupLoading,
    isFetching: isAllSettingGroupFetching,
    refetch: refetchAllGroups,
  } = useGetSettingsGroupQuery();

  useEffect(() => {
    if (isAllSettingGroupSuccess && allSettingGroup?.data) {
      setAllSettingGroups(allSettingGroup.data);
    }
  }, [allSettingGroup, isAllSettingGroupSuccess, isAllSettingGroupLoading]);

  // Generate default settings
  const {
    data: genSettings,
    isSuccess: isGenSettingsSuccess,
    refetch: refetchGenerateSettings,
  } = useGetGenerateAllSettingsQuery();

  const handleGenerateSettingGroups = async () => {
    try {
      await refetchGenerateSettings();
      await refetchAllGroups();
    } catch (error) {
      console.error("Failed to generate settings:", error);
    }
  };

  // Get all settings
  const {
    data: allSettingData,
    isSuccess: isAllSettingsSuccess,
    isFetching: isAllSettingsFetching,
    refetch: refetchAllSettings,
  } = useGetAllSettingsQuery();

  useEffect(() => {
    if (isAllSettingsSuccess) {
      refetchAllSettings();
      refetchAllGroups();
    }
  }, [isGenSettingsSuccess]);

  useEffect(() => {
    if (isAllSettingsSuccess && allSettingData?.data) {
      setAllSettings(allSettingData.data);
    }
  }, [isAllSettingsSuccess, allSettingData, isAllSettingsFetching]);

  // Set tab selection
  const handleChange = (event, newValue) => {
    setSelectedGroup(allSettingGroups[newValue]);
    setTabValue(newValue);
  };

  // Filter settings by group
  const groupSettings = (group) => {
    return allSettings.filter((setting) => setting.group === group);
  };

  // Get selected setting
  const handleView = (setting) => {
    setting = JSON.parse(setting);
    setSelectedGroupSettings(setting);
    setIsSettingEditing(true);
    setOpenSettingDialog(true);
  };

  //   modal section
  const handleOpenDialog = (modalType, isModalEditing) => {
    if (modalType === "group") {
      setIsGroupDelete(false);
      setOpenGroupDialog(true);
      setIsGroupEditing(() => isModalEditing);
    } else {
      setSelectedGroupSettings({});
      setOpenSettingDialog(true);
      setIsGroupEditing(() => isModalEditing);
    }
  };

  const handleCloseDialog = (modalType) => {
    if (modalType === "group") {
      setOpenGroupDialog(false);
      if (isGroupDelete) {
        setTabValue("1");
      }
    } else {
      setOpenSettingDialog(false);
      setIsSettingEditing(false);
    }
  };

  const handleOpenDeleteDialog = (modalType, isModalDelete) => {
    if (modalType === "group") {
      setIsGroupEditing(false);
      setOpenGroupDialog(true);
      setIsGroupDelete(() => isModalDelete);
    } else {
      setOpenSettingDialog(true);
      setIsGroupDelete(() => isModalDelete);
    }
  };

  console.log("all settings", allSettings);
  // settings columns
  const settingsColumns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        const thisRow = { id: params.row.id };
        fields.forEach((f) => {
          thisRow[f] = params.getValue(params.id, f);
        });

        const getSetting = () => JSON.stringify(thisRow, null, 4);

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              {canAdd && (
                <Button
                  color="warning"
                  onClick={() => handleView(getSetting())}
                  size="small"
                >
                  {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
                </Button>
              )}
            </ButtonGroup>
          </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t("setting_group.table.title"),
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "payload",
      headerName: t("setting_group.table.payload"),
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "is_locked",
      headerName: t("setting_group.table.is_locked"),
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "encrypted",
      headerName: t("setting_group.table.encrypted"),
      flex: 0.5,
      minWidth: 150,
    },
  ];

  return (
    <Box m="20px">
      <Header
        title={t("setting_group.index.title")}
        subtitle={t("setting_group.index.subtitle")}
      />
      {/* Breadcrumbs */}
      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <p>{t("sidebar.Home")} </p>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.setting_groups")}
        </Typography>
      </Breadcrumbs>

      {/* Add setting group */}
      {canAdd && allSettings.length > 0 && (
        <Box mb="10px" display="flex" justifyContent="end" alignItems="center">
          <button
            className="btn btn-primary"
            onClick={() => handleOpenDialog("group")}
          >
            {t("setting_group.index.add_group_button")}
          </button>
        </Box>
      )}

      {allSettingGroups.length > 0 ? (
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label={t("setting_group.index.subtitle")}
                textColor="secondary"
                indicatorColor="secondary"
              >
                {allSettingGroups.map((tab) => (
                  <Tab
                    key={tab.id}
                    label={tab.title}
                    value={tab.id.toString()}
                  />
                ))}
              </TabList>
            </Box>
            {/* general tab */}
            {allSettingGroups.map((tab) => (
              <TabPanel key={tab.id} value={tab.id.toString()}>
                <Box className="data_grid_box_container_within_tabs">
                  {canAdd && (
                    <Box
                      mb="10px"
                      display="flex"
                      justifyContent="end"
                      alignItems="center"
                      gap="10px"
                    >
                      {/* Update group */}
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleOpenDialog("group", true)}
                      >
                        {t("setting_group.index.update_settings_group_button")}
                      </button>
                      {/* Delete settings */}
                      <button
                        className="btn btn-danger"
                        onClick={() => handleOpenDeleteDialog("group", true)}
                      >
                        {t("setting_group.index.delete_settings_group_button")}
                      </button>
                      {/* Add settings */}
                      <button
                        className="btn btn-primary"
                        onClick={() => handleOpenDialog("settings")}
                      >
                        {t("setting_group.index.add_settings_button")}
                      </button>
                    </Box>
                  )}

                  {isAllSettingsFetching ? (
                    <Spinner />
                  ) : (
                    <StyledDataGrid
                      rows={groupSettings(tab.title)}
                      columns={settingsColumns}
                    />
                  )}
                </Box>
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      ) : (
        // No settings found section
        <>
          <Box
            sx={{
              mb: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={handleGenerateSettingGroups}
            >
              {t("setting_group.index.generate_settings")}
            </button>
          </Box>
          <Alert severity="info">
            <AlertTitle>
              <strong>{t("setting_group.index.no_setting_groups_data")}</strong>
            </AlertTitle>
            {t("setting_group.index.generate_default")}{" "}
          </Alert>
        </>
      )}

      {/* modals */}
      <SystemDialog
        open={openGroupDialog}
        handleClose={() => handleCloseDialog("group")}
        formContent={
          <AddSettingGroupForm
            groupId={tabValue}
            settingGroup={allSettingGroup}
            isEditMode={isGroupEditing}
            isDeleteMode={isGroupDelete}
            handleClose={() => handleCloseDialog("group")}
          />
        }
      />
      <SystemDialog
        open={openSettingDialog}
        handleClose={() => handleCloseDialog("settings")}
        formContent={
          <AddSettingsForm
            groupId={tabValue}
            refetchAllSettings={refetchAllSettings}
            groupSettings={selectedGroupSettings}
            isEditMode={isSettingEditing}
            handleClose={() => handleCloseDialog("settings")}
          />
        }
      />
    </Box>
  );
}
