import React from "react";
import { useParams } from "react-router-dom";
import { useGetKoboFormQuery } from "../../../features/kobo/koboApiSlice";
import Spinner from "../../global/Spinner";
import {
  Box,
  Paper,
  Card,
  Typography,
  CardContent
} from "@mui/material";
import Header from "../../Header";
import { useTranslation } from "react-i18next";

const KoboForm = () => {
  const { t } = useTranslation();
  const { formid } = useParams();

  const { data, isLoading } = useGetKoboFormQuery(formid);
  const koboForm = data?.data;

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={`${koboForm.title}`}
        subtitle={`${koboForm.description}`}
      />
      <Box
        sx={{
          display: "flex",
          "& > :not(style)": {
            m: 1,
          },
          maxWidth: "400px",
        }}
      >
        <Paper variant="outlined">
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {koboForm.title}
              </Typography>
              <hr />
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t('kobo.table.description')}:
                </Typography>
                <Typography variant="h6" component="div">
                  {koboForm.description}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t('kobo.table.date_created')}:
                </Typography>
                <Typography variant="h6" component="div">
                  {koboForm.date_created}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t('kobo.table.last_submitted_time')}:
                </Typography>
                <Typography variant="h6" component="div">
                  {koboForm.last_submission_time}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t('kobo.table.submissions_number')}:
                </Typography>
                <Typography variant="h6" component="div">
                  {koboForm.num_of_submissions}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t('kobo.table.formid')}:
                </Typography>
                <Typography variant="h6" component="div">
                  {koboForm.formid}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t('kobo.table.url')}:
                </Typography>
                <Typography variant="h6" component="div">
                  {koboForm.url}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t('kobo.table.uuid')}:
                </Typography>
                <Typography variant="h6" component="div">
                  {koboForm.uuid}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </Box>
    </Box>
  );

  return content;
};

export default KoboForm;