import React, { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import { useAddSeasonMutation } from "../../../features/seasons/seasonsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const initialValues = {
  project_id: "",
  intervention_id: "",
  title: "",
  code: "",
  type: "",
};

const AddSeason = () => {
  const { t } = useTranslation();
  const seasonsSchema = yup.object().shape({
    project_id: yup.string().required(t("global.form.helpertext.project")),
    intervention_id: yup
      .string()
      .required(t("global.form.helpertext.intervention")),
    title: yup.string().required(t("seasons.form.helpertext.title")),
    code: yup.string().required(t("seasons.form.helpertext.code")),
    type: yup.string().required(t("seasons.form.helpertext.type")),
  });
  const navigate = useNavigate();
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [projects, setProjects] = useState([]);
  const [interventions, setInterventions] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: seasonsSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const {
    data: projectsData,
    isLoading: fetchingProjects,
    isSuccess: projectSuccess,
  } = useGetProjectsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (projectsData?.data) setProjects(projectsData?.data);
  }, [projectsData, projectSuccess]);

  const {
    data: interventionsData,
    isLoading: fetchingInterventions,
    isSuccess: interventionsSuccess,
  } = useGetInterventionsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (interventionsData?.data) {
      const projectId = formik.values.project_id;

      if (projectId) {
        const interventions = interventionsData?.data?.filter(
          (intervention) => {
            return intervention?.project?.id === projectId;
          }
        );
        setInterventions(interventions);
      }
    }
  }, [interventionsData, interventionsSuccess, formik.values.project_id]);

  const [addSeason, { isLoading }] = useAddSeasonMutation();

  const handleFormSubmit = async (values) => {
    values = {
      ...values,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    try {
      const response = await addSeason(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
          function () {
            navigate("/interventions/seasons", { state: { message: response?.message } });
          },
          3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header title={t('seasons.add.title')} subtitle={t('seasons.add.subtitle')} />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/seasons">
          <Typography variant="body2">{t("sidebar.Seasons")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('seasons.index.add_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={seasonsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={formik.handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingProjects ? (
                <p>{t("global.loading.project")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.project")}
                  name="project_id"
                  value={formik.values.project_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.project_id && !!formik.errors.project_id
                  }
                  helperText={
                    formik.touched.project_id && formik.errors.project_id
                  }
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.code} - {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {fetchingInterventions ? (
                <p>{t("global.loading.intervention")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    projects?.length > 0 && interventions?.length === 0
                      ? t("global.form.helpertext.no_intervention")
                      : t("global.form.helpertext.intervention")
                  }
                  name="intervention_id"
                  value={formik.values.intervention_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.intervention_id &&
                    !!formik.errors.intervention_id
                  }
                  helperText={
                    formik.touched.intervention_id &&
                    formik.errors.intervention_id
                  }
                >
                  {interventions &&
                    interventions.map((intervention) => (
                      <MenuItem key={intervention.id} value={intervention.id}>
                        {intervention.title}
                      </MenuItem>
                    ))}
                </TextField>
              )}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("global.form.label.name")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.title}
                name="title"
                error={!!formik.touched.title && !!formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("seasons.form.label.code")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                name="code"
                error={!!formik.touched.code && !!formik.errors.code}
                helperText={formik.touched.code && formik.errors.code}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("seasons.form.label.type")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.type}
                name="type"
                error={!!formik.touched.type && !!formik.errors.type}
                helperText={formik.touched.type && formik.errors.type}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("project.shared.start_date")}
                  value={startDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setStartDate(Date.parse(newValue));
                  }}
                  formatDate={(startDate) =>
                    moment(startDate).format("DD-MM-YYYY")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("project.shared.end_date")}
                  value={endDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setEndDate(Date.parse(newValue));
                  }}
                  formatDate={(endDate) =>
                    moment(startDate).format("DD-MM-YYYY")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/interventions/seasons" underline="none">

              <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("seasons.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddSeason;
