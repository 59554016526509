import { apiSlice } from "../../api/apiSlice";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    getDashboardStats: builder.query({
      query: () => ({
        url: "/dashboard/stats",
        method: "GET", 
      }),
      providesTags: ["Dashboard"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getDashboardPieStats: builder.query({
      query: () => ({
        url: "/dashboard/pie/stats",
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const { useGetDashboardStatsQuery, useGetDashboardPieStatsQuery } = dashboardApiSlice;
