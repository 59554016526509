import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  TextField,
  MenuItem,
  Typography,
  Breadcrumbs, Alert
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  useGetKoboFormsQuery,
  useImportFromKoboMutation,
  useSyncKoboFormsMutation,
} from "../../../features/kobo/koboApiSlice";
import Spinner from "../../global/Spinner";
import Header from "../../Header";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import Toast from "../../global/Toast";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import StyledDataGrid from "../../global/StyledDataGrid";
import moment from "moment";
import "./kobo.css";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const KoboForms = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canSync = currentPermissions.some(permission => permission.name === 'koboforms-list');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const [selectedKoboForm, setSelectedKoboForm] = useState("");

  const [syncForms, { isLoading: syncingForms }] = useSyncKoboFormsMutation();
  const [koboForms, setKoboForms] = useState([]);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const { data, isLoading, isSuccess, isFetching } = useGetKoboFormsQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (data?.data) setKoboForms(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  // Fetch interventions
  const { data: interventionData, isLoading: fetchingInterventions } =
    useGetInterventionsQuery({ page: 1, pageSize: default_rows_per_page });
  const interventions = interventionData?.data;

  // Mutation to import data
  const [importData] = useImportFromKoboMutation();

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, koboForm) => {
    setAnchorEl(event.currentTarget);
    setSelectedKoboForm(koboForm);
  };

  const handleSubmit = async (values) => {
    const payload = { ...values, formid: JSON.parse(selectedKoboForm).formid };
    try {
      const importBeneficiaryData = new Promise((resolve) =>
        setTimeout(resolve, 3000)
      );
      toast.promise(importBeneficiaryData, {
        pending: t("kobo.shared.toast.import"),
      });
      const response = await importData(payload).unwrap();
      if (response.status === 200) {
        Toast(response?.message, "success");
        handleClose();
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
      handleClose();
    }
  };

  const handleSyncBeneficiaries = async (koboForm) => {
    koboForm = JSON.parse(koboForm);
    const payload = { formid: koboForm.formid };
    try {
      const syncBeneficiaryData = new Promise((resolve) =>
        setTimeout(resolve, 3000)
      );
      toast.promise(syncBeneficiaryData, {
        pending: t("kobo.shared.toast.syncing"),
      });
      const response = await importData(payload).unwrap();
      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const handleViewPreloads = (koboForm) => {
    koboForm = JSON.parse(koboForm);
    navigate(`/data/kobo/form/preloads/${koboForm.id}`, {
      state: { koboForm },
    });
  };

  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));

  const initialValues = {
    intervention_id: "",
  };

  const koboSchema = yup.object().shape({
    intervention_id: yup
      .string()
      .required(t("global.form.helpertext.intervention")),
  });

  const handleFormSync = async () => {
    try {
      toast.promise(resolveAfter3Sec, {
        pending: t("kobo.shared.toast.forms"),
      });

      const response = await syncForms();

      Toast(response?.data?.message, "success");
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  moment.updateLocale(moment.locale(), { invalidDate: "Not Synced" });
  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const getKoboForm = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              {params?.row?.status === "SYNCED" ? (
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  size="small"
                >
                  <Button
                    color="secondary"
                    onClick={() => handleSyncBeneficiaries(getKoboForm())}
                    size="small"
                  >
                    {t("kobo.table.fetch_button")} <RotateLeftOutlinedIcon />
                  </Button>
                  <Button
                    color="warning"
                    onClick={() => handleViewPreloads(getKoboForm())}
                    size="small"
                  >
                    {t("kobo.table.preload_button")} <DownloadingOutlinedIcon />
                  </Button>
                </ButtonGroup>
              ) : (
                <Button
                  aria-describedby={popoverId}
                  onClick={(e) => handleClick(e, getKoboForm())}
                  color="warning"
                  size="small"
                >
                  {t("kobo.table.import_button")}
                  <ImportExportOutlinedIcon />
                </Button>
              )}
              <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={koboSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label={t("kobo.form.label.form")}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={JSON.parse(selectedKoboForm).title}
                        name="formid"
                        inputProps={{ readOnly: true }}
                      />
                      {fetchingInterventions ? (
                        <p>{t("global.loading.intervention")}</p>
                      ) : (
                        <TextField
                          sx={{ width: "100%" }}
                          select
                          variant="filled"
                          label={t("global.form.helpertext.intervention")}
                          name="intervention_id"
                          value={values.intervention_id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={
                            !!touched.intervention_id &&
                            !!errors.intervention_id
                          }
                          helperText={
                            touched.intervention_id && errors.intervention_id
                          }
                        >
                         {interventions.map((intervention) => (
                            <MenuItem key={intervention.id} value={intervention.id}>
                              {intervention.project ? `${intervention.project.code} - ` : ''}
                              {intervention.title}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      <Box
                        display="flex"
                        justifyContent="end"
                        mt="20px"
                        mr="2px"
                        mb="2px"
                      >
                        <button
                          type="submit"
                         className="btn btn-primary"
                        >
                          {t("kobo.index.import_button")}
                        </button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Popover>
            </ButtonGroup>
          </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t("kobo.table.title"),
      flex: 1.5,
      cellClassName: "name-column--cell",
    },
    { field: "formid", headerName: t("kobo.table.formid"), flex: 0.5 },
    {
      field: "date_created",
      headerName: t("kobo.table.date_created"),
      flex: 0.5,
    },
    {
      field: "last_submission_time",
      headerName: t("kobo.table.last_submitted"),
      valueGetter: (params) =>
        moment(params.row?.last_submission_time).fromNow(),
      flex: 0.5,
    },
    {
      field: "last_synced",
      headerName: t("kobo.table.last_synced"),
      valueGetter: (params) => moment(params.row?.last_synced).fromNow(),
      flex: 0.5,
    },
    {
      field: "num_of_submissions",
      headerName: t("kobo.table.submissions"),
      flex: 0.5,
    },
    { field: "is_synced", headerName: t("kobo.table.synced"), flex: 0.5 },
  ];

  const content =
    isLoading || fetchingInterventions || syncingForms ? (
      <Spinner />
    ) : (
      <Box m="20px">
        <ToastContainer />
        <Header
          title={t("kobo.index.title")}
          subtitle={t("kobo.index.subtitle")}
        />

        <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
          <Link style={LinkColor()} color="inherit" to="/">
            <Typography variant="body2">{t("sidebar.Home")}</Typography>
          </Link>
          <Typography color="text.primary" variant="body2">
            {t("sidebar.Data Manager")}
          </Typography>
          <Typography color="text.primary" variant="body2">
            {t("sidebar.Kobo")}
          </Typography>
        </Breadcrumbs>

        <Box display="flex" justifyContent="end" mt="20px">
          {canSync && (
          <button
            className="btn btn-primary"
            onClick={handleFormSync}
          >
            {t("kobo.index.sync_button")}
          </button>
          )}
        </Box>

        <Box className="data_grid_box_container">
          {koboForms?.length < 1 ? (
              <Alert severity="info">{t("kobo.index.no_forms_data")}</Alert>
          ) : (
              <StyledDataGrid
                  rows={koboForms}
                  columns={columns}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        formid: false,
                      },
                    },
                  }}
                  pagination
                  paginationMode="server"
                  pageSize={pagination.perPage}
                  rowCount={pagination.total}
                  onFilterModelChange={handleFilterModelChange}
                  loading={isFetching}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={default_rows_per_page_options}
              />
          )}
        </Box>
      </Box>
    );
  return content;
};

export default KoboForms;
