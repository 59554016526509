import { apiSlice } from "../../api/apiSlice";

export const entitlementTypesApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["EntitlementTypes"],
  endpoints: (builder) => ({
    addEntitlementType: builder.mutation({
      query: (payload) => ({
        url: "/entitlement/type/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["EntitlementTypes"],
    }),
    getEntitlementTypes: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
          const queryString = new URLSearchParams({
              ...apiFilters,
              page: page,
              pageSize: pageSize
          }).toString();

          return {
              url: `/entitlement/type/list/${pageSize}?${queryString}`,
              method: "GET",
          };
      },
      providesTags: ["EntitlementTypes"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateEntitlementType: builder.mutation({
      query: (payload) => ({
        url: `/entitlement/type/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["EntitlementTypes"],
    }),
    deleteEntitlementType: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["EntitlementTypes"],
    }),
  }),
});

export const {
  useAddEntitlementTypeMutation,
  useGetEntitlementTypesQuery,
  useUpdateEntitlementTypeMutation,
  useDeleteEntitlementTypeMutation,
} = entitlementTypesApiSlice;
