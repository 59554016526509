import Toast from "../components/global/Toast";
import { ToastContainer, toast } from "react-toastify";
import { Box, Button } from "@mui/material";

export function displayRequestErrors(errors) {
  if (typeof errors === "string") {
    Toast(errors, "error");
  }
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const errorMessages = errors[key];
      errorMessages.forEach((message) => {
        Toast(message, "error");
      });
    }
  }
}

const CustomToast = ({ closeToast, header, message }) => (
  <Box>
    <Box>{header}</Box>
    <Box>{message}</Box>
    <Button onClick={closeToast}>{message}</Button>
  </Box>
);

export function displayToast(header, message, type = "success") {
  toast[type](<CustomToast header={header} message={message} />, {
    position: toast.POSITION.TOP_CENTER,
  });
}
