import React from "react";
import { Popup } from "react-leaflet";
import { Box, Typography, Paper, Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";

const PlotData = ({ selectedFarm }) => {
  const { t } = useTranslation();
  return (
    <Popup>
      <Box
        sx={{
          display: "flex",
          "& > :not(style)": {
            m: 1,
          },
        }}
      >
        <Paper variant="contained">
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                fontSize="12"
                color="text.secondary"
                variant="h3"
                fontWeight={500}
              >
                {selectedFarm?.plot_name}
              </Typography>
              <hr />
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("nonbiometrics.index.beneficiary")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedFarm?.beneficiary_details?.full_name}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("plots.popup.ownership_type")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedFarm?.plot_ownership_type}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("plots.popup.titling")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedFarm?.plot_titling}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("plots.popup.activities")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedFarm?.plot_activities}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("plots.popup.crops")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedFarm?.plot_crops}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("plots.popup.size")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedFarm?.plot_size}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("plots.popup.status")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedFarm?.status}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </Box>
    </Popup>
  );
};

export default PlotData;
