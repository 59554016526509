import { createSlice } from "@reduxjs/toolkit";

const downloadPdfSlice = createSlice({
  name: "pdf",
  initialState: {
    downloading: false,
  },
  reducers: {
    startDownloading: (state) => {
      state.downloading = true;
    },
    finishDownloading: (state) => {
      state.downloading = false;
    },
  },
});

export const { startDownloading, finishDownloading } = downloadPdfSlice.actions;
export default downloadPdfSlice.reducer;
