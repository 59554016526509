import { useState } from "react";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Container,
  Typography,
  Box,
} from "@mui/material";

const FieldSelector = ({ columns, onFieldToggle, fieldLabels }) => {
  const [selectedFields, setSelectedFields] = useState([]);

  const handleToggle = (field) => {
    const updatedFields = selectedFields.includes(field)
      ? selectedFields.filter((selected) => selected !== field)
      : [...selectedFields, field];

    setSelectedFields(updatedFields);
    onFieldToggle(updatedFields);
  };

  const content = (
    <Container>
      <Box
        borderBottom="1px dashed #ccc"
        flex="1"
      >
        <Typography variant="h6" gutterBottom>
          {fieldLabels}
        </Typography>
      </Box>
      <FormGroup>
        {columns.map((field) => (
          <FormControlLabel
            key={field}
            control={
              <Checkbox
                checked={selectedFields.includes(field)}
                onChange={() => handleToggle(field)}
              />
            }
            label={field}
          ></FormControlLabel>
        ))}
      </FormGroup>
    </Container>
  );
  return content;
};

export default FieldSelector;
