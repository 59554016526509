import { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useAddUserMutation } from "../../../features/users/usersApiSlice";
import { useGetRolesQuery } from "../../../features/roles/rolesApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import PasswordStrengthChecker from "../../../utils/PasswordStrengthChecker";
import { displayRequestErrors } from "../../../utils/ToastUtils";
import { LinkColor } from "../../../theme";

const initialValues = {
  role_id: "",
  first_name: "",
  last_name: "",
  email: "",
  telephone: "",
  user_name: "",
};

const AddUser = () => {
  const { t } = useTranslation();
  const usersSchema = yup.object().shape({
    role_id: yup.string().required(t("users.form.helpertext.role")),
    first_name: yup.string().required(t("users.form.helpertext.first_name")),
    last_name: yup.string().required(t("users.form.helpertext.last_name")),
    email: yup
      .string()
      .required(t("users.form.helpertext.email"))
      .email(t("global.form.helpertext.invalid_email")),
    // password: yup.string().required(t("users.form.helpertext.password")),
    user_name: yup.string().required(t("users.form.helpertext.user_name")),
  });
  const navigate = useNavigate();
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [roles, setRoles] = useState([]);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const {
    data,
    isLoading: fetchingRoles,
    isSuccess,
  } = useGetRolesQuery({ pageSize: default_rows_per_page, page: 1 });
  useEffect(() => {
    if (data?.data) setRoles(data?.data);
  }, [data, fetchingRoles, isSuccess]);

  const [addUser, { isLoading }] = useAddUserMutation();

  const handleFormSubmit = async (values) => {
    const payload = { ...values, password, confirm_password };
    try {
      const response = await addUser(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(function () {
        navigate("/users", { state: { message: response?.message } });
      }, 3000);
    } catch (err) {
      if (err?.status === 400) {
        displayRequestErrors(err?.data?.errors);
      }
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header title={t("users.add.title")} subtitle={t("users.add.subtitle")} />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/users">
          <Typography variant="body2">{t("sidebar.Users")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("users.index.add_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={usersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                required
                fullWidth
                variant="filled"
                type="text"
                label={t("users.form.label.first_name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.first_name}
                name="first_name"
                error={!!touched.first_name && !!errors.first_name}
                helperText={touched.first_name && errors.first_name}
              />

              <TextField
                required
                fullWidth
                variant="filled"
                type="text"
                label={t("users.form.label.last_name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.last_name}
                name="last_name"
                error={!!touched.last_name && !!errors.last_name}
                helperText={touched.last_name && errors.last_name}
              />

              <TextField
                required
                fullWidth
                variant="filled"
                type="email"
                label={t("users.form.label.email")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />

              <TextField
                required
                fullWidth
                variant="filled"
                type="text"
                label={t("users.form.label.telephone")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.telephone}
                name="telephone"
                error={!!touched.telephone && !!errors.telephone}
                helperText={touched.telephone && errors.telephone}
              />

              <PasswordStrengthChecker
                password={password}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                confirm_password={confirm_password}
              />

              <TextField
                required
                fullWidth
                variant="filled"
                type="text"
                label={t("users.form.label.user_name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.user_name}
                name="user_name"
                error={!!touched.user_name && !!errors.user_name}
                helperText={touched.user_name && errors.user_name}
              />

              {fetchingRoles ? (
                <p>{t("global.loading.roles")}</p>
              ) : (
                <TextField
                  required
                  select
                  variant="filled"
                  label={t("global.form.helpertext.role")}
                  name="role_id"
                  value={values.role_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.role_id && !!errors.role_id}
                  helperText={touched.role_id && errors.role_id}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Link to="/users" underline="none">
                <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("users.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddUser;
