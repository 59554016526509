import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Box,
  Breadcrumbs,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { useFormik, Formik } from "formik";
import { ToastContainer } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";

import Header from "../../Header";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import {
  useBulkEditBeneficiariesMutation,
  useGetBulkEditColumnsQuery,
  useGetEditColumnValuesMutation,
} from "../../../features/beneficiaries/beneficiariesApiSlice";
import Toast from "../../global/Toast";
import { LinkColor } from "../../../theme";

const initialValues = {
  project_id: "",
  intervention_id: "",
  column_to_use: "",
  column_to_use_value: "",
  column_to_edit: "",
  old_value: "",
  new_value: "",
};

const BulkEditBeneficiaries = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const validationSchema = yup.object().shape({
    project_id: yup.string().required(t("global.form.helpertext.project")),
    intervention_id: yup
      .string()
      .required(t("global.form.helpertext.intervention")),
    column_to_use: yup
      .string()
      .required(t("beneficiaries.bulk_edit.form.helpertext.column_to_use")),
    column_to_use_value: yup
      .string()
      .required(
        t("beneficiaries.bulk_edit.form.helpertext.column_to_use_value")
      ),
    column_to_edit: yup
      .string()
      .required(t("beneficiaries.bulk_edit.form.helpertext.column_to_edit")),
    new_value: yup
      .string()
      .required(t("beneficiaries.bulk_edit.form.helpertext.new_value")),
  });

  const [projects, setProjects] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [editColumns, setEditColumns] = useState([]);
  const [columnToUseValues, setColumnToUseValues] = useState([]);
  const [fetchingColumnToUseValues, setFetchingColumnToUseValues] =
    useState(false);
  const [columnToEditValues, setColumnToEditValues] = useState([]);
  const [fetchingColumnToEditValues, setFetchingColumnToEditValues] =
    useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const {
    data: projectsData,
    isLoading: fetchingProjects,
    isSuccess: projectSuccess,
  } = useGetProjectsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (projectsData?.data) setProjects(projectsData?.data);
  }, [projectsData, projectSuccess]);

  const {
    data: interventionsData,
    isLoading: fetchingInterventions,
    isSuccess: interventionsSuccess,
  } = useGetInterventionsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (interventionsData?.data) {
      const projectId = formik.values.project_id;

      if (projectId) {
        const interventions = interventionsData?.data?.filter(
          (intervention) => {
            return intervention?.project?.id === projectId;
          }
        );
        setInterventions(interventions);
      }
    }
  }, [interventionsData, interventionsSuccess, formik.values.project_id]);

  const {
    data: columnsData,
    isLoading: fetchingColumns,
    isSuccess: columnsSuccess,
  } = useGetBulkEditColumnsQuery();

  useEffect(() => {
    if (columnsData?.columns) setEditColumns(columnsData?.columns);
  }, [columnsData, columnsSuccess, fetchingColumns]);

  const [listColumnValues] = useGetEditColumnValuesMutation();

  useEffect(() => {
    if (formik.values.intervention_id && formik.values.column_to_use) {
      fetchColumnValues(formik.values.column_to_use, "column_to_use");
    }
  }, [formik.values.column_to_use, formik.values.intervention_id]);

  useEffect(() => {
    if (formik.values.intervention_id && formik.values.column_to_edit) {
      fetchColumnValues(formik.values.column_to_edit, "column_to_edit");
    }
  }, [formik.values.column_to_edit, formik.values.intervention_id]);

  const fetchColumnValues = async (columnValue, columnType) => {
    if (columnType === "column_to_use") {
      setFetchingColumnToUseValues(true);
    }
    if (columnType === "column_to_edit") setFetchingColumnToEditValues(true);
    const payload = {
      intervention_id: formik.values.intervention_id,
      column: columnValue,
    };

    try {
      const response = await listColumnValues(payload).unwrap();
      if (columnType === "column_to_use") {
        setFetchingColumnToUseValues(false);
        setColumnToUseValues(response?.data);
        console.log(columnToUseValues);
      }
      if (columnType === "column_to_edit") {
        setFetchingColumnToEditValues(false);
        setColumnToEditValues(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [bulkEditBeneficiaries] = useBulkEditBeneficiariesMutation();

  const handleFormSubmit = async (values) => {
    try {
      const response = await bulkEditBeneficiaries(values).unwrap();
      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }
      setTimeout(function () {
        navigate("/beneficiaries", {
          state: { message: response?.message },
        });
      }, 3000);
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const content = (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("beneficiaries.bulk_edit.title")}
        subtitle={t("beneficiaries.bulk_edit.subtitle")}
      />
      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/beneficiaries">
          <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Edit Beneficiaries")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={formik.handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingProjects ? (
                <p>{t("global.loading.project")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.project")}
                  name="project_id"
                  value={formik.values.project_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.project_id && !!formik.errors.project_id
                  }
                  helperText={
                    formik.touched.project_id && formik.errors.project_id
                  }
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.code} - {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {fetchingInterventions ? (
                <p>{t("global.loading.intervention")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    projects?.length > 0 && interventions?.length === 0
                      ? t("global.form.helpertext.no_intervention")
                      : t("global.form.helpertext.intervention")
                  }
                  name="intervention_id"
                  value={formik.values.intervention_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.intervention_id &&
                    !!formik.errors.intervention_id
                  }
                  helperText={
                    formik.touched.intervention_id &&
                    formik.errors.intervention_id
                  }
                >
                  {interventions &&
                    interventions.map((intervention) => (
                      <MenuItem key={intervention.id} value={intervention.id}>
                        {intervention.title}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {fetchingColumns ? (
                <p>{t("global.loading.bulk_edit_columns")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("beneficiaries.bulk_edit.form.label.column_to_use")}
                  name="column_to_use"
                  value={formik.values.column_to_use}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.column_to_use &&
                    !!formik.errors.column_to_use
                  }
                  helperText={
                    formik.touched.column_to_use && formik.errors.column_to_use
                  }
                >
                  {editColumns &&
                    editColumns.map((editColumn) => (
                      <MenuItem
                        key={editColumn.column}
                        value={editColumn.column}
                      >
                        {editColumn.value}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {fetchingColumnToUseValues ? (
                <p>{t("global.loading.column_to_use_values")}</p>
              ) : (
                <TextField
                  select
                  fullWidth
                  variant="filled"
                  type="text"
                  label={t(
                    "beneficiaries.bulk_edit.form.label.column_to_use_value"
                  )}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.column_to_use_value}
                  name="column_to_use_value"
                  error={
                    !!formik.touched.column_to_use_value &&
                    !!formik.errors.column_to_use_value
                  }
                  helperText={
                    formik.touched.column_to_use_value &&
                    formik.errors.column_to_use_value
                  }
                >
                  {columnToUseValues &&
                    columnToUseValues.map((columnToUse) => (
                      <MenuItem
                        key={columnToUse.value}
                        value={columnToUse.value}
                      >
                        {columnToUse.value}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {fetchingColumns ? (
                <p>{t("global.loading.bulk_edit_columns")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("beneficiaries.bulk_edit.form.label.column_to_edit")}
                  name="column_to_edit"
                  value={formik.values.column_to_edit}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.column_to_edit &&
                    !!formik.errors.column_to_edit
                  }
                  helperText={
                    formik.touched.column_to_edit &&
                    formik.errors.column_to_edit
                  }
                >
                  {editColumns &&
                    editColumns.map((editColumn) => (
                      <MenuItem
                        key={editColumn.column}
                        value={editColumn.column}
                      >
                        {editColumn.value}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {fetchingColumnToEditValues ? (
                <p>{t("global.loading.column_to_edit_values")}</p>
              ) : (
                <TextField
                  select
                  fullWidth
                  variant="filled"
                  type="text"
                  label={
                    columnToUseValues?.length > 0 && editColumns?.length > 0
                      ? t("beneficiaries.bulk_edit.form.label.old_value")
                      : t("beneficiaries.bulk_edit.form.label.no_column_value")
                  }
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.old_value}
                  name="old_value"
                  helperText={
                    formik.touched.old_value && formik.errors.old_value
                  }
                >
                  {columnToEditValues &&
                    columnToEditValues.map((columnToEdit) => (
                      <MenuItem
                        key={columnToEdit.value}
                        value={columnToEdit.value}
                      >
                        {columnToEdit.value}
                      </MenuItem>
                    ))}
                </TextField>
              )}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("beneficiaries.bulk_edit.form.label.new_value")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.new_value}
                name="new_value"
                error={!!formik.touched.new_value && !!formik.errors.new_value}
                helperText={formik.touched.new_value && formik.errors.new_value}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Link
                style={{ textDecoration: "none" }}
                to="/beneficiaries"
                underline="none"
              >
                <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("beneficiaries.bulk_edit.bulk_edit_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );

  return content;
};

export default BulkEditBeneficiaries;
