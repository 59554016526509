import React from 'react'
import { styled } from '@mui/material/styles';
import {DataGrid} from "@mui/x-data-grid";
import { tokens } from "../../theme"

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    border: "none"
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none"
  },
  "& .name-column--cell": {
    color: tokens(theme.palette.mode).greenAccent[300]
  },
  "& .MuiDataGrid-columnHeaders": {
    color:"#F7F8F9",
    backgroundColor: tokens(theme.palette.mode).faoBlue[500],
    borderBottom: "none"
  },
  "& .MuiDataGrid-virtualScroller": {
    backgroundColor: tokens(theme.palette.mode).primary[400]
  },
  "& .MuiDataGrid-footerContainer": {
    color:"#F7F8F9",
    borderTop: "none",
    backgroundColor: tokens(theme.palette.mode).faoBlue[500]
  },
  "& .MuiTablePagination-root": {
    color:"#F7F8F9"
  },
  "& .MuiDataGrid-cellContent": {
    fontSize:"0.85rem",
  },
  "& .matched-name-cell": {
    color: "#808080"
    },
}));

export default StyledDataGrid