import * as React from 'react';

import {
  Box,
  Typography,
  Breadcrumbs
} from "@mui/material";
import Header from "../../Header";
import Spinner from "../../global/Spinner";
import { Link } from "react-router-dom";

import L from 'leaflet';
import {MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "./leaflet.css";

import icon from './images/marker-icon.png';
import iconShadow from './images/marker-shadow.png';

import { useTranslation } from "react-i18next";
import { LinkColor } from '../../../theme';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25,41],
  iconAnchor: [12,41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapAllBeneficiaries = () => {
  const { t } = useTranslation();

  const map_center_lat = process.env.REACT_APP_DEFAULT_MAP_CENTER_LAT;
  const map_center_lon = process.env.REACT_APP_DEFAULT_MAP_CENTER_LON;
  const map_zoom = process.env.REACT_APP_DEFAULT_MAP_ZOOM_LEVEL;
  const position = [map_center_lat, map_center_lon];
  const isLoading = "";

  const content = isLoading ? (
      <Spinner />
  ) : (
      <Box m="20px">
        <Header
            title={t("beneficiaries.map.title")}
            subtitle={t("beneficiaries.map.subtitle")}
        />

        <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
          <Link style={LinkColor()} color="inherit" to="/">
            <Typography variant="body2">{t("sidebar.Home")}</Typography>
          </Link>
          <Link style={LinkColor()} color="inherit" to="/beneficiaries">
            <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
          </Link>
          <Typography color="text.primary" variant="body2">
            {t("sidebar.Map Beneficiaries")}
          </Typography>
        </Breadcrumbs>

        <Box sx={{height: "75vh"}} >
          <MapContainer center={[map_center_lat, map_center_lon]} zoom={map_zoom} scrollWheelZoom={false} style={{height: "75vh"}}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker key="1" position={position} >
              <Popup position={position}>
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
              </Popup>
            </Marker>
          </MapContainer>
        </Box>

      </Box>
  );

  return content;
};

export default MapAllBeneficiaries;