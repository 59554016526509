import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs, Alert,
} from "@mui/material";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate, Link } from "react-router-dom";
import DeleteDialog from "../shared/DeleteDialog";
import {
  useGetDonorsQuery,
  useDeleteDonorMutation,
} from "../../../features/donors/donorsApiSlice";
import Spinner from "../../global/Spinner";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const Donors = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(permission => permission.name === 'donor-add');
  const canEdit = currentPermissions.some(permission => permission.name === 'donor-update');
  const canDelete = currentPermissions.some(permission => permission.name === 'donor-delete');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDonor, setSelectedDonor] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [donors, setDonors] = useState([]);

  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
      const apiFilters = {};
      
      filterModel?.forEach(item => {
        if (item.columnField && item.value) {
          apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
        }
      });

      return apiFilters;
  };
  
  const { data, isLoading, isSuccess, isFetching } = useGetDonorsQuery({
    page,
    pageSize,
    ...getApiFilters()
  });

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const [deleteDonor] = useDeleteDonorMutation();

  useEffect(() => {
    if (data?.data) setDonors(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const handleEdit = (donor) => {
    donor = JSON.parse(donor);
    navigate(`/projects/donors/edit/${donor.id}`, { state: { donor } });
  };

  const handleClickOpen = (donor) => {
    setSelectedDonor(donor);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedDonor(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => {
        const getDonor = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">

              {canEdit && (
                <Button color="secondary" onClick={() => handleEdit(getDonor())} size="small">
                  {t("global.table.edit_button")} <EditOutlinedIcon />
                </Button>
              )}
              {canDelete && (
                <Button color="error" onClick={() => handleClickOpen(getDonor())} size="small">
                {t("global.table.delete_button")} <DeleteOutlineOutlinedIcon />
                </Button>
              )}
              </ButtonGroup>


              {openDialog && (
                  <DeleteDialog
                      open={openDialog}
                      onClose={handleClose}
                      name={JSON.parse(selectedDonor).title}
                      deleteMethod={deleteDonor}
                      url={`/donor/delete/${JSON.parse(selectedDonor).id}`}
                  />
              )}
            </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.form.label.description"),
      flex: 1,
    }
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("donors.index.title")}
        subtitle={t("donors.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects">
          <Typography variant="body2">{t('sidebar.Projects')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
        {t('sidebar.Donors')}
        </Typography>
      </Breadcrumbs>

      {canAdd && (
          <Box display="flex" justifyContent="end" mt="20px">
          <Link to="/projects/donors/add" underline="none">
            <button className="btn btn-primary">
                {t("donors.index.add_button")}
            </button>
          </Link>
        </Box>
      )}
    

      <Box className="data_grid_box_container">
        {donors?.length < 1 ? (
            <Alert severity="info">{t("donors.index.no_donors_data")}</Alert>
        ) : (
            <StyledDataGrid
                rows={donors}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pagination.perPage}
                rowCount={pagination.total}
                onFilterModelChange={handleFilterModelChange}
                loading={isFetching}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={default_rows_per_page_options}
            />
        )}
      </Box>
    </Box>
  );

  return content;
};

export default Donors;
