import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Paper,
  Tab,
  Typography,useTheme
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  useGetCardQuery,
  useGetCardBeneficiariesQuery,
  useGetCardCopayTransactionsQuery,
  useGetCardCashTransactionsQuery,
  useGetCardEvoucherTransactionsQuery,
  useGetCardInkindTransactionsQuery,
  useGetCardEventsQuery,
  useBlockCardMutation,
  useUnBlockCardMutation
} from "../../../features/cards/cardsApiSlice";
import Spinner from "../../global/Spinner";
import Header from "../../Header";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import BeneficiaryCard from "../shared/BeneficiaryCard";
import ViewCopayDialog from "../copay/ViewCopayDialog";
import ViewCashDialog from "../cash/ViewCashDialog";
import ViewRedemptionDialog from "../redemptions/ViewRedemptionDialog";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { LinkColor, tokens } from "../../../theme";
import BlockCardDialog from "../shared/BlockCardDialog";
import UnBlockCardDialog from "../shared/UnBlockCardDialog";

const ViewCard = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [card, setCard] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);

  const [copayTransactions, setCopayTransactions] = useState([]);
  const [selectedCopayData, setSelectedCopayData] = useState("");
  const [openDialogCopay, setOpenDialogCopay] = useState(false);

  const [cashTransactions, setCashTransactions] = useState([]);
  const [selectedCashData, setSelectedCashData] = useState("");
  const [openDialogCash, setOpenDialogCash] = useState(false);

  const [evoucherTransactions, setEvoucherTransactions] = useState([]);
  const [inkindTransactions, setInkindTransactions] = useState([]);
  const [selectedRedemptionData, setSelectedRedemptionData] = useState("");
  const [openDialogEvoucher, setOpenDialogEvoucher] = useState(false);
  const [openDialogInkind, setOpenDialogInkind] = useState(false);

  const [cardEvents, setCardEvents] = useState([]);
  const [selectedCard, setSelectedCard] = useState("");
  const [openDialogCardStatus, setOpenDialogCardStatus] = useState(false);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data: cardData,
    isLoading: fetchingCard,
    isSuccess: cardSuccess,
    isFetching: isFetchingCard
  } = useGetCardQuery(id);

  const {
    data: beneficiaryData,
    isLoading: fetchingBeneficiaries,
    isSuccess: benSuccess,
    isFetching: isFetchingBeneficiary
  } = useGetCardBeneficiariesQuery(id);

  const {
    data: copayData,
    isLoading: fetchingCopay,
    isSuccess: copaySuccess,
    isFetching: isFetchingCopay
  } = useGetCardCopayTransactionsQuery(id);

  const {
    data: cashData,
    isLoading: fetchingCash,
    isSuccess: cashSuccess,
    isFetching: isFetchingCash
  } = useGetCardCashTransactionsQuery(id);

  const {
    data: evoucherData,
    isLoading: fetchingEvoucher,
    isSuccess: evoucherSuccess,
    isFetching: isFetchingEvoucher
  } = useGetCardEvoucherTransactionsQuery(id);

  const {
    data: inkindData,
    isLoading: fetchingInkind,
    isSuccess: inkindSuccess,
    isFetching: isFetchingInkind
  } = useGetCardInkindTransactionsQuery(id);

  const {
    data: eventsData,
    isLoading: fetchingEvents,
    isSuccess: eventsSuccess,
    isFetching: isFetchingEvents
  } = useGetCardEventsQuery(id);

  const [blockCard] = useBlockCardMutation();
  const [unBlockCard] = useUnBlockCardMutation();

  useEffect(() => {
    if (cardData?.data)
      setCard(cardData?.data);
  }, [cardData, cardSuccess, fetchingCard]);

  useEffect(() => {
    if (beneficiaryData?.data)
      setBeneficiaries(beneficiaryData?.data);
  }, [beneficiaryData, benSuccess, fetchingBeneficiaries]);

  useEffect(() => {
    if (copayData?.data)
      setCopayTransactions(copayData?.data);
  }, [copayData, copaySuccess, fetchingCopay]);

  useEffect(() => {
    if (cashData?.data)
      setCashTransactions(cashData?.data);
  }, [cashData, cashSuccess, fetchingCash]);

  useEffect(() => {
    if (evoucherData?.data)
      setEvoucherTransactions(evoucherData?.data);
  }, [evoucherData, evoucherSuccess, fetchingEvoucher]);

  useEffect(() => {
    if (inkindData?.data)
      setInkindTransactions(inkindData?.data);
  }, [inkindData, inkindSuccess, fetchingInkind]);

  useEffect(() => {
    if (eventsData?.data)
      setCardEvents(eventsData?.data);
  }, [eventsData, eventsSuccess, fetchingEvents]);

  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const handleClickOpenCash = (cash) => {
    const cashData = JSON.parse(cash);
    const transaction = cashTransactions.filter((txn) => {
      return txn.id === cashData.id;
    });

    setSelectedCashData(JSON.stringify(transaction));
    setOpenDialogCash(true);
  };

  const handleCloseCash = (value) => {
    setOpenDialogCash(false);
    setSelectedCashData(value);
  };

  const handleClickOpenCopay = (copay) => {
    const copayData = JSON.parse(copay);
    const transaction = copayTransactions.filter((txn) => {
      return txn.id === copayData.id;
    });

    setSelectedCopayData(JSON.stringify(transaction));
    setOpenDialogCopay(true);
  };

  const handleCloseCopay = (value) => {
    setOpenDialogCopay(false);
    setSelectedCopayData(value);
  };

  const handleClickOpenEvoucher = (redemption) => {
    const redemptionData = JSON.parse(redemption);
    const transaction = evoucherTransactions.filter((txn) => {
      return txn.id === redemptionData.id;
    });

    setSelectedRedemptionData(JSON.stringify(transaction));
    setOpenDialogEvoucher(true);
  };

  const handleCloseEvoucher = (value) => {
    setOpenDialogEvoucher(false);
    setSelectedRedemptionData(value);
  };

  const handleClickOpenInkind = (redemption) => {
    const redemptionData = JSON.parse(redemption);
    const transaction = inkindTransactions.filter((txn) => {
      return txn.id === redemptionData.id;
    });

    setSelectedRedemptionData(JSON.stringify(transaction));
    setOpenDialogInkind(true);
  };

  const handleCloseInkind = (value) => {
    setOpenDialogInkind(false);
    setSelectedRedemptionData(value);
  };

  const handleClickOpenStatus = (card) => {
    setSelectedCard(card);
    setOpenDialogCardStatus(true);
  };

  const handleCloseStatus = (value) => {
    setOpenDialogCardStatus(false);
    setSelectedCard(value);
  };

  const cash_columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      renderCell: (params) => {
        const getCash = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                <Button
                    color="warning"
                    onClick={() => handleClickOpenCash(getCash())}
                >
                  {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
                </Button>
              </ButtonGroup>
              {openDialogCash && (
                  <ViewCashDialog
                      open={openDialogCash}
                      selectedcashdata={selectedCashData}
                      onClose={handleCloseCash}
                  />
              )}
            </Box>
        );
      },
    },
    {
      field: "transaction_id",
      headerName: t("cash.shared.table.transaction_id"),
      flex: 0.5,
    },
    {
      field: "transaction_amount",
      headerName: t("cash.shared.table.amount"),
      flex: 0.5,
    },
    {
      field: "beneficiary",
      headerName: t("cash.shared.table.beneficiary"),
      flex: 1,
      valueGetter: (params) => params.row?.beneficiary?.full_name,
    },
    {
      field: "intervention",
      headerName: t("cash.shared.table.intervention"),
      flex: 1,
      valueGetter: (params) => params.row?.intervention?.title,
    },
    {
      field: "package",
      headerName: t("cash.shared.table.package"),
      flex: 1,
      valueGetter: (params) => params.row?.package?.title,
    },
    {
      field: "service_provider",
      headerName: t("cash.shared.table.service_provider"),
      flex: 1,
      valueGetter: (params) => params.row?.user?.user_name,
    },
    {
      field: "transaction_date",
      headerName: t("cash.shared.table.transaction_date"),
      flex: 1,
    }
  ];

  const copay_columns = [
    {
      field: "action",
      headerName: t('global.table.actions'),
      flex: 0.5,
      renderCell: (params) => {
        const getCash = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                <Button
                    color="warning"
                    onClick={() => handleClickOpenCopay(getCash())}
                >
                  {t('global.table.view_button')} <RemoveRedEyeOutlinedIcon />
                </Button>
              </ButtonGroup>
              {openDialogCopay && (
                  <ViewCopayDialog
                      open={openDialogCopay}
                      selectedcopaydata={selectedCopayData}
                      onClose={handleCloseCopay}
                  />
              )}
            </Box>
        );
      },
    },
    { field: "transaction_id", headerName: t('cash.shared.table.transaction_id'), flex: 0.5 },
    { field: "transaction_amount", headerName: t('cash.shared.table.amount'), flex: 0.5 },
    {
      field: "beneficiary",
      headerName: t('cash.shared.table.beneficiary'),
      flex: 1,
      valueGetter: (params) => params.row?.beneficiary?.full_name,
    },
    {
      field: "intervention",
      headerName: t('cash.shared.table.intervention'),
      flex: 1,
      valueGetter: (params) => params.row?.intervention?.title,
    },
    {
      field: "package",
      headerName: t('cash.shared.table.package'),
      flex: 1,
      valueGetter: (params) => params.row?.package?.title,
    },
    {
      field: "service_provider",
      headerName: t('cash.shared.table.service_provider'),
      flex: 1,
      valueGetter: (params) => params.row?.user?.user_name,
    },
    { field: "transaction_date", headerName: t('cash.shared.table.transaction_date'), flex: 1 }
  ];

  const evoucher_columns = [
    {
      field: "action",
      headerName: t('global.table.actions'),
      flex: 0.5,
      renderCell: (params) => {
        const getRedemption = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                <Button
                    color="warning"
                    onClick={() => handleClickOpenEvoucher(getRedemption())}
                >
                  {t('global.table.view_button')} <RemoveRedEyeOutlinedIcon />
                </Button>
              </ButtonGroup>
              {openDialogEvoucher && (
                  <ViewRedemptionDialog
                      open={openDialogEvoucher}
                      selectedredemptiondata={selectedRedemptionData}
                      onClose={handleCloseEvoucher}
                  />
              )}
            </Box>
        );
      },
    },
    { field: "transaction_id", headerName: t('cash.shared.table.transaction_id'), flex: 0.5 },
    { field: "grand_total", headerName: t('cash.shared.table.amount'), flex: 0.5 },
    {
      field: "beneficiary",
      headerName: t('cash.shared.table.beneficiary'),
      flex: 1,
      valueGetter: (params) => params.row?.beneficiary?.full_name,
    },
    {
      field: "intervention",
      headerName: t('cash.shared.table.intervention'),
      flex: 1,
      valueGetter: (params) => params.row?.intervention?.title,
    },
    {
      field: "package",
      headerName: t('cash.shared.table.package'),
      flex: 1,
      valueGetter: (params) => params.row?.package?.title,
    },
    {
      field: "service_provider",
      headerName: t('cash.shared.table.service_provider'),
      flex: 1,
      valueGetter: (params) => params.row?.user?.user_name,
    },
    { field: "transaction_date", headerName: t('cash.shared.table.transaction_date'), flex: 1 }
  ];

  const inkind_columns = [
    {
      field: "action",
      headerName: t('global.table.actions'),
      flex: 0.5,
      renderCell: (params) => {
        const getRedemption = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                <Button
                    color="warning"
                    onClick={() => handleClickOpenInkind(getRedemption())}
                >
                  {t('global.table.view_button')} <RemoveRedEyeOutlinedIcon />
                </Button>
              </ButtonGroup>
              {openDialogInkind && (
                  <ViewRedemptionDialog
                      open={openDialogInkind}
                      selectedredemptiondata={selectedRedemptionData}
                      onClose={handleCloseInkind}
                  />
              )}
            </Box>
        );
      },
    },
    { field: "transaction_id", headerName: t('cash.shared.table.transaction_id'), flex: 0.5 },
    { field: "grand_total", headerName: t('cash.shared.table.amount'), flex: 0.5 },
    {
      field: "beneficiary",
      headerName: t('cash.shared.table.beneficiary'),
      flex: 1,
      valueGetter: (params) => params.row?.beneficiary?.full_name,
    },
    {
      field: "intervention",
      headerName: t('cash.shared.table.intervention'),
      flex: 1,
      valueGetter: (params) => params.row?.intervention?.title,
    },
    {
      field: "package",
      headerName: t('cash.shared.table.package'),
      flex: 1,
      valueGetter: (params) => params.row?.package?.title,
    },
    {
      field: "service_provider",
      headerName: t('cash.shared.table.service_provider'),
      flex: 1,
      valueGetter: (params) => params.row?.user?.user_name,
    },
    { field: "transaction_date", headerName: t('cash.shared.table.transaction_date'), flex: 1 }
  ];

  const events_columns = [
    { field: "event", headerName: t("cards.view.event"), flex: 1 },
    { field: "comment", headerName: t("cards.view.comment"), flex: 1 },
    {
      field: "user",
      headerName: t("cards.view.user"),
      flex: 1,
      valueGetter: (params) => params.row?.user?.user_name,
    },
    { field: "created_at", headerName: t("cards.view.created_at"), flex: 1 },
  ];

  return fetchingCard ? (
      <Spinner/>
  ) : (
      <Box m="20px">
        <ToastContainer/>
        <Header title={t("cards.view.title")} subtitle={t("cards.view.subtitle")}/>

        <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
          <Link style={LinkColor()} color="inherit"to="/">
            <Typography variant="body2">{t('sidebar.Home')}</Typography>
          </Link>
          <Link style={LinkColor()} color="inherit"to="/beneficiaries">
            <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
          </Link>
          <Link style={LinkColor()} color="inherit"to="/beneficiaries/cards">
            {t('sidebar.Cards')}
          </Link>
          <Typography color="text.primary" variant="body2">
            {t("cards.view.card")}
          </Typography>
        </Breadcrumbs>

        <Box display="flex">
          <Box
              sx={{
                display: "flex",
                "& > :not(style)": {
                  m: 1,
                },
                maxWidth: "400px",
              }}
          >
            <Paper variant="outlined">
              <Card sx={{minWidth: 275}}>
                <CardContent>
                  <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    {card.serial}
                  </Typography>
                  <hr/>
                  <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Typography variant="h5" component="div">
                      {t("cards.table.type")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {card.type}
                    </Typography>
                  </Box>
                  <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Typography variant="h5" component="div">
                      {t("cards.table.has_chip")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {
                        card.has_chip == 1 ?
                            t("cards.view.has_chip_yes") :
                            t("cards.view.has_chip_no")
                      }
                    </Typography>
                  </Box>
                  <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Typography variant="h5" component="div">
                      {t("cards.table.storage")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {card.storage}
                    </Typography>
                  </Box>
                  <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Typography variant="h5" component="div">
                      {t("cards.table.free_space")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {card.free_space}
                    </Typography>
                  </Box>
                  <Box display="flex" alignContent="center" justifyContent="space-between">
                    <Typography variant="h5" component="div">
                      {t("cards.table.status")}:
                    </Typography>
                    <Typography variant="h6" component="div">
                      {card.status}
                    </Typography>
                  </Box>
                </CardContent>
                {/*--*/}
                <CardActions sx={{ bgcolor: colors.primary[900] }}>
                  {card.status === "ACTIVE" ? (
                      <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleClickOpenStatus(card)}
                      >
                        {t('global.table.block_button')} <BlockOutlinedIcon />
                      </Button>
                  ) : (
                      <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleClickOpenStatus(card)}
                      >
                        {t('global.table.unblock_button')} <RemoveCircleOutlineOutlinedIcon />
                      </Button>
                  )}

                  {openDialogCardStatus && card.status === "ACTIVE" && (
                      <BlockCardDialog
                          open={openDialogCardStatus}
                          onClose={handleCloseStatus}
                          name={card.serial}
                          blockMethod={blockCard}
                          url={`/cards/block/${
                              card.id
                          }`}
                          cardData={card}
                      />
                  )}
                  {openDialogCardStatus && card.status !== "ACTIVE" && (
                      <UnBlockCardDialog
                          open={openDialogCardStatus}
                          onClose={handleCloseStatus}
                          name={card.serial}
                          blockMethod={unBlockCard}
                          url={`/cards/block/${
                              card.id
                          }`}
                          cardData={card}
                      />
                  )}

                </CardActions>
                {/*--*/}
              </Card>
            </Paper>

            <Paper variant="outlined">
              <BeneficiaryCard beneficiary={beneficiaries} />
            </Paper>
          </Box>
      </Box>

        <Box sx={{width: "100%", typography: "body1"}}>
          <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" textColor="secondary" indicatorColor="secondary">
                <Tab label={t("sidebar.Cash")} value="1"/>
                <Tab label={t("sidebar.Copay")} value="2"/>
                <Tab label={t("sidebar.E-Voucher")} value="3"/>
                <Tab label={t("sidebar.In-Kind")} value="4"/>
                <Tab label={t("sidebar.Events")} value="5"/>
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box className="data_grid_box_container_within_tabs">
                {cashTransactions?.length < 1 ? (
                    <Alert severity="info">{t("cash.index.no_cash")}</Alert>
                ) : (
                    <StyledDataGrid
                        rows={cashTransactions}
                        columns={cash_columns}
                        pagination
                        pageSize={pagination.perPage}
                        rowCount={pagination.total}
                        onPageChange={handlePageChange}
                        loading={isFetchingCash}
                        paginationMode="server"
                        onPageSizeChange={handlePageSizeChange}
                        rowsPerPageOptions={default_rows_per_page_options}
                    />
                )}
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box className="data_grid_box_container_within_tabs">
                {copayTransactions?.length < 1 ? (
                    <Alert severity="info">{t('copay.index.no_copay')}</Alert>
                ) : (
                    <StyledDataGrid
                        rows={copayTransactions}
                        columns={copay_columns}
                        pagination
                        paginationMode="server"
                        pageSize={pagination.perPage}
                        rowCount={pagination.total}
                        loading={isFetchingCopay}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        rowsPerPageOptions={default_rows_per_page_options}
                    />
                )}
              </Box>
            </TabPanel>
            <TabPanel value="3">
              <Box className="data_grid_box_container_within_tabs">
                {evoucherTransactions?.length < 1 ? (
                    <Alert severity="info">{t('redemption.index.no_redemption')}</Alert>
                ) : (
                    <StyledDataGrid
                        rows={evoucherTransactions}
                        columns={evoucher_columns}
                        pagination
                        paginationMode="server"
                        pageSize={pagination.perPage}
                        rowCount={pagination.total}
                        loading={isFetchingEvoucher}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        rowsPerPageOptions={default_rows_per_page_options}
                    />
                )}
              </Box>
            </TabPanel>
            <TabPanel value="4">
              <Box className="data_grid_box_container_within_tabs">
                {inkindTransactions?.length < 1 ? (
                    <Alert severity="info">{t('redemption.index.no_inkind')}</Alert>
                ) : (
                    <StyledDataGrid
                        rows={inkindTransactions}
                        columns={inkind_columns}
                        pagination
                        paginationMode="server"
                        pageSize={pagination.perPage}
                        rowCount={pagination.total}
                        loading={isFetchingInkind}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        rowsPerPageOptions={default_rows_per_page_options}
                    />
                )}
              </Box>
            </TabPanel>
            <TabPanel value="5">
              <Box className="data_grid_box_container_within_tabs">
                {cardEvents?.length < 1 ? (
                    <Alert severity="info">{t("cards.view.no_event")}</Alert>
                ) : (
                    <StyledDataGrid
                        rows={cardEvents}
                        columns={events_columns}
                        pagination
                        paginationMode="server"
                        pageSize={pagination.perPage}
                        rowCount={pagination.total}
                        loading={isFetchingEvoucher}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        rowsPerPageOptions={default_rows_per_page_options}
                    />
                )}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
  );
};

export default ViewCard;
