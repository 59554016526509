import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Breadcrumbs } from "@mui/material";
import Header from "../../Header";

import { Link } from "react-router-dom";
import { LinkColor } from "../../../theme";
import DropDown from "../shared/DropDown";
import { BioInformation } from "./components/BioInformation";
import DuplicatesDataTable from "./components/DuplicatesDataTable";

const BiometricDeduplication = () => {
  const { t } = useTranslation();

  const accuracyFields = [
    { label: "Less or Equal 1500", defaultChecked: true, value: "1500" },
    { label: "Less or Equal 1000", value: "1000" },
    { label: "Less or Equal 500", value: "500" },
    { label: "Less or Equal 100", value: "100" },
  ];
  const [selectedAccuracy, setSelectedAccuracy] = useState([]);
  const [selectedDuplicate, setSelectedDuplicate] = useState(undefined);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(undefined);
  const [filterDuplicates, setFilterDuplicates] = useState([]);

  const handleAccuracySubmit = (duplicates) => {
    setFilterDuplicates(duplicates);
  };

  const handleBeneficiarySelected = (selectedBeneficiary) => {
    setSelectedBeneficiary(selectedBeneficiary);
  };
  const handlePossibleDuplicateSelected = (selectedDuplicate) => {
    setSelectedDuplicate(selectedDuplicate);
  };
  const [isReviewed, setIsReviewed] = useState(true);

  const handleSetIsReviewed = (isReviewed) => {
    setIsReviewed(isReviewed);
  };

  return (
    <Box m="20px">
      <Header
        title={t("biometrics.index.title")}
        subtitle={t("biometrics.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/beneficiaries">
          <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Biometric Deduplication")}
        </Typography>
      </Breadcrumbs>

      <Box>
        <div style={{ display: "flex", gap: "10px", justifyContent: "end" }}>
          <DropDown
            borderTitle="Set Accuracy"
            dropDownTitle="Accuracy"
            isBiometric={true}
            checkboxItems={accuracyFields}
            buttonTitle="Set Range"
            onSubmit={handleAccuracySubmit}
          />
        </div>
      </Box>

      <Box>
        {selectedBeneficiary !== undefined &&
        selectedDuplicate !== undefined ? (
          <BioInformation
            selectedUser={selectedBeneficiary}
            possibleDuplicate={selectedDuplicate}
            onMarkedDuplicate={handlePossibleDuplicateSelected}
            isReviwed={isReviewed}
          />
        ) : null}

        <DuplicatesDataTable
          onPossibleDuplicate={handlePossibleDuplicateSelected}
          onBeneficiarySelect={handleBeneficiarySelected}
          onTabChange={handleSetIsReviewed}
          filterDuplicates={filterDuplicates}
        />
      </Box>
    </Box>
  );
};

export default BiometricDeduplication;
