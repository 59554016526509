import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs, Alert
} from "@mui/material";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate, Link } from "react-router-dom";
import { useGetTopupsQuery } from "../../../features/topups/topupsApiSlice";
import Spinner from "../../global/Spinner";
import { useDeleteDonorMutation } from "../../../features/donors/donorsApiSlice";
import DeleteDialog from "../shared/DeleteDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { RemoveRedEye } from "@mui/icons-material";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const Topups = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(permission => permission.name === 'topup-add');
  const canEdit = currentPermissions.some(permission => permission.name === 'topup-update');
  const canDelete = currentPermissions.some(permission => permission.name === 'topup-delete');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedTopup, setSelectedTopup] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [topups, setTopups] = useState([]);

  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
      const apiFilters = {};
      
      filterModel?.forEach(item => {
        if (item.columnField && item.value) {
          apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
        }
      });

      return apiFilters;
  };
  
  const { data, isLoading, isSuccess, isFetching } = useGetTopupsQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  useEffect(() => {
    if (data?.data) setTopups(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);


  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const [deleteTopup] = useDeleteDonorMutation();

  const handleEdit = (topup) => {
    topup = JSON.parse(topup);
    navigate(`/interventions/topups/edit/${topup.id}`, { state: { topup } });
  };

  const handleView = (topup) => {
    topup = JSON.parse(topup);
    navigate(`/interventions/topups/view/${topup.id}`, { state: { topup } });
  };

  const handleClickOpen = (topup) => {
    setSelectedTopup(topup);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedTopup(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        const getTopup = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
              <Button color="warning" onClick={() => handleView(getTopup())} size="small">
                  {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
                </Button>
                {canEdit && (
                <Button color="secondary" onClick={() => handleEdit(getTopup())} size="small">
                  {t("global.table.edit_button")} <EditOutlinedIcon />
                </Button>
                )}
                {canDelete && (
                <Button color="error" onClick={() => handleClickOpen(getTopup())} size="small">
                  {t("global.table.delete_button")} <DeleteOutlineOutlinedIcon />
                </Button>
                )}
              </ButtonGroup>
             
            </Box>
        );
      },
    },
    {
      field: "name",
      headerName: t("global.table.name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "title",
      headerName: t("packages.form.label.name"),
      flex: 1,
      valueGetter: (params) => params?.row?.package?.title,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "copay_amount",
      headerName: t("packages.form.label.copay_amount"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "subsidy_amount",
      headerName: t("packages.form.label.subsidy_amount"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "start_date",
      headerName: t("project.shared.start_date"),
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => moment(params?.value).format("YYYY-MM-DD"),
    },
    {
      field: "end_date",
      headerName: t("project.shared.end_date"),
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => moment(params?.value).format("YYYY-MM-DD"),
    },
    {
      field: "topup_type",
      headerName: t("seasons.form.label.type"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "cva_type",
      headerName: t("global.table.cva_type"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: t("global.table.status"),
      flex: 1,
      minWidth: 200,
    }
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("topups.index.title")}
        subtitle={t("topups.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Topups")}
        </Typography>
      </Breadcrumbs>


      {canAdd && (
      <Box display="flex" justifyContent="end" mt="20px">
      <Link to="/interventions/topups/add" underline="none">

        <button className="btn btn-primary">
            {t("topups.index.add_button")}
        </button>
        </Link>
      </Box>
      )}

      <Box className="data_grid_box_container">
        {topups?.length < 1 ? (
            <Alert severity="info">{t("topups.index.no_topups_data")}</Alert>
        ) : (
            <StyledDataGrid
                rows={topups}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pagination.perPage}
                rowCount={pagination.total}
                onFilterModelChange={handleFilterModelChange}
                loading={isFetching}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={default_rows_per_page_options}
            />
        )}
      </Box>
      {openDialog && (
                  <DeleteDialog
                      open={openDialog}
                      onClose={handleClose}
                      name={JSON.parse(selectedTopup).topup_type}
                      deleteMethod={deleteTopup}
                      url={`/topup/delete/${JSON.parse(selectedTopup).id}`}
                  />
              )}
    </Box>
  );

  return content;
};

export default Topups;
