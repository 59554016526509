import React from 'react'
import { Typography, Box, useTheme } from '@mui/material'
import { tokens } from '../theme'

const Header = ({title, subtitle}) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  return <Box mb="5px">
      <Typography variant="h3" color={colors.faoBlue[500]} fontWeight="bold" sx={{mb: "5px"}}>{title}</Typography>
      <Typography variant="h5" color={colors.faoOrange[500]}>{subtitle}</Typography>
  </Box>
}

export default Header