import { Outlet } from "react-router-dom"

import { ColorModeContext, useMode } from '../theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector  } from 'react-redux'

import Topbar from './global/Topbar';
import Sidebar from './global/Sidebar';
import { selectCurrentPermissions } from "../features/auth/authSlice";

const Layout = () => {
  const [theme, colorMode] = useMode();
  const currentPermissions = useSelector(selectCurrentPermissions);
  

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        {(currentPermissions && currentPermissions.length > 0) ?   <Sidebar /> : <></> }

          {/* <Sidebar />  */}
          <main className="content" style={{height: '100vh', overflow: 'hidden', overflowY: 'scroll'}}>
            <Topbar />
            <Outlet />
            </main>
        </div>
      </ThemeProvider>
  </ColorModeContext.Provider>
  )
}

export default Layout