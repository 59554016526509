import { apiSlice } from "../../api/apiSlice";

export const rolesApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Roles", "RolePermissions", "RoleUsers", "RoleInterventions"],
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/role/list/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Roles"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getRole: builder.query({
      query: (id) => ({
        url: `/role/single/${id}`,
        method: "GET",
      }),
      providesTags: ["Roles"],
    }),
    addRole: builder.mutation({
      query: (payload) => ({
        url: "/role/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Roles"],
    }),
    updateRole: builder.mutation({
      query: (payload) => ({
        url: `/role/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Roles"],
    }),
    deleteRole: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Roles"],
    }),
    getRoleUsers: builder.query({
      query: (id) => ({
        url: `/role/users/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      providesTags: ["RoleUsers"],
      refetchOnMountOrArgChange: true,
    }),
    getRolePermissions: builder.query({
      query: (id) => ({
        url: `/role/permissions/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      providesTags: ["RolePermissions"],
      refetchOnMountOrArgChange: true,
    }),
    getRoleInterventions: builder.query({
      query: (id) => ({
        url: `/role/${id}/list/interventions`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      providesTags: ["RoleInterventions"],
      refetchOnMountOrArgChange: true,
    }),
    addRoleUsers: builder.mutation({
      query: (payload) => ({
        url: "role/add/users",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["RoleUsers", "Roles"],
    }),
    addRolePermissions: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: "/role/add/permissions",
        body: { ...payload },
      }),
      invalidatesTags: ["RolePermissions", "Roles"],
    }),
    removeRolePermissions: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: "/role/remove/permissions",
        body: { ...payload },
      }),
      invalidatesTags: ["RolePermissions", "Roles"],
    }),
  }),
});

export const {
  useAddRoleMutation,
  useGetRolesQuery,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useGetRoleUsersQuery,
  useGetRoleInterventionsQuery,
  useGetRolePermissionsQuery,
  useAddRoleUsersMutation,
  useAddRolePermissionsMutation,
  useRemoveRolePermissionsMutation,
} = rolesApiSlice;
