import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const BeneficiaryCard = ({ beneficiary }) => {
  const { t } = useTranslation();

  const biometrics =
    beneficiary?.biometrics !== null
      ? t("beneficiaries.view.yes")
      : t("beneficiaries.view.no");
  const card_serial =
    beneficiary?.cards !== undefined ? beneficiary?.cards[0]?.serial : "";

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {t("beneficiaries.view.primary_ben")}
        </Typography>
        <hr />
        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="div">
            {t("global.form.label.name")}:
          </Typography>
          <Typography variant="h6" component="div">
            {beneficiary?.full_name}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="div">
            {t("users.form.label.telephone")}:
          </Typography>
          <Typography variant="h6" component="div">
            {beneficiary?.telephone}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="div">
            {t("users.form.label.id_number")}:
          </Typography>
          <Typography variant="h6" component="div">
            {beneficiary?.id_number}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="div">
            {t("beneficiaries.table.dob")}:
          </Typography>
          <Typography variant="h6" component="div">
            {beneficiary?.date_of_birth}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="div">
            {t("beneficiaries.table.pin")}:
          </Typography>
          <Typography variant="h6" component="div">
            {beneficiary?.pin_code}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="div">
            {t("beneficiaries.table.biometrics")}:
          </Typography>
          <Typography variant="h6" component="div">
            {biometrics}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="div">
            {t("cards.table.serial")}:
          </Typography>
          <Typography variant="h6" component="div">
            {card_serial}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="div">
            {t("beneficiaries.table.status")}:
          </Typography>
          <Typography variant="h6" component="div">
            {beneficiary?.status}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" component="div">
            {t("beneficiaries.table.photo")}:
          </Typography>
          <Typography variant="h6" component="div">
            <Avatar
              src={beneficiary?.photo}
              alt=""
              variant="rounded"
              sx={{
                width: "120px",
                height: "120px"
              }}
            />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BeneficiaryCard;
