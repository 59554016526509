import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Divider,
} from "@mui/material";
import React from "react";
import Toast from "../../../global/Toast";
import { ToastContainer } from "react-toastify";
import { Formik } from "formik";
import * as yup from "yup";
import { useRestoreDuplicateMutation } from "../../../../features/biometric_deduplication/nonBiometricDeuplicationApiSlice";
import { useTranslation } from "react-i18next";

const initialValues = {
  comment: "",
};
const commentSchema = yup.object().shape({
  comment: yup.string().required("required"),
});

const ConfirmRestoreDialog = ({ onClose, open, beneficiary }) => {
  const { t } = useTranslation();
  const [restoreDuplicate] = useRestoreDuplicateMutation();
  const handleClose = async (value) => {
    onClose(true);
  };

  const handleSubmit = async (values) => {
    const payload = { ...values, beneficiary_id: beneficiary.id };
    try {
      const response = await restoreDuplicate(payload).unwrap();
      onClose(true);
      if (response.status === 200) {
        onClose(true);
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
      onClose(true);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <ToastContainer />
      <DialogTitle>
        {t("nonbiometrics.index.you_are_restoring")} {beneficiary?.full_name}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" sx={{ padding: "10px" }}>
          {t("nonbiometrics.index.want_to_restore")} {beneficiary?.full_name}?
        </Typography>

        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={commentSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("nonbiometrics.index.beneficiary")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={beneficiary.full_name}
                name="formid"
                inputProps={{ readOnly: true }}
              />
              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={3}
                type="text"
                label={t(
                  "nonbiometrics.index.add_comment_why_restore_beneficiary"
                )}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.comment}
                name="comment"
                error={!!touched.comment && !!errors.comment}
                helperText={t(
                  "nonbiometrics.index.add_comment_why_restore_beneficiary"
                )}
              />

              <Box display="flex" justifyContent="end" mt="20px">
                <button className="btn btn-primary" type="submit">
                  {t("nonbiometrics.index.restore_beneficiary")}
                </button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
      <Divider />
      <DialogActions>
        <button className="btn btn-danger" onClick={() => handleClose(true)}>
          {t("nonbiometrics.table.close_button")}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRestoreDialog;
