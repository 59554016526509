import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs,
  Alert,
} from "@mui/material";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import { useNavigate, Link } from "react-router-dom";
import {
  useGetUsersQuery,
  useDeleteUserMutation,
  useRestoreUserMutation,
} from "../../../features/users/usersApiSlice";
import Spinner from "../../global/Spinner";
import DeleteDialog from "../shared/DeleteDialog";
import RestoreDialog from "../shared/RestoreDeletedDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const Users = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(
    (permission) => permission.name === "user-add"
  );
  const canEdit = currentPermissions.some(
    (permission) => permission.name === "user-update"
  );
  const canDelete = currentPermissions.some(
    (permission) => permission.name === "user-delete"
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

  const [users, setUsers] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  // Get the users from the API
  const { data, isLoading, isSuccess, isFetching } = useGetUsersQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (data?.data) setUsers(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const [deleteUser] = useDeleteUserMutation();
  const [restoreUser] = useRestoreUserMutation();

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const handleEdit = (user) => {
    user = JSON.parse(user);
    navigate(`/users/edit/${user.id}`, { state: { user } });
  };

  const handleClickOpen = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleClickOpenRestore = (user) => {
    setSelectedUser(user);
    setOpenRestoreDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setOpenRestoreDialog(false);
    setSelectedUser(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const getUser = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              {canEdit && (
                <Button
                  color="secondary"
                  onClick={() => handleEdit(getUser())}
                  size="small"
                >
                  {t("global.table.edit_button")} <EditOutlinedIcon />
                </Button>
              )}

              {params?.row?.status === "DELETED" && canDelete ? (
                <Button
                  color="primary"
                  onClick={() => handleClickOpenRestore(getUser())}
                  size="small"
                >
                  {t("global.table.restore_button")}{" "}
                  <RestoreFromTrashOutlinedIcon />
                </Button>
              ) : (
                canDelete && (
                  <Button
                    color="error"
                    onClick={() => handleClickOpen(getUser())}
                    size="small"
                  >
                    {t("global.table.delete_button")}{" "}
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                )
              )}
            </ButtonGroup>
          </Box>
        );
      },
    },
    {
      field: "first_name",
      headerName: t("users.form.label.first_name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "last_name",
      headerName: t("users.form.label.last_name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: t("users.form.label.email"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: "user_name",
      headerName: t("users.form.label.user_name"),
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: t("users.form.label.status"),
      flex: 0.5,
      minWidth: 200,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("users.index.title")}
        subtitle={t("users.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <p>{t("sidebar.Home")} </p>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Users")}
        </Typography>
      </Breadcrumbs>

      {canAdd && (
        <Box display="flex" justifyContent="end" mt="20px">
          <Link style={LinkColor()} to="/users/add" underline="none">
            <button className="btn btn-primary">
              {t("users.index.add_button")}
            </button>
          </Link>
        </Box>
      )}

      <Box className="data_grid_box_container">
        {users?.length < 1 ? (
          <Alert severity="info">{t("users.index.no_users_data")}</Alert>
        ) : (
          <StyledDataGrid
            rows={users}
            columns={columns}
            pagination
            pageSize={pagination.perPage}
            rowCount={pagination.total}
            onFilterModelChange={handleFilterModelChange}
            onPageChange={handlePageChange}
            loading={isFetching}
            paginationMode="server"
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={default_rows_per_page_options}
          />
        )}
      </Box>

      {/* Render the DeleteDialog component */}
      {openDialog && (
        <DeleteDialog
          open={openDialog}
          onClose={handleClose}
          name={JSON.parse(selectedUser).user_name}
          deleteMethod={deleteUser}
          url={`/users/delete/${JSON.parse(selectedUser).id}`}
        />
      )}
      {/* Render the RestoreDialog component */}
      {openRestoreDialog && (
        <RestoreDialog
          open={openRestoreDialog}
          onClose={handleClose}
          name={JSON.parse(selectedUser).user_name}
          restoreMethod={restoreUser}
          url={`/users/restore/${JSON.parse(selectedUser).id}`}
        />
      )}
    </Box>
  );

  return content;
};

export default Users;
