import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Breadcrumbs } from "@mui/material";
import Header from "../../Header";

import { Link } from "react-router-dom";
import { LinkColor } from "../../../theme";
import DropDown from "../shared/DropDown";
import { BioInformation } from "./components/BioInformation";
import DuplicatesDataTable from "./components/DuplicatesDataTable";
import { ToastContainer } from "react-toastify";

const NonBiometricDeduplication = () => {
  const { t } = useTranslation();
  const selectFields = [
    { label: "Full Name", defaultChecked: true, value: "full_name" },
    { label: "Phone Number", value: "telephone" },
    { label: "Email", value: "email" },
    { label: "Combined", value: "no" },
  ];

  const [selectedDuplicate, setSelectedDuplicate] = useState(undefined);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(undefined);
  const [filterDuplicates, setFilterDuplicates] = useState([]);

  const handleFieldsSubmit = (duplicates) => {
    setFilterDuplicates(duplicates);
  };

  const handleBeneficiarySelected = (selectedBeneficiary) => {
    setSelectedBeneficiary(selectedBeneficiary);
  };
  const handlePossibleDuplicateSelected = (selectedDuplicate) => {
    setSelectedDuplicate(selectedDuplicate);
  };

  return (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("nonbiometrics.index.title")}
        subtitle={t("nonbiometrics.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/beneficiaries">
          <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Non Biometric Deduplication")}
        </Typography>
      </Breadcrumbs>

      <Box>
        <div style={{ display: "flex", gap: "10px", justifyContent: "end" }}>
          <DropDown
            borderTitle="Select Fields"
            dropDownTitle="Set Fields"
            checkboxItems={selectFields}
            buttonTitle="Check Duplicated"
            onSubmit={handleFieldsSubmit}
          />
        </div>
      </Box>

      <Box>
        {selectedBeneficiary !== undefined &&
        selectedDuplicate !== undefined ? (
          <BioInformation
            selectedUser={selectedBeneficiary}
            possibleDuplicate={selectedDuplicate}
            onMarkedDuplicate={handlePossibleDuplicateSelected}
          />
        ) : null}

        <DuplicatesDataTable
          onPossibleDuplicate={handlePossibleDuplicateSelected}
          onBeneficiarySelect={handleBeneficiarySelected}
          filterDuplicates={filterDuplicates}
        />
      </Box>
    </Box>
  );
};

export default NonBiometricDeduplication;
