import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams, Link} from "react-router-dom";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import {Field, Formik} from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useUpdateProjectMutation, useGetProjectQuery } from "../../../features/projects/projectsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditProject = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [project, setProject] = useState([]);

  // Fetch project
  const { data, isLoading } = useGetProjectQuery(id);
  useEffect(() => {
    if (data?.data) {
      setProject(data?.data);
    }
  }, [data]);

  //const project = location.state.project;
  const [updateProject, { isUpdating }] = useUpdateProjectMutation();

  const initialValues = {
    code: '',
    title: '',
    description: '',
    document_number: '',
    project_number: '',
    section_name: '',
    section_code: '',
    status: '',
    status_code: '',
    country_coverage: ''
  };

  const EditProjectSchema = yup.object().shape({
    code: yup.string().required(t("project.add.form.helpertext.code")),
    title: yup.string().required(t("project.add.form.helpertext.name")),
    description: yup.string().required(t("project.add.form.helpertext.description")),
  });

  const [startDate, setStartDate] = useState(project.start_date);
  const [endDate, setEndDate] = useState(project.nte_date);

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      id: project.id,
      title: values.title,
      description: values.description,
      start_date: startDate === null ? null : moment(startDate).format("YYYY-MM-DD"),
      nte_date: endDate === null ? null : moment(endDate).format("YYYY-MM-DD"),
    };

    try {
      const response = await updateProject(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
        function () {
          navigate("/projects", { state: { message: response?.message } });
        },
        3000
      );
    } catch (err) {
      Toast(err?.data?.errors, "error");
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('project.edit.title')}
        subtitle={t('project.edit.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/projects">
          <Typography variant="body2">{t('sidebar.Projects')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('project.edit.edit_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleSubmit}
        initialValues={project || initialValues}
        validationSchema={EditProjectSchema}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                  fullWidth
                  required
                  variant="filled"
                  type="text"
                  label={t('project.shared.project_code')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code || ""}
                  name="code"
                  message={t("project.form.helpertext.code")}
                  error={!!touched.code && !!errors.code}
                  helperText={touched.code && errors.code}
                  InputLabelProps={{ shrink: true }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('project.shared.project_name')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title || ""}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                multiline
                rows={4}
                label={t('project.shared.description')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description || ""}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                InputLabelProps={{ shrink: true }}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('project.shared.start_date')}
                  value={startDate || project.start_date}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setStartDate(Date.parse(newValue));
                  }}
                  formatDate={(startDate) =>
                    moment(startDate).format("DD-MM-YYYY")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('project.shared.end_date')}
                  value={endDate || project.nte_date}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setEndDate(Date.parse(newValue));
                  }}
                  formatDate={(endDate) =>
                      moment(endDate).format("DD-MM-YYYY")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <div id="my-radio-group">
                {t("project.form.label.country_coverage")}
              </div>
              <div role="group" aria-labelledby="my-radio-group">
                <label>
                  <Field
                      type="radio"
                      name="country_coverage"
                      value="1"
                      onChange={handleChange}
                      checked={values.country_coverage == 1}
                  />
                  {t("project.form.label.country_coverage_single")}
                </label>
                <label>
                  <Field
                      type="radio"
                      name="country_coverage"
                      value="2"
                      onChange={handleChange}
                      checked={values.country_coverage == 2}
                  />
                  {t("project.form.label.country_coverage_multiple")}
                </label>
              </div>

              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={t('project.shared.document_number')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.document_number || ""}
                  name="document_number"
                  message={t("project.form.helpertext.document_number")}
                  error={!!touched.document_number && !!errors.document_number}
                  helperText={t("project.form.helpertext.document_number")}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={t('project.shared.number')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.project_number || ""}
                  name="project_number"
                  message={t("project.form.helpertext.number")}
                  error={!!touched.project_number && !!errors.project_number}
                  helperText={t("project.form.helpertext.number")}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={t('project.shared.section_name')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.section_name || ""}
                  name="section_name"
                  message={t("project.form.helpertext.section_name")}
                  error={!!touched.section_name && !!errors.section_name}
                  helperText={t("project.form.helpertext.section_name")}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={t('project.shared.section_code')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.section_code || ""}
                  name="section_code"
                  message={t("project.form.helpertext.section_code")}
                  error={!!touched.section_code && !!errors.section_code}
                  helperText={t("project.form.helpertext.section_code")}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={t('project.shared.status')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.status || ""}
                  name="status"
                  message={t("project.form.helpertext.status")}
                  error={!!touched.status && !!errors.status}
                  helperText={t("project.form.helpertext.status")}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label={t('project.shared.status_code')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.status_code || ""}
                  name="status_code"
                  message={t("project.form.helpertext.status_code")}
                  error={!!touched.status_code && !!errors.status_code}
                  helperText={t("project.form.helpertext.status_code")}
              />

            </Box>
            <Box display="flex" gap="1rem" justifyContent="end" mt="20px">
            <Link to="/projects" underline="none">
                <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('project.edit.edit_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );

  return content;
};

export default EditProject;
