import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import React from "react";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { useUnblockBeneficiaryMutation } from "../../../features/beneficiaries/beneficiariesApiSlice"

const UnBlockBeneficiaryDialog = ({ onClose, name, open, beneficiaryData }) => {
  const { t } = useTranslation();

  const initialValues = {
    unblock_reason: ""
  };
  const beneficiarySchema = yup.object().shape({
    unblock_reason: yup.string().required(t('beneficiaries.view.unblock_reason'))
  });
  const [unBlockBeneficiary] = useUnblockBeneficiaryMutation();

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      id: beneficiaryData.id
    };

    try {
      const response = await unBlockBeneficiary(payload).unwrap();
      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }
      onClose(values);
    } catch (err) {
      //console.log(err);
    }
  };

  const handleClose = async (value) => {
    onClose(name);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <ToastContainer />
      <DialogTitle>
        {t("global.dialog.unblock_heading")} <b>{name}</b>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">
          {t("global.dialog.unblock_content")} {name}? {" "}
        </Typography>
        {/*--*/}
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={beneficiarySchema}
        >
          {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                    autoFocus
                    fullWidth
                    variant="filled"
                    type="text"
                    multiline
                    maxRows={4}
                    required
                    label={t('beneficiaries.view.unblock_reason')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.unblock_reason}
                    name="unblock_reason"
                    error={!!touched.unblock_reason && !!errors.unblock_reason}
                    helperText={touched.unblock_reason && errors.unblock_reason}
                />

                <Box display="flex" gap="10px" justifyContent="end" mt="20px">
                  <button type="submit" className="btn btn-primary">
                    {t("global.dialog.buttons.unblock")}
                  </button>
                  <button autoFocus  onClick={onClose} className="btn btn-danger">
                    {t('global.page.cancel_button')}
                  </button>
                </Box>
              </form>
          )}
        </Formik>
        {/*--*/}
      </DialogContent>
      <DialogActions>
        {/*onClick={() => handleClose(false)}*/}
      </DialogActions>
    </Dialog>
  );
};

export default UnBlockBeneficiaryDialog;
