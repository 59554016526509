import { apiSlice } from "../../api/apiSlice";

export const projectsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["ProjectsData"],
  endpoints: (builder) => ({
    addProject: builder.mutation({
      query: (payload) => ({
        url: "/project/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["ProjectsData"],
    }),
    getProjects: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
          const queryString = new URLSearchParams({
              ...apiFilters,
              page: page,
              pageSize: pageSize
          }).toString();

          return {
              url: `project/list/${pageSize}?${queryString}`,
              method: "GET",
          };
      },
      providesTags: ["ProjectsData"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getProject: builder.query({
      query: (id) => ({
        url: `/project/single/${id}`,
        method: "GET",
      }),
      providesTags: ["ProjectsData"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateProject: builder.mutation({
      query: (payload) => ({
        url: `/project/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["ProjectsData"],
    }),
    deleteProject: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectsData"],
    }),
  }),
});

export const {
  useAddProjectMutation,
  useGetProjectsQuery,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectsApiSlice;
