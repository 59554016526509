import { apiSlice } from "../../api/apiSlice";

export const koboApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["KoboForms", "KoboPreloads", "KoboColumns"],
  endpoints: (builder) => ({
    getKoboForms: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/kobo/forms/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["KoboForms"],
      keepUnusedDataFor: 2,
      refetchOnMountOrArgChange: true,
    }),
    getKoboSync: builder.query({
      query: () => "/kobo/syncedform",
      method: "GET",
    }),
    getKoboForm: builder.query({
      query: (id) => ({
        url: `/kobo/form/${id}`,
        method: "GET",
      }),
    }),
    importFromKobo: builder.mutation({
      query: (payload) => ({
        url: "/beneficiaries/sync/kobo",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Beneficiaries", "KoboForms"],
    }),
    syncKoboForms: builder.mutation({
      query: () => ({
        url: "/kobo/sync/forms",
        method: "GET",
      }),
      invalidatesTags: ["KoboForms", "Beneficiaries"],
    }),
    getKoboFormColumns: builder.query({
      query: (formid) => ({
        url: `/kobo/beneficiaries/columns/${formid}`,
        method: "GET",
      }),
      providesTags: ["KoboColumns"],
      keepUnusedDataFor: 5,
      refetchOnMountOrArgChange: true,
    }),
    createKoboPreload: builder.mutation({
      query: (payload) => ({
        url: "/kobo/generate/preload",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["KoboPreloads"],
    }),
    getKoboPreloads: builder.query({
      query: ({ id, page, pageSize }) => ({
        url: `/kobo/form/preloads/${id}/${pageSize}?page=${page}`,
        method: "GET",
      }),
      providesTags: ["KoboPreloads"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    deletePreload: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["KoboPreloads"],
    }),
    restorePreload: builder.mutation({
      query: (payload) => ({
        url: `/kobo/restore/preload`,
        method: "POST",
        body: {...payload}
      }),
      invalidatesTags: ["KoboPreloads"],
    }),
  }),
});

export const {
  useGetKoboFormsQuery,
  useGetKoboSyncQuery,
  useGetKoboFormQuery,
  useImportFromKoboMutation,
  useSyncKoboFormsMutation,
  useGetKoboFormColumnsQuery,
  useCreateKoboPreloadMutation,
  useGetKoboPreloadsQuery,
  useDeletePreloadMutation,
  useRestorePreloadMutation,
} = koboApiSlice;
