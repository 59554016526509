import { Box, TextField } from "@mui/material";
import { Formik, Field } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import Toast from "../../global/Toast";
import {
  useAddSettingsMutation,
  useUpdateSettingsMutation,
  useDeleteSettingsMutation,
} from "../../../features/settings/settingsApiSlice";

const AddSettingsForm = ({
  handleClose,
  isEditMode = false,
  groupId,
  refetchAllSettings,
  groupSettings,
}) => {
  const initial_values = {
    setting_group_id: groupId,
    title: groupSettings && groupSettings.title ? groupSettings.title : "",
    payload:
      groupSettings && groupSettings.payload ? groupSettings.payload : "",
    is_locked:
      groupSettings && groupSettings.is_locked !== undefined
        ? groupSettings.is_locked
        : false,
    is_encrypted:
      groupSettings && groupSettings.encrypted !== undefined
        ? groupSettings.encrypted
        : false,
  };

  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [addSettings, { isLoading }] = useAddSettingsMutation();
  const [editSettings] = useUpdateSettingsMutation();
  const [deleteSettings] = useDeleteSettingsMutation();

  const handleFormSubmit = async (values) => {
    try {
      let response;
      if (isEditMode) {
        const payload = { ...values, id: groupSettings["id"] };
        response = await editSettings(payload);
      } else {
        response = await addSettings(values);
      }
      if (response.data.status === 200) {
        Toast(response.data.message, "success");
      } else {
        console.log("Not Success");
        Toast(response.data.message, "error");
      }
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      handleClose();
      refetchAllSettings();
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteSettings(groupSettings["id"]);

      if (response.data.status === 200) {
        Toast(response.data.message, "success");
      } else {
        Toast(response.data.message, "error");
      }
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      handleClose();
      refetchAllSettings();
    }
  };

  const handleToggleEdit = () => {
    setIsEditing((preState) => !preState);
  };

  const SettingGroupSchema = yup.object().shape({
    setting_group_id: yup.string(),
    title: yup.string().required(t("setting_group.form.helpertext.title")),
    payload: yup.string().required(t("setting_group.form.helpertext.payload")),
    is_locked: yup
      .boolean()
      .required(t("setting_group.form.helpertext.is_locked")),
    is_encrypted: yup
      .boolean()
      .required(t("setting_group.form.helpertext.is_encrypted")),
  });

  return (
    <>
      <p>{isEditMode ? "Update Setting" : "Add Setting"}</p>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initial_values}
        validationSchema={SettingGroupSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          handleChange,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="group_setting_id"
                onBlur={handleBlur}
                onChange={handleChange}
                value={groupId}
                name="group_setting_id"
                style={{ display: "none" }}
              />
              <TextField
                required
                fullWidth
                variant="filled"
                type="text"
                label={t("setting_group.form.label.title")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                disabled={!isEditing && isEditMode}
              />
              <TextField
                required
                fullWidth
                variant="filled"
                type="text"
                label={t("setting_group.form.label.payload")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.payload}
                name="payload"
                error={!!touched.payload && !!errors.payload}
                helperText={touched.payload && errors.payload}
                disabled={!isEditing && isEditMode}
              />

              <label>
                <span style={{ marginRight: "10px" }}>
                  {t("setting_group.form.label.is_locked")}
                </span>
                <Field
                  required
                  type="checkbox"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  checked={values.is_locked}
                  name="is_locked"
                  disabled={!isEditing && isEditMode}
                />
                <strong
                  style={{ marginLeft: "10px" }}
                >{`${values.is_locked}`}</strong>
              </label>

              <label>
                <span style={{ marginRight: "10px" }}>
                  {t("setting_group.form.label.is_encrypted")}
                </span>
                <Field
                  required
                  type="checkbox"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  checked={values.is_encrypted}
                  name="is_encrypted"
                  disabled={!isEditing && isEditMode}
                />
                <strong
                  style={{ marginLeft: "10px" }}
                >{`${values.is_encrypted}`}</strong>
              </label>
            </Box>

            <Box display="flex" mt="20px" justifyContent="end">
              {isEditMode ? (
                <>
                  <a
                    role="button"
                    className="btn btn-secondary"
                    onClick={handleToggleEdit}
                    style={{ marginRight: "10px" }}
                  >
                    {isEditing ? "Disable Editing" : "Enable Editing"}
                  </a>

                  <a
                    role="button"
                    className="btn btn-danger"
                    onClick={handleDelete}
                    style={{ marginRight: "10px" }}
                  >
                    Delete
                  </a>
                </>
              ) : (
                <a
                  role="button"
                  className="btn btn-danger"
                  onClick={handleClose}
                >
                  close
                </a>
              )}

              {isEditing ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddSettingsForm;
