import React from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  useUpdateUserMutation,
} from "../../../features/users/usersApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const user = location.state.user;

  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    telephone: user.telephone,
  };

  const usersSchema = yup.object().shape({
    first_name: yup.string().required(t("users.form.helpertext.first_name")),
    last_name: yup.string().required(t("users.form.helpertext.last_name")),
    telephone: yup.string().required(t("users.form.helpertext.telephone")),
  });

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const handleFormSubmit = async (values) => {
    const payload = { ...values, id: user.id };
    try {
      const response = await updateUser(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
          function () {
            navigate("/users", { state: { message: response?.message } });
          },
          3000
      );
    } catch (err) {
      Toast(err?.data?.errors, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("users.edit.title")} 
        subtitle={t("users.edit.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/users">
          <Typography variant="body2">{t("sidebar.Users")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("users.edit.edit_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={usersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("users.form.label.first_name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.first_name}
                name="first_name"
                error={!!touched.first_name && !!errors.first_name}
                helperText={touched.first_name && errors.first_name}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("users.form.label.last_name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.last_name}
                name="last_name"
                error={!!touched.last_name && !!errors.last_name}
                helperText={touched.last_name && errors.last_name}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("users.form.label.telephone")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.telephone}
                name="telephone"
                error={!!touched.telephone && !!errors.telephone}
                helperText={touched.telephone && errors.telephone}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/users" underline="none">

              <button className="btn btn-danger">
                  {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('users.edit.edit_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default EditUser;
