import { Box, TextField } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";

import Toast from "../../global/Toast";
import {
  useAddSettingsGroupMutation,
  useUpdateSettingsGroupMutation,
  useDeleteSettingsGroupMutation,
} from "../../../features/settings/settingsApiSlice";

const AddSettingGroupForm = ({
  handleClose,
  isEditMode = false,
  isDeleteMode = false,
  groupId,
  settingGroup,
}) => {
  const selectedGroup = settingGroup.data.find((group) => group.id == groupId);

  const placeholderText =
    selectedGroup && (isEditMode || isDeleteMode) ? selectedGroup.title : "";

  const INITIAL_VALUES = {
    title: placeholderText,
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [addSettingGroup, { isLoading: isAdding }] =
    useAddSettingsGroupMutation();

  const [editSettingGroup, { isLoading: isEditing }] =
    useUpdateSettingsGroupMutation();

  const [deleteSettingGroup] = useDeleteSettingsGroupMutation();

  const handleFormSubmit = async (values) => {
    try {
      let response;
      if (isEditMode) {
        const payload = { ...values, id: groupId };
        response = await editSettingGroup(payload);
      } else {
        response = await addSettingGroup(values);
      }

      if (response.data.status === 200) {
        Toast(response.data.message, "success");
      } else {
        Toast(response.data.message, "error");
      }
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      handleClose();
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteSettingGroup(groupId);

      if (response.data.status === 200) {
        Toast(response.data.message, "success");
      } else {
        Toast(response.data.message, "error");
      }
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      handleClose();
    }
  };

  const SettingGroupSchema = yup.object().shape({
    title: yup.string().required(t("setting_group.form.helpertext.title")),
  });

  return (
    <>
      <p>
        <strong>
          {" "}
          {isEditMode
            ? "Update Group"
            : isDeleteMode
            ? "Delete Group"
            : "Add Group"}
        </strong>
      </p>
      {isDeleteMode ? (
        <>
          <p>
            {t("setting_group.index.delete_settings_group_button")}{" "}
            <strong>{placeholderText}</strong>
          </p>
          <Box display="flex" gap="10px" mt="20px" justifyContent="end">
            <a role="button" className="btn btn-primary" onClick={handleClose}>
              close
            </a>
            <button
              type="submit"
              className="btn btn-danger"
              onClick={handleDelete}
            >
              Delete
            </button>
          </Box>
        </>
      ) : (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={INITIAL_VALUES}
          validationSchema={SettingGroupSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="20px">
                <TextField
                  required
                  fullWidth
                  variant="filled"
                  type="text"
                  label={t("setting_group.form.label.title")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  name="title"
                  error={!!touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                  placeholder={placeholderText}
                />
              </Box>

              <Box display="flex" mt="20px" justifyContent="end">
                <a
                  role="button"
                  className="btn btn-danger"
                  onClick={handleClose}
                >
                  close
                </a>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddSettingGroupForm;
