import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs, Alert,
} from "@mui/material";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate, Link } from "react-router-dom";
import {
  useGetSeasonsQuery,
  useDeleteSeasonMutation,
} from "../../../features/seasons/seasonsApiSlice";
import Spinner from "../../global/Spinner";
import DeleteDialog from "../shared/DeleteDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const Seasons = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(permission => permission.name === 'season-add');
  const canEdit = currentPermissions.some(permission => permission.name === 'season-update');
  const canDelete = currentPermissions.some(permission => permission.name === 'season-delete');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedSeason, setSelectedSeason] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [seasons, setSeasons] = useState([]);
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
      const apiFilters = {};
      
      filterModel?.forEach(item => {
        if (item.columnField && item.value) {
          apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
        }
      });

      return apiFilters;
  };
  
  const { data, isLoading, isSuccess, isFetching } = useGetSeasonsQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  useEffect(() => {
    if (data?.data) setSeasons(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const [deleteSeason] = useDeleteSeasonMutation();

  const handleEdit = (season) => {
    season = JSON.parse(season);
    navigate(`/interventions/seasons/edit/${season.id}`, { state: { season } });
  };

  const handleClickOpen = (season) => {
    setSelectedSeason(season);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedSeason(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t('global.table.actions'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const getSeason = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                {canEdit && (
                <Button color="secondary" onClick={() => handleEdit(getSeason())} size="small">
                  {t('global.table.edit_button')} <EditOutlinedIcon />
                </Button>
                )}

                {canDelete && (
                <Button color="error" onClick={() => handleClickOpen(getSeason())} size="small">
                  {t('global.table.delete_button')}  <DeleteOutlineOutlinedIcon />
                </Button>
                )}
              </ButtonGroup>
             
            </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t('global.form.label.name'),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    { field: "code", headerName: t('seasons.form.label.code'), flex: 1, minWidth: 100 },
    { field: "type", headerName: t('seasons.form.label.type'), flex: 1, minWidth: 200 },
    {
      field: "start_date",
      headerName: t('project.shared.start_date'),
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => moment(params?.value).format("YYYY-MM-DD"),
    },
    {
      field: "end_date",
      headerName: t('project.shared.end_date'),
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => moment(params?.value).format("YYYY-MM-DD"),
    }
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header title={t('seasons.index.title')} subtitle={t('seasons.index.subtitle')} />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
        {t("sidebar.Seasons")}
        </Typography>
      </Breadcrumbs>

      {canAdd && (
      <Box display="flex" justifyContent="end" mt="20px">
      <Link to="/interventions/seasons/add" underline="none">

        <button className="btn btn-primary">
          {t('seasons.index.add_button')} 
        </button>
        </Link>

      </Box>
      )}

      <Box className="data_grid_box_container">
        {seasons?.length < 1 ? (
            <Alert severity="info">{t("seasons.index.no_seasons_data")}</Alert>
        ) : (
            <StyledDataGrid
                rows={seasons}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pagination.perPage}
                rowCount={pagination.total}
                onFilterModelChange={handleFilterModelChange}
                loading={isFetching}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={default_rows_per_page_options}
            />
        )}

      </Box>
      {openDialog && (
                  <DeleteDialog
                      open={openDialog}
                      onClose={handleClose}
                      name={JSON.parse(selectedSeason).title}
                      deleteMethod={deleteSeason}
                      url={`/season/delete/${JSON.parse(selectedSeason).id}`}
                  />
              )}
    </Box>
  );

  return content;
};

export default Seasons;
