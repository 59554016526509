import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import React from "react";
import { useAddPackageEntitlementsMutation } from "../../../features/packages/packagesApiSlice";
import { Formik, Field } from "formik";
import * as yup from "yup";
import Spinner from "../../global/Spinner";
import { useTranslation } from 'react-i18next';

const AddEntitlementDialog = ({
  onClose,
  open,
  packageData,
  entitlementData,
}) => {
  const { t } = useTranslation()
  const entitlement = JSON.parse(entitlementData);
  const initialValues = {
    max_redeemable: "",
    per_season: "no",
  };
  const entitlementsSchema = yup.object().shape({
    max_redeemable: yup.string().required(t('entitlements.form.helpertext.max_redeemable')),
    per_season: yup.string().required(t('packages.form.helpertext.per_season')),
  });
  const [addPackageEntitlements, { isLoading }] = useAddPackageEntitlementsMutation();

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      per_season: values.per_season === "yes" ? true : false,
      package_id: packageData.id,
      entitlement_id: entitlement.id,
    };

    try {
      await addPackageEntitlements(payload).unwrap();
      onClose(packageData);
    } catch (err) {
      //console.log(err);
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Dialog open={open}>
      <DialogTitle>
        {t("packages.add_entitlements.add")}{" "}
        <b>{entitlement.title}</b>{" "}{t('entitlements.form.label.entitlement')}{" "}
        {t("packages.add_entitlements.to")}{" "}
        <b>{packageData?.title}</b>{" "}{t("packages.form.label.package")}
      </DialogTitle>
      <DialogContent>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={entitlementsSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t('entitlements.form.label.max_redeemable')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.max_redeemable}
                name="max_redeemable"
                error={!!touched.max_redeemable && !!errors.max_redeemable}
                helperText={touched.max_redeemable && errors.max_redeemable}
              />
              <Box mt="20px">{t('packages.add_dialog.per_season')}</Box>
              <Box role="group" aria-labelledby="my-radio-group">
                <label>
                  <Field
                    type="radio"
                    name="per_season"
                    value="yes"
                    onChange={handleChange}
                  />
                  {t('global.checkbox.yes')}
                </label>
                <label>
                  <Field
                    type="radio"
                    name="per_season"
                    value="no"
                    onChange={handleChange}
                  />
                  {t('global.checkbox.no')}
                </label>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" mt="20px">
                <button type="submit" className="btn btn-primary">
                 {t('packages.add_dialog.add_button')}
                </button>
                <button autoFocus className="btn btn-danger" onClick={onClose} m="5px">
                    {t('global.page.cancel_button')}
                </button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );

  return content;
};

export default AddEntitlementDialog;
