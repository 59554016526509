import { Container, Grid, Card, CardMedia, CardContent, Box, Button, Typography } from "@mui/material";
import React from "react";
import Header from "../../Header";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const emailAddress = 'oer-idea@fao.org';
  const emailSubject = t('about_idea.know_more');
  const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}`;

  const content = (
    <Container maxWidth="md">
      <Box my={4}>
        <Box pb="20px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title={t('about_idea.title')} subtitle={t('about_idea.subtitle')} />
            <Button variant="contained" color="secondary" href={mailtoLink} size="small">
              {t('about_idea.know_more')}
            </Button>
          </Box>
        </Box>

        <Typography variant="h4" component="h1" gutterBottom>
          {t('about_idea.what_is_idea')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('about_idea.what_is_idea_content')}
        </Typography>

        <Typography variant="h4" component="h1" gutterBottom>
          {t('about_idea.how_does_idea_work')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('about_idea.how_does_idea_work_content')}
        </Typography>

        <Grid container spacing={4}>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image="/images/idea_registration.png"
                alt={t('about_idea.idea_registration')}
                sx={{
                  objectFit: 'contain',
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('about_idea.idea_registration')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('about_idea.idea_registration_content')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image="/images/idea_biometrics.png"
                alt={t('about_idea.idea_biometrics')}
                sx={{
                  objectFit: 'contain',
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('about_idea.idea_biometrics')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('about_idea.idea_biometrics_content')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image="/images/idea_distribution.png"
                alt={t('about_idea.idea_distribution')}
                sx={{
                  objectFit: 'contain',
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('about_idea.idea_distribution')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('about_idea.idea_distribution_content')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image="/images/idea_webapp.png"
                alt={t('about_idea.idea_web')}
                sx={{
                  objectFit: 'contain',
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('about_idea.idea_web')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('about_idea.idea_web_content')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image="/images/idea_contactless.png"
                alt={t('about_idea.idea_contactless')}
                sx={{
                  objectFit: 'contain',
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('about_idea.idea_contactless')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('about_idea.idea_contactless_content')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image="/images/idea_additional_modules.png"
                alt={t('about_idea.additional_modules')}
                sx={{
                  objectFit: 'contain',
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {t('about_idea.additional_modules')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('about_idea.additional_modules_content')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

        </Grid>

      </Box>
    </Container>
    );

  return content;
};

export default About;
