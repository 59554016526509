import React from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useAddRoleMutation } from "../../../features/roles/rolesApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const initialValues = {
  name: "",
  description: "",
  guard_name: "api",
};

const AddRole = () => {
  const { t } = useTranslation();
  const roleSchema = yup.object().shape({
    name: yup.string().required(t("roles.form.helpertext.name")),
    description: yup.string().required(t("roles.form.helpertext.description")),
    guard_name: yup.string().required(t("roles.form.helpertext.guard_name")),
  });
  const navigate = useNavigate();
  const [addRole, { isLoading }] = useAddRoleMutation();

  const handleFormSubmit = async (values) => {
    const payload = {
      ...values,
      guard_name: values.guard_name === "api" ? "api" : "web",
    };

    try {
      const response = await addRole(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
        function () {
          navigate("/roles", { state: { message: response?.message } });
        },
        3000
      );

    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header title={t("roles.add.title")} subtitle={t("roles.add.subtitle")} />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/roles">
          <Typography variant="body2">{t("sidebar.Roles")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("roles.add.add_role")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={roleSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("global.form.label.name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("global.form.label.description")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />

              <div id="my-radio-group">{t("roles.form.label.guard_name")}</div>
              <div role="group" aria-labelledby="my-radio-group">
                <label>
                  <Field
                    type="radio"
                    name="guard_name"
                    value="api"
                    onChange={handleChange}
                  />
                  {t("roles.form.label.api")}
                </label>
                <label>
                  <Field
                    type="radio"
                    name="guard_name"
                    value="web"
                    onChange={handleChange}
                  />
                  {t("roles.form.label.web")}
                </label>
              </div>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/roles" underline="none">

              <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("roles.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddRole;
