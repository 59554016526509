import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs,
  Alert,
} from "@mui/material";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate, Link } from "react-router-dom";
import {
  useGetRolesQuery,
  useDeleteRoleMutation,
} from "../../../features/roles/rolesApiSlice";
import Spinner from "../../global/Spinner";
import DeleteDialog from "../shared/DeleteDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const Roles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [roles, setRoles] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  const { data, isLoading, isSuccess, isFetching } = useGetRolesQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  useEffect(() => {
    if (data?.data) setRoles(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const [deleteRole] = useDeleteRoleMutation();

  const handleEdit = (role) => {
    role = JSON.parse(role);
    navigate(`/roles/edit/${role.id}`, {
      state: { role },
    });
  };

  const handleClickOpen = (role) => {
    setSelectedRole(role);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedRole(value);
  };

  const handleView = (role) => {
    role = JSON.parse(role);
    navigate(`/roles/${role.id}`, { state: { role } });
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 290,
      renderCell: (params) => {
        const getRole = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button color="warning" onClick={() => handleView(getRole())}>
                {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
              </Button>
              <Button color="secondary" onClick={() => handleEdit(getRole())}>
                {t("global.table.edit_button")} <EditOutlinedIcon />
              </Button>
              <Button color="error" onClick={() => handleClickOpen(getRole())}>
                {t("global.table.delete_button")} <DeleteOutlineOutlinedIcon />
              </Button>
            </ButtonGroup>
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: t("global.form.label.name"),
      flex: 1,
      minWidth: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("roles.form.label.description"),
      flex: 1.5,
      minWidth: 150,
    },
    {
      field: "guard_name",
      headerName: t("roles.form.label.guard_name"),
      flex: 0.5,
      minWidth: 50,
    },
  ];

  return isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("roles.index.title")}
        subtitle={t("roles.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">Home</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          Roles
        </Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/roles/add" underline="none">
          <button className="btn btn-primary">
            {t("roles.index.add_button")}
          </button>
        </Link>
      </Box>
      <Box className="data_grid_box_container">
        {roles?.length < 1 ? (
          <Alert severity="info">{t("roles.index.no_roles_data")}</Alert>
        ) : (
          <StyledDataGrid
            rows={roles}
            columns={columns}
            pagination
            paginationMode="server"
            pageSize={pagination.perPage}
            rowCount={pagination.total}
            onFilterModelChange={handleFilterModelChange}
            loading={isFetching}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={default_rows_per_page_options}
          />
        )}
      </Box>
      {openDialog && (
        <DeleteDialog
          open={openDialog}
          onClose={handleClose}
          name={JSON.parse(selectedRole).title}
          deleteMethod={deleteRole}
          url={`/role/delete/${JSON.parse(selectedRole).id}`}
        />
      )}
    </Box>
  );
};

export default Roles;
