import React, { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useAddEntitlementCriteriaMutation } from "../../../features/entitlement_criteria/entitlementCriteriaApiSlice";
import { useGetEntitlementTypesQuery } from "../../../features/entitlement_types/entitlementTypesApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const initialValues = {
  entitlement_type_id: "",
  title: "",
  description: "",
  selection_criteria: "",
  formula: "",
};

const AddEntitlementCriteria = () => {
  const { t } = useTranslation();
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const entitlementCriteriaSchema = yup.object().shape({
    entitlement_type_id: yup.string().required(t('global.form.helpertext.entitlement_type')),
    title: yup.string().required(t('criteria.form.helpertext.title')),
    description: yup.string().required(t('criteria.form.helpertext.description')),
    selection_criteria: yup.string().required(t('criteria.form.helpertext.criteria')),
    formula: yup.string().required(t('criteria.form.helpertext.formula')),
  });
  const navigate = useNavigate();
  const { data, isLoading: fetchingEntitlementTypes } =
    useGetEntitlementTypesQuery({ page: 1, pageSize: default_rows_per_page });
  const entitlementTypes = data?.data;
  const [addEntitlementCriteria, { isLoading }] =
    useAddEntitlementCriteriaMutation();

  const handleFormSubmit = async (values) => {
    try {
      const response = await addEntitlementCriteria(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
        function () {
          navigate("/interventions/entitlement-criteria", { state: { message: response?.message } });
        },
        3000
      );

    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('criteria.add.title')}
        subtitle={t('criteria.add.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t('sidebar.Interventions')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions/entitlement-criteria">
          <Typography variant="body2">{t('criteria.form.label.eligibility_criteria')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('criteria.index.add_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={entitlementCriteriaSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingEntitlementTypes ? (
                <p>{t('global.loading.type')}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t('global.form.helpertext.entitlement_type')}
                  name="entitlement_type_id"
                  value={values.entitlement_type_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={
                    !!touched.entitlement_type_id &&
                    !!errors.entitlement_type_id
                  }
                  helperText={
                    touched.entitlement_type_id && errors.entitlement_type_id
                  }
                >
                  {entitlementTypes.map((entitlementType) => (
                    <MenuItem
                      key={entitlementType.id}
                      value={entitlementType.id}
                    >
                      {entitlementType.type}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('criteria.form.label.title')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('criteria.form.label.criteria')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.selection_criteria}
                name="selection_criteria"
                error={
                  !!touched.selection_criteria && !!errors.selection_criteria
                }
                helperText={
                  touched.selection_criteria && errors.selection_criteria
                }
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={4}
                type="text"
                label={t('global.form.label.description')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('criteria.form.label.formula')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.formula}
                name="formula"
                error={!!touched.formula && !!errors.formula}
                helperText={touched.formula && errors.formula}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link
                  to="/interventions/entitlement-criteria"
                  underline="none"
                >
              <button className="btn btn-danger">
                
                  {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('criteria.add.create_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddEntitlementCriteria;
