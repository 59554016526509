import { useMap } from "react-leaflet";
import L from "leaflet";

const ZoomToPolygon = ({ farm }) => {
  const map = useMap();

  const polygonCoordinates = farm?.plot_polygon_geometry?.coordinates;
  // Initialize bounds with the first coordinate
  let bounds = L.latLngBounds(polygonCoordinates[0]);

  // Extend the bounds to include all other coordinates
  for (let i = 1; i < polygonCoordinates.length; i++) {
    bounds.extend(polygonCoordinates[i]);
  }

  map.flyToBounds(bounds, {animate: true, duration: 1.5 });
};

export default ZoomToPolygon;
