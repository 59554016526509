import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Radio,
  NativeSelect,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledFieldContainer = styled("div")({
  padding: "10px",
  border: "1px solid #ccc",
  marginBottom: "10px",
  borderRadius: "4px",
  backgroundColor: "#f5f5f5",
});

const DynamicForm = ({ initialForm }) => {
  const [form, setForm] = useState(null);
  useEffect(() => {
    setForm(initialForm);
  }, [initialForm]);

  console.log(form);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedFields = [...form.fields];
    const [movedField] = updatedFields.splice(result.source.index, 1);
    updatedFields.splice(result.destination.index, 0, movedField);

    setForm({ ...form, fields: updatedFields });
  };

  if (initialForm === null || initialForm === undefined) {
    return <div>No form data available.</div>;
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="form-fields" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {form?.fields.map((field, index) => (
              <Draggable key={field.key} draggableId={field.key} index={index}>
                {(provided) => (
                  <StyledFieldContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <FormControl component="fieldset">
                      <Typography variant="subtitle1" gutterBottom>
                        {field.label}
                      </Typography>
                      {field.type === "checkbox" && (
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Checkbox Label"
                        />
                      )}
                      {field.type === "radio" && (
                        <>
                          {field.choices.map((choice) => (
                            <FormControlLabel
                              key={choice}
                              value={choice}
                              control={<Radio />}
                              label={choice}
                            />
                          ))}
                        </>
                      )}
                      {field.type === "select" && (
                        <NativeSelect>
                          {field.choices.map((choice) => (
                            <option key={choice} value={choice}>
                              {choice}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </FormControl>
                  </StyledFieldContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DynamicForm;
