import React, { useState, useEffect } from "react";
import Header from "../../Header";
import { Box, TextField, Button, Typography, Breadcrumbs } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useAddDistributionPointMutation } from "../../../features/distribution_points/distributionPointsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const initialValues = {
  title: "",
  description: "",
  location: "",
};

const AddDistributionPoint = () => {
  const { t } = useTranslation();
  const distributionPointsSchema = yup.object().shape({
    title: yup
      .string()
      .required(t("distribution_points.form.helpertext.name"))
      .min(3, t("global.form.helpertext.min_value")),
    description: yup
      .string()
      .required(t("distribution_points.form.helpertext.description")),
    location: yup
      .string()
      .required(t("distribution_points.form.helpertext.location")),
  });
  const navigate = useNavigate();

  const [addDistributionPoint, { isLoading }] =
    useAddDistributionPointMutation();

  const handleFormSubmit = async (values) => {
    try {
      const response = await addDistributionPoint(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(function () {
        navigate("/distribution-points", {
          state: { message: response?.message },
        });
      }, 3000);
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("distribution_points.add.title")}
        subtitle={t("distribution_points.add.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/distribution-points">
          <Typography variant="body2">
            {t("sidebar.Distribution Points")}
          </Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("distribution_points.index.add_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={distributionPointsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("global.form.label.name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={3}
                type="text"
                label={t("global.form.label.description")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("distribution_points.form.label.location")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.location}
                name="location"
                error={!!touched.location && !!errors.location}
                helperText={touched.location && errors.location}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Link
                style={{ textDecoration: "none" }}
                to="/distribution-points"
                underline="none"
              >
                <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("distribution_points.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddDistributionPoint;
