import React, { useState } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useUpdateSeasonMutation } from "../../../features/seasons/seasonsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditSeason = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const season = location.state.season;

  const initialValues = {
    title: season.title,
    code: season.code,
    type: season.type,
  };

  const seasonsSchema = yup.object().shape({
    title: yup.string().required(t("seasons.form.helpertext.title")),
    code: yup.string().required(t("seasons.form.helpertext.code")),
    type: yup.string().required(t("seasons.form.helpertext.type")),
  });

  const [startDate, setStartDate] = useState(season.start_date);
  const [endDate, setEndDate] = useState(season.end_date);

  const [updateSeason, { isLoading }] = useUpdateSeasonMutation();

  const handleFormSubmit = async (values) => {
    values = {
      ...values,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      id: season.id,
    };

    try {
      const response = await updateSeason(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
          function () {
            navigate("/interventions/seasons", { state: { message: response?.message } });
          },
          3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('seasons.edit.title')}
        subtitle={t('seasons.edit.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link  style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link  style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link  style={LinkColor()} color="inherit" to="/interventions/seasons">
          <Typography variant="body2">{t("sidebar.Seasons")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('seasons.edit.edit_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={seasonsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('global.form.label.name')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('seasons.form.label.code')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                name="code"
                error={!!touched.code && !!errors.code}
                helperText={touched.code && errors.code}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('seasons.form.label.type')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                name="type"
                error={!!touched.type && !!errors.type}
                helperText={touched.type && errors.type}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('project.shared.start_date')}
                  value={startDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setStartDate(Date.parse(newValue));
                  }}
                  formatDate={(startDate) =>
                    moment(startDate).format("DD-MM-YYYY")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('project.shared.end_date')}
                  value={endDate}
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue) => {
                    setEndDate(Date.parse(newValue));
                  }}
                  formatDate={(startDate) =>
                    moment(startDate).format("DD-MM-YYYY")
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/interventions/seasons" underline="none">

              <button className="btn btn-danger">
                  {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('seasons.edit.edit_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default EditSeason;
