import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  Breadcrumbs,
  Alert,
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

import {
  useCreateKoboPreloadMutation,
  useGetKoboFormColumnsQuery,
} from "../../../features/kobo/koboApiSlice";
import Spinner from "../../global/Spinner";
import FieldSelector from "./includes/FieldSelector";
import axios from "../../../api/axios";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import Header from "../../Header";
import Toast from "../../global/Toast";
import { LinkColor } from "../../../theme";

const CreatePreload = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [beneficiariesColumns, setBeneficiariesColumns] = useState([]);
  const [beneficiariesExtraColumns, setBeneficiariesExtraColumns] = useState(
    []
  );
  const [selectedFields, setSelectedFields] = useState([
    "system_id",
    "beneficiary_unique_id",
  ]);
  const [generatingPreload, setGeneratingPreload] = useState(false);
  const token = useSelector(selectCurrentToken);

  // Fetch kobo form beneficiaries columns
  const { data, isLoading, isSuccess } = useGetKoboFormColumnsQuery(id);

  useEffect(() => {
    if (data?.status === 200) {
      setBeneficiariesColumns(data?.beneficiary_columns);
      setBeneficiariesExtraColumns(data?.beneficiary_extra_columns);
    }
  }, [data, isLoading, isSuccess]);

  const handleFieldToggle = (fields) => {
    setSelectedFields((prevSelectedFields) => [
      ...new Set([...prevSelectedFields, ...fields]),
    ]);
  };

  const handleExtraFieldToggle = (fields) => {
    setSelectedFields((prevSelectedFields) => [
      ...new Set([...prevSelectedFields, ...fields]),
    ]);
  };

  const [createKoboPreload] = useCreateKoboPreloadMutation();

  const handleGeneratePreload = async () => {
    setGeneratingPreload(true);

    // Every preload must have system id and beneficiary unique id
    const mustHaveFields = ["system_id", "beneficiary_unique_id"];
    mustHaveFields.forEach((key) => {
      setSelectedFields((prevSelectedFields) => [
        ...new Set([...prevSelectedFields, ...mustHaveFields]),
      ]);
    });

    const payload = {
      id: id,
      fields: selectedFields,
    };

    try {
      const response = await createKoboPreload(payload).unwrap();
      if (response.status === 200) {
        setGeneratingPreload(false);
        Toast(response?.message, "success");
      }
      setTimeout(function () {
        navigate(`/data/kobo/form/preloads/${id}`);
      }, 3000);
    } catch (err) {
      setGeneratingPreload(false);
      Toast(err?.data?.message, "error");
    }
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    border: "1px solid #ccc",
    borderBottomStyle: "dotted",
    padding: "20px",
  };

  const boxStyle = {
    borderBottom: "1px dashed #ccc",
    flex: "1",
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("kobo.preloads.title")}
        subtitle={t("kobo.preloads.subtitle")}
      />
      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/data/kobo">
          <Typography variant="body2">{t("sidebar.Kobo")}</Typography>
        </Link>
        <Link
          style={LinkColor()}
          color="inherit"
          to={`/data/kobo/form/preloads/${id}`}
        >
          <Typography variant="body2">{t("kobo.preloads.preloads")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("kobo.preloads.create_preload")}
        </Typography>
      </Breadcrumbs>
      {beneficiariesColumns?.length <  1 || beneficiariesExtraColumns?.length <  1 ? (
        <Alert severity="info">{t("kobo.preloads.no_columns")}</Alert>
      ) : (
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Container sx={{ marginTop: "20px" }}>
            <Box style={containerStyle}>
              <Box style={{ ...boxStyle, borderRight: "1px dashed #ccc" }}>
                <FieldSelector
                  columns={beneficiariesColumns}
                  onFieldToggle={handleFieldToggle}
                  fieldLabels={t("kobo.preloads.beneficiary_columns")}
                />
              </Box>
              <Box style={boxStyle}>
                <FieldSelector
                  columns={beneficiariesExtraColumns}
                  onFieldToggle={handleExtraFieldToggle}
                  fieldLabels={t("kobo.preloads.kobo_columns")}
                />
              </Box>
            </Box>
            <div>
              <Typography variant="h6" gutterBottom>
                {t("kobo.preloads.selected_fields")}:
              </Typography>
              <ul>
                {selectedFields.map((field) => (
                  <li key={field}>{field}</li>
                ))}
              </ul>
            </div>
          </Container>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleGeneratePreload}
              disabled={generatingPreload}
            >
              {generatingPreload
                ? t("kobo.preloads.generating_preload")
                : t("kobo.preloads.generate_preload")}
            </Button>
          </Box>
        </Paper>
      )}
    </Box>
  );

  return content;
};

export default CreatePreload;
