import { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useAddInterventionMutation } from "../../../features/interventions/interventionsApiSlice";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useGetEntitlementTypesQuery } from "../../../features/entitlement_types/entitlementTypesApiSlice";
import { LinkColor } from "../../../theme";

const initialValues = {
  project_id: "",
  title: "",
  description: "",
  entitlement_type_id: "",
  copay_upfront: "no",
};

const AddIntervention = () => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState();
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const interventionSchema = yup.object().shape({
    project_id: yup
      .string()
      .required(t("intervention.form.helpertext.project")),
    title: yup.string().required(t("intervention.form.helpertext.title")),
    description: yup
      .string()
      .required(t("intervention.form.helpertext.description")),
    entitlement_type_id: yup
      .string()
      .required(t("entitlement_types.form.helpertext.type")),
    copay_upfront: yup
      .string()
      .required(t("intervention.form.helpertext.copay_upfront")),
  });

  const navigate = useNavigate();
  const { data, isLoading: fetchingProjects } = useGetProjectsQuery({
    page: 1,
    pageSize: default_rows_per_page,
  });
  const projects = data?.data;
  // Fetch available entitlement types
  const [entitlementTypes, setEntitlementTypes] = useState([]);
  const {
    data: entitlementTypeData,
    isLoading: fetchingEntitlementTypes,
    isSuccess,
  } = useGetEntitlementTypesQuery({
    page: 1,
    pageSize: default_rows_per_page,
  });

  useEffect(() => {
    if (entitlementTypeData?.data)
      setEntitlementTypes(entitlementTypeData?.data);
  }, [entitlementTypeData, isSuccess, fetchingEntitlementTypes]);

  const [addIntervention, { isLoading }] = useAddInterventionMutation();

  const handleFormSubmit = async (values) => {
    const payload = {
      ...values,
      copay_upfront: values.copay_upfront === "yes" ? true : false,
    };

    try {
      const response = await addIntervention(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(function () {
        navigate("/interventions", { state: { message: response?.message } });
      }, 3000);
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("intervention.add.title")}
        subtitle={t("intervention.add.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("intervention.index.add_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={interventionSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingProjects ? (
                <p>{t("global.loading.project")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.project")}
                  name="project_id"
                  value={values.project_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.project_id && !!errors.project_id}
                  helperText={touched.project_id && errors.project_id}
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.code} - {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("global.table.name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={4}
                type="text"
                label={t("global.table.description")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />

              {fetchingEntitlementTypes ? (
                <p>{t("global.loading.entitlement_types")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.entitlement_type")}
                  name="entitlement_type_id"
                  value={values.entitlement_type_id}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    const selectedType = entitlementTypes.find(
                      (type) => type.id === e.target.value
                    );
                    setSelectedType(selectedType ? selectedType.type : "");
                  }}
                  error={
                    !!touched.entitlement_type_id &&
                    !!errors.entitlement_type_id
                  }
                  helperText={
                    touched.entitlement_type_id && errors.entitlement_type_id
                  }
                >
                  {entitlementTypes.map((entitlementType) => (
                    <MenuItem
                      key={entitlementType.id}
                      value={entitlementType.id}
                    >
                      {entitlementType.type}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {selectedType &&
                selectedType !== "CASH" &&
                selectedType !== "IN-KIND" && (
                  <div role="group" aria-labelledby="my-radio-group">
                    <p id="my-radio-group">
                      {t("intervention.form.label.copay_upfront")}
                    </p>
                    <label>
                      <Field
                        type="radio"
                        name="copay_upfront"
                        value="yes"
                        onChange={handleChange}
                      />
                      {t("intervention.form.label.copay_upfront_yes")}
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="copay_upfront"
                        value="no"
                        onChange={handleChange}
                      />
                      {t("intervention.form.label.copay_upfront_no")}
                    </label>
                  </div>
                )}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Link to="/interventions" underline="none">
                <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("intervention.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddIntervention;
