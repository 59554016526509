import { apiSlice } from "../../api/apiSlice";

export const entitlementsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Entitlements", "SPEntitlements"],
  endpoints: (builder) => ({
    addEntitlement: builder.mutation({
      query: (payload) => ({
        url: "/entitlement/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Entitlements"],
    }),
    getEntitlements: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
          const queryString = new URLSearchParams({
              ...apiFilters,
              page: page,
              pageSize: pageSize
          }).toString();

          return {
              url: `/entitlement/list/${pageSize}?${queryString}`,
              method: "GET",
          };
      },
      providesTags: ["Entitlements"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateEntitlement: builder.mutation({
      query: (payload) => ({
        url: `/entitlement/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Entitlements"],
    }),
    deleteEntitlement: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Entitlements"],
    }),
    getUserEntitlements: builder.query({
      query: (id) => ({
        url: `/entitlement/user/${id}`,
        method: "GET",
      }),
      providesTags: ["SPEntitlements"],
      keepUnusedDataFor: 1
    }),
    addUserEntitlement: builder.mutation({
      query: (payload) => ({
        url: "/entitlement/user/price",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Entitlements", "ServiceProviders", "SPEntitlements"],
    }),
  }),
});

export const {
  useAddEntitlementMutation,
  useGetEntitlementsQuery,
  useUpdateEntitlementMutation,
  useDeleteEntitlementMutation,
  useGetUserEntitlementsQuery,
  useAddUserEntitlementMutation,
} = entitlementsApiSlice;
