import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetRoleInterventionsQuery } from "../../../features/roles/rolesApiSlice";
import {
  Box,
  Alert,
  AlertTitle,
  Typography,
  Breadcrumbs,
  Button,
} from "@mui/material";
import StyledDataGrid from "../../global/StyledDataGrid";
import { LinkColor } from "../../../theme";
import Header from "../../Header";
import { ToastContainer } from "react-toastify";
import Spinner from "../../global/Spinner";
import { useDeleteRoleInterventionMutation } from "../../../features/interventions/interventionsApiSlice";
import Toast from "../../global/Toast";

export default function RemoveRoleIntervention() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  // Default values from environment variables
  const defaultRowsPerPage = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const defaultRowsPerPageOptions =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [presentInterventions, setPresentInterventions] = useState([]);
  const [selectedInterventionIds, setSelectedInterventionIds] = useState([]);

  // Fetch present interventions
  const {
    data: interventionData,
    isLoading: isLoadingIntervention,
    isSuccess: isSuccessIntervention,
  } = useGetRoleInterventionsQuery(id);

  useEffect(() => {
    if (isSuccessIntervention && interventionData?.interventions) {
      setPresentInterventions(interventionData.interventions);
    }
  }, [interventionData, isSuccessIntervention]);

  // Selection handler
  const handleSelectionChange = (ids) => {
    setSelectedInterventionIds(ids);
  };

  // Remove intervention from role
  const [removeIntervention] = useDeleteRoleInterventionMutation();

  const handleRemove = async () => {
    if (selectedInterventionIds.length === 0) {
      // Handle case where no roles are selected
      return;
    }

    const payload = {
      role_id: id,
      intervention_ids: selectedInterventionIds,
    };

    try {
      const response = await removeIntervention(payload).unwrap();
      if (response.status === 200) {
        navigate(`/roles/${id}`);
      } else {
        throw new Error(response?.message || "Unexpected response from server");
      }
    } catch (err) {
      Toast(err?.data?.message || "Failed to remove interventions", "error");
    }
  };

  // Define columns for the data grid
  const interventionColumns = [
    {
      field: "title",
      headerName: t("global.table.name"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.table.description"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "copay_upfront",
      headerName: t("intervention.shared.copay_upfront"),
      valueGetter: (params) => (params.row?.copay_upfront ? "Yes" : "No"),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "project title",
      headerName: t("project.shared.project_name"),
      valueGetter: (params) => params?.row?.project?.title,
      flex: 1,
      minWidth: 100,
    },
  ];

  // Component content
  return isLoadingIntervention ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("roles.remove_interventions.title")}
        subtitle={t("roles.remove_interventions.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/roles">
          <Typography variant="body2">{t("sidebar.Roles")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("roles.remove_interventions.view_role")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("roles.remove_interventions.alert_head")}</AlertTitle>
        {t("roles.remove_interventions.alert_message")}
      </Alert>

      <Box marginTop="20px">
        <button
          className={
            !selectedInterventionIds.length
              ? "btn btn-disabled"
              : "btn btn-primary"
          }
          onClick={handleRemove}
          disabled={!selectedInterventionIds.length}
        >
          {t("global.buttons.remove_intervention")}
        </button>
      </Box>

      <Box className="data_grid_box_container" marginTop="20px">
        <StyledDataGrid
          checkboxSelection
          rows={presentInterventions}
          columns={interventionColumns}
          rowsPerPageOptions={defaultRowsPerPageOptions}
          onSelectionModelChange={handleSelectionChange}
        />
      </Box>
    </Box>
  );
}
