import { useState, useEffect } from "react";
import { useGetOverlapsQuery } from "../../../../features/farms/farmsApiSlice";
import Spinner from "../../../global/Spinner";
import OverlapsContainer from "./OverlapsContainer";
import Overlap2 from "./Overlap2";

const Overlaps = () => {
  const [overlaps, setOverlaps] = useState([]);

  const { data, isLoading, isSuccess } = useGetOverlapsQuery();

  useEffect(() => {
    if (data?.data) setOverlaps(data?.data);
  }, [data, isLoading, isSuccess]);

  const content = isLoading ? (
    <Spinner />
  ) : (
    // <OverlapsContainer overlaps={overlaps} />
    <Overlap2 data={overlaps} />
  );

  return content;
};

export default Overlaps;
