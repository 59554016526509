import { apiSlice } from "../../api/apiSlice";

export const cardsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Cards", "Card", "CardCopayTransactions", "CardCashTransactions", "CardEvoucherTransactions", "CardBeneficiaries"],
  endpoints: (builder) => ({
    getCards: builder.query({
      query: ({ page, pageSize }) => ({
        url: `/cards/list/${pageSize}?page=${page}`,
        method: "GET",
      }),
      providesTags: ["Cards"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getCard: builder.query({
      query: (id) => ({
        url: `/cards/single/${id}`,
        method: "GET",
      }),
      providesTags: ["Card"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateCard: builder.mutation({
      query: (payload) => ({
        url: `/cards/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Cards", "Card", "CardEvents"],
    }),
    blockCard: builder.mutation({
      query: (payload) => ({
        url: `/cards/block/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Cards", "Card", "CardEvents"],
    }),
    unBlockCard: builder.mutation({
      query: (payload) => ({
        url: `/cards/unblock/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Cards", "Card", "CardEvents"],
    }),
    deleteCard: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Cards", "Card", "CardEvents"],
    }),
    getCardBeneficiaries: builder.query({
      query: (id) => ({
        url: `/cards/beneficiaries/${id}`,
        method: "GET",
      }),
      providesTags: ["CardBeneficiaries"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getCardCopayTransactions: builder.query({
      query: (id) => ({
        url: `/cards/copay/${id}`,
        method: "GET",
      }),
      providesTags: ["CardCopayTransactions"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getCardCashTransactions: builder.query({
      query: (id) => ({
        url: `/cards/cash/${id}`,
        method: "GET",
      }),
      providesTags: ["CardCashTransactions"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getCardEvoucherTransactions: builder.query({
      query: (id) => ({
        url: `/cards/evoucher/${id}`,
        method: "GET",
      }),
      providesTags: ["CardEvoucherTransactions"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getCardInkindTransactions: builder.query({
      query: (id) => ({
        url: `/cards/inkind/${id}`,
        method: "GET",
      }),
      providesTags: ["CardInkindTransactions"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getCardEvents: builder.query({
      query: (id) => ({
        url: `/cards/events/${id}`,
        method: "GET",
      }),
      providesTags: ["CardEvents"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const {
  useGetCardsQuery,
  useGetCardQuery,
  useUpdateCardMutation,
  useBlockCardMutation,
  useUnBlockCardMutation,
  useDeleteCardMutation,
  useGetCardBeneficiariesQuery,
  useGetCardCopayTransactionsQuery,
  useGetCardCashTransactionsQuery,
  useGetCardEvoucherTransactionsQuery,
  useGetCardInkindTransactionsQuery,
  useGetCardEventsQuery,
} = cardsApiSlice;
