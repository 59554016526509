import { apiSlice } from "../../api/apiSlice";

export const changePasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    changePassword: builder.mutation({
      query: (payload) => ({
        url: "/settings/password/change",
        method: "POST",
        body: { ...payload },
      }),
    }),
  }),
});

export const { useChangePasswordMutation } = changePasswordApiSlice;
