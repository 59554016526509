import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs,
  Popover,
  Chip,
  Stack, Alert
} from "@mui/material";
import Header from "../../Header";
import { useNavigate, Link } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  useGetBeneficiariesQuery,
} from "../../../features/beneficiaries/beneficiariesApiSlice";
import Spinner from "../../global/Spinner";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useGetBeneficiaryColumnsQuery } from "../../../features/beneficiaries/beneficiariesApiSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../api/axios";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import moment from "moment";
import { getSegment } from "../../../utils/stringUtils";
import { LinkColor } from "../../../theme";

import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const Beneficiaries = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canExport = currentPermissions.some(permission => permission.name === 'beneficiary-export-pdf');
  const canEdit = currentPermissions.some(permission => permission.name === 'beneficiary-update');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);

  const [downloadingPdf, setDownloadingPdf] = useState(false);

  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const [beneficiariesData, setBeneficiariesData] = useState([]);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);

  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });

    return apiFilters;
  };

  // Get the beneficiaries from the API
  const { data, isLoading, isSuccess, isFetching } = useGetBeneficiariesQuery({
    page,
    pageSize,
    ...getApiFilters(),
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "export-popover" : undefined;

  useEffect(() => {
    if (data?.data) setBeneficiariesData(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const handleView = (beneficiary) => {
    beneficiary = JSON.parse(beneficiary);
    navigate(`/beneficiaries/${beneficiary.id}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePDFExport = () => {
    setDownloadingPdf(true);

    const url = `${process.env.REACT_APP_BASE_URL}/beneficiaries/generate/pdf`;

    axios
      .post(url, null, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `IDEA Beneficiaries List as at ${moment().format('YYYYMMDD_HHmmss')}.pdf`;
        a.click();
        setDownloadingPdf(false);
      })
      .catch((error) => {
        setDownloadingPdf(false);
      });
  };

  const handleExcelExport = () => {
    setDownloadingExcel(true);
    const url = `${process.env.REACT_APP_BASE_URL}/beneficiaries/export/excel`;

    axios
      .post(url, null, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `IDEA Beneficiary Card Presso Export_${moment().format("YYYYMMDD_HHmmss")}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        setDownloadingExcel(false);
      })
      .catch((error) => {
        setDownloadingExcel(false);
      });
  };

  const handleMetroBankExcelExport = () => {
    setDownloadingExcel(true);
    const url = `${process.env.REACT_APP_BASE_URL}/beneficiaries/export/metrobank/excel`;

    axios
      .post(url, null, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `IDEA Metro Bank Report as at ${moment().format(
          "YYYYMMDD_HHmmss"
        )}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        setDownloadingExcel(false);
      })
      .catch((error) => {
        setDownloadingExcel(false);
      });
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const getBeneficiary = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="warning"
                onClick={() => handleView(getBeneficiary())}
              >
                {t("global.table.view_button")} <RemoveRedEyeOutlinedIcon />
              </Button>
            </ButtonGroup>
          </Box>
        );
      },
    },
    {
      field: "full_name",
      headerName: t("beneficiaries.table.fullname"),
      flex: 1,
      minWidth: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "unique_id",
      headerName: t("beneficiaries.table.unique_id"),
      valueGetter: (params) => getSegment(params.row?.unique_id, "-", -1),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      valueGetter: (params) => params.row?.telephone,
      minWidth: 150,
    },
    {
      field: "date_of_birth",
      headerName: t("beneficiaries.table.dob"),
      valueGetter: (params) => params.row?.date_of_birth,
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "code",
      headerName: t("project.form.label.code"),
      valueGetter: (params) => params?.row?.intervention[0]?.project?.code,
      minWidth: 200,
    },
    {
      field: "intervention",
      headerName: t("intervention.form.label.intervention"),
      valueGetter: (params) =>
        params.row.intervention[0]?.title
          ? params.row?.intervention[0]?.title
          : "",
      minWidth: 200,
    },
    {
      field: "Distribution Point",
      headerName: t("distribution_points.form.label.distribution_point"),
      valueGetter: (params) =>
        params.row.distribution_point?.title
          ? params.row?.distribution_point?.title
          : "",
      minWidth: 300,
    },
    {
      field: "Status",
      headerName: t("beneficiaries.table.status"),
      valueGetter: (params) => params.row?.status,
      minWidth: 200,
    },
    {
      field: "Kobo ID",
      headerName: t("beneficiaries.table.kobo_id"),
      valueGetter: (params) => params?.row?.extra?.kobo_id,
      minWidth: 200,
    },
    {
      field: "geo_level_1",
      headerName: t("beneficiaries.table.geolevel1"),
      valueGetter: (params) => params.row?.geo_level_1,
      minWidth: 200,
    },
    {
      field: "geo_level_2",
      headerName: t("beneficiaries.table.geolevel2"),
      valueGetter: (params) => params.row?.geo_level_2,
      minWidth: 200,
    },
    {
      field: "geo_level_3",
      headerName: t("beneficiaries.table.geolevel3"),
      valueGetter: (params) => params.row?.geo_level_3,
      minWidth: 200,
    },
    {
      field: "geo_level_4",
      headerName: t("beneficiaries.table.geolevel4"),
      valueGetter: (params) => params.row?.geo_level_4,
      minWidth: 200,
    },
    {
      field: "geo_level_5",
      headerName: t("beneficiaries.table.geolevel5"),
      valueGetter: (params) => params.row?.geo_level_5,
      minWidth: 200,
    },
    {
      field: "geo_level_6",
      headerName: t("beneficiaries.table.geolevel6"),
      valueGetter: (params) => params.row?.geo_level_6,
      minWidth: 200,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("beneficiaries.index.title")}
        subtitle={t("beneficiaries.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Beneficiaries")}
        </Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="flex-end" mt="20px">
        {/*Start export button, but only if we have data*/}
        {(beneficiariesData?.length > 0 && canExport) && (
            <>
        <Box display="flex" justifyContent="flex-end" ml="20px">
          <button
            aria-describedby={popoverId}
            onClick={(e) => handleExport(e)}
           className="btn btn-primary"
          >
            {t("global.buttons.export")}
          </button>
        </Box>
            </>
        )}

        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Stack direction="row" spacing={1} sx={{ padding: "10px" }}>
            <Chip
              label={
                downloadingPdf
                  ? t("global.buttons.exporting_pdf")
                  : t("global.buttons.export_pdf")
              }
              onClick={handlePDFExport}
              disabled={downloadingPdf}
              icon={<PictureAsPdfOutlinedIcon />}
              color="info"
            />
            <Chip
              label={
                downloadingExcel
                  ? t("global.buttons.exporting_excel")
                  : t("global.buttons.export_excel")
              }
              onClick={handleExcelExport}
              disabled={downloadingExcel}
              icon={<DocumentScannerOutlinedIcon />}
              color="secondary"
            />
            <Chip
              label={
                downloadingExcel
                  ? t("global.buttons.exporting_metrobank_excel")
                  : t("global.buttons.export_metrobank_excel")
              }
              onClick={handleMetroBankExcelExport}
              disabled={downloadingExcel}
              icon={<DocumentScannerOutlinedIcon />}
              color="secondary"
            />
          </Stack>
        </Popover>
        {/*End export button*/}

        {/*Start import button*/}
        <Box display="flex" justifyContent="flex-end" ml="20px">
          <Link style={{ textDecoration: 'none'}} to="/beneficiaries/import" underline="none">
            <button className="btn btn-primary">
                {t("beneficiaries.index.import_button")}
            </button>
          </Link>
        </Box>
        {/*End import button*/}

        {/*Start import photos button*/}
        <Box display="flex" justifyContent="flex-end" ml="20px">
          <Link style={{ textDecoration: 'none' }} to="/beneficiaries/import/photos" underline="none">
            <button className="btn btn-primary">
                {t("beneficiaries.index.import_photos")}
            </button>
          </Link>
        </Box>
        {/*End import photos button*/}

        {/* only show Edit Beneficiary button if there are beneficiaries*/}
        {/*Start Edit/Sync button(s)*/}
        <Box display="flex" justifyContent="flex-end" ml="20px">
          {beneficiariesData?.length < 1 ? (
              <Link style={{ textDecoration: 'none'}} to="/data/kobo" underline="none">
                <button className="btn btn-primary">
                  {t("beneficiaries.index.sync_button")}
                </button>
              </Link>
          ) : ( canEdit && (
            <Link style={{ textDecoration: 'none' }} to="/beneficiaries/edit" underline="none">
              <button className="btn btn-primary">
                  {t("beneficiaries.index.edit_button")}
              </button>
            </Link>
            )
          )}
        </Box>
        {/*End Edit/Sync button(s)*/}

        {/*Start Map button*/}
        {/*<Box display="flex" justifyContent="flex-end" ml="20px">*/}
        {/* <Button color="secondary" variant="contained" size="small">
              <Link href="/beneficiaries/map" underline="none">
                {t('beneficiaries.index.map_beneficiaries')}
              </Link>
            </Button> */}
        {/*</Box>  */}
        {/*End Map button*/}
      </Box>

      <Box className="data_grid_box_container">
        {beneficiariesData?.length < 1 ? (
            <Alert severity="info">{t("beneficiaries.index.no_beneficiary_data")}</Alert>
        ) : (
            <StyledDataGrid
                rows={beneficiariesData}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pagination.perPage}
                rowCount={pagination.total}
                loading={isFetching}
                onFilterModelChange={handleFilterModelChange}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={default_rows_per_page_options}
            />
        )}
      </Box>
    </Box>
  );

  return content;
};

export default Beneficiaries;
