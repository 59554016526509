import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs, Alert
} from "@mui/material";
import Header from "../../Header";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate, Link} from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  useGetDistributionPointsQuery,
  useDeleteDistributionPointMutation,
} from "../../../features/distribution_points/distributionPointsApiSlice";
import Spinner from "../../global/Spinner";
import DeleteDialog from "../shared/DeleteDialog";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const DistributionPoints = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(permission => permission.name === 'distribution-point-add');
  const canEdit = currentPermissions.some(permission => permission.name === 'distribution-point-update');
  const canDelete = currentPermissions.some(permission => permission.name === 'distribution-point-delete');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDistributionPoint, setSelectedDistributionPoint] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [distributionPoints, setDistributionPoints] = useState([]);

  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
      const apiFilters = {};

      filterModel?.forEach(item => {
        if (item.columnField && item.value) {
          apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
        }
      });

      return apiFilters;
  };

  const { data, isLoading, isSuccess, isFetching } =
    useGetDistributionPointsQuery({
      page,
      pageSize,
      ...getApiFilters(),
    });

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  useEffect(() => {
    if (data?.data) setDistributionPoints(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const [deleteDistributionPoint] = useDeleteDistributionPointMutation();

  const handleEdit = (distributionPoint) => {
    distributionPoint = JSON.parse(distributionPoint);
    navigate(`/distribution-points/edit/${distributionPoint.id}`, {
      state: { distributionPoint },
    });
  };

  const handleView = (distributionPoint) => {
    distributionPoint = JSON.parse(distributionPoint);
    navigate(`/distribution-points/${distributionPoint.id}`, {
      state: { distributionPoint },
    });
  };

  const handleClickOpen = (distributionPoint) => {
    setSelectedDistributionPoint(distributionPoint);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedDistributionPoint(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t('global.table.actions'),
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        const getDistributionPoint = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                <Button
                    color="warning"
                    onClick={() => handleView(getDistributionPoint())}
                >
                  {t('global.table.view_button')} <RemoveRedEyeOutlinedIcon />
                </Button>
                {canEdit && (
                <Button
                    color="secondary"
                    onClick={() => handleEdit(getDistributionPoint())}
                >
                  {t('global.table.edit_button')} <EditOutlinedIcon />
                </Button>
                )}
                {canDelete && (
                <Button
                    color="error"
                    onClick={() => handleClickOpen(getDistributionPoint())}
                >
                  {t('global.table.delete_button')} <DeleteOutlineOutlinedIcon />
                </Button>
                )}
              </ButtonGroup>
              
            </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t('global.table.name'),
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 200,
    },
    { field: "description", headerName: t('global.table.description'), flex: 1, minWidth: 200 },
    { field: "location", headerName: t('distribution_points.form.label.location'), flex: 1, minWidth: 200 }
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t('distribution_points.index.title')}
        subtitle={t('distribution_points.index.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
        {t('sidebar.Distribution Points')}
        </Typography>
      </Breadcrumbs>

      {canAdd && (
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/distribution-points/add" underline="none">
          <button className="btn btn-primary">
            {t('distribution_points.index.add_button')}
          </button>
        </Link>
      </Box>
      )}

      <Box className="data_grid_box_container">
        {distributionPoints?.length < 1 ? (
            <Alert severity="info">{t("distribution_points.index.no_distribution_point_data")}</Alert>
        ) : (
            <StyledDataGrid
                rows={distributionPoints}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pagination.perPage}
                rowCount={pagination.total}
                onFilterModelChange={handleFilterModelChange}
                loading={isFetching}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={default_rows_per_page_options}
            />
        )}
      </Box>
      {openDialog && (
                  <DeleteDialog
                      open={openDialog}
                      onClose={handleClose}
                      name={JSON.parse(selectedDistributionPoint).title}
                      deleteMethod={deleteDistributionPoint}
                      url={`/distributionpoint/delete/${
                          JSON.parse(selectedDistributionPoint).id
                      }`}
                  />
              )}
    </Box>
  );

  return content;
};

export default DistributionPoints;
