import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../features/auth/authSlice";

//const storedLanguage = localStorage.getItem("ideaLanguage");
//const language = storedLanguage === null ? "en" : storedLanguage;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Accept-Language", getState()?.language.selectedLanguage);
    const token = getState()?.auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {

    const refresh_token = api.getState().auth.refresh_token;

    const payload = {
      refresh_token: refresh_token,
    };

    const query = {
      url: "/refresh/token",
      method: "POST",
      body: { ...payload },
    };
    const refreshResult = await baseQuery(query, api, extraOptions);

    if (refreshResult?.data?.access_token) {
      const user = api.getState().auth.user;
      const roles = api.getState().auth.roles;
      const permissions = api.getState().auth.permissions;
      const access_token = refreshResult?.data?.access_token;
      const refresh_token = refreshResult?.data?.refresh_token;
      const expires_in = refreshResult?.data?.expires_in;

      // Store the new token
      api.dispatch(
        setCredentials({
          access_token,
          refresh_token,
          expires_in,
          roles,
          permissions,
          user,
        })
      );

      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
