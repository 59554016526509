import {
  ProgressBar,
} from "react-loader-spinner";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const Spinner = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      m="25% auto"
      display="flex"
      alignItems="center"
      justifyItems="center"
      justifyContent="center"
      to="50px"
    >
       <ProgressBar
        height="80"
        width="80"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass="progress-bar-wrapper"
        borderColor={colors.faoBlue[500]}
        barColor={colors.faoOrange[500]}
      />
    </Box>
  );
};

export default Spinner;
