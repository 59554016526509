import { useState, useEffect } from "react";
import {
  useGetInterventionRolesQuery,
  useAddInterventionRoleMutation,
} from "../../../features/interventions/interventionsApiSlice";
import { useGetRolesQuery } from "../../../features/roles/rolesApiSlice";
import { useParams, useNavigate, Link } from "react-router-dom";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { Box, Alert, AlertTitle, Typography, Breadcrumbs } from "@mui/material";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const AddInterventionRoles = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedRolesIds, setSelectedRolesIds] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [presentRoles, setPresentRoles] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Start Fetching roles in an interventions
  const {
    data: rolesData,
    isLoading: fetchingRoles,
    isSuccess: rolesSuccess,
  } = useGetInterventionRolesQuery({ id, page, pageSize });

  useEffect(() => {
    if (rolesData?.roles) setPresentRoles(rolesData.roles);
  }, [rolesData, rolesSuccess]);

  // End Fetching roles in an interventions

  // start Fetching all roles
  const {
    data: allRolesData,
    isLoading: fetchingAllRoles,
    isSuccess: allRolesSuccess,
  } = useGetRolesQuery({ id, page, pageSize });

  useEffect(() => {
    if (allRolesData?.data) setAllRoles(allRolesData.data);
  }, [allRolesData, allRolesSuccess]);
  // End Fetching all roles

  // Start Check if the title value of tale matches present role title value
  const checkNameMatch = (name) => {
    return presentRoles.some((role) => role.name === name);
  };
  const getCellClassName = (params) => {
    return checkNameMatch(params.row.name) ? "matched-name-cell" : "";
  };
  // End Check if the title value of tale matches present role title value

  // Start Separate matching and non-matching Roles And Concatenate arrays to place matching Roles at the bottom
  const sortedRoles = () => {
    const matchingRoles = [];
    const nonmatchingRoles = [];

    allRoles.forEach((role) => {
      if (presentRoles.some((present) => present.name === role.name)) {
        matchingRoles.push(role);
      } else {
        nonmatchingRoles.push(role);
      }
    });

    return [...nonmatchingRoles, ...matchingRoles];
  };
  // End Separate matching and non-matching Roles And Concatenate arrays to place matching Roles at the bottom

  // Start Handle add roles to intervention Section
  const [addRoles] = useAddInterventionRoleMutation();

  const selectedRoles = (ids) => {
    setSelectedRolesIds(ids);
  };

  const handleAdd = async () => {
    if (selectedRolesIds.length === 0) {
      // Handle case where no roles are selected
      return;
    }
    const payload = {
      intervention_id: id,
      role_ids: selectedRolesIds,
    };

    try {
      const response = await addRoles(payload).unwrap();
      if (response.status === 200) {
        // Navigate on success
        navigate(`/interventions/${id}`);
      } else {
        // Handle unexpected response status
        throw new Error(response?.message || "Unexpected response from server");
      }
    } catch (err) {
      // Handle network or server errors
      Toast(err?.data?.message, "error");
    }
  };
  // End Handle add roles to intervention section

  // Start Roles columns
  const Rolescolumns = [
    {
      field: "name",
      headerName: t("global.table.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.table.description"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
  ];
  // End Roles columns

  // Start Roles data grid
  const content = fetchingRoles ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("intervention.add_role.title")}
        subtitle={t("intervention.add_role.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to={`/interventions/${id}`}>
          <Typography variant="body2">{t("intervention.view.view")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("global.buttons.add_role")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("intervention.add_role.alert_head")}</AlertTitle>
        {t("intervention.add_role.alert_message")}
      </Alert>

      <Box marginTop={"20px"}>
        <button
          className={
            !selectedRolesIds.length ? "btn btn-disabled" : "btn btn-primary"
          }
          onClick={handleAdd}
          disabled={!selectedRolesIds.length}
        >
          {t("global.buttons.add_role")}
        </button>
      </Box>
      <Box className="data_grid_box_container">
        <StyledDataGrid
          checkboxSelection
          rows={sortedRoles()}
          columns={Rolescolumns}
          rowsPerPageOptions={default_rows_per_page_options}
          onSelectionModelChange={(ids) => selectedRoles(ids)}
          getCellClassName={(params) => getCellClassName(params)}
          disableSelectionOnClick={(params) => checkNameMatch(params.row.name)}
        />
      </Box>
    </Box>
  );

  return content;
};

export default AddInterventionRoles;
