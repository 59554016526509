import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@mui/material";
import * as yup from "yup";

import { useMarkDuplicateMutation } from "../../../../features/biometric_deduplication/nonBiometricDeuplicationApiSlice";
import Toast from "../../../global/Toast";
import { Formik } from "formik";
import ImageComparisonModal from "../../shared/ImageComparisonModal";

export const BioInformation = ({
  selectedUser,
  possibleDuplicate,
  onMarkedDuplicate,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getCellClassName = (field) => {
    return selectedUser?.[field] === possibleDuplicate?.[field] ? "match" : "";
  };
  const [isLoading, setIsLoading] = useState(false);

  const [showSelectedComment, setShowSelectedComment] = useState(false);
  const [showPossibleComment, setShowPossibleComment] = useState(false);

  const [showComment, setShowComment] = useState(false);
  const [markDuplicate] = useMarkDuplicateMutation();

  const initialValues = {
    comment: "",
  };
  const commentSchema = yup.object().shape({
    comment: yup.string().required("required"),
  });
  const [payload, setPayload] = useState({
    beneficiary_id: "",
    duplicate_id: "",
    duplicate: false,
    comment: "",
  });

  const handleDuplicate = async ({
    beneficiary_id,
    duplicate_id,
    duplicate,
  }) => {
    setShowComment(true);
    setPayload({
      beneficiary_id: beneficiary_id,
      duplicate_id: duplicate_id,
      duplicate: duplicate,
    });
  };
  const handleSubmit = async (values) => {
    const data = { ...payload, comment: values.comment.trim() };

    try {
      setIsLoading((prev) => !prev);
      const response = await markDuplicate(data).unwrap();
      if (response?.status === 200) {
        setShowComment((prev) => !prev);
        onMarkedDuplicate(undefined);
        setIsLoading((prev) => !prev);
      } else {
        Toast(response?.message, "error");
        setIsLoading((prev) => !prev);
      }
    } catch (err) {
      Toast(err?.data?.message, "error");
      setIsLoading((prev) => !prev);
    }
  };
  const [images, setImages] = useState({
    image1: selectedUser?.photo,
    image2: possibleDuplicate?.photo,
    beneficiary1: selectedUser?.full_name,
    beneficiary2: possibleDuplicate?.full_name,
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
      <div className="flex">
        <div className="table-container">
          <div className="table-header">
            <div className="table-row">
              <div className="table-cell"></div>
              <div className="table-cell">Selected Beneficiary</div>
              <div className="table-cell">
                {t("biometrics.index.possible_duplicates")}
              </div>
            </div>
          </div>
          <div className="table-body">
            <div className="table-row">
              <div className="table-cell table-cell-title">
                {t("beneficiaries.table.fullname")}:
              </div>
              <div className={`table-cell ${getCellClassName("full_name")}`}>
                {selectedUser?.full_name}
              </div>
              <div className={`table-cell ${getCellClassName("full_name")}`}>
                {possibleDuplicate?.full_name}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell table-cell-title">
                {t("users.form.label.telephone")}:
              </div>
              <div className={`table-cell ${getCellClassName("telephone")}`}>
                {selectedUser?.telephone}
              </div>
              <div className={`table-cell ${getCellClassName("telephone")}`}>
                {possibleDuplicate?.telephone}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell table-cell-title">
                {t("beneficiaries.table.id_number")}:
              </div>
              <div className={`table-cell ${getCellClassName("id_number")}`}>
                {selectedUser?.id_number}
              </div>
              <div className={`table-cell ${getCellClassName("id_number")}`}>
                {possibleDuplicate?.id_number}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell table-cell-title">
                {t("beneficiaries.table.pin")}:
              </div>
              <div className={`table-cell ${getCellClassName("pin_code")}`}>
                {selectedUser?.pin_code}
              </div>
              <div className={`table-cell ${getCellClassName("pin_code")}`}>
                {possibleDuplicate?.pin_code}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell table-cell-title">
                {t("biometrics.table.biometrics_type")}:
              </div>
              <div className="table-cell">
                {selectedUser?.biometrics_type.length < 1
                  ? "No Biometrics"
                  : selectedUser?.biometrics_type}
              </div>
              <div className="table-cell">
                {possibleDuplicate?.biometrics_type.length < 1
                  ? "No Biometrics"
                  : possibleDuplicate?.biometrics_type}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell table-cell-title">
                {t("beneficiaries.table.status")}:
              </div>
              <div className={`table-cell ${getCellClassName("status")}`}>
                {selectedUser?.status}
              </div>
              <div className={`table-cell ${getCellClassName("status")}`}>
                {possibleDuplicate?.status}
              </div>
            </div>
          </div>
        </div>
        <div className="table-container">
          <div className="table-header">
            <div className="table-row">
              <div className="table-cell table-image-container">
                Selected Beneficiary
              </div>
              <div className="table-cell table-image-container">
                {t("biometrics.index.possible_duplicates")}
              </div>
            </div>
          </div>
          <div className="table-body">
            <div className="table-row">
              <div className="image-placeholder table-cell">
                <img
                  onClick={openModal}
                  style={{
                    width: "333px",
                    height: "222px",
                    objectFit: "cover",
                  }}
                  src={
                    selectedUser?.photo !== null
                      ? selectedUser?.photo
                      : "/assets/img/no-user-icon.webp"
                  }
                  alt="Placeholder"
                />
                {showSelectedComment ? (
                  <Box mt="20px">
                    <Formik
                      onSubmit={handleSubmit}
                      initialValues={initialValues}
                      validationSchema={commentSchema}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            fullWidth
                            variant="filled"
                            multiline
                            rows={3}
                            type="text"
                            label={
                              payload.selectedUser
                                ? t(
                                    "nonbiometrics.index.add_comment_why_duplicate"
                                  )
                                : t(
                                    "nonbiometrics.index.add_comment_why_not_duplicate"
                                  )
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.comment}
                            name="comment"
                            error={!!touched.comment && !!errors.comment}
                            helperText={
                              payload.selectedUser
                                ? t(
                                    "nonbiometrics.index.add_comment_why_duplicate"
                                  )
                                : t(
                                    "nonbiometrics.index.add_comment_why_not_duplicate"
                                  )
                            }
                          />
                          <Box
                            display="flex"
                            justifyContent="end"
                            gap="10px"
                            m="10px"
                          >
                            <button type="submit" className="btn btn-primary">
                              {isLoading
                                ? "Loading.."
                                : t("nonbiometrics.table.submit_button")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setShowSelectedComment(
                                  (prevComment) => !prevComment
                                );
                              }}
                            >
                              Cancel
                            </button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                ) : (
                  <div className="button-container">
                    <button
                      className="btn btn-danger btn-full"
                      onClick={() => {
                        setShowPossibleComment(false);
                        setShowSelectedComment(true);

                        handleDuplicate({
                          beneficiary_id: possibleDuplicate?.id,
                          duplicate_id: selectedUser?.id,
                          duplicate: true,
                        });
                      }}
                    >
                      Duplicate
                    </button>
                    <button
                      className="btn btn-secondary btn-full"
                      onClick={() => {
                        setShowPossibleComment(false);
                        setShowSelectedComment(true);

                        handleDuplicate({
                          beneficiary_id: possibleDuplicate?.id,
                          duplicate_id: selectedUser?.id,
                          duplicate: false,
                        });
                      }}
                    >
                      Not a Duplicate
                    </button>
                  </div>
                )}
              </div>
              <div className="image-placeholder table-cell">
                <img
                  onClick={openModal}
                  style={{
                    width: "333px",
                    height: "222px",
                    objectFit: "cover",
                  }}
                  src={
                    possibleDuplicate?.photo !== null
                      ? possibleDuplicate?.photo
                      : "/assets/img/no-user-icon.webp"
                  }
                  alt="Placeholder"
                />
                {showPossibleComment ? (
                  <Box mt="20px">
                    <Formik
                      onSubmit={handleSubmit}
                      initialValues={initialValues}
                      validationSchema={commentSchema}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            fullWidth
                            variant="filled"
                            multiline
                            rows={3}
                            type="text"
                            label={
                              payload.duplicate
                                ? t(
                                    "nonbiometrics.index.add_comment_why_duplicate"
                                  )
                                : t(
                                    "nonbiometrics.index.add_comment_why_not_duplicate"
                                  )
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.comment}
                            name="comment"
                            error={!!touched.comment && !!errors.comment}
                            helperText={
                              payload.duplicate
                                ? t(
                                    "nonbiometrics.index.add_comment_why_duplicate"
                                  )
                                : t(
                                    "nonbiometrics.index.add_comment_why_not_duplicate"
                                  )
                            }
                          />
                          <Box
                            display="flex"
                            justifyContent="end"
                            gap="10px"
                            m="10px"
                          >
                            <button type="submit" className="btn btn-primary">
                              {isLoading
                                ? "Loading.."
                                : t("nonbiometrics.table.submit_button")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setShowPossibleComment(
                                  (prevComment) => !prevComment
                                );
                              }}
                            >
                              Cancel
                            </button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                ) : (
                  <div className="button-container">
                    <button
                      className="btn btn-danger btn-full"
                      onClick={() => {
                        setShowSelectedComment(false);
                        setShowPossibleComment(true);

                        handleDuplicate({
                          beneficiary_id: selectedUser?.id,
                          duplicate_id: possibleDuplicate?.id,
                          duplicate: true,
                        });
                      }}
                    >
                      Duplicate
                    </button>

                    <button
                      className="btn btn-secondary btn-full"
                      onClick={() => {
                        setShowSelectedComment(false);
                        setShowPossibleComment(true);

                        handleDuplicate({
                          beneficiary_id: selectedUser?.id,
                          duplicate_id: possibleDuplicate?.id,
                          duplicate: false,
                        });
                      }}
                    >
                      Not a Duplicate
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageComparisonModal
        open={isModalOpen}
        onClose={closeModal}
        image1={images.image1}
        image2={images.image2}
        beneficiary1={images.beneficiary1}
        beneficiary2={images.beneficiary2}
      />
    </>
  );
};
