import { Puff } from "react-loader-spinner";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
  
  const Loader = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
      <Box
        m="25% auto"
        display="flex"
        alignItems="center"
        justifyItems="center"
        justifyContent="center"
        to="50px"
      >
        <Puff
          height="80"
          width="80"
          radius={1}
          color={colors.greenAccent[400]}
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Box>
    );
  };
  
  export default Loader;
  