import { apiSlice } from "../../api/apiSlice";

export const beneficiariesApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Beneficiaries", "BeneficiariesStatus", "Beneficiary"],
  endpoints: (builder) => ({
    getBeneficiaries: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/beneficiaries/list/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Beneficiaries"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getBeneficiary: builder.query({
      query: (id) => ({
        url: `/beneficiaries/single/${id}`,
        method: "GET",
      }),
      providesTags: ["Beneficiary"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    deleteBeneficiary: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Beneficiaries"],
    }),
    restoreBeneficiary: builder.mutation({
      query: (url) => ({
        url: url,
        method: "GET",
      }),
      invalidatesTags: ["Beneficiaries"],
    }),
    getBeneficiaryByStatus: builder.query({
      query: ({ page, pageSize, status = "DUPLICATE" }) => ({
        url: `/beneficiaries/by_status/${status}/${pageSize}?page=${page}`,
        method: "GET",
      }),
      providesTags: ["BeneficiariesStatus"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getBeneficiaryPackageEntries: builder.query({
      query: (id) => ({
        url: `/beneficiaries/package/entries/${id}`,
        method: "GET",
      }),
    }),
    getBeneficiaryCopayEntries: builder.query({
      query: (id) => ({
        url: `/beneficiaries/copay/${id}`,
        method: "GET",
      }),
    }),
    getBeneficiaryCashEntries: builder.query({
      query: (id) => ({
        url: `/beneficiaries/cash/${id}`,
        method: "GET",
      }),
    }),
    getBeneficiaryEvoucherEntries: builder.query({
      query: (id) => ({
        url: `/beneficiaries/evoucher/${id}`,
        method: "GET",
      }),
    }),
    getBeneficiaryInkindEntries: builder.query({
      query: (id) => ({
        url: `/beneficiaries/inkind/${id}`,
        method: "GET",
      }),
    }),
    importBeneficiaryData: builder.mutation({
      query: (payload) => ({
        url: "/beneficiaries/import",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Beneficiaries", "BeneficiariesStatus", "Beneficiary"],
    }),
    importBeneficiaryPhotos: builder.mutation({
      query: (payload) => ({
        url: "/beneficiaries/import/photos",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Beneficiaries", "BeneficiariesStatus", "Beneficiary"],
    }),
    updateBeneficiary: builder.mutation({
      query: (payload) => ({
        url: `/beneficiaries/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Beneficiaries", "BeneficiariesStatus", "Beneficiary"],
    }),
    getBeneficiaryColumns: builder.query({
      query: () => "/beneficiaries/columnnames",
      method: "GET",
    }),
    blockBeneficiary: builder.mutation({
      query: (payload) => ({
        url: `/beneficiaries/block/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Beneficiaries", "BeneficiariesStatus", "Beneficiary"],
    }),
    blockBeneficiaries: builder.mutation({
      query: (payload) => ({
        url: `/beneficiaries/block`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Beneficiaries", "BeneficiariesStatus", "Beneficiary"],
    }),
    unblockBeneficiary: builder.mutation({
      query: (payload) => ({
        url: `/beneficiaries/unblock/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Beneficiaries", "BeneficiariesStatus", "Beneficiary"],
    }),
    unblockBeneficiaries: builder.mutation({
      query: (payload) => ({
        url: `/beneficiaries/unblock`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Beneficiaries", "BeneficiariesStatus", "Beneficiary"],
    }),
    getBulkEditColumns: builder.query({
      query: () => ({
        method: "GET",
        url: "beneficiaries/bulk/edit/columns",
      }),
    }),
    getEditColumnValues: builder.mutation({
      query: (payload) => ({
        url: "/beneficiaries/select/column",
        method: "POST",
        body: { ...payload },
      }),
    }),
    bulkEditBeneficiaries: builder.mutation({
      query: (payload) => ({
        url: "/beneficiaries/bulk/edit",
        method: "POST",
        body: { ...payload },
      }),
    }),
    markBiometricDuplicate: builder.mutation({
      query: (payload) => ({
        url: "/deduplication/biometric/block",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Beneficiaries", "BeneficiariesStatus", "Beneficiary"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const {
  useGetBeneficiariesQuery,
  useGetBeneficiaryQuery,
  useDeleteBeneficiaryMutation,
  useRestoreBeneficiaryMutation,
  useGetBeneficiaryByStatusQuery,
  useGetBeneficiaryPackageEntriesQuery,
  useGetBeneficiaryCopayEntriesQuery,
  useGetBeneficiaryCashEntriesQuery,
  useGetBeneficiaryEvoucherEntriesQuery,
  useGetBeneficiaryInkindEntriesQuery,
  useImportBeneficiaryDataMutation,
  useImportBeneficiaryPhotosMutation,
  useUpdateBeneficiaryMutation,
  useGetBeneficiaryColumnsQuery,
  useBlockBeneficiaryMutation,
  useBlockBeneficiariesMutation,
  useUnblockBeneficiaryMutation,
  useUnblockBeneficiariesMutation,
  useGetBulkEditColumnsQuery,
  useGetEditColumnValuesMutation,
  useBulkEditBeneficiariesMutation,

  // useMarkBiometricDuplicateMutation,
} = beneficiariesApiSlice;
