import { apiSlice } from "../../api/apiSlice";

export const serviceProvidersApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["ServiceProviders"],
  endpoints: (builder) => ({
    getServiceProviders: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
          const queryString = new URLSearchParams({
              ...apiFilters,
              page: page,
              pageSize: pageSize
          }).toString();

          return {
              url: `/users/service/providers/${pageSize}?${queryString}`,
              method: "GET",
          };
      },
      providesTags: ["ServiceProviders"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateServiceProviderEntitlement: builder.mutation({
      query: (payload) => ({
        url: `/entitlement/${payload.entitlement_id}/user/${payload.user_id}/update`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["entitlements"],
    }),
    updateServiceProviderEntitlementStatus: builder.mutation({
      query: (payload) => ({
        url: `/entitlement/${payload.entitlement_id}/user/${payload.user_id}/updatestatus`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["entitlements"],
    }),
  }),
});

export const { 
  useGetServiceProvidersQuery, 
  useUpdateServiceProviderEntitlementMutation,
  useUpdateServiceProviderEntitlementStatusMutation } = serviceProvidersApiSlice;
