import {
  Box,
  Paper,
  Card,
  Typography,
  CardContent,
  Button,
  Alert,
  Tab,
  Breadcrumbs,
  ButtonGroup,
  useTheme,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { ToastContainer } from "react-toastify";

import Header from "../../Header";
import {
  useChangeEntitlementPackageStatusMutation,
  useChangeBeneficiaryPackageStatusMutation,
  useGetPackageEntitlementsQuery,
  useGetPackageQuery,
  useUpdatePackageEntitlementMutation,
  useGetPackageBeneficiariesQuery
} from "../../../features/packages/packagesApiSlice";
import Spinner from "../../global/Spinner";
import StyledDataGrid from "../../global/StyledDataGrid";
import { LinkColor, tokens } from "../../../theme";
import Toast from "../../global/Toast";
import ConfirmChangeStatus from "../shared/ConfirmChangeStatus";
import EditEntitlementDialog from "./EditEntitlementDialog";
import { getSegment } from "../../../utils/stringUtils";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const ViewPackage = () => {
  // Being able to add/remove/deactivate entitltments and beneficiaries from a package is essentially
  // updating the properties of a package, hence user needs the "package-update" permission
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAddEntitlement = currentPermissions.some(
    //(permission) => permission.name === "entitlement-add"
    (permission) => permission.name === "package-update"
  );
  const canAddBeneficiary = currentPermissions.some(
    //(permission) => permission.name === "beneficiary-add"
    (permission) => permission.name === "package-update"
  );
  const canEdit = currentPermissions.some(
    //(permission) => permission.name === "entitlement-update"
    (permission) => permission.name === "package-update"
  );
  const canDelete = currentPermissions.some(
    //(permission) => permission.name === "entitlement-delete"
    (permission) => permission.name === "package-update"
  );

  const { t } = useTranslation();
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openDialog, setOpenDialog] = useState(false);
  const [openBeneficiaryDialog, setOpenBeneficiaryDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [packageData, setPackageData] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [entitlements, setEntitlements] = useState([]);
  const [selectedEntitlement, setSelectedEntitlement] = useState(null);
  const [activeEntitlements, setActiveEntitlements] = useState(null);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [activeBeneficiaries, setActiveBeneficiaries] = useState(null);
  const [action, setAction] = useState(null);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const { data, isLoading, isSuccess } = useGetPackageQuery(id);

  useEffect(() => {
    if (data?.data) {
      setPackageData(data?.data);
    }
  }, [isSuccess, data, isLoading]);

  // Fetch beneficiaries
  const {
    data: packageBeneficiaries,
    isLoading: fetchingBeneficiaries,
    isSuccess: benSuccess,
  } = useGetPackageBeneficiariesQuery(id);

  useEffect(() => {
    if (packageBeneficiaries?.beneficiaries)
      setBeneficiaries(packageBeneficiaries?.beneficiaries);
  }, [benSuccess, packageBeneficiaries, fetchingBeneficiaries]);

  // Fetch entitlements in a package
  const {
    data: entitlementsData,
    isLoading: fetchingEntitlements,
    isSuccess: entitlementsSuccess,
  } = useGetPackageEntitlementsQuery(id);

  useEffect(() => {
    if (entitlementsData?.data) {
      setEntitlements(entitlementsData?.data);
      setActiveEntitlements(entitlementsData?.active_entitlements);
    }
  }, [entitlementsData, fetchingEntitlements, entitlementsSuccess]);

  const [changePackageEntitlementStatus, { isLoading: changingEntitlement }] =
    useChangeEntitlementPackageStatusMutation();

  const [changePackageBeneficiaryStatus, { isLoading: changingBeneficiary }] =
      useChangeBeneficiaryPackageStatusMutation();

  const [updatePackageEntitlement, { isLoading: updatingEntitlement }] =
    useUpdatePackageEntitlementMutation();

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseBeneficiary = () => {
    setOpenBeneficiaryDialog(false);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  useEffect(() => {
    if (selectedEntitlement !== null) {
    }
  }, [selectedEntitlement]);

  useEffect(() => {
    if (action !== null) {
    }
  }, [action]);

  const handleClickOpen = (entitlement, action) => {
    setSelectedEntitlement(entitlement);
    setAction(action);
    setOpenDialog(true);
  };

  const handleClickOpenBeneficiary = (beneficiary, action) => {
    setSelectedBeneficiary(beneficiary);
    setAction(action);
    setOpenBeneficiaryDialog(true);
  };

  const handleChangeEntitlementStatus = async (entitlement, action) => {
    let status = "";
    if (action == "ACTIVATE") {
      status = "ACTIVE";
    } else if (action == "DEACTIVATE") {
      status = "INACTIVE";
    } else if (action == "ARCHIVE") {
      status = "ARCHIVED";
    }
    const payload = {
      package_id: id,
      entitlement_id: entitlement.id,
      status,
    };
    try {
      const res = await changePackageEntitlementStatus(payload).unwrap();
      Toast(res?.message, "success");
    } catch (err) {
      // Toast(JSON.stringify(err), "error");
    }
  };

  const handleChangeBeneficiaryStatus = async (beneficiary, action) => {
    let status = "";
    if (action == "ACTIVATE") {
      status = "ACTIVE";
    } else if (action == "DEACTIVATE") {
      status = "INACTIVE";
    } else if (action == "ARCHIVE") {
      status = "ARCHIVED";
    }
    const payload = {
      package_id: id,
      beneficiary_id: beneficiary.id,
      status,
    };
    try {
      const res = await changePackageBeneficiaryStatus(payload).unwrap();
      Toast(res?.message, "success");
    } catch (err) {
      // Toast(JSON.stringify(err), "error");
    }
  };

  const handleEdit = (entitlement) => {
    setSelectedEntitlement(entitlement);
    setOpenEditDialog(true);
  };

  const handleEntitlementEdit = async (payload) => {
    try {
      const res = await updatePackageEntitlement(payload).unwrap();
      Toast(res?.message, "success");
    } catch (err) {
      // Toast(JSON.stringify(err), "err");
    }
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1.25,
      renderCell: (params) => {
        const entitlement = params.row;
        const getEntitlement = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id, status: params.row.status };

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              {entitlement.status === "ACTIVE" && canEdit ? (
                <Button
                  color="secondary"
                  onClick={() => handleEdit(getEntitlement())}
                >
                  {t("global.table.edit_button")} <EditOutlinedIcon />
                </Button>
              ) : (
                canEdit && (
                  <Button
                    color="inherit"
                    onClick={() => handleClickOpen(getEntitlement(), "ARCHIVE")}
                  >
                    {t("global.table.archive_button")} <ArchiveOutlinedIcon />
                  </Button>
                )
              )}
              {entitlement.status === "ACTIVE" && canDelete ? (
                <Button
                  color="error"
                  onClick={() =>
                    handleClickOpen(getEntitlement(), "DEACTIVATE")
                  }
                >
                  {t("global.table.deactivate_button")}
                  <DeleteOutlineOutlinedIcon />
                </Button>
              ) : (
                canDelete && (
                  <Button
                    color="warning"
                    onClick={() =>
                      handleClickOpen(getEntitlement(), "ACTIVATE")
                    }
                  >
                    {t("global.table.activate_button")}
                    <CheckCircleOutlinedIcon />
                  </Button>
                )
              )}

              {openDialog && selectedEntitlement && (
                <ConfirmChangeStatus
                  open={openDialog}
                  onClose={handleClose}
                  child={JSON.parse(selectedEntitlement)}
                  parent={packageData}
                  action={action}
                  handleChangeStatus={handleChangeEntitlementStatus}
                  childLabel={t('entitlements.form.label.entitlement')}
                  parentLabel={t('packages.form.label.package')}
                />
              )}

            </ButtonGroup>

            {openEditDialog && selectedEntitlement && (
              <EditEntitlementDialog
                open={openEditDialog}
                onClose={handleCloseEditDialog}
                entitlementData={selectedEntitlement}
                packageData={packageData}
                handleEntitlementEdit={handleEntitlementEdit}
              />
            )}
          </Box>
        );
      },
    },
    { field: "title", headerName: t("global.form.label.name"), flex: 1 },
    { field: "unit", headerName: t("entitlements.form.label.unit"), flex: 1 },
    { field: "max_redeemable", headerName: t("entitlements.form.label.max_redeemable"), flex: 1 },
    { field: "status", headerName: t("global.table.status"), flex: 1 }
  ];

  const beneficiariesColumns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1.25,
      minWidth: 200,
      renderCell: (params) => {
        const beneficiary = params.row;
        const getBeneficiary = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id, status: params.row.pivot.status };

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  size="small"
              >
                {beneficiary.pivot.status === "ACTIVE" && canEdit ? (
                    <Box></Box>
                    ) : (
                    canEdit && (
                        <Button
                            color="inherit"
                            onClick={() => handleClickOpenBeneficiary(getBeneficiary(), "ARCHIVE")}
                        >
                          {t("global.table.archive_button")} <ArchiveOutlinedIcon />
                        </Button>
                    )
                )}
                {beneficiary.pivot.status === "ACTIVE" && canDelete ? (
                    <Button
                        color="error"
                        onClick={() =>
                            handleClickOpenBeneficiary(getBeneficiary(), "DEACTIVATE")
                        }
                    >
                      {t("global.table.deactivate_button")}
                      <DeleteOutlineOutlinedIcon />
                    </Button>
                ) : (
                    canDelete && (
                        <Button
                            color="warning"
                            onClick={() =>
                                handleClickOpenBeneficiary(getBeneficiary(), "ACTIVATE")
                            }
                        >
                          {t("global.table.activate_button")}
                          <CheckCircleOutlinedIcon />
                        </Button>
                    )
                )}

                {openBeneficiaryDialog && selectedBeneficiary && (
                    <ConfirmChangeStatus
                        open={openBeneficiaryDialog}
                        onClose={handleCloseBeneficiary}
                        child={JSON.parse(selectedBeneficiary)}
                        parent={packageData}
                        action={action}
                        handleChangeStatus={handleChangeBeneficiaryStatus}
                        childLabel={t('nonbiometrics.index.beneficiary')}
                        parentLabel={t('packages.form.label.package')}
                    />
                )}

              </ButtonGroup>
            </Box>
        );
      },
    },
    {
      field: "full_name",
      headerName: t("beneficiaries.table.fullname"),
      flex: 1,
      minWidth: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "unique_id",
      headerName: t("beneficiaries.table.unique_id"),
      valueGetter: (params) => getSegment(params.row?.unique_id, "-", -1),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      valueGetter: (params) => params.row?.telephone,
      minWidth: 150,
    },
    {
      field: "date_of_birth",
      headerName: t("beneficiaries.table.dob"),
      valueGetter: (params) => params.row?.date_of_birth,
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "distribution_point",
      headerName: t("distribution_points.form.label.distribution_point"),
      valueGetter: (params) => params.row?.distribution_point?.title,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: t("beneficiaries.table.status"),
      valueGetter: (params) => params.row?.pivot?.status,
      minWidth: 100,
    },
    {
      field: "geo_level_1",
      headerName: t("beneficiaries.table.geolevel1"),
      valueGetter: (params) => params.row?.geo_level_1,
      minWidth: 200,
    },
    {
      field: "geo_level_2",
      headerName: t("beneficiaries.table.geolevel2"),
      valueGetter: (params) => params.row?.geo_level_2,
      minWidth: 200,
    },
    {
      field: "geo_level_3",
      headerName: t("beneficiaries.table.geolevel3"),
      valueGetter: (params) => params.row?.geo_level_3,
      minWidth: 200,
    },
    {
      field: "geo_level_4",
      headerName: t("beneficiaries.table.geolevel4"),
      valueGetter: (params) => params.row?.geo_level_4,
      minWidth: 200,
    },
    {
      field: "geo_level_5",
      headerName: t("beneficiaries.table.geolevel5"),
      valueGetter: (params) => params.row?.geo_level_5,
      minWidth: 200,
    },
    {
      field: "geo_level_6",
      headerName: t("beneficiaries.table.geolevel6"),
      valueGetter: (params) => params.row?.geo_level_6,
      minWidth: 200,
    },
    {
      field: "copay_amount",
      headerName: t("packages.form.label.copay_amount"),
      valueGetter: (params) => params.row?.pivot?.copay_amount,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "subsidy_amount",
      headerName: t("packages.form.label.subsidy_amount"),
      valueGetter: (params) => params.row?.pivot?.subsidy_amount,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "copay_balance",
      headerName: t("packages.form.label.copay_balance"),
      valueGetter: (params) => params.row?.pivot?.copay_balance,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "subsidy_balance",
      headerName: t("packages.form.label.subsidy_balance"),
      valueGetter: (params) => params.row?.pivot?.subsidy_balance,
      flex: 1,
      minWidth: 100,
    },
  ];

  const content =
    isLoading || fetchingBeneficiaries ? (
      <Spinner />
    ) : (
      <Box m="20px">
        <ToastContainer />
        <Header
          title={t("packages.view.title")}
          subtitle={t("packages.view.subtitle")}
        />

        <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
          <Link style={LinkColor()} color="inherit" to="/">
            <Typography variant="body2">{t("sidebar.Home")}</Typography>
          </Link>
          <Link style={LinkColor()} color="inherit" to="/interventions">
            <Typography variant="body2">
              {t("sidebar.Interventions")}
            </Typography>
          </Link>
          <Link
            style={LinkColor()}
            color="inherit"
            to="/interventions/packages"
          >
            <Typography variant="body2">{t("sidebar.Packages")}</Typography>
          </Link>
          <Typography color="text.primary" variant="body2">
            {t("packages.form.helpertext.view")}
          </Typography>
        </Breadcrumbs>

        <Box
          sx={{
            display: "flex",
            "& > :not(style)": {
              m: 1,
            },
          }}
        >
          <Paper variant="outlined">
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {packageData?.title}
                </Typography>
                <hr />
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("intervention.form.label.intervention")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {packageData?.intervention?.title}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("project.form.label.project")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {packageData?.intervention?.project?.code}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("packages.form.label.code")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {packageData?.code}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("packages.form.label.copay_amount")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {packageData?.copay_amount}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("packages.form.label.subsidy_amount")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {packageData?.subsidy_amount}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" component="div">
                    {t("packages.form.label.entitlements")}:
                  </Typography>
                  <Typography variant="h6" component="div">
                    {activeEntitlements}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Box>

        {/* Entitlements In the package */}
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab label={t("packages.view.entitlements")} value="1" />
                <Tab label={t("packages.view.beneficiaries")} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box className="data_grid_box_container_within_tabs">
                {canAddEntitlement && (
                  <Box mb="10px" display="flex" justifyContent="space-between" alignItems="center">
                    <Link to={`/interventions/packages/${packageData.id}/add/entitlements`} underline="none">
                      <button className="btn btn-primary">
                        {t("global.buttons.add_entitlements")}
                      </button>
                    </Link>
                  </Box>
                )}
                {fetchingEntitlements ? (
                  <Spinner />
                ) : entitlements?.length < 1 ? (
                  <Alert severity="info">
                    {t("packages.view.no_entitlements")}
                  </Alert>
                ) : (
                  <StyledDataGrid
                    rows={entitlements}
                    columns={columns}
                    rowsPerPageOptions={default_rows_per_page_options}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box className="data_grid_box_container_within_tabs">
                {canAddBeneficiary && (
                  <Box mb="10px" display="flex" justifyContent="space-between" alignItems="center">
                    <Link to={`/interventions/packages/${packageData.id}/add/beneficiaries`} underline="none">
                      <button className="btn btn-primary">
                        {t("global.buttons.add_ben")}
                      </button>
                    </Link>
                  </Box>
                )}
                {beneficiaries?.length < 1 ? (
                  <Alert severity="info">
                    {t("packages.view.no_beneficiaries")}
                  </Alert>
                ) : (
                  <StyledDataGrid
                    rows={beneficiaries}
                    columns={beneficiariesColumns}
                    rowsPerPageOptions={default_rows_per_page_options}
                  />
                )}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    );
  return content;
};

export default ViewPackage;
