import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import {
  useGetInterventionQuery,
  useGetInterventionBeneficiariesQuery,
  useGetInterventionServiceProvidersQuery,
  useGetInterventionRolesQuery,
  useDeleteInterventionRoleMutation,
} from "../../../features/interventions/interventionsApiSlice";
import Spinner from "../../global/Spinner";
import {
  Box,
  Paper,
  Card,
  ButtonGroup,
  Typography,
  CardContent,
  Button,
  Tab,
  Breadcrumbs,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import { useTranslation } from "react-i18next";
import { getSegment } from "../../../utils/stringUtils";
import { LinkColor } from "../../../theme";
import DeleteDialog from "../shared/DeleteDialog";
import Toast from "../../global/Toast";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const ViewIntervention = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(
    (permission) => permission.name === "intervention-add-beneficiaries"
  );

  const canEdit = currentPermissions.some(
    (permission) => permission.name === "intervention-update"
  );
  const canDelete = currentPermissions.some(
    (permission) => permission.name === "intervention-delete"
  );

  const { t } = useTranslation();
  const { id } = useParams();
  const [serviceProviders, setServiceProviders] = useState([]);
  const [roles, setRoles] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [intervention, setIntervention] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedRole, setSelectedRole] = useState();

  //   Fetch intervention
  const { data, isLoading } = useGetInterventionQuery(id);

  useEffect(() => {
    if (data?.data) setIntervention(data?.data);
  }, [data]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );

  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Fetch beneficiaries in an intervention
  const {
    data: benData,
    isLoading: fetchingBeneficiaries,
    isSuccess: benSuccess,
  } = useGetInterventionBeneficiariesQuery({ id, page, pageSize });

  useEffect(() => {
    if (benData?.data) setBeneficiaries(benData?.data);
  }, [benData, benSuccess]);

  // Fetch service providers in an intervention
  const {
    data: spData,
    isLoading: fetchingServiceProviders,
    isSuccess: spSuccess,
  } = useGetInterventionServiceProvidersQuery({ id, page, pageSize });

  useEffect(() => {
    if (spData?.service_providers)
      setServiceProviders(spData?.service_providers);
  }, [spData, spSuccess]);

  // Fetch roles in an interventions
  const {
    data: rolesData,
    isLoading: fetchingRoles,
    isSuccess: rolesSuccess,
  } = useGetInterventionRolesQuery({ id, page, pageSize });

  useEffect(() => {
    if (rolesData?.roles) setRoles(rolesData.roles);
  }, [rolesData, rolesSuccess]);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getPackages = (params) => {
    return Object.keys(params.row.packages).length;
  };

  // Beneficiary columns
  const beneficiariesColumns = [
    {
      field: "full_name",
      headerName: t("beneficiaries.table.fullname"),
      flex: 1,
      minWidth: 250,
      cellClassName: "name-column--cell",
    },
    {
      field: "unique_id",
      headerName: t("beneficiaries.table.unique_id"),
      valueGetter: (params) => getSegment(params.row?.unique_id, "-", -1),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "telephone",
      headerName: t("users.form.label.telephone"),
      valueGetter: (params) => params.row?.telephone,
      minWidth: 150,
    },
    {
      field: "date_of_birth",
      headerName: t("beneficiaries.table.dob"),
      valueGetter: (params) => params.row?.date_of_birth,
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "distribution_point",
      headerName: t("distribution_points.form.label.distribution_point"),
      valueGetter: (params) => params.row?.distribution_point?.title,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "packages",
      headerName: t("beneficiaries.table.packages"),
      valueGetter: getPackages,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "Status",
      headerName: t("beneficiaries.table.status"),
      valueGetter: (params) => params.row?.status,
      minWidth: 100,
    },
    {
      field: "geo_level_1",
      headerName: t("beneficiaries.table.geolevel1"),
      valueGetter: (params) => params.row?.geo_level_1,
      minWidth: 200,
    },
    {
      field: "geo_level_2",
      headerName: t("beneficiaries.table.geolevel2"),
      valueGetter: (params) => params.row?.geo_level_2,
      minWidth: 200,
    },
    {
      field: "geo_level_3",
      headerName: t("beneficiaries.table.geolevel3"),
      valueGetter: (params) => params.row?.geo_level_3,
      minWidth: 200,
    },
    {
      field: "geo_level_4",
      headerName: t("beneficiaries.table.geolevel4"),
      valueGetter: (params) => params.row?.geo_level_4,
      minWidth: 200,
    },
    {
      field: "geo_level_5",
      headerName: t("beneficiaries.table.geolevel5"),
      valueGetter: (params) => params.row?.geo_level_5,
      minWidth: 200,
    },
    {
      field: "geo_level_6",
      headerName: t("beneficiaries.table.geolevel6"),
      valueGetter: (params) => params.row?.geo_level_6,
      minWidth: 200,
    },
  ];

  // Service providers columns
  const SPcolumns = [
    {
      field: "first_name",
      headerName: t("users.form.label.first_name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "last_name",
      headerName: t("users.form.label.last_name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "email", headerName: t("users.form.label.email"), flex: 1 },
    {
      field: "id_number",
      headerName: t("users.form.label.id_number"),
      flex: 1,
    },
    {
      field: "user_name",
      headerName: t("users.form.label.user_name"),
      flex: 1,
    },
  ];

  const [deleteRole] = useDeleteInterventionRoleMutation();

  const handleEdit = (int) => {
    const intervention = JSON.parse(int);
    // console.log("role", intervention);
  };

  const handleClickOpen = (intervention) => {
    setSelectedRole(intervention);
    setOpenDialog(true);
  };
  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedRole(value);
  };

  const handleDelete = async (selectedRole) => {
    const payload = {
      intervention_id: id,
      role_ids: [selectedRole],
    };

    try {
      const response = await deleteRole(payload).unwrap();
      if (response.status === 200) {
        // Navigate on success
      } else {
        // Handle unexpected response status
        throw new Error(response?.message || "Unexpected response from server");
      }
    } catch (err) {
      // Handle network or server errors
      Toast(err?.message, "error");
    }
  };

  // Roles columns
  const Rolescolumns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        const getIntervention = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              {/* {canEdit && (
                <Button
                  color="secondary"
                  onClick={() => handleEdit(getIntervention())}
                >
                  {t("global.table.edit_button")} <EditOutlinedIcon />
                </Button>
              )} */}
              {canDelete && (
                <Button
                  color="error"
                  onClick={() => handleClickOpen(getIntervention())}
                >
                  {t("global.table.delete_button")}{" "}
                  <DeleteOutlineOutlinedIcon />
                </Button>
              )}
            </ButtonGroup>
            {openDialog && (
              <DeleteDialog
                open={openDialog}
                onClose={handleClose}
                name={JSON.parse(selectedRole).name}
                deleteMethod={() => {
                  handleDelete(JSON.parse(selectedRole).id);
                }}
                // url={`/intervention/delete/${
                //   JSON.parse(selectedIntervention).id
                // }`}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: t("global.table.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.table.description"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("intervention.view.title")}
        subtitle={t("intervention.view.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Typography variant="body2">{t("intervention.view.view")}</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          "& > :not(style)": {
            m: 1,
          },
          maxWidth: "400px",
        }}
      >
        <Paper variant="outlined">
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {intervention?.title}
              </Typography>
              <hr />
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("global.form.label.name")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {intervention?.title}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("global.form.label.description")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {intervention?.description}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("intervention.shared.copay_upfront")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {intervention?.copay_upfront
                    ? t("purchase_orders.edit.select.yes")
                    : t("purchase_orders.edit.select.no")}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("intervention.shared.cva_type")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {intervention?.cva_type}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("project.shared.project_code")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {intervention?.project?.code}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignContent="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" component="div">
                  {t("global.table.status")}:
                </Typography>
                <Typography variant="h6" component="div">
                  {intervention?.status}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </Box>

      {/* Add tabs for beneficiaries and service providers */}
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label={t(
                "intervention.form.helpertext.manage_beneficiaries"
              )}
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label={t("sidebar.Beneficiaries")} value="1" />
              <Tab label={t("sidebar.Service Providers")} value="2" />
              <Tab label={t("sidebar.Roles")} value="3" />
            </TabList>
          </Box>
          {/* Beneficiary tab */}
          <TabPanel value="1">
            <Box className="data_grid_box_container_within_tabs">
              {canAdd && (
                <Box
                  mb="10px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Link
                    to={`/interventions/${id}/add/beneficiaries`}
                    underline="none"
                  >
                    <button className="btn btn-primary">
                      {t("global.buttons.add_ben")}
                    </button>
                  </Link>
                </Box>
              )}

              {fetchingBeneficiaries ? (
                <p>{t("global.loading.beneficiaries")}</p>
              ) : (
                <StyledDataGrid
                  rows={beneficiaries}
                  columns={beneficiariesColumns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>

          {/* Service provider tab */}
          <TabPanel value="2">
            <Box className="data_grid_box_container_within_tabs">
              {canAdd && (
                <Box
                  mb="10px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Link
                    to={`/interventions/${id}/add/service/providers`}
                    underline="none"
                  >
                    <button className="btn btn-primary">
                      {t("global.buttons.add_sp")}
                    </button>
                  </Link>
                </Box>
              )}
              {fetchingServiceProviders ? (
                <p>{t("global.loading.service_providers")}</p>
              ) : (
                <StyledDataGrid
                  rows={serviceProviders}
                  columns={SPcolumns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>

          {/* Roles tab */}
          <TabPanel value="3">
            <Box className="data_grid_box_container_within_tabs">
              {canAdd && (
                <Box
                  mb="10px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Link to={`/interventions/${id}/add/roles`} underline="none">
                    <button className="btn btn-primary">
                      {t("global.buttons.add_role")}
                    </button>
                  </Link>
                </Box>
              )}
              {fetchingRoles ? (
                <p>{t("global.loading.service_providers")}</p>
              ) : (
                <StyledDataGrid
                  rows={roles}
                  columns={Rolescolumns}
                  rowsPerPageOptions={default_rows_per_page_options}
                />
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );

  return content;
};

export default ViewIntervention;
