import React from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useUpdateInterventionMutation } from "../../../features/interventions/interventionsApiSlice";
import Spinner from "../../global/Spinner";
import { ToastContainer } from "react-toastify";
import Toast from "../../global/Toast";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditIntervention = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const intervention = location.state.intervention;

  const initialValues = {
    title: intervention.title,
    description: intervention.description,
    copay_upfront: intervention.copay_upfront ? "yes" : "no",
  };

  const donorsSchema = yup.object().shape({
    title: yup.string().required(t('intervention.form.helpertext.title')),
    description: yup.string().required(t('intervention.form.helpertext.description')),
    copay_upfront: yup.string().required(t('intervention.form.helpertext.copay_upfront')),
  });

  const [updateIntervention, { isLoading }] = useUpdateInterventionMutation();

  const handleFormSubmit = async (values) => {
    const payload = {
      ...values,
      copay_upfront: values.copay_upfront === "yes" ? true : false,
      id: intervention.id,
    };
    try {
      const response = await updateIntervention(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
        function () {
          navigate("/interventions", { state: { message: response?.message } });
        },
        3000
      );

    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('intervention.edit.title')}
        subtitle={t('intervention.edit.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t('sidebar.Interventions')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('intervention.edit.edit_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={donorsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('global.table.name')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={4}
                type="text"
                label={t('global.table.description')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
              <div id="my-radio-group">{t('intervention.form.label.copay_upfront')}</div>
              <div role="group" aria-labelledby="my-radio-group">
                <label>
                  <Field
                    type="radio"
                    name="copay_upfront"
                    value="yes"
                    onChange={handleChange}
                  />
                  {t('intervention.form.label.copay_upfront_yes')}
                </label>
                <label>
                  <Field
                    type="radio"
                    name="copay_upfront"
                    value="no"
                    onChange={handleChange}
                  />
                  {t('intervention.form.label.copay_upfront_no')}
                </label>
              </div>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link to="/interventions" underline="none">

              <button className="btn btn-danger">
                  {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('intervention.edit.edit_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default EditIntervention;
