import React, { useEffect, useState } from "react";
import {
  useAddRolePermissionsMutation,
  useGetRolePermissionsQuery,
} from "../../../features/roles/rolesApiSlice";
import { useParams, useNavigate, Link } from "react-router-dom";
import Spinner from "../../global/Spinner";
import {
  Box,
  Button,
  Alert,
  AlertTitle,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { useGetPermissionsQuery } from "../../../features/permissions/permissionsApiSlice";
import StyledDataGrid from "../../global/StyledDataGrid";
import Header from "../../Header";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const AddRolePermissions = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [ids, setIds] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [presentPermissions, setPresentPermissions] = useState([]);

  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  // Fetch Permisions
  const { data, isLoading, isSuccess } = useGetPermissionsQuery();

  useEffect(() => {
    if (data?.permissions) setPermissions(data?.permissions);
  }, [data, isLoading, isSuccess]);

  // Fetch present permissions in a role
  const {
    data: permissionData,
    isLoading: fetchingPermissions,
    isSuccess: permissionSuccess,
  } = useGetRolePermissionsQuery(id);

  useEffect(() => {
    if (permissionData?.data) setPresentPermissions(permissionData?.data);
  }, [permissionData, permissionSuccess, fetchingPermissions]);

  // Add role beneficiaries
  const [addPermissions] = useAddRolePermissionsMutation();

  // check if the title value of tale matches present permissions title value
  const checkNameMatch = (name) => {
    return presentPermissions.some((permission) => permission.name === name);
  };
  const isSelectionDisabled = (params) => {
    return checkNameMatch(params.row.name);
  };
  const getCellClassName = (params) => {
    return checkNameMatch(params.row.name) ? "matched-name-cell" : "";
  };

  // Separate matching and non-matching entitlements
  const matchingPermissions = [];
  const nonMatchingPermissions = [];
  permissions.forEach((permission) => {
    if (
      presentPermissions.some((present) => present.name === permission.name)
    ) {
      matchingPermissions.push(permission);
    } else {
      nonMatchingPermissions.push(permission);
    }
  });

  // Concatenate arrays to place matching permissions at the bottom
  const sortedPermissions = [...nonMatchingPermissions, ...matchingPermissions];
  const columns = [
    {
      field: "name",
      headerName: t("global.form.label.name"),
      flex: 1,
      cellClassName: "name-column--cell",
      valueGetter: (params) => params.row?.name,
    },
    {
      field: "description",
      headerName: t("global.form.label.description"),
      flex: 1.5,
    },
    {
      field: "guard_name",
      headerName: t("roles.form.label.guard_name"),
      flex: 0.5,
    },
  ];

  const selectedPermissions = (ids) => {
    setIds(ids);
  };

  const handleAdd = async () => {
    if (permissions.length) {
      const payload = {
        role_id: id,
        permissions: ids,
      };
      try {
        const response = await addPermissions(payload).unwrap();

        if (response.status === 200) {
          Toast(response?.message, "success");
        } else {
          Toast(response?.message, "error");
        }

        setTimeout(function () {
          navigate(`/roles/${id}`, { state: { message: response?.message } });
        }, 3000);
      } catch (err) {
        Toast(err?.data?.message, "error");
      }
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("roles.add_permissions.title")}
        subtitle={t("roles.add.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/roles">
          <Typography variant="body2">{t("sidebar.Roles")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to={`/roles/${id}`}>
          <Typography variant="body2">{t("roles.view.view_role")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("roles.add_permissions.add_permissions")}
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <AlertTitle>{t("roles.add_permissions.alert_head")}</AlertTitle>
        {t("roles.add_permissions.alert_message")}
      </Alert>

      <button
        className={!ids.length ? "btn btn-disabled" : "btn btn-primary"}
        onClick={handleAdd}
        disabled={!ids.length}
        style={{ marginTop: "10px" }}
      >
        {t("global.buttons.add_permissions")}
      </button>
      <Box className="data_grid_box_container">
        <StyledDataGrid
          checkboxSelection
          rows={sortedPermissions}
          columns={columns}
          onSelectionModelChange={(ids) => selectedPermissions(ids)}
          rowsPerPageOptions={default_rows_per_page_options}
          getCellClassName={(params) => getCellClassName(params)}
          disableSelectionOnClick={(params) => isSelectionDisabled(params)}
        />
      </Box>
    </Box>
  );
};

export default AddRolePermissions;
