import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Breadcrumbs,
  ButtonGroup,
  Alert,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import moment from "moment";

import Header from "../../Header";
import {
  useDeletePreloadMutation,
  useGetKoboFormQuery,
  useGetKoboPreloadsQuery,
  useRestorePreloadMutation,
} from "../../../features/kobo/koboApiSlice";
import StyledDataGrid from "../../global/StyledDataGrid";
import axios from "../../../api/axios";
import Spinner from "../../global/Spinner";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import Toast from "../../global/Toast";
import DeleteDialog from "../shared/DeleteDialog";
import { LinkColor } from "../../../theme";

const KoboFormPreloads = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [preloads, setPreloads] = useState([]);
  const [koboForm, setKoboForm] = useState("");
  const [downloadingState, setDownloadingState] = useState({});
  const [deletingState, setDeletingState] = useState({});

  const [selectedPreload, setSelectedPreload] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const token = useSelector(selectCurrentToken);
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  const payload = { page, pageSize, id };
  const { data, isLoading, isSuccess } = useGetKoboPreloadsQuery(payload);

  useEffect(() => {
    if (data?.data) setPreloads(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isLoading, isSuccess, pagination.currentPage]);

  // Fetch kobo form details
  const {
    data: koboFormData,
    isLoading: koboLoading,
    isSuccess: koboSuccess,
  } = useGetKoboFormQuery(id);
  useEffect(() => {
    if (koboFormData?.data) setKoboForm(koboFormData?.data);
  }, [koboFormData, koboLoading, koboSuccess]);

  const [deletePreload] = useDeletePreloadMutation();

  const handleDownload = async (preload) => {
    preload = JSON.parse(preload);
    setDownloadingState((prev) => ({ ...prev, [preload?.id]: true }));
    const url = `${process.env.REACT_APP_BASE_URL}/kobo/download/preload/${preload?.id}`;
    try {
      if (preload?.file) {
        await axios
          .get(url, {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = preload?.preload_name;
            a.click();
            window.URL.revokeObjectURL(url);
            setDownloadingState((prev) => ({ ...prev, [preload?.id]: false }));
            if (response.statusText === "OK") {
              Toast(t("kobo.preloads.preload_download_success"), "success");
            }
          })
          .catch((error) => {
            Toast(t("kobo.preloads.preload_download_fail"), "error");
            setDownloadingState((prev) => ({ ...prev, [preload?.id]: false }));
          });
      }
    } catch (error) {
      setDownloadingState((prev) => ({ ...prev, [preload?.id]: false }));
      Toast(t("kobo.preloads.preload_download_fail"), "error");
    }
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  const handleClickOpen = (preload) => {
    setSelectedPreload(preload);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedPreload(value);
  };

  const [restorePreload] = useRestorePreloadMutation();

  const handleRestore = async (preload) => {
    preload = JSON.parse(preload);
    const payload = { id: preload.id };
    try {
      const response = await restorePreload(payload).unwrap();
      Toast(response?.message, "success");
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const getPreload = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id, file: params.row.file };

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              {params.row.status === "EXPORTED" && (
                <Button
                  color="warning"
                  size="small"
                  onClick={() => handleDownload(getPreload())}
                >
                  {downloadingState[params.row.id]
                    ? t("global.buttons.downloading")
                    : t("global.buttons.download")}{" "}
                  <FileDownloadOutlinedIcon />
                </Button>
              )}
              {params.row.status != "DELETED" && (
                <Button
                  color="error"
                  size="small"
                  onClick={() => handleClickOpen(getPreload())}
                >
                  {deletingState[params.row.id]
                    ? t("global.buttons.deleting")
                    : t("global.buttons.delete")}{" "}
                  <DeleteOutlineOutlinedIcon />
                </Button>
              )}
              {params.row.status === "DELETED" && (
                <Button
                  color="error"
                  size="small"
                  onClick={() => handleRestore(getPreload())}
                >
                  {deletingState[params.row.id]
                    ? t("global.buttons.restoring")
                    : t("global.buttons.restore")}{" "}
                  <RestoreOutlinedIcon />
                </Button>
              )}
            </ButtonGroup>
            {openDialog && (
              <DeleteDialog
                open={openDialog}
                onClose={handleClose}
                name={JSON.parse(selectedPreload).preload_name}
                deleteMethod={deletePreload}
                url={`/kobo/delete/preload/${JSON.parse(selectedPreload).id}`}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "preload_name",
      headerName: t("kobo.table.preload_name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: t("kobo.table.created_by"),
      flex: 1,
      valueGetter: (params) =>
        params.row?.user?.first_name + " " + params.row?.user?.last_name || "",
    },
    {
      field: "created_at",
      headerName: t("kobo.table.created_at"),
      flex: 1,
      valueGetter: (params) =>
        moment(params.row?.created_at).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      field: "fields",
      headerName: t("kobo.table.fields"),
      flex: 0.5,
      valueGetter: (params) => params.row?.fields?.length || 0,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("kobo.preloads.kobo_form_preloads")}
        subtitle={
          (koboForm?.title ? ` ${koboForm.title}` : "") +
          " " +
          t("kobo.preloads.preloads")
        }
      />
      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" href="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" href="/data/kobo">
          <Typography variant="body2">{t("sidebar.Kobo")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("kobo.preloads.preloads")}
        </Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="end" mt="20px">
        <Link href={`/data/kobo/create/preload/${id}`} underline="none">
          <Button color="secondary" variant="contained" size="small">
            {t("kobo.preloads.create_preload")}
          </Button>
        </Link>
      </Box>

      <Box className="data_grid_box_container">
        {preloads?.length < 1 ? (
          <Alert severity="info">{t("kobo.preloads.no_preloads")}</Alert>
        ) : (
          <StyledDataGrid
            rows={preloads}
            columns={columns}
            pagination
            pageSize={pagination.perPage}
            rowCount={pagination.total}
            onPageChange={handlePageChange}
            loading={isLoading}
            paginationMode="server"
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={default_rows_per_page_options}
          />
        )}
      </Box>
    </Box>
  );

  return content;
};

export default KoboFormPreloads;
