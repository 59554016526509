import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "../api/apiSlice";
import authReducer from "../features/auth/authSlice";
import languageReducer from "../features/settings/languageSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import i18n from "../i18n";
import themeReducer from "../features/settings/themeSlice";
import downloadPdfReducer from "../features/beneficiaries/downloadPdfSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  language: languageReducer,
  theme: themeReducer,
  pdf: downloadPdfReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: [thunk, apiSlice.middleware],
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store, null, () => {
  const selectedLanguage = store.getState().language.selectedLanguage;
  i18n.changeLanguage(selectedLanguage);
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
