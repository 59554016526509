import { apiSlice } from "../../api/apiSlice";

export const donorssApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Donors"],
  endpoints: (builder) => ({
    addDonor: builder.mutation({
      query: (payload) => ({
        url: "/donor/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Donors"],
    }),
    getDonors: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
          const queryString = new URLSearchParams({
              ...apiFilters,
              page: page,
              pageSize: pageSize
          }).toString();

          return {
              url: `/donor/list/${pageSize}?${queryString}`,
              method: "GET",
          };
      },
      providesTags: ["Donors"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateDonor: builder.mutation({
      query: (payload) => ({
        url: `/donor/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Donors"],
    }),
    deleteDonor: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Donors"],
    }),
  }),
});

export const {
  useAddDonorMutation,
  useGetDonorsQuery,
  useUpdateDonorMutation,
  useDeleteDonorMutation,
} = donorssApiSlice;
