import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../Header";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import StatBox from "../../global/shared/StatBox";
import ProgressCircle from "../../global/shared/ProgressCircle";
import Spinner from "../../global/Spinner";
import {
  useGetDashboardStatsQuery,
} from "../../../features/dashboard/dashboardApiSlice";
import PieChart from "../../global/shared/PieChart";
import AssessmentIcon from "@mui/icons-material/Assessment";
import InsightsIcon from "@mui/icons-material/Insights";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { data, isLoading } = useGetDashboardStatsQuery();

  const stats = data?.data;
  const cashData = data?.cash;
  const copayData = data?.copay_data;
  const redemptions = data?.redemptions;

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title={t("home.index.title")}
          subtitle={t("home.index.subtitle")}
        />
      </Box>

      {/* Grid and charts*/}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* Row 1*/}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={stats?.projects}
            subtitle={t('sidebar.Projects')}
            progress="0.75"
            increase="+14%"
            icon={
              <AssessmentIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={stats?.interventions}
            subtitle={t('sidebar.Interventions')}
            progress="0.5"
            increase="+21%"
            icon={
              <InsightsIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={stats?.beneficiaries}
            subtitle={t('sidebar.Beneficiaries')}
            progress="0.30"
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={stats?.entitlements}
            subtitle={t('sidebar.Entitlements')}
            progress="0.80"
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* Row 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                {t('home.cards.transactions')}
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[100]}
              ></Typography>
            </Box>

          </Box>
          <Box height="250px" mt="-20px">
            <PieChart />
          </Box>
        </Box>

        {/* Transactions */}
        {/*Start project progress*/}
        <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            p="30px"
            overflow="auto"
        >
          <Typography variant="h5" fontWeight="600">
            {t('home.cards.progress')}
          </Typography>
          <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
                variant="h5"
                color={colors.greenAccent[500]}
                sx={{ mt: "15px" }}
            >
            </Typography>
          </Box>
        </Box>
        {/*End project progress*/}

        {/* Row 3 */}
        <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            overflow="auto"
        >
          <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.grey[100]}
              p="15px"
          >
            <Typography variant="h5" color={colors.grey[400]} fontWeight="600">
              {t('home.cards.synced_cash')}
            </Typography>
          </Box>
          {cashData &&
              cashData?.map((cash, i) => (
                  <Box
                      key={cash?.id}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom={`4px solid ${colors.primary[500]}`}
                      colors={colors.grey[100]}
                      p="15px"
                  >
                    <Box>
                      <Typography
                          variant="h5"
                          color={colors.greenAccent[500]}
                          fontWeight="600"
                      >
                        {cash.transaction_id.length > 10
                            ? cash.transaction_id.substring(0, 10) + "..."
                            : cash.transaction_id}
                      </Typography>
                      <Typography color={colors.greenAccent[100]}>
                        {cash?.beneficiary?.full_name}
                      </Typography>
                    </Box>
                    <Box color={colors.grey[100]}>
                      {cash.transaction_id.length > 10
                          ? cash.transaction_id.substring(0, 10) + "..."
                          : cash.transaction_id}
                    </Box>
                    <Box
                        backgroundColor={colors.greenAccent[500]}
                        p="5px 10px"
                        borderRadius="4px"
                    >
                      {cash.transaction_amount}
                    </Box>
                  </Box>
              ))}
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography variant="h5" color={colors.grey[400]} fontWeight="600">
            {t('home.cards.synced_copay')}
            </Typography>
          </Box>
          <Box height="250px">
            {copayData &&
              copayData?.map((copay, i) => (
                <Box
                  key={copay?.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  colors={colors.grey[100]}
                  p="15px"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      fontWeight="600"
                    >
                      {copay.transaction_id.length > 10
                        ? copay.transaction_id.substring(0, 10) + "..."
                        : copay.transaction_id}
                    </Typography>
                    <Typography color={colors.greenAccent[100]}>
                      {copay?.beneficiary?.full_name}
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>
                    {copay.transaction_id.length > 10
                      ? copay.transaction_id.substring(0, 10) + "..."
                      : copay.transaction_id}
                  </Box>
                  <Box
                    backgroundColor={colors.greenAccent[500]}
                    p="5px 10px"
                    borderRadius="4px"
                  >
                    {copay.transaction_amount}
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography variant="h5" color={colors.grey[400]} fontWeight="600">
            {t('home.cards.synced_redemptions')}
            </Typography>
          </Box>
          <Box height="250px">
            {redemptions &&
              redemptions?.map((redemption, i) => (
                <Box
                  key={redemption?.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  colors={colors.grey[100]}
                  p="15px"
                >
                  <Box>
                    <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      fontWeight="600"
                    >
                      {redemption.transaction_id.length > 10
                        ? redemption.transaction_id.substring(0, 10) + "..."
                        : redemption.transaction_id}
                    </Typography>
                    <Typography color={colors.greenAccent[100]}>
                      {redemption?.beneficiary?.full_name}
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>
                    {redemption.transaction_id.length > 10
                      ? redemption.transaction_id.substring(0, 10) + "..."
                      : redemption.transaction_id}
                  </Box>
                  <Box
                    backgroundColor={colors.greenAccent[500]}
                    p="5px 10px"
                    borderRadius="4px"
                  >
                    {redemption.grand_total}
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return content;
};

export default Dashboard;
