import React from "react";
import Header from "../../Header";
import { Box, TextField, Button, Typography, Breadcrumbs } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useUpdateEntitlementMutation } from "../../../features/entitlements/entitlementsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditEntitlement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const entitlement = location.state.entitlement;

  const initialValues = {
    title: entitlement.title,
    description: entitlement.description,
    amount: entitlement.amount,
    unit: entitlement.unit,
    max_redeemable: entitlement.max_redeemable,
  };

  const entitlementsSchema = yup.object().shape({
    title: yup.string().required(t("entitlement_type.form.helpertext.title")),
    description: yup
      .string()
      .required(t("entitlement_type.form.helpertext.description")),
    // amount: yup.string().required(t('entitlement_type.form.helpertext.amount')),
    unit: yup.string().required(t("entitlement_type.form.helpertext.unit")),
    // max_redeemable: yup.string().required(t('entitlement_type.form.helpertext.max_redeemable')),
  });

  const [updateEntitlement, { isLoading }] = useUpdateEntitlementMutation();

  const handleFormSubmit = async (values) => {
    const payload = { ...values, id: entitlement.id };
    try {
      const response = await updateEntitlement(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(function () {
        navigate("/interventions/entitlements", {
          state: { message: response?.message },
        });
      }, 3000);
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("entitlements.edit.title")}
        subtitle={t("entitlements.edit.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link
          style={LinkColor()}
          color="inherit"
          to="/interventions/entitlements"
        >
          <Typography variant="body2">{t("sidebar.Entitlements")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("entitlements.edit.edit_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={entitlementsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("global.table.name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={3}
                type="text"
                label={t("global.table.description")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('entitlements.form.label.amount')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={!!touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
              /> */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("entitlements.form.label.unit")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.unit}
                name="unit"
                error={!!touched.unit && !!errors.unit}
                helperText={touched.unit && errors.unit}
              />
              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('entitlements.form.label.max_redeemable')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.max_redeemable}
                name="max_redeemable"
                error={!!touched.max_redeemable && !!errors.max_redeemable}
                helperText={touched.max_redeemable && errors.max_redeemable}
              /> */}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Link to="/interventions/entitlements" underline="none">
                <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("entitlements.edit.edit_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default EditEntitlement;
