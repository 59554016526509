import React, { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useUpdateEntitlementCriteriaMutation } from "../../../features/entitlement_criteria/entitlementCriteriaApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const EditEntitlementCriteria = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const entitlementCriteria = location.state.entitlementCriteria;

  const initialValues = {
    title: entitlementCriteria.title,
    description: entitlementCriteria.description,
    selection_criteria: entitlementCriteria.selection_criteria,
    formula: entitlementCriteria.formula,
  };

  const entitlementCriteriaSchema = yup.object().shape({
    title: yup.string().required(t('criteria.form.helpertext.title')),
    description: yup.string().required(t('criteria.form.helpertext.description')),
    selection_criteria: yup.string().required(t('criteria.form.helpertext.criteria')),
    formula: yup.string().required(t('criteria.form.helpertext.formula')),
  });

  const [updateEntitlementCriteria, { isLoading }] =
    useUpdateEntitlementCriteriaMutation();

  const handleFormSubmit = async (values) => {
    const payload = { ...values, id: entitlementCriteria.id };
    try {
      const response = await updateEntitlementCriteria(payload).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(
          function () {
            navigate("/interventions/entitlement-criteria", { state: { message: response?.message } });
          },
          3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t('criteria.edit.title')}
        subtitle={t('criteria.edit.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()}
 color="inherit" to="/">
          <Typography variant="body2">{t('sidebar.Home')}</Typography>
        </Link>
        <Link style={LinkColor()}
 color="inherit" to="/interventions">
          <Typography variant="body2">{t('sidebar.Interventions')}</Typography>
        </Link>
        <Link style={LinkColor()}
 color="inherit" to="/interventions/entitlement-criteria">
          <Typography variant="body2">{t('criteria.form.label.eligibility_criteria')}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t('criteria.edit.edit_button')}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={entitlementCriteriaSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('criteria.form.label.title')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('criteria.form.label.criteria')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.selection_criteria}
                name="selection_criteria"
                error={
                  !!touched.selection_criteria && !!errors.selection_criteria
                }
                helperText={
                  touched.selection_criteria && errors.selection_criteria
                }
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={4}
                type="text"
                label={t('global.form.label.description')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t('criteria.form.label.formula')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.formula}
                name="formula"
                error={!!touched.formula && !!errors.formula}
                helperText={touched.formula && errors.formula}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
            <Link
                  to="/interventions/entitlement-criteria"
                  underline="none"
                >
              <button className="btn btn-danger">
                  {t('global.page.cancel_button')}
              </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t('criteria.edit.edit_button')}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default EditEntitlementCriteria;
