import React, { useState, useEffect } from "react";
import Header from "../../Header";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useGetEntitlementTypesQuery } from "../../../features/entitlement_types/entitlementTypesApiSlice";
import { useAddEntitlementMutation } from "../../../features/entitlements/entitlementsApiSlice";
import Spinner from "../../global/Spinner";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const initialValues = {
  project_id: "",
  intervention_id: "",
  entitlement_type_id: "",
  title: "",
  description: "",
  amount: "0",
  unit: "",
  max_redeemable: "0",
};

const AddEntitlement = () => {
  const { t } = useTranslation();
  const default_rows_per_page = Number(
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE
  );
  const default_rows_per_page_options =
    process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const entitlementsSchema = yup.object().shape({
    project_id: yup
      .string()
      .required(t("global.shared.form.helpertext.project")),
    intervention_id: yup
      .string()
      .required(t("global.shared.form.helpertext.intervention")),
    entitlement_type_id: yup
      .string()
      .required(t("global.shared.form.helpertext.entitlement_type")),
    title: yup.string().required(t("entitlement_type.form.helpertext.title")),
    // amount: yup.string().required(t('entitlement_type.form.helpertext.amount')),
    description: yup.string(),
    unit: yup.string().required(t("entitlement_type.form.helpertext.unit")),
    // max_redeemable: yup
    //   .string()
    //   .required(t("entitlement_type.form.helpertext.max_redeemable")),
  });
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [interventions, setInterventions] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: entitlementsSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const {
    data: projectsData,
    isLoading: fetchingProjects,
    isSuccess: projectSuccess,
  } = useGetProjectsQuery({ page: 1, pageSize: default_rows_per_page });
  useEffect(() => {
    if (projectsData?.data) setProjects(projectsData?.data);
  }, [projectsData, projectSuccess]);

  const {
    data: interventionsData,
    isLoading: fetchingInterventions,
    isSuccess: interventionsSuccess,
  } = useGetInterventionsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (interventionsData?.data) {
      const projectId = formik.values.project_id;

      if (projectId) {
        const interventions = interventionsData?.data?.filter(
          (intervention) => {
            return intervention?.project?.id === projectId;
          }
        );
        setInterventions(interventions);
      }
    }
  }, [interventionsData, interventionsSuccess, formik.values.project_id]);

  const { data, isLoading: fetchingEntitlementTypes } =
    useGetEntitlementTypesQuery({ page: 1, pageSize: default_rows_per_page });
  const entitlementTypes = data ? data.data : [];

  const [addEntitlement, { isLoading }] = useAddEntitlementMutation();

  const handleFormSubmit = async (values) => {
    try {
      const response = await addEntitlement(values).unwrap();

      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }

      setTimeout(function () {
        navigate("/interventions/entitlements", {
          state: { message: response?.message },
        });
      }, 3000);
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };
  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("entitlements.index.title")}
        subtitle={t("entitlements.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Link
          style={LinkColor()}
          color="inherit"
          to="/interventions/entitlements"
        >
          <Typography variant="body2">{t("sidebar.Entitlements")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("entitlements.index.add_button")}
        </Typography>
      </Breadcrumbs>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={entitlementsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={formik.handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingProjects ? (
                <p>{t("global.loading.project")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.project")}
                  name="project_id"
                  value={formik.values.project_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.project_id && !!formik.errors.project_id
                  }
                  helperText={
                    formik.touched.project_id && formik.errors.project_id
                  }
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.code} - {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {fetchingInterventions ? (
                <p>{t("global.loading.intervention")}</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    projects?.length > 0 && interventions?.length === 0
                      ? t("global.form.helpertext.no_intervention")
                      : t("global.form.helpertext.intervention")
                  }
                  name="intervention_id"
                  value={formik.values.intervention_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.intervention_id &&
                    !!formik.errors.intervention_id
                  }
                  helperText={
                    formik.touched.intervention_id &&
                    formik.errors.intervention_id
                  }
                >
                  {interventions &&
                    interventions.map((intervention) => (
                      <MenuItem key={intervention.id} value={intervention.id}>
                        {intervention.title}
                      </MenuItem>
                    ))}
                </TextField>
              )}

              {fetchingEntitlementTypes ? (
                <Spinner />
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("global.form.helpertext.entitlement_type")}
                  name="entitlement_type_id"
                  value={formik.values.entitlement_type_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.entitlement_type_id &&
                    !!formik.errors.entitlement_type_id
                  }
                  helperText={
                    formik.touched.entitlement_type_id &&
                    formik.errors.entitlement_type_id
                  }
                >
                  {entitlementTypes.map((entitlementType) => (
                    <MenuItem
                      key={entitlementType.id}
                      value={entitlementType.id}
                    >
                      {entitlementType.type}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("global.table.name")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.title}
                name="title"
                error={!!formik.touched.title && !!formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
              />

              <TextField
                fullWidth
                variant="filled"
                multiline
                rows={3}
                type="text"
                label={t("global.table.description")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                name="description"
              />
              {/* <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t('entitlements.form.label.amount')}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.amount}
                name="amount"
                error={!!formik.touched.amount && !!formik.errors.amount}
                helperText={formik.touched.amount && formik.errors.amount}
              /> */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("entitlements.form.label.unit")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.unit}
                name="unit"
                error={!!formik.touched.unit && !!formik.errors.unit}
                helperText={formik.touched.unit && formik.errors.unit}
              />
              {/* <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t("entitlements.form.label.max_redeemable")}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                value={formik.values.max_redeemable}
                name="max_redeemable"
                error={
                  !!formik.touched.max_redeemable &&
                  !!formik.errors.max_redeemable
                }
                helperText={
                  formik.touched.max_redeemable && formik.errors.max_redeemable
                }
              /> */}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Link to="/interventions/entitlements" underline="none">
                <button className="btn btn-danger">
                  {t("global.page.cancel_button")}
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {t("entitlements.add.create_button")}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default AddEntitlement;
