import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Typography,
  Breadcrumbs, Alert,
} from "@mui/material";
import Header from "../../Header";
import { useNavigate, Link } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  useGetEntitlementTypesQuery,
  useDeleteEntitlementTypeMutation,
} from "../../../features/entitlement_types/entitlementTypesApiSlice";
import Spinner from "../../global/Spinner";
import DeleteDialog from "../shared/DeleteDialog";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "../../../features/auth/authSlice";

const EntitlementTypes = () => {
  const currentPermissions = useSelector(selectCurrentPermissions);
  const canAdd = currentPermissions.some(permission => permission.name === 'entitlement-type-add');
  const canDelete = currentPermissions.some(permission => permission.name === 'entitlement-type-delete');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedEntitlementType, setSelectedEntitlementType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [entitlementTypes, setEntitlementTypes] = useState([]);
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: default_rows_per_page,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(default_rows_per_page);

  // Keep track of the filter model to pass to the API
  const [filterModel, setFilterModel] = useState([]);
  // Event handler for filter changes
  const handleFilterModelChange = (params) => {
    setFilterModel(params.items);
  };
  // Get the filters from the filter model and convert them to an object
  const getApiFilters = () => {
    const apiFilters = {};

    filterModel?.forEach((item) => {
      if (item.columnField && item.value) {
        apiFilters[`filters[${item.columnField}][$contains]`] = item.value;
      }
    });


    return apiFilters;
  };

  const { data, isLoading, isSuccess, isFetching } =
    useGetEntitlementTypesQuery({
      page,
      pageSize,
      ...getApiFilters(),
    });

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
    setPage(1);
  };

  useEffect(() => {
    if (data?.data) setEntitlementTypes(data?.data);
    if (data?.meta)
      setPagination({
        currentPage: data?.meta?.current_page,
        lastPage: data?.meta?.last_page,
        perPage: data?.meta?.per_page,
        total: data?.meta?.total,
      });
  }, [data, isSuccess, pagination.currentPage, pageSize]);

  // Reset the page number when the filters change
  useEffect(() => {
    setPage(1); // Set to the first page whenever filters change
  }, [filterModel]);

  const [deleteEntitlementType] = useDeleteEntitlementTypeMutation();

  const handleEdit = (entitlementType) => {
    entitlementType = JSON.parse(entitlementType);
    navigate(`/interventions/entitlement-types/edit/${entitlementType.id}`, {
      state: { entitlementType },
    });
  };

  const handleClickOpen = (entitlementType) => {
    setSelectedEntitlementType(entitlementType);
    setOpenDialog(true);
  };

  const handleClose = (value) => {
    setOpenDialog(false);
    setSelectedEntitlementType(value);
  };

  const columns = [
    {
      field: "action",
      headerName: t("global.table.actions"),
      flex: 0.5,
      renderCell: (params) => {
        const getEntitlementType = () => {
          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
          <Box display="flex" flexDirection="column">
            {canDelete && (

            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              size="small"
            >
              <Button
                color="error"
                onClick={() => handleClickOpen(getEntitlementType())}
              >
                {t("global.table.delete_button")} <DeleteOutlineOutlinedIcon />
              </Button>
            </ButtonGroup>
            )}

            {openDialog && (
              <DeleteDialog
                open={openDialog}
                onClose={handleClose}
                name={JSON.parse(selectedEntitlementType).type}
                deleteMethod={deleteEntitlementType}
                url={`/entitlement/type/delete/${
                  JSON.parse(selectedEntitlementType).id
                }`}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: t("global.table.type"),
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: t("global.table.description"),
      flex: 1,
      minWidth: 200,
    },
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
        title={t("entitlement_types.index.title")}
        subtitle={t("entitlement_types.index.subtitle")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/interventions">
          <Typography variant="body2">{t("sidebar.Interventions")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Entitlement Types")}
        </Typography>
      </Breadcrumbs>

      {canAdd && (
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/interventions/entitlement-types/add" underline="none">
          <button className="btn btn-primary">
              {t("entitlement_types.index.add_button")}
          </button>
        </Link>
      </Box>
      )}


      <Box className="data_grid_box_container">
        {entitlementTypes?.length < 1 ? (
            <Alert severity="info">{t("entitlement_types.index.no_entitlement_types_data")}</Alert>
        ) : (
            <StyledDataGrid
                rows={entitlementTypes}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pagination.perPage}
                rowCount={pagination.total}
                onFilterModelChange={handleFilterModelChange}
                loading={isFetching}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={default_rows_per_page_options}
            />
        )}
      </Box>
    </Box>
  );

  return content;
};

export default EntitlementTypes;
