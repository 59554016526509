import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { useSelector  } from 'react-redux'
import { selectCurrentToken } from '../features/auth/authSlice'
import { selectCurrentPermissions } from "../features/auth/authSlice";

const PrivateRoutes = () => {
    const token = useSelector(selectCurrentToken)
    const location = useLocation();
    const currentPermissions = useSelector(selectCurrentPermissions);
    
    if (token && currentPermissions.length > 0 && currentPermissions !== null) {
        // Token exists and permissions length is more than 0
        return <Outlet />;
      } else if (!token) {
        // Token doesn't exist but permissions length is more than 0
        return <Navigate to="/login" state={{ from: location }} replace />;
      } else if (token && currentPermissions.length === 0 && currentPermissions !==null) {
        // Token exists but permissions length is 0
        return <Navigate to="/unauthorized" state={{ from: location }} replace />;
      }
}

export default PrivateRoutes