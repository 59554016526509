import { useState, useEffect } from "react";
import zxcvbn from "zxcvbn";
import {
  Box,
  TextField,
  LinearProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordStrengthChecker = ({
  password,
  setPassword,
  confirm_password,
  setConfirmPassword,
}) => {
  const { t } = useTranslation();
  const [strength, setStrength] = useState(0);
  const [strongPassword, setStrongPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const result = zxcvbn(password);
    setStrength(result.score);
    setStrongPassword(result.feedback.warning ? false : true);
  }, [password]);

  const passwordMatch = password === confirm_password;
  const isStrong = strength >= 3;

  const getPasswordCriteria = () => {
    const criteria = [
      {
        text: t("global.password.eight_characters"),
        met: password.length >= 8,
      },
      {
        text: t("global.password.lower_and_uppercase"),
        met: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
      },
      { text: t("global.password.one_number"), met: /\d/.test(password) },
      {
        text: t("global.password.special_character"),
        met: /[^a-zA-Z0-9]/.test(password),
      },
      {
        text: t("global.password.compromised_password"),
        met: strongPassword,
      },
    ];

    return criteria.map((condition, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          {condition.met ? (
            <IconButton color="success" aria-label="password-matching">
              <CheckCircleOutlinedIcon />
            </IconButton>
          ) : (
            <IconButton color="error" aria-label="password-not-matching">
              <CancelIcon />
            </IconButton>
          )}
        </ListItemIcon>
        <ListItemText primary={condition.text} />
      </ListItem>
    ));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box display="grid" gap="20px">
      <TextField
        required
        fullWidth
        variant="filled"
        type={showPassword ? "text" : "password"}
        label={t("users.form.label.password")}
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        name="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {password && (
        <>
          <LinearProgress
            variant="determinate"
            value={isStrong ? 100 : (strength + 1) * 25}
            color={
              isStrong
                ? "success"
                : strength < 50
                ? "error"
                : strength < 75
                ? "warning"
                : "success"
            }
            sx={{
              marginTop: "-20px",
            }}
          />
          <List>{getPasswordCriteria()}</List>
        </>
      )}

      <TextField
        required
        fullWidth
        variant="filled"
        type={showPassword ? "text" : "password"}
        label={t("users.form.label.confirm_password")}
        value={confirm_password}
        name="confirm_password"
        onChange={(e) => setConfirmPassword(e.target.value)}
        InputProps={{
          endAdornment: confirm_password ? (
            passwordMatch ? (
              <IconButton color="success" aria-label="password-matching">
                <CheckCircleOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton color="error" aria-label="password-not-matching">
                <CancelIcon />
              </IconButton>
            )
          ) : null,
        }}
      />
    </Box>
  );
};

export default PasswordStrengthChecker;
