import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Tooltip,
  Badge,
} from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { SidebarData } from "./SidebarData";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectCurrentRoles,
  selectCurrentPermissions,
} from "../../features/auth/authSlice";
import { useTranslation } from "react-i18next";
import {
  useGetVoucherRedemptionsQuery,
  useGetInkindRedemptionsQuery,
} from "../../features/redemptions/redemptionApiSlice";
import { useGetCopayDataQuery } from "../../features/copay/copayApiSlice";
import { useGetCashDataQuery } from "../../features/copay/copayApiSlice";
import { LinkColor } from "../../theme";

// Custom hook to make API calls and return the count and loading state
const useApiQuery = (queryFn, params) => {
  const { data, isLoading, isSuccess, isFetching } = queryFn(params);
  const count = data?.meta?.total;
  return { count, isLoading, isSuccess, isFetching };
};

const Item = ({
  title,
  to,
  icon,
  subNav,
  selected,
  setSelected,
  tooltip,
  dataCount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  // Helper function to format the count
  const formatCount = (count) => {
    if (count < 1e3) return count; // less than 1,000
    if (count < 1e6) return (count / 1e3).toFixed(1) + "K"; // less than 1 million
    if (count < 1e9) return (count / 1e6).toFixed(1) + "M"; // less than 1 billion
    if (count < 1e12) return (count / 1e9).toFixed(1) + "B"; // less than 1 trillion
    return (count / 1e12).toFixed(1) + "T"; // 1 trillion and above
  };

  // Helper function to determine if tooltip should be shown
  const shouldShowTooltip = (title) => {
    const tooltipTitles = [
      "E-Voucher",
      "Voucher",
      "In-Kind",
      "Copay Data",
      "Cash",
    ];
    return tooltipTitles.includes(title);
  };

  return (
    <>
      {!subNav &&
        (shouldShowTooltip(title) ? (
          <Tooltip
            title={`${
              dataCount >= 0 ? formatCount(dataCount) : 0
            } ${title} records`}
            placement="right"
          >
            <div
              className={`react-pro-sidebar-style ${
                dataCount === 0 ? "disabled-item" : ""
              }`}
            >
              <MenuItem
                active={selected === title}
                style={{ color: colors.grey[100] }} // this only affects level 1 menus that dont have submenus
                onClick={() => setSelected(title)}
                icon={icon}
                disabled-item="true" //{dataCount === 0 ? true : false}
              >
                <Typography>
                  {title}
                  {typeof dataCount !== "undefined" && (
                    <Badge
                      color="primary"
                      badgeContent={dataCount >= 0 ? formatCount(dataCount) : 0}
                      max={999} // Limit the max number shown in the badge
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      sx={{ ml: 3 }} // To adjust the badge position
                      // showZero // Ensures the badge displays even if the content is zero
                    ></Badge>
                  )}
                </Typography>
                <Link style={LinkColor()} to={to} />
              </MenuItem>
            </div>
          </Tooltip>
        ) : (
          <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }} // this only affects level 1 menus that dont have submenus
            onClick={() => setSelected(title)}
            icon={icon}
          >
            <Typography>{title}</Typography>
            <Link id={title} style={LinkColor()} to={to} />
          </MenuItem>
        ))}

      {subNav && (
        <SubMenu
          title={<Typography>{title}</Typography>}
          style={{ color: colors.grey[100] }} // this only affects level 1 menus that have submenus
          icon={icon}
        >
          {subNav.map((item, index) => {
            return (
              <Item
                id={index}
                title={t(`${item.title}`)}
                to={item.to}
                icon={item.icon}
                subNav={item.subNav}
                selected={selected}
                setSelected={setSelected}
                tooltip={item.tooltip}
                dataCount={item.dataCount}
                key={index}
              />
            );
          })}
        </SubMenu>
      )}
    </>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard"); // @todo will the word "Dashboard" still work when in French?

  const { count: redemptionsCount } = useApiQuery(
    useGetVoucherRedemptionsQuery,
    { page: 1, pageSize: 1 }
  );
  const { count: inkindRedemptionsCount } = useApiQuery(
    useGetInkindRedemptionsQuery,
    { page: 1, pageSize: 1 }
  );
  const { count: copayCount } = useApiQuery(useGetCopayDataQuery, {
    page: 1,
    pageSize: 1,
  });
  const { count: cashCount } = useApiQuery(useGetCashDataQuery, {
    page: 1,
    pageSize: 1,
  });
  const currentPermissions = useSelector(selectCurrentPermissions);

  const enrichSubNavWithCounts = (subNav) => {
    return subNav.map((item) => {
      switch (item.title) {
        case "Voucher":
          return { ...item, dataCount: redemptionsCount };
        case "E-Voucher":
          return { ...item, dataCount: redemptionsCount };
        case "In-Kind":
          return { ...item, dataCount: inkindRedemptionsCount };
        case "Copay Data":
          return { ...item, dataCount: copayCount };
        case "Cash":
          return { ...item, dataCount: cashCount };
        default:
          return item;
      }
    });
  };

  const enrichSidebarDataWithCounts = () => {
    return SidebarData.map((item) => {
      if (item.subNav) {
        const subNavWithPermissions = item.subNav.filter((subNavItem) => {
          return currentPermissions.some(
            (permission) => permission.name === subNavItem.allowed
          );
        });
        return {
          ...item,
          subNav: subNavWithPermissions,
        };
      }
      return item;
    }).filter((item) => {
      if (currentPermissions) {
        return currentPermissions.some(
          (permission) => permission.name === item.allowed
        );
      }
    });
  };

  const content = (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
          height: "40px",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.faoOrange[500]} !important`,
        },
        "& .pro-menu-item.active": {
          color: `${colors.faoOrange[500]} !important`,
          borderLeft: `1px solid ${colors.faoOrange[500]} !important`,
          backgroundColor: `${colors.faoBlue[900]} !important`,
          borderRadius: "5px",
        },
        "& .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus": {
          color: `${colors.faoOrange[500]} !important`,
        },
        "& .pro-menu-item.open": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* Logo and Menu icon */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color={colors.faoBlue[500]}
                >
                  IDEA Web
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* Menu Items */}
          <Box paddingLeft={isCollapsed ? undefined : "5px"}>
            {enrichSidebarDataWithCounts().map((item, index) => {
              return (
                <Item
                  title={t(`${item.title}`)}
                  to={item.to}
                  icon={item.icon}
                  subNav={item.subNav}
                  selected={selected}
                  setSelected={setSelected}
                  key={index}
                />
              );
            })}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );

  return content;
};

export default Sidebar;
