import { apiSlice } from "../../api/apiSlice";

export const permissionsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Permissions"],
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: () => ({
        url: "/permissions/list",
        method: "GET",
      }),
      providesTags: ["Permissions"],
      keepUnusedDataFor: 2,
    }),
  }),
});

export const { useGetPermissionsQuery } = permissionsApiSlice;