import { apiSlice } from "../../api/apiSlice";

export const interventionsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Interventions",
    "InterventionServiceProviders",
    "InterventionBeneficiaries",
    "InterventionRoles",
    "RoleInterventions",
  ],
  endpoints: (builder) => ({
    addIntervention: builder.mutation({
      query: (payload) => ({
        url: "/intervention/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Interventions"],
    }),
    getInterventions: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/intervention/list/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Interventions"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getIntervention: builder.query({
      query: (id) => ({
        url: `/intervention/single/${id}`,
        method: "GET",
      }),
    }),
    updateIntervention: builder.mutation({
      query: (payload) => ({
        url: `/intervention/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Interventions"],
    }),
    deleteIntervention: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Interventions"],
    }),
    getInterventionBeneficiaries: builder.query({
      query: ({ id, page, pageSize }) => ({
        url: `/intervention/${id}/list/beneficiaries/${pageSize}?page=${page}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      providesTags: ["InterventionBeneficiaries"],
    }),
    getInterventionServiceProviders: builder.query({
      query: ({ id, page, pageSize }) => ({
        url: `/intervention/${id}/list/service_providers/${pageSize}?page=${page}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      providesTags: ["InterventionServiceProviders"],
    }),
    addInterventionBeneficiaries: builder.mutation({
      query: (payload) => ({
        url: "intervention/add/beneficiaries",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["InterventionBeneficiaries"],
    }),
    addInterventionServiceProviders: builder.mutation({
      query: (payload) => ({
        method: "POST",
        url: "/intervention/add/service_providers",
        body: { ...payload },
      }),
      invalidatesTags: [
        "Interventions",
        "ServiceProviders",
        "InterventionServiceProviders",
      ],
    }),
    // New endpoint to get roles in intervention
    getInterventionRoles: builder.query({
      query: ({ id, page, pageSize }) => ({
        url: `/intervention/${id}/list/roles/${pageSize}?page=${page}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,
      providesTags: ["InterventionRoles"], // Tags for caching
    }),
    // New mutation endpoint to add roles to intervention
    addInterventionRole: builder.mutation({
      query: (payload) => ({
        url: "/intervention/add/role",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["InterventionRoles"], // Invalidate cache after mutation
    }),
    // New mutation endpoint to delete role from intervention
    deleteInterventionRole: builder.mutation({
      query: (payload) => ({
        url: "/intervention/remove/role",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["InterventionRoles"],
    }),

    // New mutation endpoint to add intervention to roles
    addRoleIntervention: builder.mutation({
      query: (payload) => ({
        url: "/role/add/interventions",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["RoleInterventions"], // Invalidate cache after mutation
    }),
    // New mutation endpoint to delete intervention from role
    deleteRoleIntervention: builder.mutation({
      query: (payload) => ({
        url: "/role/remove/interventions",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["RoleInterventions"],
    }),
  }),
});

export const {
  useAddInterventionMutation,
  useGetInterventionsQuery,
  useGetInterventionQuery,
  useUpdateInterventionMutation,
  useDeleteInterventionMutation,
  useGetInterventionBeneficiariesQuery,
  useGetInterventionServiceProvidersQuery,
  useAddInterventionBeneficiariesMutation,
  useAddInterventionServiceProvidersMutation,
  useGetInterventionRolesQuery,
  useAddInterventionRoleMutation,
  useDeleteInterventionRoleMutation,
  useAddRoleInterventionMutation,
  useDeleteRoleInterventionMutation,
} = interventionsApiSlice;
