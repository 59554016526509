import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Button,
  Breadcrumbs
} from "@mui/material";
import { Formik, useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useGetProjectsQuery } from "../../../features/projects/projectsApiSlice";
import { useGetInterventionsQuery } from "../../../features/interventions/interventionsApiSlice";
import { useImportBeneficiaryDataMutation } from "../../../features/beneficiaries/beneficiariesApiSlice";
import Header from "../../Header";
import * as yup from "yup";
import FileUpload from "react-material-file-upload";
import { useForm } from "react-hook-form";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import Toast from "../../global/Toast";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const initialValues = {
  intervention_id: "",
  project_id: "",
};

const ImportBeneficiaries = () => {
  const { t } = useTranslation();
  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);
  const importSchema = yup.object().shape({
    intervention_id: yup.string().required(t("beneficiaries.import.intervention_required")),
  });
  const navigate = useNavigate();

  const token = useSelector(selectCurrentToken);

  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { register, handleRegister } = useForm();

  const [projects, setProjects] = useState([]);
  const [interventions, setInterventions] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: importSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const {
    data: projectsData,
    isLoading: fetchingProjects,
    isSuccess: projectSuccess,
  } = useGetProjectsQuery({ page: 1, pageSize: default_rows_per_page });
  useEffect(() => {
    if (projectsData?.data) setProjects(projectsData?.data);
  }, [projectsData, projectSuccess]);

  const {
    data: interventionsData,
    isLoading: fetchingInterventions,
    isSuccess: interventionsSuccess,
  } = useGetInterventionsQuery({ page: 1, pageSize: default_rows_per_page });

  useEffect(() => {
    if (interventionsData?.data) {
      const projectId = formik.values.project_id;

      if (projectId) {
        const interventions = interventionsData?.data?.filter(
          (intervention) => {
            return intervention?.project?.id === projectId;
          }
        );
        setInterventions(interventions);
      }
    }
  }, [interventionsData, interventionsSuccess, formik.values.project_id]);

  useImportBeneficiaryDataMutation();

  const handleFormSubmit = async (values) => {
    const payload = { ...values, beneficiary_data: files[0] };

    const url = `${process.env.REACT_APP_BASE_URL}/beneficiaries/import`;

    const importBeneficiaryData = new Promise((resolve) =>
      setTimeout(resolve, 10000)
    );

    toast.promise(importBeneficiaryData, {
      pending:t("beneficiaries.import.data"),
    });

    try {
      const response = await axios({
        method: "post",
        url: url,
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.data?.status === 200) {
        Toast(response?.data?.message, "success");
      } else {
        Toast(response?.data?.message, "error");
      }

      setTimeout(
        function () {
          navigate("/beneficiaries");
        },
        3000
      );
    } catch (err) {
      Toast(err?.data?.message, "error");
    }
  };

  const content = (
    <Box m="20px">
      <Header
        title={t("beneficiaries.import.beneficiaries")}
        subtitle={t("beneficiaries.import.beneficiaries_from_excel")}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Link style={LinkColor()} color="inherit" to="/beneficiaries">
          <Typography variant="body2">{t("sidebar.Beneficiaries")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">
          {t("sidebar.Import Beneficiaries")}
        </Typography>
      </Breadcrumbs>

      <ToastContainer />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={importSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={formik.handleSubmit}>
            <Box display="grid" gap="20px">
              {fetchingProjects ? (
                <p>Fetching Projects...</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={t("beneficiaries.import.choose_project")}
                  name="project_id"
                  value={formik.values.project_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.project_id && !!formik.errors.project_id
                  }
                  helperText={
                    formik.touched.project_id && formik.errors.project_id
                  }
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.code} - {project.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {fetchingInterventions ? (
                <p>Fetching Interventions...</p>
              ) : (
                <TextField
                  select
                  variant="filled"
                  label={
                    projects?.length > 0 && interventions?.length === 0
                      ? t("beneficiaries.import.no_interventions")
                      : t("beneficiaries.import.choose_intervention")
                  }
                  name="intervention_id"
                  value={formik.values.intervention_id}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.intervention_id &&
                    !!formik.errors.intervention_id
                  }
                  helperText={
                    formik.touched.intervention_id &&
                    formik.errors.intervention_id
                  }
                >
                  {interventions &&
                    interventions.map((intervention) => (
                      <MenuItem key={intervention.id} value={intervention.id}>
                        {intervention.title}
                      </MenuItem>
                    ))}
                </TextField>
              )}

              <Box sx={{ border: "1px solid #fff", borderRadius: "5px" }}>
                <Typography
                    color="text.secondary"
                    variant="h5"
                    gutterBottom
                >
                  {t("beneficiaries.import.file_to_upload")}: {t("beneficiaries.import.instructions")}
                </Typography>

                <Box
                    display="flex"
                    alignContent="center"
                    justifyContent="space-between"
                >
                  <Typography variant="h7" component="div">
                    <ul>
                      <li>{t("beneficiaries.import.only_xlsx")}</li>
                      <li>{t("beneficiaries.import.one_worksheet")}</li>
                      <li>{t("beneficiaries.import.no_hidden_columns")}</li>
                      <li>{t("beneficiaries.import.no_empty_rows")}</li>
                      <li>{t("beneficiaries.import.no_formatting")}</li>
                      <li>{t("beneficiaries.import.ignored_first_row")}</li>
                    </ul>
                  </Typography>
                </Box>

                <FileUpload
                    value={files}
                    onChange={setFiles}
                    title={t("beneficiaries.import.title_text")}
                    buttonText={t("beneficiaries.import.button_text")}
                    accept={'.xlsx'}
                />
              </Box>

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" size="small">
                {t("beneficiaries.import.upload_data")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
  return content;
};

export default ImportBeneficiaries;
