import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import React from "react";
import Toast from "../../global/Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Formik, Field } from "formik";
import * as yup from "yup";
import {useBlockBeneficiaryMutation} from "../../../features/beneficiaries/beneficiariesApiSlice";

const BlockBeneficiaryDialog = ({ onClose, name, open, beneficiaryData }) => {
  const { t } = useTranslation();

  const initialValues = {
    block_reason: ""
  };
  const beneficiarySchema = yup.object().shape({
    block_reason: yup.string().required(t('beneficiaries.view.block_reason'))
  });
  const [blockBeneficiary] = useBlockBeneficiaryMutation();

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      id: beneficiaryData.id
    };

    try {
      const response = await blockBeneficiary(payload).unwrap();
      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }
      onClose(values);
    } catch (err) {
      //console.log(err);
    }
  };

  const handleClose = async (value) => {
    onClose(value);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <ToastContainer />
      <DialogTitle>
        {t("global.dialog.block_heading")} <b>{name}</b>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">
          {t("global.dialog.block_content")} {name}? {" "}
        </Typography>
        {/*--*/}
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={beneficiarySchema}
        >
          {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                    autoFocus
                    fullWidth
                    variant="filled"
                    type="text"
                    multiline
                    maxRows={4}
                    required
                    label={t('beneficiaries.view.block_reason')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.block_reason}
                    name="block_reason"
                    error={!!touched.block_reason && !!errors.block_reason}
                    helperText={touched.block_reason && errors.block_reason}
                />

                <Box display="flex" gap="10px" justifyContent="end" mt="20px">
                  <button type="submit" className="btn btn-danger">
                    {t("global.dialog.buttons.block")}
                  </button>
                  <button autoFocus  onClick={onClose} className="btn btn-primary">
                    {t('global.page.cancel_button')}
                  </button>
                </Box>
              </form>
          )}
        </Formik>
        {/*--*/}
      </DialogContent>
      <DialogActions>
        {/*onClick={() => handleClose(false)}*/}
      </DialogActions>
    </Dialog>
  );
};

export default BlockBeneficiaryDialog;
