import { useState } from "react";
import Header from "../../Header";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, TextField, Button } from "@mui/material";
import { useChangePasswordMutation } from "../../../features/auth/changePasswordApiSlice";
import Spinner from "../../global/Spinner";
import { ToastContainer } from "react-toastify";
import Toast from "../../global/Toast";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordStrengthChecker from "../../../utils/PasswordStrengthChecker";
import { displayRequestErrors } from "../../../utils/ToastUtils";

const initialValues = {
  oldPassword: "",
};

const ChangePassword = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(
      t("change_password.form.helpertext.old_password")
    ),
  });

  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [changePassword, { isLoading, isSubmitting }] =
    useChangePasswordMutation();

  const handleFormSubmit = async (values) => {
    const payload = {
      old_password: values.oldPassword,
      password,
      confirm_password,
    };

    try {
      const response = await changePassword(payload).unwrap();
      if (response.status === 200) {
        Toast(response?.message, "success");
      } else {
        Toast(response?.message, "error");
      }
      navigate(from, { replace: true });
    } catch (err) {
      if (err?.status === 400) {
        displayRequestErrors(err?.data?.message);
      }
    }
  };

  const handleGoBack = () => {
    navigate(from, { replace: true });
  };

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <ToastContainer />
      <Header
        title={t("change_password.title")}
        subtitle={t("change_password.subtitle")}
      />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="20px">
              <TextField
                required
                fullWidth
                variant="filled"
                type="password"
                label={t("change_password.form.label.old_password")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.oldPassword}
                name="oldPassword"
                error={!!touched.oldPassword && !!errors.oldPassword}
                helperText={touched.oldPassword && errors.oldPassword}
              />
              <PasswordStrengthChecker
                password={password}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                confirm_password={confirm_password}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                color="info"
                variant="contained"
                sx={{ mr: 1, ml: 1 }}
                onClick={handleGoBack}
                size="small"
              >
                {t("global.page.cancel_button")}
              </Button>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
                size="small"
              >
                {isSubmitting
                  ? t("global.loading.submitting")
                  : t("change_password.submit")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );

  return content;
};

export default ChangePassword;
