import React from "react";
import { useGetPaymentsQuery } from "../../../features/payments/paymentsApiSlice";
import Spinner from "../../global/Spinner";
import {
  Box,
  ButtonGroup,
  Button,
  Alert,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import Header from "../../Header";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate, Link } from "react-router-dom";
import StyledDataGrid from "../../global/StyledDataGrid";
import { useTranslation } from "react-i18next";
import { LinkColor } from "../../../theme";

const Payments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetPaymentsQuery();
  const payments = data?.payments;

  const default_rows_per_page = Number(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE);
  const default_rows_per_page_options = process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE_OPTIONS.split(",").map(Number);

  const handleView = (payment) => {
    payment = JSON.parse(payment);
    navigate(`/payments/${payment.id}`, {
      state: { payment },
    });
  };

  const columns = [
    {
      field: "action",
      headerName: t('global.table.actions'),
      flex: 1,
      renderCell: (params) => {
        const getPayment = () => {
          const api = params.api;
          const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
          const thisRow = { id: params.row.id }; // doing this because columns does not have an id field

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });

          return JSON.stringify(thisRow, null, 4);
        };

        return (
            <Box display="flex" flexDirection="column">
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                <Button color="warning" onClick={() => handleView(getPayment())} size="small">
                  {t('global.table.view_button')} <RemoveRedEyeOutlinedIcon />
                </Button>
              </ButtonGroup>
            </Box>
        );
      },
    },
    {
      field: "title",
      headerName: t("beneficiaries.table.fullname"),
      cellClassName: "name-column--cell",
      flex: 1,
    },
    {
      field: "intervention",
      headerName: t("intervention.shared.intervention"),
      valueGetter: (params) => params.row?.intervention.title,
      flex: 1,
    },
    {
      field: "package",
      headerName: t("packages.shared.package"),
      valueGetter: (params) => params.row?.package.title,
      flex: 1,
    },
    {
      field: "fields",
      headerName: t("payment.index.fields"),
      valueGetter: (params) => JSON.stringify(params.row?.fields),
      flex: 1
    }
  ];

  const content = isLoading ? (
    <Spinner />
  ) : (
    <Box m="20px">
      <Header
          title={t('payment.index.title')}
          subtitle={t('payment.index.subtitle')}
      />

      <Breadcrumbs separator="›" aria-label="breadcrumb" mb="20px">
        <Link style={LinkColor()} color="inherit" to="/">
          <Typography variant="body2">{t("sidebar.Home")}</Typography>
        </Link>
        <Typography color="text.primary" variant="body2">{t("sidebar.Payments")}</Typography>
        <Typography color="text.primary" variant="body2">{t('payment.view.view_payments')}</Typography>
      </Breadcrumbs>

      <Box display="flex" justifyContent="end" mt="20px">
      <Link to="/payments/create" underline="none">
        <Button color="secondary" variant="contained" size="small">
            {t('payment.index.create_payment')}
        </Button>
        </Link>

      </Box>
      <Box className="data_grid_box_container">
        {payments?.length < 1 ? (
            <Alert severity="info">{t('payment.index.no_payment_data')}</Alert>
        ) : (
          <StyledDataGrid rows={payments} columns={columns} rowsPerPageOptions={default_rows_per_page_options} />
        )}
      </Box>
    </Box>
  );
  return content;
};

export default Payments;
