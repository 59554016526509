import { apiSlice } from "../../api/apiSlice";

export const seasonsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Seasons"],
  endpoints: (builder) => ({
    addSeason: builder.mutation({
      query: (payload) => ({
        url: "/season/add",
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Seasons"],
    }),
    getSeasons: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
          const queryString = new URLSearchParams({
              ...apiFilters,
              page: page,
              pageSize: pageSize
          }).toString();

          return {
              url: `/season/list/${pageSize}?${queryString}`,
              method: "GET",
          };
      },
      providesTags: ["Seasons"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    updateSeason: builder.mutation({
      query: (payload) => ({
        url: `/season/update/${payload.id}`,
        method: "POST",
        body: { ...payload },
      }),
      invalidatesTags: ["Seasons"],
    }),
    deleteSeason: builder.mutation({
      query: (url) => ({
        url: url,
        method: "DELETE",
      }),
      invalidatesTags: ["Seasons"],
    }),
  }),
});

export const {
  useAddSeasonMutation,
  useGetSeasonsQuery,
  useUpdateSeasonMutation,
  useDeleteSeasonMutation,
} = seasonsApiSlice;
