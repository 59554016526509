import { apiSlice } from "../../api/apiSlice";

export const redemptionApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Redemptions", "Voucher", "In-Kind"],
  endpoints: (builder) => ({
    getRedemptions: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/redemption/list/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Redemptions"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getVoucherRedemptions: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/redemption/voucher/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["Voucher"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
    getInkindRedemptions: builder.query({
      query: ({ page, pageSize, ...apiFilters }) => {
        const queryString = new URLSearchParams({
          ...apiFilters,
          page: page,
          pageSize: pageSize,
        }).toString();

        return {
          url: `/redemption/inkind/${pageSize}?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["In-Kind"],
      keepUnusedDataFor: 1,
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const {
  useGetRedemptionsQuery,
  useGetVoucherRedemptionsQuery,
  useGetInkindRedemptionsQuery} = redemptionApiSlice;
